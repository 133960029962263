const { ConstructionComponentHandle } = require('construction-designer-core/construction')
class ShellSegmentHandle extends ConstructionComponentHandle {
  constructor(wallSegment, buildingLevel) {
    super(wallSegment)
    this._buildingLevel = buildingLevel
  }

  wallSegment() { return this.object() }
  buildingLevel() { return this._buildingLevel }
  validWhen() { return this.constructor.VALID_FOR_SELECTED }

  selectableComponent() { return this.buildingLevel() }
}

module.exports = ShellSegmentHandle
