const WallConnection = require('./WallConnection')

class GableWallConnection extends WallConnection {
  height() {
    const heights = this.segments().map(segment => {
      const terminus = this.getTerminus(segment)

      return Math.abs(segment.upperEdge()[terminus]().z()) - Math.abs(segment.edge()[terminus]().z())
    })

    return Math.max(...heights).inches()
  }

  _conformToBuildingLevel(locators) {
    const buildingLevel = this.segments().first() && this.segments().first().buildingLevel()
    if(buildingLevel) {
      return locators.map(locator => buildingLevel.conformToZLevel(locator))
    }
    return locators
  }
}

module.exports = GableWallConnection
