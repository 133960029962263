const STRATEGIES = {
  Custom: () => require('shared/strategies/system/CustomHomeStrategy'),
  Simple: () => require('shared/strategies/system/SimpleShellStrategy')
}

class SystemStrategyService {
  strategyIdentifier() {
    if (!this._strategyIdentifier) {
      this._strategyIdentifier = this.defaultStrategyIdentifier()
    }
    return this._strategyIdentifier
  }
  defaultStrategyIdentifier() { return 'Custom' }
  setStrategyIdentifier(newIdentifier) { this._strategyIdentifier = newIdentifier }

  strategy() {
    const identifier = this.strategyIdentifier()
    const StrategyClass = STRATEGIES[identifier]()
    return new StrategyClass()
  }
}

// NOTE: Exporting an object like this is intentional. This allows for a single
// object with set state that everything can use, whereas exporting the class
// would make every user have to create a new one, and lose the shared state
module.exports = new SystemStrategyService()
