const {
  ThreeMaterialLibrary,
  three: THREE
} = require("construction-designer-core/drawing-editor-3D")

const loadTexture = (name, loader) => {
  // Uses the wood-door textures, just with a different color
  const texture = loader.load(require(`./wood-door-textures/${name}.jpg`))
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping
  texture.repeat.set(0.03, 0.03)

  return texture
}

ThreeMaterialLibrary.instance().register('sgd-frame', textureLoader => (
  new THREE.MeshStandardMaterial({
    roughnessMap: loadTexture('roughness', textureLoader),
    color: 0xf4f4f4
  })
))
