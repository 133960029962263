const {
  Composite,
  Rectangle
} = require('construction-designer-core/geometry')

const {
  extend
} = require('construction-designer-core/standard-utilities')

const {
  Composite3DFigure
} = require('construction-designer-core/drawing-editor-3D')

const {
  CompositeFigure
} = require('construction-designer-core/drawing-editor')

const BuildingLevel = require('./BuildingLevel')
const CompositeFigureXZ = require('shared/drawables/CompositeFigureXZ')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')

class Building extends ShellBuilderConstructionComponent {
  defaultParts() {
    const level = new BuildingLevel()
    level.setComponentOf(this)
    return [level]
  }

  // NOTE: These building levels are assumed to be sorted by height
  levels() {
    return this.components()
  }

  buildingLevelBaseOffset(level) {
    const levelIndex = this.levels().indexOf(level)

    const offset = this.levels().sum((level, index) => {
      if (index >= levelIndex) return 0

      return level.height().toInches()
    })
    return offset.inches()
  }

  defaultFigure() {
    return CompositeFigure.withModel(this)
  }

  defaultFigureXZ() {
    return CompositeFigureXZ.withModel(this)
  }

  threeFigure() {
    return Composite3DFigure.withModel(this)
  }

  moveBy(_x, _y, _z) {
    return false
  }

  boundingShape() {
    return this.boundingBox()
  }

  boundingBox() {
    const parts = this.parts()
    if (parts.length === 0) {
      return new Rectangle(0, 0, 0, 0)
    }

    return this.parts()
               .map(f => f.boundingBox())
               .reduce((merged, box) => merged.merge(box))
  }
}
extend(Building, Composite)

module.exports = Building
