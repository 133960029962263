const React = require('react')
const PropTypes = require('prop-types')
const {
  Icon,
  IconFactoryContext,
} = require('construction-designer-core/drawing-editor-react')
const ShellBuilderIconFactory = require('./ShellBuilderIconFactory')
const classNames = require('classnames')

class EstimationFields extends React.Component {
  static propTypes = {
    factorOptions: PropTypes.array.isRequired,
    existingFactors: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      factors: props.existingFactors
    }
  }

  iconFactory() {
    if (!this._iconFactory) {
      this._iconFactory = new ShellBuilderIconFactory()
    }
    return this._iconFactory
  }

  _availableOptions() {
    const allOptions = this.props.factorOptions
    const usedVariables = this.state.factors.map(factor => factor.variable)

    return allOptions.filter(({ variable }) => !usedVariables.includes(variable))
  }

  _addFactor(event) {
    event.preventDefault()

    const factors = this.state.factors
    factors.push({ variable: this._availableOptions().first().variable, cost: 0 })
    this.setState({ factors })
  }

  _removeFactor(factor) {
    this.setState(state => {
      const factors = state.factors
      factors.remove(factor)

      return factors
    })
  }

  _updateFactor(factor, { variable, cost } = {}) {
    this.setState(state => {
      if (variable !== undefined) factor.variable = variable
      if (cost !== undefined) factor.cost = cost

      return state.factors
    })
  }

  _generateOptionElements() {
    const allOptions = this.props.factorOptions
    const availableOptions = this._availableOptions()

    return allOptions.map(option => {
      const { title, variable } = option
      const disabled = !availableOptions.includes(option)

      return <option key={variable} value={variable} disabled={disabled}>{title}</option>
    })
  }

  _renderFactor(factor, deletable, index) {
    const { cost: costError } = factor.errors || {}

    return (
      <div key={factor.variable} className="flex full-width margin-bottom-sm">
        <div className="field_without_errors flex-grow-2 flex-basis-0">
          <select
            name="project_estimation[multipliers][][variable]"
            id={`variableSelect-${index}`}
            data-testid="variableSelect"
            value={factor.variable || ''}
            onChange={event => this._updateFactor(factor, { variable: event.target.value })}
            className="required form__input width-90"
          >
            {this._generateOptionElements()}
          </select>
        </div>

        <div className="field_without_errors flex-grow flex-basis-0">
          <div className="flex items-center input--money">
            <input
              type="number"
              name="project_estimation[multipliers][][cost]"
              id={`costInput-${index}`}
              data-testid="costInput"
              value={factor.cost || ''}
              onChange={event => this._updateFactor(factor, { cost: event.target.value })}
              className="required form__input"
            />
            {deletable &&
              <Icon
                name="trash"
                onClick={() => this._removeFactor(factor)}
                className="btn__icon margin-left-sm form__remove"
              />
            }
          </div>

          {costError ? <span className="error">{costError}</span> : undefined}
        </div>
      </div>
    )
  }

  render() {
    const disabled = this._availableOptions().length === 0
    const factors = this.state.factors
    // Factors should only be deletable if there's more than one
    const factorsDeleteable = factors.length > 1

    return (
      <IconFactoryContext.Provider value={this.iconFactory()}>
        {factors.map((factor, index) => this._renderFactor(factor, factorsDeleteable, index))}

        <button
          disabled={disabled}
          className={`btn btn--outline form__row-add ${classNames({ 'btn--disabled': disabled})}`}
          onClick={event => this._addFactor(event)}
        >
          <Icon name="add" className="btn__icon" />
          &nbsp;Add Factor
        </button>
      </IconFactoryContext.Provider>
    )
  }
}

module.exports = EstimationFields
