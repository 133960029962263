const { ConstructionComponentHandle } = require('construction-designer-core/construction')
const { ClosedShapeFigure } = require('construction-designer-core/drawing-editor')

class WindowFigure extends ClosedShapeFigure {
  constructor(model, systemStrategy) {
    super()
    this._model = model
    this._systemStrategy = systemStrategy
  }

  model() {
    return this._model
  }

  edge() {
    return this.model().edge()
  }

  shape() {
    return this.edge()
  }

  layer() { return 'Windows' }
  zLevel() { return this.edge().center().z() }
  systemStrategy() { return this._systemStrategy }

  _draw(context, _options) {
    this.systemStrategy().drawWindow(context, this.model())
  }

  defaultProperties() {
    return this.systemStrategy().configOptions()['window']['visualProperties']
  }

  selfHandle() {
    return new ConstructionComponentHandle(this.model())
  }
}

module.exports = WindowFigure
