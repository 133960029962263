class ProjectEstimation {
  constructor(id, title, multipliers) {
    this._id = id
    this._title = title
    this._multipliers = multipliers
  }

  id() { return this._id }
  title() { return this._title }
  multipliers() {
    if(!this._multipliers) {
      this._multipliers = []
    }
    return this._multipliers
  }

  estimate(project) {
    // NOTE: This currently makes the assumption that each multiplier variable
    // will be in sqft - once we add more types, we'll need a way to specifiy
    // what the unit is
    return this.multipliers().sum(multiplier => {
      // This is the value of the multiplier, in its units (sqft, ft, or just a number)
      const value = project[multiplier.variable]()
      // Convert the value to a number (3 sqft => 3, 5 ft => 5, 12 => 12)
      const rawNumber = value.toNumber?.() ?? value
      return rawNumber * multiplier.cost
    })
  }

  formattedEstimate(project) {
    return this._formatMoney(this.estimate(project))
  }

  _formatMoney(number) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })

    return formatter.format(number)
  }
}
module.exports = ProjectEstimation
