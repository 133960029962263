const React = require('react')
const PropTypes = require('prop-types')

class RadioSelect extends React.Component {
  static propTypes = {
    getProperty: PropTypes.func.isRequired,
    setProperty: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    wrapperClassName: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedValue: props.getProperty().toString()
    }
  }

  _setProperty(newValue) {
    this.props.setProperty(newValue)
    this.setState({ selectedValue: this.props.getProperty().toString() })
  }

  _renderLabel() {
    const label = this.props.label
    if (!label) return ''

    return <legend className="radio-select__label label--side-panel">{label}:</legend>
  }

  /**
   * @param {Object} option An option object with "value" and "label" keys
   * @param {Boolean} selected Whether this option is selected or not
   */
  _renderOption(option, selected) {
    return (
      <label key={option.value} className={selected ? 'selected' : ''} title={option.label}>
        <input
          type="radio"
          value={option.value}
          className="hidden"
          selected={selected}
          onClick={() => this._setProperty(option.value)}
        />
        {option.label}
      </label>
    )
  }

  render() {
    const { selectedValue } = this.state
    const { options } = this.props
    const currentOption = options.find(option => selectedValue === option.value.toString())

    return (
      <fieldset className={`radio-select ${this.props.wrapperClassName}`}>
        {this._renderLabel()}
        <div className="radio-select__options flex flex-wrap justify-around">
          {options.map(option => {
            const selected = currentOption === option
            return this._renderOption(option, selected)
          })}
        </div>
      </fieldset>
    )
  }
}
module.exports = RadioSelect
