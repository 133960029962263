const DoorFigure = require('./DoorFigure')

class SlidingGlassDoorFigure extends DoorFigure {
  _draw(context) {
    const windowEdge = this.model().windowEdge()
    context._drawLine(windowEdge.begin(), windowEdge.end())

    const doorEdge = this.model().doorEdge()
    context._drawLine(doorEdge.begin(), doorEdge.end())
  }
}

module.exports = SlidingGlassDoorFigure
