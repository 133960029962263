const React = require('react')
const { createBrowserHistory } = require('history')
const PropTypes = require('prop-types')

const {
  MultiPerspectiveProjectEditorView,
  Icon,
  IconFactoryContext,
  ModalRootContext,
  'react-router-dom': { Router }
} = require('construction-designer-core/drawing-editor-react')

const { ApiClient } = require('construction-designer-core/drawing-editor')
const {
  Drawing3DEditor,
  PhotorealisticDrawing3DController,
  three: THREE
} = require('construction-designer-core/drawing-editor-3D')
const BuildingLevelDrawingEditor = require('../shared/editor-models/BuildingLevelDrawingEditor')
const ShellBuilderProjectRepository = require('../shared/editor-models/ShellBuilderProjectRepository')
const ToolsPanel = require('components/ToolsPanel')
const ShellBuilderIconFactory = require('./ShellBuilderIconFactory')
const PropertyPanel = require('./PropertyPanel')
const RoofPanel = require('shared/domain-models/RoofPanel')
const RoofPanelPropertyPanel = require('./property-panels/RoofPanelPropertyPanel')
const ShellBuilderActionBar = require('./ShellBuilderActionBar')

const SystemStrategyService = require('shared/helpers/SystemStrategyService')
const ProjectModeStrategy = require('./editor-strategies/ProjectModeStrategy')
const QuoteModeStrategy = require('./editor-strategies/QuoteModeStrategy')
const ReadOnlyModeStrategy = require('./editor-strategies/ReadOnlyModeStrategy')
const GuestModeStrategy = require('./editor-strategies/GuestModeStrategy')

const MODE_STRATEGY_CLASSES = {
  project: ProjectModeStrategy,
  quote: QuoteModeStrategy,
  readOnly: ReadOnlyModeStrategy,
  guest: GuestModeStrategy
}

class App extends React.Component {
  static propTypes = {
    mode: PropTypes.string.isRequired,
    projectsPath: PropTypes.string.isRequired,
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    basePath: PropTypes.string,
    homePath: PropTypes.string,
    strategyIdentifier: PropTypes.string,
    signedIn: PropTypes.bool
  }

  static defaultProps = {
    basePath: '/',
    homePath: '/projects',
    signedIn: false
  }

  constructor(props) {
    super(props)
    this._modalRoot = document.getElementById('modal_root') || document.createElement('div')

    SystemStrategyService.setStrategyIdentifier(props.strategyIdentifier)

    this.state = {
      loaded: false,
      signedIn: this.props.signedIn
    }
  }

  componentDidMount() {
    this._loadProject()
  }

  onSignIn() {
    this.setState({ signedIn: true })
  }

  async _loadProject() {
    const projectId = this.props.projectId
    await this.projectRepository().loadFromServer(projectId)

    this._project = this.projectRepository().project()

    this._configureDrawingEditors()
    this.setState({ loaded: true })
  }

  _configureDrawingEditors()  {
    const project = this._project

    this._drawingEditors = {}

    project.buildingLevels().forEach(level => {
      const editor = new BuildingLevelDrawingEditor(project, level, this.editorModeStrategy())
      editor.zoomPalette().setActiveZoomLevel('1x')

      this._drawingEditors[level.name()] = editor
    })

    const drawing3DEditor = new Drawing3DEditor(project, PhotorealisticDrawing3DController)
    // The tool palette is lazy-initialized, so this verifies that it'll always
    // be created, regardless of if there's a tool panel
    drawing3DEditor.toolPalette()
    this._drawingEditors['3d'] = drawing3DEditor

    const drawing3DController = drawing3DEditor.drawingController()
    drawing3DController.setEnvironmentTextureUrl('/environment.hdr')
    drawing3DController.setRendererOutputEncoding(THREE.LinearEncoding)
    drawing3DController.setImageBasedLighting(false)
    drawing3DController.layerManager().setLayers({})
  }

  modalRoot() { return this._modalRoot }

  history() {
    if (!this._history) {
      this._history = createBrowserHistory({ basename: this.props.basePath })
    }
    return this._history
  }

  apiClient() {
    if (!this._apiClient) {
      this._apiClient = new ApiClient()
    }
    return this._apiClient
  }

  iconFactory() {
    if (!this._iconFactory) {
      this._iconFactory = new ShellBuilderIconFactory()
    }
    return this._iconFactory
  }

  editorModeStrategy() {
    if (!this._editorModeStrategy) {
      const StrategyClass = MODE_STRATEGY_CLASSES[this.props.mode]
      this._editorModeStrategy = new StrategyClass(this)
    }
    return this._editorModeStrategy
  }

  projectRepository() {
    if (!this._projectRepository) {
      const modeStrategy = this.editorModeStrategy()
      this._projectRepository = new ShellBuilderProjectRepository()
      this._projectRepository.setProjectsPath(this.props.projectsPath)

      if (!modeStrategy.autosaveable()) this._projectRepository.disableAutosave()
    }
    return this._projectRepository
  }

  async _homeLinkClicked(event) {
    event.preventDefault()
    const href = event.currentTarget.href

    let shouldRedirect = true
    if (this.editorModeStrategy().autosaveable()) {
      const repository = this.projectRepository()
      if (!repository.project().isValid() && repository.isDirty()) {
        shouldRedirect = window.confirm(
          'Redirecting to the main page will save this project, which is currently ' +
          'marked as being in an invalid state. Are you sure you want to continue? ' +
          "(If everything seems to work, it's probably fine to continue)"
        )
      }

      if (shouldRedirect) { await repository.updateOnServer() }
    }

    if (shouldRedirect) window.location.assign(href)
  }

  render() {
    if (!this.state.loaded) {
      return <div>Loading...</div>
    }

    const basePerspective = Object.keys(this._drawingEditors).first()
    const propertyPanelLookup = new Map([
      [RoofPanel, RoofPanelPropertyPanel]
    ])

    const editorModeStrategy = this.editorModeStrategy()

    return (
      <ModalRootContext.Provider value={this.modalRoot()}>
      <Router history={this.history()}>
        <IconFactoryContext.Provider value={this.iconFactory()}>
          <MultiPerspectiveProjectEditorView
            drawingEditors={this._drawingEditors}
            versionedProject={this._project}
            projectRepository={this.projectRepository()}
            defaultPerspective={basePerspective}
            perspectiveOptions={
              {
                default: {
                  actionBarOptions: {
                    renderBackLink: () => (
                      <a
                        href={this.props.homePath}
                        title="Home"
                        className="action-bar__home-link"
                        onClick={this._homeLinkClicked.bind(this)}
                      >
                        <Icon name="home" />
                      </a>
                    ),
                    includePerspectiveIcons: false,
                    renderSaveButton: repository => editorModeStrategy.renderSaveButton(repository),
                    includeUndoRedo: false
                  },
                  leftSidePanel: editorModeStrategy.showToolPanel(),
                  rightSidePanel: editorModeStrategy.showPropertyPanel(),
                  renderLeftSidePanel: drawingEditor => (
                    <ToolsPanel
                      drawingController={drawingEditor.drawingController()}
                      toolPalette={drawingEditor.toolPalette()}
                    />
                  ),
                  renderRightSidePanel: drawingEditor => (
                    <PropertyPanel
                      drawingController={drawingEditor.drawingController()}
                      propertyPanelLookup={propertyPanelLookup}
                    />
                  ),
                  renderActionBar: props => <ShellBuilderActionBar {...props} editorModeStrategy={editorModeStrategy} />
                }
              }
            }
          />
        </IconFactoryContext.Provider>
      </Router>
      </ModalRootContext.Provider>
    )
  }
}
module.exports = App
