const { LinearMeasurement, ProportionalEdgeLocator } = require('construction-designer-core/geometry')
const { StableID } = require('construction-designer-core/drawing-editor')
const { Point: { $P } } = require('construction-designer-core/geometry')
const { extend } = require('construction-designer-core/standard-utilities')

const EditableProperty = require('./EditableProperty')
const SimpleShellBuildingLevelFigure = require('shared/drawables/SimpleShellBuildingLevelFigure')
const BuildingLevel = require('./BuildingLevel')
const CenterlineWallSegment = require('./CenterlineWallSegment')
const CenterlineWallAndSurfaceBuilder = require('shared/operations/CenterlineWallAndSurfaceBuilder')
const math = require('mathjs')
const ReadableProperty = require('./ReadableProperty')
const Window = require('./Window')
const Doorway = require('./Doorway')
const SwingingDoor = require('./SwingingDoor')

class SimpleShellBuildingLevel extends BuildingLevel {
  name() { return this._name || 'Shell' }

  defaultDisplayProperties() {
    return [
      new EditableProperty(this, 'Width', {
        type: 'unit',
        defaultUnit: 'ft',
        set: value => this.setSpecifiedWidth(value),
        ignoreIntermediateEdits: true
      }),
      new EditableProperty(this, 'Length', {
        type: 'unit',
        defaultUnit: 'ft',
        set: value => this.setSpecifiedLength(value),
        ignoreIntermediateEdits: true
      }),
      new EditableProperty(this, 'Wall Height', { type: 'unit', defaultUnit: 'ft', label: 'Ceiling Height' }),
      new ReadableProperty(this, 'Finished Floor Area', { type: 'unit', defaultUnit: 'sqft' }),
      new ReadableProperty(this, 'Unfinished Floor Area', { type: 'unit', defaultUnit: 'sqft' })
    ]
  }

  postRestorationAction() {
    this.exteriorWallSegments().forEach(segment => (
      segment.addSegmentMoveObserver(this, this._refreshDisplayProperties)
    ))
  }

  selectable() { return true }

  // NOTE: These should only be set from the server
  rawWidth() { return parseFloat(this._rawWidth) || 25 }
  rawLength() { return parseFloat(this._rawLength) || 20 }
  rawWallHeight() { return parseFloat(this._rawWallHeight) || 8 }
  unitString() { return this._unitString || 'feet' }

  width() {
    return this.boundingBox().width().inches()
  }

  /**
  * @return {Unit}
  */
  specifiedWidth() {
    if (!this._specifiedWidth) {
      this._specifiedWidth = math.unit(this.rawWidth(), this.unitString())
    }
    return this._specifiedWidth
  }
  setSpecifiedWidth(newWidth) {
    this._setMeasurementProperty('_specifiedWidth', newWidth)
  }

  length() {
    return this.boundingBox().height().inches()
  }

  /**
   * @return {Unit}
   */
  specifiedLength() {
    if (!this._specifiedLength) {
      this._specifiedLength = math.unit(this.rawLength(), this.unitString())
    }
    return this._specifiedLength
  }
  setSpecifiedLength(newLength) {
    this._setMeasurementProperty('_specifiedLength', newLength)
  }

  /**
   * @return {Unit}
   */
  wallHeight() {
    if (!this._wallHeight) {
      this._wallHeight = this.defaultWallHeight()
    }
    return this._wallHeight
  }
  setWallHeight(newHeight) {
    this._setMeasurementProperty('_wallHeight', newHeight)
  }
  defaultWallHeight() { return math.unit(this.rawWallHeight(), this.unitString()) }

  height() {
    return math.add(this.wallHeight(), this.floorThickness())
  }

  _setMeasurementProperty(propertyName, value) {
    let propertySet = false

    if (value.scalar) {
      this[propertyName] = value
      propertySet = true
    } else if (parseFloat(value)) {
      this[propertyName] = LinearMeasurement.fromString(value)
      propertySet = true
    }

    if (propertySet) this._generateSegmentLoop()
  }

  exteriorWallSegments() {
    if (!this._exteriorWallSegments) {
      this._generateSegmentLoop()
    }
    return this._exteriorWallSegments
  }

  exteriorWallSurfaces() {
    if (!this._exteriorWallSurfaces) {
      this._generateSegmentLoop()
    }
    return this._exteriorWallSurfaces
  }

  // NOTE: This isn't entirely needed now, but will be when we can have more
  // than 4 exterior walls
  /**
   * @return {CenterlineWallSegment[]} An array of the 4 outer segments, which
   * should **always** be a subset of the exterior wall segments
   */
  shellSegments() {
    if (!this._shellSegments) {
      this._shellSegments = []
    }
    return this._shellSegments
  }

  _generateSegmentLoop() {
    const thickness = CenterlineWallSegment.defaultThickness()
    const width = this.specifiedWidth().toInches()
    const length = this.specifiedLength().toInches()

    this._exteriorWallSegments = []

    // If we already have shell segments, modify those instead of creating new
    // ones. This allows for openings to remain constant, and not be
    // re-generated
    if (this.shellSegments().length > 0) {
      this._modifyExistingSegments(width, length)
      this._exteriorWallSegments = this.shellSegments().slice()
    } else {
      this._exteriorWallSurfaces = []

      this._createNewSegments(width, length, thickness)
      this._shellSegments = this._exteriorWallSegments.slice()
    }
  }

  /**
   * Modifies existing segments to match the given centerlines, while keeping
   * segment properties such as openings, thickness, and height
   *
   * @param {number} width The exterior width of the shell, as a number
   * @param {number} length The exterior length of the shell, as a number
   */
  _modifyExistingSegments(width, length) {
    const shellSegments = this.shellSegments()

    const boundingBox = shellSegments.reduce((mergedBox, segment) => (
      mergedBox.merge(segment.boundingBox())
    ), shellSegments.first().boundingBox())

    const currentWidth = boundingBox.width()
    const currentLength = boundingBox.height()
    // We want to move each wall segment only half of the needed distance, so
    // that the center of the shell remains the same
    const widthDelta = (width - currentWidth) / 2
    const lengthDelta = (length - currentLength) / 2

    shellSegments.forEach(shellSegment => {
      const normal = shellSegment.edge().normal()
      // If parallel to the x-axis, use the widthDelta. Otherwise, use the length delta
      const distance = normal.theta() === 0 || normal.theta() === Math.PI ? widthDelta : lengthDelta
      const movement = normal.multipliedBy(distance)

      shellSegment.moveBy(movement.x(), movement.y(), movement.z())
    })
  }

  /**
   * Creates new segments, surfaces, and openings given a set of 4 centerlines
   *
   * @param {number} width The intended exterior width of the shell
   * @param {number} length The intended exterior length of the shell, as a number
   * @param {Unit} segmentThickness The thickness of the new segments
   */
  _createNewSegments(width, length, segmentThickness) {
    const centerlineWidth = width - segmentThickness.toInches()
    const centerlineLength = length - segmentThickness.toInches()

    const center = $P(0, 0)
    const topLeft = center.add(-centerlineWidth / 2, -centerlineLength / 2)
    const topRight = center.add(centerlineWidth / 2, -centerlineLength / 2)
    const bottomRight = center.add(centerlineWidth / 2, centerlineLength / 2)
    const bottomLeft = center.add(-centerlineWidth / 2, centerlineLength / 2)

    const centerlines = [
      topLeft.to(topRight.snapshot()),
      topRight.to(bottomRight.snapshot()),
      bottomRight.to(bottomLeft.snapshot()),
      bottomLeft.to(topLeft.snapshot())
    ]

    centerlines.forEach((centerline, index) => {
      const builder = new CenterlineWallAndSurfaceBuilder(this, centerline)
      builder.setThickness(segmentThickness)
      builder.setWallType(CenterlineWallSegment.TYPES.Exterior)

      const { segments } = builder.process()
      // In this basic scenario, there should only ever be one
      const segment = segments.first()

      segment.setHeight(this.wallHeight())
      const withDoorway = index % 2 === 0
      this._generateOpeningsForSegment(segment, withDoorway)
      segment.addSegmentMoveObserver(this, this._refreshDisplayProperties)
    })
  }

  _generateOpeningsForSegment(segment, withDoorway = false) {
    const edge = segment.exteriorEdge()
    const edgeDirection = edge.vector().normalized()
    const totalOpenings = 2
    // We want to space the openings out equally, but have them closer to the ends of the wall than
    // the center. So, if we have 2 openings, we'll divide the segment into fourths (2 * 2).
    // Then, when  we place the openings, we put them at the 1/4 and 3/4 marks.
    const segmentDivisions = totalOpenings * 2

    for (let index = 0; index < totalOpenings; index++) {
      let OpeningType
      let openingWidth
      // If we wanted to place a doorway, then only do it the first slot
      if (withDoorway && index === 0) {
        OpeningType = Doorway
        openingWidth = (36).inches()
      } else {
        OpeningType = Window
        openingWidth = OpeningType.defaultWidth()
      }

      // To place the openings correctly, we get the index, multiply it by 2, and then add 1. This makes it
      // so that the first opening (index 0), is placed at the 1/4 mark ((0 * 2) + 1 = 1). However, this also
      // allows the second opening (index 1) to be placed at the 3/4 mark ((1 * 2) + 1 = 3).

      // NOTE: This logic will stand for any number of segment divisions
      const percentageAlongEdge = ((index * 2) + 1) / segmentDivisions

      const openingCenter = new ProportionalEdgeLocator(edge, percentageAlongEdge)
      const opening = OpeningType.fromLocator(openingCenter, edgeDirection, openingWidth)
      if (OpeningType === Doorway) {
        opening.setDoorType(SwingingDoor.displayClassName())
      }
      segment.addOpening(opening)
    }
  }

  _refreshDisplayProperties() {
    this.displayProperties().forEach(property => property.update())
  }

  // endregion statistics

  defaultFigure() {
    return SimpleShellBuildingLevelFigure.withModel(this)
  }

  shape() { return this.boundingBox().toPolygon() }
  vertices() { return this.shape().vertices() }

  contains(x, y) {
    return this.shape().contains(x, y)
  }
}

extend(SimpleShellBuildingLevel, StableID)
module.exports = SimpleShellBuildingLevel
