const {
  CompositeFigure
} = require('construction-designer-core/drawing-editor')
const SystemStrategyService = require('shared/helpers/SystemStrategyService')

class WallSegmentFigure extends CompositeFigure {
  zLevel() { return this.model().zLevel() }

  layer() { return 'Walls' }

  _drawSelf(_context, _options) {
    // Do nothing
  }

  systemStrategy() {
    if (!this._systemStrategy) {
      this._systemStrategy = SystemStrategyService.strategy()
    }
    return this._systemStrategy
  }

  selfHandle() {
    return this.systemStrategy().segmentSelfHandle(this.model(), this.model().buildingLevel())
  }

  boundingBox() {
    return this.model().boundingBox()
  }

  vertices() {
    return this.model().vertices()
  }

  contains(x, y) {
    return this.model().contains(x, y)
  }

  moveBy(x, y) {
    return this.model().moveBy(x, y)
  }

  // TODO: handles need to be figured out with this approach, unclear how to proceed.

  defaultProperties() {
    return {
      strokeStyle: '#00000000',
      fillStyle: '#CFCFCF88'
    }
  }
}
module.exports = WallSegmentFigure
