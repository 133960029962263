const { Drawable } = require('construction-designer-core/drawing-editor')

class DoorFigure extends Drawable {
  constructor(door, systemStrategy) {
    super()
    this.setModel(door)
    this._systemStrategy = systemStrategy
  }

  boundingBox() {
    return this.model().boundingBox()
  }

  defaultProperties() {
    return this.systemStrategy().configOptions()['door']['visualProperties']
  }

  systemStrategy() { return this._systemStrategy }

  _draw(context, _options) {
    context._drawLine(this.model().edge().begin(), this.model().edge().end())
  }

  layer() { return 'Doors' }
  contains() { return false }
  _moveBy() { return false }

  defaultHandles() {
    return []
  }
}

module.exports = DoorFigure
