const PropTypes = require('prop-types')
const React = require('react')
const { 'react-router-dom': { Link } } = require('construction-designer-core/drawing-editor-react')
const FormFromServer = require('./FormFromServer')

class SignUpForm extends React.Component {
  static propTypes = {
    onSignIn: PropTypes.func.isRequired,
    signInUrl: PropTypes.string
  }

  onSuccessfulSignUp(doc) {
    const updatedCSRFToken = doc.querySelector('meta[name="csrf-token"]')?.content
    const metaTag = document.querySelector('meta[name="csrf-token"]')
    if (metaTag) metaTag.content = updatedCSRFToken
    this.props.onSignIn()
  }

  renderSignInLink() {
    return (
      <>
        <hr className="margin-top-md"></hr>
        <div className="margin-top-md flex justify-center">
          <span className="margin-right-xs">Already have an account?</span>
          <Link to={this.props.signInUrl} className="link">Sign In</Link>
        </div>
      </>
    )
  }

  render() {
    return (
      <FormFromServer
        onSuccess={this.onSuccessfulSignUp.bind(this)}
        url="/users/sign_up"
        renderExtraElements={this.renderSignInLink.bind(this)}
        renderHeader={() => <>
          <h2 className="margin-none">Sign Up</h2>
          <hr className="full-width"></hr>
        </>}
      />
    )
  }
}

module.exports = SignUpForm
