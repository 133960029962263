const {
  DrawingController,
  KeyboardShortcutManager
} = require('construction-designer-core/drawing-editor')
const FloorPlanDrawing = require('../drawables/FloorPlanDrawing')

// TODO: Revisit how this interacts with BuildingLevelDrawingEditor
class BuildingLevelDrawingController extends DrawingController {
  buildingLevel() { return this._buildingLevel }
  setBuildingLevel(newLevel) {
    this._buildingLevel = newLevel
  }

  activate() {
    super.activate()
    this._active = true
  }

  deactivate() {
    super.deactivate()
    this._active = false
  }

  active() { return this._active }

  defaultLayerManager() {
    const manager = super.defaultLayerManager()
    manager.setLayers({
      'Walls': true,
      'Roof Panels': true,
      'Doors': true,
      'Windows': true,
      'Annotations': true
    })
    return manager
  }

  _onVersionReplaced() {
    const id = this.buildingLevel().stableID()

    const building = this.project().building()
    const newBuildingLevel = building.levels().find(part => part.stableID() === id)
    this.setBuildingLevel(newBuildingLevel)

    if (this._drawing) { // Don't create drawing if it doesn't exist
      this._drawing = undefined
      if (this.active()) { this.redraw() }
    }

    this._tryToRestoreSelection()
  }

  _onSelectionChanged() {
    this.notifySelectionChangedObservers()
    // don't create a drawing, but redraw it if it exists already
    if (this._drawing && this.active()) {
      this.redraw()
    }
  }

  drawing() {
    if (!this._drawing) {
      const figures = [
        this.buildingLevel().floorPlan(), this.buildingLevel()
      ].filter(item => item).map(item => item.defaultFigure())

      this._drawing = FloorPlanDrawing.withFigures(figures)
    }
    return this._drawing
  }

  defaultShortcutManager() {
    const manager = new KeyboardShortcutManager()
    manager.unhandledRejectionHandler = this.handleKeyboardEventError.bind(this)
    return manager
  }

  handleKeyboardEventError(_keyboardEvent, _shortcutEvent, error) {
    this.project().setValid(false)
    console.error(error)
  }

  handleMouseEvent(...args) {
    try {
      super.handleMouseEvent(...args)
    } catch (error) {
      this.project().setValid(false)
      console.error(error)
    }
  }
}

module.exports = BuildingLevelDrawingController
