const DoorwayTool = require('shared/tools/DoorwayTool')
const ExteriorWallSegmentTool = require('shared/tools/ExteriorWallSegmentTool')
const OpeningTool = require('shared/tools/OpeningTool')
const RoofTool = require('shared/tools/RoofTool')
const RoomFinderTool = require('shared/tools/RoomFinderTool')
const WallColumnTool = require('shared/tools/WallColumnTool')
const WallSurfaceTool = require('shared/tools/WallSurfaceTool')
const SystemStrategy = require('../SystemStrategy')
const RoofSurfaceTool = require('shared/tools/GableSurfaceTool')

const PlaceWindowTool = require('shared/tools/PlaceWindowTool')
const PlaceDoorwayTool = require('shared/tools/PlaceDoorwayTool')
const PlaceWallTool = require('shared/tools/PlaceWallTool')
const { ConstructionComponentHandle } = require('construction-designer-core/construction')

class CustomHomeStrategy extends SystemStrategy {
  defaultTools(drawingEditor) {
    const controller = drawingEditor.drawingController()
    const zoomPalette = drawingEditor.zoomPalette()

    if (drawingEditor.project().isTemplate()) {
      return [
        new ExteriorWallSegmentTool(controller, zoomPalette),
        new WallSurfaceTool(controller, zoomPalette),
        new RoomFinderTool(controller, zoomPalette),
        new OpeningTool(controller, zoomPalette),
        new DoorwayTool(controller, zoomPalette),
        new WallColumnTool(controller, zoomPalette),
        // new FloorOpeningTool(controller, zoomPalette),
        // new StairsTool(controller, zoomPalette),
        new RoofTool(controller, zoomPalette),
        new RoofSurfaceTool(controller, zoomPalette)
      ]
    } else {
      return [
        new PlaceWindowTool(controller, zoomPalette),
        new PlaceDoorwayTool(controller, zoomPalette),
        new PlaceWallTool(controller, zoomPalette)
      ]
    }
  }

  configOptions() {
    return {
      door: {
        visualProperties: {
          strokeStyle: '#000'
        }
      },
      doorway: {
        visualProperties: {
          strokeStyle: '#F5EA25',
          fillStyle: '#FFF86E44'
        }
      },
      window: {
        visualProperties: {
          strokeStyle: 'rgba(102,178,255,1.0)',
          fillStyle: '#B3D9FF44'
        }
      },
      wallSurface: {
        selectable: true
      }
    }
  }

  supportsQuotes() { return false }

  segmentSelfHandle(segment) {
    return new ConstructionComponentHandle(segment)
  }

  drawWindow(context, window) { context._drawPolygon(window.footprint()) }
}

module.exports = CustomHomeStrategy
