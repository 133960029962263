const Room = require('./Room')
const ExteriorFloor = require('./ExteriorFloor')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')
const EditableProperty = require('./EditableProperty')
const ReadableProperty = require('./ReadableProperty')
const Label = require('./Label')

class ExteriorSpace extends Room {
  constructor(shape) {
    super()
    this._shape = shape
  }

  shape() { return this._shape }
  edges() { return this.shape().edges() }
  availableMaterialNames() { return [] }
  floorEdges() { return this.edges() }
  zLevel() { return this.buildingLevel().zLevel() }
  floorLevel() { return this.buildingLevel().floorLevel() }

  defaultDisplayProperties() {
    return [
      new EditableProperty(this, 'Name', { type: 'text', placeholder: 'Exterior Space' }),
      new EditableProperty(this, 'Label Font Size', { type: 'number' }),
      new ReadableProperty(this, 'Area', { type: 'unit', defaultUnit: 'sqft' })
    ]
  }

  floor() {
    if (!this._floor) {
      this._floor = new ExteriorFloor()
      this._floor.setComponentOf(this)
    }
    return this._floor
  }

  label() {
    if (this.edges().length < 2) return

    if (!this._label) {
      const position = this.shape().centroid()
      const fontSize = this.labelFontSize()
      this._label = new Label(position, this.name(), 0, 'center', fontSize)
    }
    return this._label
  }


  components() {
    return [this.label(), this.floor()].filter(item => item)
  }

  delete() {
    this.floor().setComponentOf(undefined)
    this.buildingLevel() && this.buildingLevel().removeExteriorSpace(this)
  }

  nonEssentialProperties() {
    return [
      ...ShellBuilderConstructionComponent.prototype.nonEssentialProperties(),
      '_label'
    ]
  }
}

module.exports = ExteriorSpace
