const WallSegmentTool = require('./WallSegmentTool')
const GableSurfaceFinder = require('shared/operations/GableSurfaceFinder')

class GableSurfaceTool extends WallSegmentTool {
  displayName() { return 'Gable Surface' }
  iconName() { return 'gable-surface' }

  _existingWallEndpoints() {
    return this.buildingLevel().gableWallSurfaces().flatMap(surface => [surface.edge().begin(), surface.edge().end()])
  }

  _maximumThickness() { return (1).feet() }
  _defaultThickness() { return this.project().defaultExteriorWallThickness() }

  constrainedToSnapPoints() { return true }
  snapPoints() {
    const segmentEndpoints = this.buildingLevel().gableWallSegments().flatMap(segment => segment.vertices())
    const connectionEndpoints = this.buildingLevel().gableWallConnections().flatMap(connection => connection.vertices())
    return [...segmentEndpoints, ...connectionEndpoints].map(vertex => vertex.xy())
  }
  planEdges() {
    return this.buildingLevel().gableWallSegments().flatMap(segment => segment.footprint().xy().edges())
  }

  finder() {
    if (!this._finder) {
      this._finder = new GableSurfaceFinder(
        this.project(),
        this.buildingLevel(),
        this._defaultThickness()
      )
    }
    return this._finder
  }

  _resetCachedPoints() {
    super._resetCachedPoints()
    // TODO: Implement some smartness to figure out when to wipe the finder,
    // instead of just deleting it every time reset is called
    this._finder = undefined
  }

  _addWallEdge(edgeAndMetadata) {
    const { edge } = edgeAndMetadata

    const allEdges = this.planEdges()
    const { surfaces } = this.finder().find(edge, allEdges)

    // There's only ever one surface returned
    this._controller().setSelectedComponent(surfaces.first())
    this._controller().snapshotProject()
  }
}

module.exports = GableSurfaceTool
