const {
  ClosedShapeFigure
} = require('construction-designer-core/drawing-editor')

class DrawnArcFigure extends ClosedShapeFigure {
  constructor(model, perspective = 'xy') {
    super()
    this._model = model
    this._arc = model.shape()
    this._perspective = perspective
  }

  zLevel() { return 0 }

  model() { return this._model }

  shape() {
    return this._arc
  }

  snapPoint() {
    return this.shape().snapPoint()
  };

  _draw(context) {
    context.beginPath()
    const locator = this._arc.locator()[this._perspective]()
    context.arc(locator.x(), locator.y(), this._arc.radius(), this._arc.begin(), this._arc.end())
    context.moveTo(locator.x(), locator.y())
    context.closePath()
    context.stroke()
  }

  defaultProperties() {
    return {
      strokeStyle: '#0077CE'
    }
  }

  defaultHandles() { return [] }
}

module.exports = DrawnArcFigure
