const {
  ThreeMaterialLibrary,
  three: THREE
} = require("construction-designer-core/drawing-editor-3D")

const loadTexture = (name, loader) => {
  const texture = loader.load(require(`./garage-door-textures/${name}.jpg`))
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping
  texture.repeat.set(1, 0.015)

  return texture
}

ThreeMaterialLibrary.instance().register('garage-door', textureLoader => {
  return new THREE.MeshStandardMaterial({
    color: 0xf4f4f4,
    normalMap: loadTexture('normal', textureLoader),
  })
})
