const React = require('react')
const PropTypes = require('prop-types')
const { TextInput } = require('construction-designer-core/drawing-editor-react')

const getKey = property => `string-key-${property.get().split(' ').join('-').toString()}`

class TextPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'text'

  constructor(props) {
    super(props)
    this.state = { key: getKey(props.property) }
  }

  _updateProperty(value) {
    this.props.property.set(value)
    this.props.drawingController.redraw()
  }

  _saveProperty(value) {
    this._updateProperty(value)
    this.props.drawingController.snapshotProjectAndRedraw()
    this.setState({ key: getKey(this.props.property) })
  }

  _renderTextInput() {
    const property = this.props.property

    return (
      <>
        <label className="label--side-panel margin-right-xs" htmlFor={property.key()}>
          {property.label()}:
        </label>
        <TextInput
          key={this.state.key}
          id={property.key()}
          name={property.key()}
          value={property.get()}
          placeholder={property.options().placeholder}
          onChange={this._updateProperty.bind(this)}
          onSave={this._saveProperty.bind(this)}
          classes="input--side-panel full-width"
          disabled={property.disabled()}
        />
      </>
    )
  }

  _renderPropertyValue() {
    return <>
      <span className="label--side-panel margin-right-xs">
        {this.props.property.label()}:
      </span>
      <span className="font-sm">{this.props.property.get()}</span>
    </>
  }

  render() {
    const property = this.props.property
    const renderFunction = property.editable() ? this._renderTextInput.bind(this) : this._renderPropertyValue.bind(this)

    return (
      <div className="side-panel__row">
        <div className="full-width flex items-center justify-between">
          {renderFunction()}
        </div>
      </div>
    )
  }
}

module.exports = TextPropertyView
