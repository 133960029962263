const {
  PolygonFigure
} = require('construction-designer-core/drawing-editor')

class PolylineFigure extends PolygonFigure {
  _draw(context) {
    context._drawConnectedLineSegments(this.shape().vertices())
   }
}

module.exports = PolylineFigure