const React = require('react')
const PropTypes = require('prop-types')

class SelectPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'select'

  constructor(props) {
    super(props)

    this.state = {
      property: props.property,
      selectedValue: props.property.get()
    }
  }

  selectedChoiceLabel() {
    const { property, selectedValue } = this.state
    const selectedChoice = property.options().choices.find(choice => (
      choice.value === selectedValue
      || (choice.value?.equals?.(selectedValue))
    ))

    // In the case of a CompositeEditableProperty, there may not be a selected choice
    return selectedChoice?.label ?? ''
  }

  _updateProperty(event) {
    const property = this.state.property
    const value = event.target.value
    property.set(value)
    this.props.drawingController.snapshotProjectAndRedraw()
    this.setState({ selectedValue: this.state.property.get() })
  }

  _renderSelectBox() {
    const { property, selectedValue } = this.state

    return (
      <>
        <label className="label--dropdown font-sm" htmlFor={property.key()}>{property.label()}:</label>
        <select
          className="form__dropdown"
          id={property.key()}
          onChange={event => this._updateProperty(event)}
          value={this.state.selectedValue}
        >
          {selectedValue === '' ? <option disabled value={''}></option> : ''}
          {property.options().choices.map(choice => <option key={choice.label} value={choice.value}>{choice.label}</option>)}
        </select>
      </>
    )
  }

  _renderSelectedOption() {
    return (
      <>
        <span className="label--side-panel">{this.props.property.label()}:</span>
        <span className="font-sm">{this.selectedChoiceLabel()}</span>
      </>
    )
  }

  render() {
    const property = this.state.property
    const renderFunction = property.editable() ? this._renderSelectBox.bind(this) : this._renderSelectedOption.bind(this)

    return (
      <div className="side-panel__row">
        {renderFunction()}
      </div>
    )
  }
}

module.exports = SelectPropertyView
