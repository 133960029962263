const {
  Polygon
} = require('construction-designer-core/geometry')
const { GeometricComponent } = require('construction-designer-core/construction')
const DrawnPolygonFigure = require('../drawables/DrawnPolygonFigure')
const EmptyFigure3D = require('shared/drawables/EmptyFigure3D')

class DrawnPolygon extends GeometricComponent {
  constructor(polygon) {
    super()
    this._polygon = polygon
  }

  shape() { return this._polygon }
  geometry() { return this.shape() }
  vertices() { return this.shape().vertices() }
  edges() { return this.shape().edges() }

  defaultFigure() {
    return new DrawnPolygonFigure(this)
  }

  defaultFigureXZ() {
    return new DrawnPolygonFigure(this, 'shapeXZ')
  }

  shapeXZ() {
    return new Polygon(this._polygon.vertices().map(v => v.xz()))
  }

  threeFigure() {
    return new EmptyFigure3D()
  }

  annotations() { return [] }
}
module.exports = DrawnPolygon