const {
  DimensionAnnotation
} = require('construction-designer-core/geometry')
const WallSurface = require('./WallSurface')
const MaterialFigure3D = require('shared/drawables/MaterialFigure3D')
const EditableProperty = require('./EditableProperty')

class ExteriorWallSurface extends WallSurface {
  static availableMaterialNames() { return ['brick', 'stone'] }

  buildingLevel() { return this.componentOf() }

  addToContext(buildingContext) {
    this.componentOf()?.removeSurface(this)

    const buildingLevel = buildingContext.buildingLevel()
    buildingLevel?.addSurface(this)
  }

  _buildingLevelAtVersion(sourceVersion) {
    if (sourceVersion < 2) return this.componentOf()._componentOf
    return this.buildingLevel()
  }

  setEdge(edge) {
    this._annotations = undefined
    super.setEdge(edge)
  }

  delete() {
    [...this.attachedSegments()].forEach(segment => segment.removeSurface(this))
    this.buildingLevel()?.removeExteriorSurface(this)
    this.setComponentOf(undefined)
  }

  defaultDisplayProperties() {
    return [
      new EditableProperty(this, 'Material Name', {
        type: 'select',
        label: 'Material',
        choices: this.availableMaterialNames().map(name => ({ label: name, value: name }))
      })
    ]
  }

  defaultAnnotations() {
    return [
      new DimensionAnnotation(this.edge(), 20, 'in')
    ]
  }

  threeFigure() { return new MaterialFigure3D(this) }
}
module.exports = ExteriorWallSurface
