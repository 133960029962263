const CompositeFigureXZ = require('shared/drawables/CompositeFigureXZ')
const {
  LinearMeasurement,
  ThreeSourceRelativeLocator,
  Unit,
  PolygonWithHoles,
  Point: { $P },
  Rectangle
} = require('construction-designer-core/geometry')

const {
  Composite3DFigure
} = require('construction-designer-core/drawing-editor-3D')

const {
  StableID
} = require('construction-designer-core/drawing-editor')
const { extend } = require('construction-designer-core/standard-utilities')

const Floor = require('./Floor')
const ProxyLocator = require('shared/geometry/ProxyLocator')
const Roof = require('./Roof')
const EditableProperty = require('./EditableProperty')
const ReadableProperty = require('./ReadableProperty')
const math = require('mathjs')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')
const ContourFinder = require('shared/operations/ContourFinder')
const BuildingLevelFigure = require('shared/drawables/BuildingLevelFigure')
const EdgeWallSegment = require('./EdgeWallSegment')

class BuildingLevel extends ShellBuilderConstructionComponent {
  /**
   * @param {String} [name]
   */
  constructor(name) {
    super()
    this._name = name
  }

  name() {
    return this._name || 'Building Level'
  }
  setName(name) { this._name = name }

  defaultDisplayProperties() {
    return [
      new EditableProperty(this, 'Base Offset', { type: 'unit', defaultUnit: 'ft', label: 'Floor' }),
      new EditableProperty(this, 'Height', { type: 'unit', defaultUnit: 'ft' }),
      new ReadableProperty(this, 'Finished Floor Area', { type: 'unit', defaultUnit: 'sqft' }),
      new ReadableProperty(this, 'Unfinished Floor Area', { type: 'unit', defaultUnit: 'sqft' })
    ]
  }

  get delete() { return false }
  selectable() { return false }

  building() { return this.componentOf() }

  /**
   * @param {Locator} locator
   * @returns {ThreeSourceRelativeLocator}
   */
  conformToFloorLevel(locator) {
    // NOTE: This method will de-relativize any locators passed to it. If
    // something needs to remain relative, you'll have to re-associate afterwards
    const baseLocator = this.zLevelLocator()
    const baseZ = baseLocator.z()

    const floorLevel = this.floorLevel(locator)
    const zOffset = floorLevel - baseZ
    const point = $P(locator.x(), locator.y(), locator.z())

    return new ThreeSourceRelativeLocator(
      point, point, baseLocator, 0, 0, zOffset
    )
  }

  /**
   * @param {Locator} locator
   * @returns {ThreeSourceRelativeLocator}
   */
  conformToZLevel(locator) {
    // NOTE: This method will de-relativize any locators passed to it. If
    // something needs to remain relative, you'll have to re-associate afterwards
    const baseLocator = this.zLevelLocator()
    const point = $P(locator.x(), locator.y(), locator.z())

    return new ThreeSourceRelativeLocator(
      point, point, baseLocator, 0, 0, 0
    )
  }

  previousLevel() {
    if (!this.building()) return
    const currentIndex = this.building().levels().indexOf(this)
    return this.building().levels()[currentIndex - 1]
  }

  nextLevel() {
    if (!this.building()) return
    const currentIndex = this.building().levels().indexOf(this)
    return this.building().levels()[currentIndex + 1]
  }

  wallColumns() {
    if (!this._wallColumns) {
      this._wallColumns = []
    }
    return this._wallColumns
  }

  addWallColumn(column) {
    this.wallColumns().push(column)
    column.setComponentOf(this)

    this.nextLevel()?.resetRoof()
  }
  removeWallColumn(column) {
    this.wallColumns().remove(column)
    column.setComponentOf(undefined)

    this.nextLevel()?.resetRoof()
  }

  height() {
    if(!this._height) { this._height = this.defaultHeight()}
    return this._height
  }
  setHeight(height) {
    if(height.scalar) {
      this._height = height
    } else if (parseFloat(height)) {
      this._height = LinearMeasurement.fromString(height)
    }
  }
  defaultHeight() { return (9).feet() }
  absoluteHeight() { return this.zLevel() - this.height().toInches() }

  wallHeight() {
    return (this.height().toInches() - this.floorThickness().toInches()).inches()
  }

  floorLevel(locator) {
    const defaultZLevel = this.floorSlabs().first().zLevel()
    if (!locator) return defaultZLevel

    const floorBelow = this.floorSlabs().find(slab => slab.shape().xy().containsPoint(locator.xy(), 1e-3))
    return floorBelow?.zLevel() ?? defaultZLevel
  }

  // NOTE: I don't think this is used in new projects
  floorLevelLocator() {
    if (!this._floorLevelLocator) {
      this._floorLevelLocator = new ProxyLocator(this, 'z', 'floorLevel')
    }
    return this._floorLevelLocator
  }

  baseOffset() {
    if (!this._baseOffset) {
      this._baseOffset = this.building() ? this.building().buildingLevelBaseOffset(this) : (0).feet()
    }
    return this._baseOffset
  }
  setBaseOffset(offset) {
    if (offset.scalar) {
      this._baseOffset = offset
    } else if (parseFloat(offset)) {
      this._baseOffset = LinearMeasurement.fromString(offset)
    }
  }

  zLevel() { return -this.baseOffset().toInches() }
  zLevelLocator() {
    if(!this._zLevelLocator) {
      this._zLevelLocator = new ProxyLocator(this, 'z', 'zLevel')
    }
    return this._zLevelLocator
  }

  interiorWallSegments() {
    if(!this._interiorWallSegments) {
      this._interiorWallSegments = []
    }
    return this._interiorWallSegments
  }

  addSegment(segment) {
    if (segment.isExterior()) {
      return this.addExteriorWallSegment(segment)
    }

    return this.addInteriorWallSegment(segment)
  }

  removeSegment(segment) {
    if (segment.isExterior()) {
      return this.removeExteriorWallSegment(segment)
    }

    return this.removeInteriorWallSegment(segment)
  }

  addInteriorWallSegment(segment) {
    segment.setComponentOf?.(this)
    this.interiorWallSegments().push(segment)
    this.nextLevel()?.resetRoof()
  }

  removeInteriorWallSegment(segment) {
    this.interiorWallSegments().remove(segment)
    segment.setComponentOf(undefined)
    this.nextLevel()?.resetRoof()
  }

  interiorWallSurfaces() {
    return this.rooms().flatMap(room => room.surfaces())
  }

  wallSegments() {
    return [
      ...this.exteriorWallSegments(),
      ...this.interiorWallSegments(),
      ...this.gableWallSegments()
    ]
  }

  wallSurfaces() {
    return [
      ...this.exteriorWallSurfaces(),
      ...this.interiorWallSurfaces(),
      ...this.gableWallSurfaces(),
      ...this.columns().flatMap(column => column.surfaces())
    ]
  }

  columns() {
    return [
      ...this.wallColumns(),
      ...this.gableColumns()
    ]
  }

  wallConnections() {
    // TODO: Implement strategy logic here...
    return [...new Set(this.wallSegments().flatMap(wall => wall.connections?.() || [] ))]
  }

  exteriorWallConnections() {
    return new Set(this.exteriorWallSegments().flatMap(wall => wall.connections?.() || []))
  }

  interiorWallConnections() {
    return new Set(this.interiorWallSegments().flatMap(wall => wall.connections?.() || [] ))
  }

  resetSegments() {
    this._interiorWallSegments = []
    this._exteriorWallSegments = []
    this._gableWallSegments = []
  }

  exteriorWallSegments() {
    if (!this._exteriorWallSegments) {
      this._exteriorWallSegments = []
    }
    return this._exteriorWallSegments
  }
  addExteriorWallSegment(segment) {
    segment.setComponentOf?.(this)
    this.exteriorWallSegments().push(segment)

    this.nextLevel()?.resetRoof()
  }
  removeExteriorWallSegment(segment) {
    this.exteriorWallSegments().remove(segment)
    segment.setComponentOf(undefined)

    this.nextLevel()?.resetRoof()
  }

  exteriorWallSurfaces() {
    if (!this._exteriorWallSurfaces) {
      this._exteriorWallSurfaces = []
    }
    return this._exteriorWallSurfaces
  }
  addExteriorSurface(surface) {
    surface.setComponentOf(this)
    this.exteriorWallSurfaces().push(surface)
  }
  removeExteriorSurface(surface) {
    this.exteriorWallSurfaces().remove(surface)
    surface.setComponentOf(undefined)
  }

  // NOTE: The building level should only ever contain exterior surfaces
  addSurface(surface) { this.addExteriorSurface(surface) }
  removeSurface(surface) { this.removeExteriorSurface(surface) }

  floorPlan() {
    return this._floorPlan
  }

  setFloorPlan(plan) {
    this._floorPlan = plan
  }

  snapPoints() {
    const floorPlan = this.floorPlan()
    return floorPlan ? floorPlan.vertices() : []
  }

  stairs() {
    if(!this._stairs) {
      this._stairs = []
    }
    return this._stairs
  }

  addStairs(stairs) {
    stairs.setComponentOf(this)
    this.stairs().push(stairs)
  }

  floorEdges() { return this.exteriorWallSurfaces().map(surface => surface.edge()) }

  floorSlabs() {
    if (this._floorSlabs) return this._floorSlabs

    return this._generateDefaultSlabs()
  }

  /**
  * Generates floor shapes based on the existing wall segments
  *
  * @returns {PolygonWithHoles[]}
  */
  _generateDefaultSlabs() {
    const floorEdges = this.floorEdges()
    if (floorEdges.length === 0) {
      const temporaryFloor = new Floor()
      const thickness = temporaryFloor.thickness().toInches()

      // A floor expects it shape to be at the top of its thickness (the floor
      // extrudes the original shape downward by its thickness)
      const baseLocation = (this.wallSegments().first()?.edge().center().xy() || $P(0, 0)).addZ(-thickness)
      const shape = new PolygonWithHoles([baseLocation, baseLocation, baseLocation])
      temporaryFloor.setShape(shape)
      temporaryFloor.setComponentOf(this)

      return [temporaryFloor]
    }

    const contours = new ContourFinder().process(floorEdges)

    const floorShapes = this._convertContoursToPolygons(contours)
    const floors = floorShapes.map(shape => {
      const floor = new Floor(shape)
      floor.setComponentOf(this)
      return floor
    })

    return floors
  }

  /**
   * Takes in an array containing arrays of vertices and returns an array of PolygonWithHoles
   *
   * @param {Locator[][]} contours
   */
  _convertContoursToPolygons(contours) {
    // We want to close the loop of the contour if it isn't already closed
    contours.forEach(contour => {
      if (!contour.first().equals(contour.last())) {
        contour.push(contour.first())
      }
    })

    return contours
      .filter(contours => contours.length > 1)
      .map(contours => new PolygonWithHoles(contours))
  }

  addFloorSlab(newSlab) {
    if (!this._floorSlabs) this._floorSlabs = []

    newSlab.setComponentOf(this)
    this.floorSlabs().push(newSlab)
  }
  resetSlabs() { this._floorSlabs = [] }

  floorThickness() {
    if (!this._floorThickness) {
      // NOTE: The assumption is that all floor slabs are the same thickness, and
      // if not, then the first slab has the thickness that should be used
      this._floorThickness = this.floorSlabs().first().thickness()
    }
    return this._floorThickness
  }
  setFloorThickness(newThickness) {
    this._floorThickness = newThickness
  }

  rooms() {
    if (!this._rooms) {
      this._rooms = []
    }
    return this._rooms
  }

  addRoom(newRoom) {
    newRoom.setComponentOf(this)
    this.rooms().push(newRoom)
  }

  removeRoom(room) {
    this.rooms().remove(room)
  }

  unfinishedRooms() {
    return this.rooms().filter(room => !room.finished())
  }

  exteriorSpaces() {
    if (!this._exteriorSpaces) {
      this._exteriorSpaces = []
    }
    return this._exteriorSpaces
  }

  addExteriorSpace(space) {
    this.exteriorSpaces().push(space)
    space.setComponentOf(this)
  }
  removeExteriorSpace(space) {
    this.exteriorSpaces().remove(space)
    space.setComponentOf(undefined)
  }

  gableWallSegments() {
    return this.roof() ? this.roof().gableSegments() : []
  }

  gableWallSurfaces() {
    return this.roof() ? this.roof().gableSurfaces() : []
  }

  gableWallConnections() {
    return this.roof() ? this.roof().gableConnections() : []
  }

  gableColumns() {
    return this.roof() ? this.roof().gableColumns() : []
  }

  roof() { return this._roof }
  setRoof(newRoof) {
    this._roof = newRoof
    newRoof.setComponentOf(this)
  }

  resetRoof() {
    this.roof() && this.roof().reset()
  }

  components() {
    return (
      [
        ...this.interiorWallConnections(),
        ...this.exteriorWallConnections(),
        ...this.exteriorWallSurfaces(),
        ...this.interiorWallSegments(),
        ...this.exteriorWallSegments(),
        ...this.rooms(),
        ...this.exteriorSpaces(),
        ...this.columns(),
        ...this.stairs(),
        this.roof(),
        ...this.floorSlabs()
      ].filter(item => item)
    )
  }

  // region statistics

  interiorWallSurfaceArea() {
    return this.interiorWallSurfaces().sum(surface => surface.area())
  }

  exteriorWallSurfaceArea() {
    // NOTE: This currently assumes that all gable surfaces are part of the
    // exterior wall. This is true right now, but could possibly change in the
    // future
    return [...this.exteriorWallSurfaces(), ...this.gableWallSurfaces()].sum(surface => surface.area())
  }

  /**
   * @returns {Unit}
   */
  totalFloorArea() {
    return this.floorSlabs().reduce((totalArea, slabs) => (
      math.add(totalArea, slabs.area())
    ), new Unit(0, 'sqft'))
  }

  unfinishedFloorArea() {
    return this.unfinishedRooms().reduce((totalArea, room) => (
      math.add(totalArea, room.area())
    ), new Unit(0, 'sqft'))
  }

  finishedFloorArea() {
    // NOTE: This makes the assumption that all rooms will be on top of the
    // floor, which is defined by the exterior wall loop
    const finishedArea = math.subtract(this.totalFloorArea(), this.unfinishedFloorArea())

    if (math.isNegative(finishedArea)) return new Unit(0, 'sqft')
    return finishedArea
  }

  exteriorLinearLength() {
    return this.exteriorWallSegments().sum(segment => (segment.linearLength().toInches())).inches()
  }

  numberOfWindows() {
    return this.wallSegments().sum(segment => segment.windows().length)
  }

  numberOfInteriorDoorways() {
    return this.interiorWallSegments().sum(segment => segment.doorways().length)
  }

  numberOfExteriorDoorways() {
    return this.exteriorWallSegments().sum(segment => segment.doorways().length)
  }

  roofCoverage() {
    return this.roof() ? this.roof().coverage() : 0
  }

  underRoofExteriorArea() {
    if (!this.roof()) return 0
    const shapeXY = this.roof().shapeXY()

    const exteriorSpaces = this.building().levels().flatMap(level => level.exteriorSpaces())
    const exteriorShapes = exteriorSpaces.map(space => space.shape())

    const intersections = []
    exteriorShapes.flatMap(shape => {
      shapeXY.forEach(overallShape => intersections.fastMerge(shape.intersection(overallShape)))
    })

    const totalArea = intersections.sum(intersection => intersection.area())
    return totalArea / (12 ** 2)
  }

  // endregion statistics

  moveBy(x, y, z) {
    if (!z) return false

    const offset = this.baseOffset().toInches()
    this.setBaseOffset((offset - z).inches())
    return true
  }

  defaultFigure() {
    return BuildingLevelFigure.withModel(this)
  }

  defaultFigureXZ() {
    return CompositeFigureXZ.withModel(this)
  }

  threeFigure() {
    return Composite3DFigure.withModel(this)
  }

  boundingBox() {
    if (this.wallSegments().length === 0) return new Rectangle(0, 0, 0, 0)

    let boundingBox = this.wallSegments().first().boundingBox()
    this.wallSegments().forEach(segment => (
      boundingBox = segment.boundingBox().merge(boundingBox)
    ))
    return boundingBox
  }

  // region smartJSON

  classVersion() { return 5 }
  migrate(sourceVersion) {
    if (sourceVersion < 2) {
      if (this._roofSections?.length > 0) {
        this._roof = new Roof()
        this._roof.setComponentOf(this)

        const roofPanels = this._roofSections.map(section => section._roofPanel)
        roofPanels.forEach(panel => this._roof.addRoofPanel(panel))

        this._roofSections = undefined
      }
    }

    if (sourceVersion < 3) {
      this._exteriorWalls?.forEach(exteriorWall => {
        const segments = exteriorWall._segments
        const surfaces = exteriorWall._surfaces

        segments.forEach(segment => this.addExteriorWallSegment(segment))
        surfaces.forEach(surface => this.addExteriorSurface(surface))
      })

      this._exteriorWalls = undefined
    }

    if (sourceVersion < 4) {
      const wallSurfaces = [...this.exteriorWallSurfaces(), ...this.interiorWallSurfaces()]
      // We know that at this point in time (when the source version is less
      // than 4), the floor's thickness was hardcoded to a foot
      const floorThickness = 12

      wallSurfaces.forEach(surface => {
        const edge = surface.edge();
        [edge.begin(), edge.end()].forEach(locator => {
          locator._zSource = this.zLevelLocator()
          locator._relativeZ = -floorThickness
        })
      })

      this._floor = undefined
    }

    if (sourceVersion < 5) {
      [...this.exteriorWallSegments(), ...this.interiorWallSegments()].forEach(segment => {
        const edge = segment._edge
        const thickness = segment._thickness

        const isExterior = this.exteriorWallSegments().includes(segment)
        const wallType = isExterior ? EdgeWallSegment.TYPES.Exterior : EdgeWallSegment.TYPES.Interior

        const newSegment = new EdgeWallSegment(edge, wallType, thickness)
        newSegment.setHeight(segment._height)

        segment._windows?.forEach(window => newSegment.addWindow(window))
        segment._doorways?.forEach(doorway => newSegment.addDoorway(doorway))

        segment._surfaces?.forEach(surface => {
          surface.removeAttachedSegment(segment)
          newSegment.addSurface(surface)
        })

        segment._connections?.forEach(connection => {
          const attachedTerminus = connection.getTerminus(segment)
          connection.detach(segment)
          connection.attach(newSegment, attachedTerminus)
        })

        this.interiorWallSegments().remove(segment)
        this.exteriorWallSegments().remove(segment)
        this.addSegment(newSegment)
      })
    }
  }

  // endregion smartJSON
}

extend(BuildingLevel, StableID)
module.exports = BuildingLevel
