const { Unit } = require('construction-designer-core/geometry')

Unit.prototype.toString = function () {
  if (this.isImperial()) {
    let feet, inches
    if (this.formatUnits() === 'ft') {
      feet = Math.floor(this.scalar())
      inches = this.toInches() % 12
    } else { // assume inches for now
      feet = Math.floor(this.scalar() / 12)
      inches = this.scalar() % 12
    }

    if (inches > 0) {
      return `${feet}' ${inches.rounded(1)}"`
    } else {
      return `${feet}'`
    }
  } else {
    // Format the output to 1 decimal place. However, trim extra .0 off the
    // end of the output, if it exists
    return this.format({ notation: 'fixed', precision: 1 }).replace(/\.0( |e)/, '$1')
  }
}
