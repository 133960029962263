const { ThreeGeometryFigure } = require("construction-designer-core/drawing-editor-3D")

class WindowFigure3D extends ThreeGeometryFigure {
  materialNames() { return this.model().materialNames() }

  _threeMeshFigure() {
    const mesh = super._threeMeshFigure()
    mesh.castShadow = false
    mesh.receiveShadow = false

    return mesh
  }
}

module.exports = WindowFigure3D
