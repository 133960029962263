/**
 * Cuts an edge with a 2D shape, returning the remainders left outside of the shape
 *   May return a single, truncated edge, or no edge, or the original edge, unchanged
 * @param {Polygon} shape - cutter shape (assumed to be convex), may be in 3D space, but will be computed in XY
 * @param {Edge} edge - edge to be cut. May be in 3D space, but will be computed in XY
 * @param {Locator} [vector = edge.vector().normalized()] - unit vector of edge (dot product defines ordinal space)
 * @param {Number} [tolerance = 1e-2] - tolerance for calculations
 * @return {Edge[]} - remainder edges
 */
const cutEdgeByShape = (shape, edge, vector = edge.vector().normalized(), tolerance = 1e-2) => {
  const significantDigits = Math.abs(Math.log10(tolerance))

  const projection = locator => locator.dot(vector)

  // NOTE: This rounding could introduce subtle errors, but it often fails to calculate intercepts without it
  const roundedEdge = edge.roundedTo(significantDigits)
  const beginLocator = roundedEdge.begin()
  const endLocator = roundedEdge.end()

  const edgeIntersections = shape.edges()
    .flatMap(shapeEdge => shapeEdge.roundedTo(significantDigits).intersectionsWithEdgeIn2D(roundedEdge))
    .filter(locator => !locator.equals(beginLocator, tolerance) && !locator.equals(endLocator, tolerance))

  const vertices = [
    // NOTE: It's important that the original edge's vertices are used here -
    // there are assumptions in the system that remaining endpoints will be
    // the same object in memory, not another point with the same position
    ...[edge.begin(), edge.end()].filter(locator => !shape.containsPoint(locator, tolerance)),
    ...edgeIntersections
  ].sort((a, b) => projection(a) - projection(b))

  // this only works because of the sorting happening above (line 30)
  const uniqueVertices = vertices.filter((vertex, index) => {
    if (index === 0) return true

    return !vertex.equals(vertices[index - 1], tolerance)
  })

  if(uniqueVertices.length < 2) { return [] }

  const remainders = []
  while(uniqueVertices.length > 0) { // assumes vertices will show up in pairs
    remainders.push(uniqueVertices.shift().to(uniqueVertices.shift()))
  }
  return remainders
}

module.exports = cutEdgeByShape
