const React = require('react')
const PropTypes = require('prop-types')
const { Icon } = require('construction-designer-core/drawing-editor-react/')
const classNames = require('classnames')
const ModelPropertyField = require('components/ModelPropertyField')

class RoofPanelPropertyPanel extends React.Component {
  static propTypes = {
    drawingController: PropTypes.object.isRequired,
    model: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (state.model !== props.model) {
      return {
        model: props.model,
        pitch: props.model.pitch(),
        slopeDirection: props.model.slopeDirectionName()
      }
    }

    return null
  }

  constructor(props) {
    super(props)

    this.state = {
      model: props.model,
      pitch: props.model.pitch(),
      slopeDirection: this.props.model.slopeDirectionName(),
    }
  }

  _onExit() {
    if (this._changed) {
      this.props.drawingController.snapshotProjectAndRedraw()
    }
    this._changed = false
  }

  componentWillUnmount() {
    this._onExit()
  }

  panelChanged() {
    this._changed = true
    this.props.drawingController.redraw()
  }

  setPitch(event) {
    const value = parseFloat(event.target.value)
    this.props.model.setPitch(value)
    this.setState({ pitch: value })

    this.panelChanged()
  }

  _renderPitchOptions() {
    return (
      <div className="side-panel__row">
        <label htmlFor="pitch" className="label--side-panel margin-right-sm">Pitch:</label>
        <select
          id="pitch"
          value={this.state.pitch}
          onChange={this.setPitch.bind(this)}
          className="form__dropdown margin-left-xs"
        >
          {this.props.model.constructor.pitchOptions.map(({ label, value }) => (
            <option value={value} key={label}>{label}</option>
          ))}
        </select>
      </div>
    )
  }

  setSlopeDirection(direction) {
    this.props.model.setSlopeDirection(direction)
    this.setState({ slopeDirection: direction })

    this.panelChanged()
  }

  slopeDirectionClassNames(option) {
    const isActive = this.state.slopeDirection === option
    return classNames(
      'margin-left-sm',
      `arrow-icon-${option}`,
      { 'arrow-icon--active': isActive }
    )
  }

  _renderHeightOffset() {
    return (
      <ModelPropertyField
        model={this.props.model}
        propertyName="heightOffset"
        wrapperClassName="side-panel__row"
        type="text"
        onChange={this.panelChanged.bind(this)}
      />
    )
  }

  _renderSlopeDirections() {
    return (
      <div className="side-panel__row">
        <label className="label--side-panel">Slope Direction:</label>
        <button onClick={this.setSlopeDirection.bind(this, 'left')} className={this.slopeDirectionClassNames('left')}>
          <Icon name="arrow_back" />
        </button>
        <button onClick={this.setSlopeDirection.bind(this, 'right')} className={this.slopeDirectionClassNames('right')}>
          <Icon name="arrow_forward" />
        </button>
        <button onClick={this.setSlopeDirection.bind(this, 'down')} className={this.slopeDirectionClassNames('down')}>
          <Icon name="arrow_down" />
        </button>
        <button onClick={this.setSlopeDirection.bind(this, 'up')} className={this.slopeDirectionClassNames('up')}>
          <Icon name="arrow_up" />
        </button>
      </div>
    )
  }

  render() {
    return (
      <div className="flex flex-col">
        {this._renderPitchOptions()}
        {this._renderHeightOffset()}
        {this._renderSlopeDirections()}
      </div>
    )
  }
}

module.exports = RoofPanelPropertyPanel
