const PlaceWindowTool = require('./PlaceWindowTool')
const Doorway = require('shared/domain-models/Doorway')
const CaseOpening = require('shared/domain-models/CaseOpening')
const HANDEDNESS = require('shared/domain-models/option-sets/HANDEDNESS')
const SWING_DIRECTIONS = require('shared/domain-models/option-sets/SWING_DIRECTIONS')

class PlaceDoorwayTool extends PlaceWindowTool {
  displayName() {
    return 'Doorways'
  }

  iconName() {
    return 'single-door'
  }

  _addOpening(wall, edge) {
    const newDoorway = new Doorway(edge)
    wall.addDoorway(newDoorway)
    newDoorway.setHeight(this.height())
    newDoorway.setDoorType(this.doorType())
    if(newDoorway.door().handedness) { newDoorway.door().setHandedness(this.handedness())}
    if(newDoorway.door().swingDirection) { newDoorway.door().setSwingDirection(this.swingDirection()) }
    return newDoorway
  }

  width() {
    return Doorway.defaultWidth()
  }

  height() {
    if(!this._height) {
      this._height = (78).inches()
    }
    return this._height
  }

  availableDoorTypes() {
    return Doorway.availableDoorTypes()
  }

  doorClass() {
    return Doorway.DOOR_CLASSES.get(this.doorType())
  }

  doorType() {
    if(!this._doorType) {
      this._doorType = CaseOpening.displayClassName()
    }
    return this._doorType
  }

  setDoorType(newType) {
    this._doorType = newType
  }

  handedness() {
    if(!this._handedness) {
      this._handedness = HANDEDNESS.Right
    }
    return this._handedness
  }

  setHandedness(newHandedness) {
    this._handedness = newHandedness
  }

  swingDirection() {
    if(!this._swingDirection) {
      this._swingDirection = SWING_DIRECTIONS.In
    }
    return this._swingDirection
  }

  setSwingDirection(swingDirection) {
    this._swingDirection = swingDirection
  }
}
module.exports = PlaceDoorwayTool
