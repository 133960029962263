const React = require('react')
const SystemStrategyService = require('shared/helpers/SystemStrategyService')
const AuthenticatedModal = require('components/AuthenticatedModal')
const DesignRelatedForm = require('components/DesignRelatedForm')
const ShellBuilderSaveButton = require('components/ShellBuilderSaveButton')

class ModeStrategy {
  constructor(app) {
    this._app = app
  }

  app() { return this._app }

  _requestQuoteSuccessUrl() {}

  renderRequestQuoteButton() {
    const strategy = SystemStrategyService.strategy()
    if (!strategy.supportsQuotes()) return ''

    const projectId = this.app().props.projectId
    const projectIdQueryParam = projectId ? `?project_id=${projectId}` : ''
    const url = `/project_quotes/new${projectIdQueryParam}`

    return (
      <AuthenticatedModal
        signedIn={this.app().state.signedIn}
        onSignIn={() => this.app().onSignIn()}
        renderChildren={closeModal => (
          <DesignRelatedForm
            repository={this.app().projectRepository()}
            closeModal={closeModal}
            formUrl={url}
            successUrl={this._requestQuoteSuccessUrl()}
            designInputName="project_quote[project][design]"
            formTitle="Request Quote"
          />
        )}
        buttonClassName="btn btn--outline margin-right-sm"
        buttonText="Request Quote"
      />
    )
  }

  renderSaveButton(repository) {
    return <ShellBuilderSaveButton projectRepository={repository} />
  }

  autosaveable() { return true }

  showPropertyPanel() {
    return true
  }

  showToolPanel() {
    return true
  }

  isViewOnly() {
    return false
  }

  showHomeButton() {
    return true
  }
}
module.exports = ModeStrategy
