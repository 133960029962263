const React = require('react')
const PropTypes = require('prop-types')
const ModelPropertyField = require('../ModelPropertyField')
const RadioSelect = require('../RadioSelect')
const wrapObservable = require('shared/helpers/wrapObservable')
const { observer } = require('mobx-react')

class RoomFinderToolPanel extends React.Component {
  static propTypes = {
    tool: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    const tool = props.tool
    wrapObservable(props.tool)

    this.state = { tool }
  }

  setBuildingMode(event) {
    const newMode = event.target.value
    this.tool().setBuildingMode(newMode)
  }

  tool() { return this.state.tool }

  _reset() { this.tool().reset() }
  _confirm() { this.tool().confirm() }

  _renderActionButtons() {
    return (
      <div className="flex justify-around margin-top-sm">
        <button className="btn btn--small btn--outline full-width" onClick={this._reset.bind(this)}>Reset</button>
        <button className="btn btn--small btn--outline full-width" onClick={this._confirm.bind(this)}>Confirm</button>
      </div>
    )
  }

  _renderBuildingModeRadioButton(displayValue, propertyValue) {
    return (
      <label className="font-sm flex items-center">
        <input
          type="radio"
          name="buildingMode"
          value={propertyValue}
          checked={this.tool().buildingMode() === propertyValue}
          onChange={this.setBuildingMode.bind(this)}
        />
        {displayValue}
      </label>
    )
  }

  _renderManualControls() {
    if (!this.tool().manualMode()) return ''

    return (
      <>
        <div className="flex justify-between">
          {this._renderBuildingModeRadioButton('Add Area', 'add')}
          {this._renderBuildingModeRadioButton('Subtract Area', 'subtract')}
        </div>
        {this._renderActionButtons()}
      </>
    )
  }

  render() {
    return (
      <>
        <div className="margin-sm">
          <RadioSelect
            getProperty={() => this.tool().roomType()}
            setProperty={newType => this.tool().setRoomType(newType)}
            label="Room Type"
            options={[{ value: 'interior', label: 'Interior' }, { value: 'exterior', label: 'Exterior' }]}
          />
        </div>
        <ModelPropertyField
          model={this.tool()}
          propertyName="manualMode"
          type="checkbox"
          wrapperClassName="flex items-center"
        />
        {this._renderManualControls()}
      </>
    )
  }
}

module.exports = observer(RoomFinderToolPanel)
