const {
  Polygon,
  RelativeZLocator
} = require('construction-designer-core/geometry')

const CaseOpening = require('./CaseOpening')
const SwingingDoor = require('shared/domain-models/SwingingDoor')
const PocketDoor = require('shared/domain-models/PocketDoor')
const DoubleDoor = require('shared/domain-models/DoubleDoor')
const GarageDoor = require('shared/domain-models/GarageDoor')
const SlidingGlassDoor = require('./SlidingGlassDoor')
const WallOpening = require('./WallOpening')

const DoorwayFigure = require('shared/drawables/DoorwayFigure')
const DoorwayFigureXZ = require('shared/drawables/DoorwayFigureXZ')
const { Composite3DFigure } = require('construction-designer-core/drawing-editor-3D')
const EditableProperty = require('./EditableProperty')

const DOOR_CLASSES = new Map([
  [CaseOpening.displayClassName(), CaseOpening],
  [SwingingDoor.displayClassName(), SwingingDoor],
  [PocketDoor.displayClassName(), PocketDoor],
  [DoubleDoor.displayClassName(), DoubleDoor],
  [GarageDoor.displayClassName(), GarageDoor],
  [SlidingGlassDoor.displayClassName(), SlidingGlassDoor]
])

class Doorway extends WallOpening {
  _door = undefined
  _doorType = undefined

  static defaultWidth() { return (28).inches() }

  static availableDoorTypes() {
    return [
      { label: CaseOpening.displayClassName(), icon: 'case-opening', value: CaseOpening.displayClassName() },
      { label: SwingingDoor.displayClassName(), icon: 'single-door', value: SwingingDoor.displayClassName() },
      { label: DoubleDoor.displayClassName(), icon: 'double-door', value: DoubleDoor.displayClassName() },
      { label: PocketDoor.displayClassName(), icon: 'pocket-door', value: PocketDoor.displayClassName() },
      { label: SlidingGlassDoor.displayClassName(), icon: 'sliding-glass-door', value: SlidingGlassDoor.displayClassName() },
      { label: GarageDoor.displayClassName(), icon: 'garage-door', value: GarageDoor.displayClassName() },
    ]
  }

  defaultDisplayProperties() {
    return [
      new EditableProperty(
        this,
        'Door Type',
        { type: 'trayselect', withDivider: true, choices: this.constructor.availableDoorTypes() }
      ),
      new EditableProperty(this, 'Height', { type: 'unit', defaultUnit: 'ft' }),
      new EditableProperty(this, 'Width', { type: 'unit', defaultUnit: 'ft' }),
      ...this.door()?.displayProperties?.() || []
    ]
  }

  locator() {
    return this.edge().begin()
  }

  delete() {
    this.componentOf().removeDoorway(this)
  }

  components() {
    return [
      this.door()
    ].filter(component => component)
  }

  door() {
    if (!this._door) {
      const DoorClass = this.doorClass()
      this._door = new DoorClass()
      this._door.setComponentOf(this)
    }
    return this._door
  }

  doorType() {
    if (!this._doorType) {
      this._doorType = CaseOpening.displayClassName()
    }
    return this._doorType
  }

  doorClass() {
    return DOOR_CLASSES.get(this.doorType())
  }

  /**
   * @param {String} doorType
   * @param {String=} handedness
   * @param {String=} swingDirection
   */
  setDoorType(doorType, handedness, swingDirection) {
    const DoorClass = DOOR_CLASSES.get(doorType)

    if (DoorClass) {
      this._doorType = doorType
    } else {
      throw new Error(`Invalid door type name. ${doorType} is not a door type`)
    }

    if (this._door) {
      this._door = new DoorClass()
      this._door.setComponentOf(this)

      if (handedness) this._door.setHandedness?.(handedness)
      if (swingDirection) this._door.setSwingDirection?.(swingDirection)

      this._displayProperties = undefined
      this.notifyDisplayPropertiesChangedObservers()
    }
  }

  doorClasses() {
    return [
      SwingingDoor,
      PocketDoor,
      DoubleDoor,
      GarageDoor,
      SlidingGlassDoor
    ]
  }

  interiorDirection() {
    return this.edge().normal()
  }

  defaultFigure() {
    return new DoorwayFigure(this, this.systemStrategy())
  }

  threeFigure() {
    return Composite3DFigure.withModel(this)
  }

  defaultFigureXZ() {
    return new DoorwayFigureXZ(this)
  }

  defaultHeight() {
    return (80).inches()
  }

  faceShape() {
    const height = this.height().toInches()
    const origin = this.edge().begin()
    const end = this.edge().end()

    return new Polygon([
      origin,
      end,
      new RelativeZLocator(end, -height),
      new RelativeZLocator(origin, -height)
    ])
  }
}

Doorway.DOOR_CLASSES = DOOR_CLASSES

module.exports = Doorway
