class WallPlacementStrategy {
  // NOTE: We're passing this a getter because the building level on the
  // DrawingController is swapped out on version replaced (See
  // BuildingLevelDrawingController #_onVersionReplaced). This makes it so that
  // the strategy will always have the most recent building level, because it
  // always looks for the newest one instead of caching

  /**
   * @param {Function} getBuildingLevel
   */
  constructor(getBuildingLevel) {
    this._getBuildingLevel = getBuildingLevel
  }

  buildingLevel() { return this._getBuildingLevel() }

  snappedObjects() {
    if (!this._snappedObjects) {
      this._snappedObjects = []
    }
    return this._snappedObjects
  }
  resetSnappedObjects() { this._snappedObjects = undefined }

  /**
   * Creates the correct WallAndSurfaceBuilder for the placement type
   *
   * @param {Edge} edge
   * @returns {EdgeWallAndSurfaceBuilder | CenterlineWallAndSurfaceBuilder}
   */
  makeWallBuilder(_edge) {
    // Subclasses should override
  }

  /**
   * Finds the correct placement for the given edge, based on various constraints
   *
   * @param {Edge} edge
   * @param {Function} constrainLocation
   * @returns {Object} - An object with keys of "begin" and "end"
   */
  placeEdge(_edge, _constrainLocation) {
    // Subclasses should override
  }

  /**
   * Snaps the given point to nearby objects, such as existing segments
   *
   * @param {Locator} point - The clicked point to be snapped
   * @param {Polygon} matcher - A shape used to detect nearby objets
   * @param {String} terminal - "begin" or "end"
   * @return {Object} - An object literal with snappedPoint and snapLocked keys
   */
  snapToExisting(_point, _matcher, _terminal) {
    // Subclasses should override
  }
}

module.exports = WallPlacementStrategy
