const Door = require("./Door")
const {
  ProportionalEdgeLocator,
  Point: { $P },
  PolygonWithHoles,
  Polygon,
  RelativeLocator
} = require("construction-designer-core/geometry")

const {
  three: THREE,
} = require('construction-designer-core/drawing-editor-3D')

const SlidingGlassDoorFigure = require('shared/drawables/SlidingGlassDoorFigure')
const SlidingGlassDoorFigure3D = require("shared/drawables/SlidingGlassDoorFigure3D")
const generateGeometryUVCoordinates = require('shared/helpers/generateGeometryUVCoordinates')
const convertShapeToThreeJS = require('shared/helpers/convertShapeToThreeJS')

class SlidingGlassDoor extends Door {
  static displayClassName() {
    return 'Sliding Glass Door'
  }

  doorEdge() {
    const doorwayEdge = this.edge().shiftedAlongNormalBy(this.thickness().toInches() / 2)
    const middle = doorwayEdge.center()
    if (this.leftHanded()) {
      const edge = middle.to(doorwayEdge.end())
      const begin = new ProportionalEdgeLocator(edge, -1.25)
      const end = new ProportionalEdgeLocator(edge, -0.25)
      return begin.to(end)
    } else if (this.rightHanded()){
      const edge = doorwayEdge.begin().to(middle)
      const begin = new ProportionalEdgeLocator(edge, 0.25)
      const end = new ProportionalEdgeLocator(edge, 1.25)
      return begin.to(end)
    }
  }

  windowEdge() {
    const edge = this.edge().shiftedAlongNormalBy((-this.thickness().toInches() / 2))
    const middle = edge.center()
    if (this.leftHanded()) {
      return edge.begin().to(middle)
    } else if (this.rightHanded()) {
      return middle.to(edge.end())
    }
  }

  footprint() {
    const edge1 = this.edge().shiftedAlongNormalBy(-this.frameThickness().toInches())
    const edge2 = this.edge().shiftedAlongNormalBy(this.frameThickness().toInches())

    return new Polygon([
      edge1.begin(),
      edge1.end(),
      edge2.end(),
      edge2.begin()
    ])
  }

  boundingBox() {
    return this.footprint().boundingBox()
  }

  boundingCube() {
    const baseZ = this.edge().begin().z()
    return this.boundingBox().extrudedFromTo(-this.height() + baseZ, baseZ)
  }

  frameThickness() {
    return (1.5).inches()
  }

  frameWidth() {
    return (2).inches()
  }

  glassThickness() {
    return (0.5).inches()
  }

  primaryDirection() {
    return $P(0, 0, 1)
  }

  // region frames

  _frameVertices(edge) {
    // Based on (0, 0) to make threeJS happy
    return [
      $P(0, edge.length()),
      $P(0, 0),
      $P(this.height(), 0),
      $P(this.height(), edge.length()),
    ]
  }

  _frameShape(edge) {
    const vertices = this._frameVertices(edge)
    const outsideShape = new PolygonWithHoles(vertices)
    const innerVertices = vertices.map(v => new RelativeLocator(v))
    const insideShape = new Polygon(innerVertices)
    const frameWidth = this.frameWidth().toInches()
    insideShape.edges().forEach(insideEdge => {
      const offset = insideEdge.normal().multipliedBy(-frameWidth)
      insideEdge.moveBy(offset.x(), offset.y(), offset.z())
    })
    return new PolygonWithHoles(outsideShape.vertices(), edge.center(), [insideShape])
  }

  _frameShape3D(edge) {
    return this._buildExtrusion(edge, this._frameShape(edge), this.frameThickness())
  }

  frameShapes3D() {
    return [
      this._frameShape3D(this.doorEdge()),
      this._frameShape3D(this.windowEdge())
    ]
  }

  // end region frames

  // region panes

  _paneVertices(edge) {
    return [
      $P(0, edge.length()),
      $P(0, 0),
      $P(this.height(), 0),
      $P(this.height(), edge.length())
    ]
  }

  _paneShape(edge) {
    return new PolygonWithHoles(this._paneVertices(edge))
  }

  _paneShape3D(edge) {
    return this._buildExtrusion(edge, this._paneShape(edge), this.glassThickness())
  }

  paneShapes3D() {
    return [
      this._paneShape3D(this.doorEdge()),
      this._paneShape3D(this.windowEdge())
    ]
  }

  // end region panes

  _buildExtrusion(edge, polygonalShape, thickness) {
    const beginPoint = edge.begin()
    const beginExtrusion = beginPoint.add(edge.normal().multipliedBy(-thickness.toInches()))
    // We want our rendering of the surface to have a little bit of thickness
    const endExtrusion = beginPoint

    const shape = convertShapeToThreeJS(polygonalShape)
    const curve = new THREE.LineCurve3(beginExtrusion.toThreeJS(), endExtrusion.toThreeJS())

    const geometry = new THREE.ExtrudeBufferGeometry(shape, {
      extrudePath: curve,
      bevelEnabled: false,
      doubleSided: true
    })

    // Geometry UV coordinates are useless; use a jackhammer this time
    const mainAxis = edge.vector().normalized().toThreeJS()
    generateGeometryUVCoordinates(geometry, mainAxis)
    return geometry
  }

  defaultFigure() {
    return new SlidingGlassDoorFigure(this, this.systemStrategy())
  }

  threeFigure() {
    return new SlidingGlassDoorFigure3D(this)
  }

  setHandedness(newHandedness) {
    super.setHandedness(newHandedness)
  }
}

module.exports = SlidingGlassDoor
