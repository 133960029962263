const { ExternalObjectCache } = require('construction-designer-core/smartJSON')
const DXFPlanExternalReference = require('./DXFPlanExternalReference')
let Dexie = require('dexie')
if (Dexie.default) Dexie = Dexie.default

class DXFPlanExternalObjectCache extends ExternalObjectCache {
  async store(object) {
    const db = this.database()
    await db.externalObjects.put(object.toIndexedDB())
    return object
  }

  async retrieve(id) {
    let externalReference

    const db = this.database()
    const [uuid, versionString] = id.split('/')
    const data = await db.externalObjects.get(uuid)

    if (data) {
      externalReference = DXFPlanExternalReference.fromIndexedDB(data)
    } else {
      const response = await fetch(`/api/v1/plans/${uuid}`, {
        method: 'GET',
        credentials: 'same-origin'
      })
      const responseJSON = await response.json()

      const unit = responseJSON.unit
      const rawDXF = responseJSON.raw_dxf
      externalReference = new DXFPlanExternalReference(rawDXF, uuid, parseInt(versionString), unit)
    }

    return externalReference
  }

  database() {
    if (!this._database) {
      // Create your instance
      this._database = new Dexie("shell-builder")

      // Define your schema
      this._database.version(1).stores({
        externalObjects: "&id"
      })
    }

    return this._database
  }
}

module.exports = DXFPlanExternalObjectCache
