const { ExternalReference } = require('construction-designer-core/smartJSON')

class DXFPlanExternalReference extends ExternalReference {
  constructor(object, id, version, unit) {
    super(object, id, version)
    this._unit = unit
  }

  static fromIndexedDB({ id, rawDXF, version, unit }) {
    return new this(rawDXF, id, version, unit)
  }

  unit() {
    return this._unit
  }
  setUnit(newUnit) {
    this._unit = newUnit
  }

  toIndexedDB() {
    return {
      id: this.uuid(),
      rawDXF: this.object(),
      unit: this.unit(),
      version: this.version()
    }
  }
}

module.exports = DXFPlanExternalReference
