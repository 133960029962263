const { three: THREE } = require('construction-designer-core/drawing-editor-3D')

const generateGeometryUVCoordinates = (geometry, mainAxis) => {
  const positions = geometry.getAttribute('position')
  const normals = geometry.getAttribute('normal')
  const uvs = geometry.getAttribute('uv')

  const vertex = new THREE.Vector3()
  const normal = new THREE.Vector3()
  const uv = new THREE.Vector2()
  const crossAxis = new THREE.Vector3()

  for (let i = 0; i < positions.count; i++) {
    vertex.fromBufferAttribute(positions, i)
    normal.fromBufferAttribute(normals, i)

    crossAxis.crossVectors(mainAxis, normal)

    uv.set(vertex.dot(mainAxis), vertex.dot(crossAxis))

    uvs.setXY(i, uv.x, uv.y)
  }
}

module.exports = generateGeometryUVCoordinates

