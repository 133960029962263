const React = require('react')
const PropTypes = require('prop-types')
const { Icon } = require('construction-designer-core/drawing-editor-react')
const {
  ProportionalEdgeLocator,
  Point: { $P }
} = require('construction-designer-core/geometry')

class ShellBuilderZoomControl extends React.Component {
  static propTypes = {
    zoomPalette: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  _zoomFactor() {
    return 1.5
  }

  _zoomIn() {
    const { zoomPalette } = this.props

    zoomPalette.zoomCanvasCenterBy(this._zoomFactor())
  }

  _zoomOut() {
    const { zoomPalette } = this.props

    zoomPalette.zoomCanvasCenterBy(1 / this._zoomFactor())
  }

  _zoomToFit() {
    const { drawingController } = this.props

    const boundingBox = drawingController.drawing().boundingBox()
    const startZoom = drawingController.scale()
    const canvas = drawingController.canvas()
    const paddingOffset = 200
    const sidePanelOffset = 500 + paddingOffset
    const canvasAspectRatio = (canvas.width - sidePanelOffset) / (canvas.height - paddingOffset)
    const boundingBoxAspectRatio = boundingBox.width() / boundingBox.height()

    let endZoom
    if (boundingBoxAspectRatio > canvasAspectRatio) {
      endZoom = (canvas.width - sidePanelOffset) / boundingBox.width()
    } else {
      endZoom = (canvas.height - paddingOffset) / boundingBox.height()
    }

    if(endZoom === Infinity) endZoom = 1
    const zoomChange = endZoom - startZoom

    const startTranslation = $P(drawingController.translateX(), drawingController.translateY())
    drawingController.setScale(endZoom)

    const endTranslation = $P(drawingController.defaultTranslateX(), drawingController.defaultTranslateY())
    drawingController.setScale(startZoom)

    const change = startTranslation.to(endTranslation)
    const animationDuration = 500 // milliseconds
    let lastTime

    const animate = time => {
      if (!lastTime) {
        lastTime = time
        requestAnimationFrame(animate)
        return
      }

      const elapsed = time - lastTime

      const x = Math.min(elapsed / animationDuration, 1)
      const position = (Math.sin((x * Math.PI) - Math.PI_2) + 1) / 2
      const currentTranslation = new ProportionalEdgeLocator(change, position)
      const currentZoom = startZoom + position * zoomChange
      drawingController.setScale(currentZoom)
      drawingController.setTranslation(currentTranslation.x(), currentTranslation.y())
      drawingController.draw()

      if (elapsed < animationDuration) requestAnimationFrame(animate)
    }

    requestAnimationFrame(animate)
  }

  render() {
    return (
      <>
        <button type="button" className="zoom__button margin-right-xs" title="Zoom out" onClick={this._zoomOut.bind(this)}>
          <Icon name="zoom-out" className="flex" />
        </button>

        <button type="button" className="zoom__button" title="Zoom in" onClick={this._zoomIn.bind(this)}>
          <Icon name="zoom-in" className="flex" />
        </button>

        <div className="half-height">
          <div className="vertical-divider"></div>
        </div>

        <button type="button" className="zoom__button zoom__button--zoom-to-fit" title="Zoom to Fit" onClick={this._zoomToFit.bind(this)}>
          <Icon name="zoom_out_map" />
        </button>
      </>
    )
  }
}

module.exports = ShellBuilderZoomControl
