const CenterlineConnectedSegmentFinder = require('shared/operations/CenterlineConnectedSegmentFinder')

class CenterlineWallMovementStrategy {
  constructor(segment) {
    this._segment = segment
  }

  segment() {
    return this._segment
  }

  finder() {
    if (!this._finder) {
      this._finder = new CenterlineConnectedSegmentFinder()
    }
    return this._finder
  }

  move(x, y, z) {
    const startSegment = this.segment()
    const masterEdge = startSegment.edge()

    const { parallelSegments, nonParallelSegments } = this.finder().process(startSegment)
    const segments = [...parallelSegments, startSegment]

    // TODO: Think about performance here
    nonParallelSegments.forEach(nonParallelSegment => {
      const sharedEndpoint = segments.reduce((endpoint, parallelSegment) => {
        if (endpoint) return endpoint
        return parallelSegment.sharedEndpoint(nonParallelSegment)
      }, undefined)

      nonParallelSegment.moveEndpoint(sharedEndpoint, x, y, z)
    })

    const parallelSurfaces = segments.flatMap(segment => segment.surfaces())
      .filter(surface => surface.edge().isParallelTo(masterEdge, 1e-3))

    const uniqueSurfaces = new Set(parallelSurfaces)

    segments.forEach(segment => segment.moveAlone(x, y, z))
    uniqueSurfaces.forEach(surface => surface.moveAlone(x, y, z))
  }
}

module.exports = CenterlineWallMovementStrategy
