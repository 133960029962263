const React = require('react')
const PropTypes = require('prop-types')
const {
  Modal,
  Icon,
} = require('construction-designer-core/drawing-editor-react')
const SignInForm = require('./SignInForm')
const SignUpForm = require('./SignUpForm')
const { 'react-router-dom': { Route, Switch, withRouter, Redirect } } = require('construction-designer-core/drawing-editor-react')

class AuthenticatedModal extends React.Component {
  static propTypes = {
    signedIn: PropTypes.bool.isRequired,
    renderChildren: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonClassName: PropTypes.string,
    onSignIn: PropTypes.func,
    match: PropTypes.object,
  }

  static defaultProps = {
    onSignIn: () => { }
  }

  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      signedIn: this.props.signedIn,
    }
  }

  _openModal(_event) {
    this.setState({ visible: true })
  }

  _closeModal(_event) {
    this.setState({ visible: false })
  }

  _routeUrl(path, base = this.props.match.url) {
    if (base.endsWith('/')) {
      return `${base}${path}`
    } else {
      return `${base}/${path}`
    }
  }

  _renderChildren() {
    if (!this.props.signedIn) return (<Redirect to={this._routeUrl('signup')} />)

    return this.props.renderChildren(this._closeModal.bind(this))
  }

  _renderLoginForm() {
    if (this.props.signedIn) return (<Redirect to={this._routeUrl('')} />)

    return (
      <SignInForm onSignIn={this.props.onSignIn} signUpUrl={this._routeUrl('signup')} />
    )
  }

  _renderSignUpForm() {
    if (this.props.signedIn) return (<Redirect to={this._routeUrl('')} />)

    return (
      <SignUpForm onSignIn={this.props.onSignIn} signInUrl={this._routeUrl('signin')} />
    )
  }

  _renderCloseButton() {
    return <button className="modal__close">
      <Icon name="close" onClick={this._closeModal.bind(this)} />
    </button>
  }

  _renderModalContents() {
    const { path } = this.props.match
    return (
      <>
        {this._renderCloseButton()}
        <Switch>
          <Route
            exact
            path={this._routeUrl('', path)}
            render={this._renderChildren.bind(this)}
          />
          <Route
            path={this._routeUrl('signin', path)}
            render={this._renderLoginForm.bind(this)}
          />
          <Route
            path={this._routeUrl('signup', path)}
            render={this._renderSignUpForm.bind(this)}
          />
        </Switch>
      </>
    )
  }

  render() {
    return (
      <>
        <button
          className={this.props.buttonClassName}
          onClick={this._openModal.bind(this)}
        >
          {this.props.buttonText}
        </button>
        <Modal
          visible={this.state.visible}
          onEscapeKey={this._closeModal.bind(this)}
        >
          <div className="container container--sm">
            {this._renderModalContents()}
          </div>
        </Modal>
      </>
    )
  }
}

module.exports = withRouter(AuthenticatedModal)
