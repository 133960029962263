const {
  PolarCoordinate,
  Polygon,
  ExtrudedPolygon
} = require('construction-designer-core/geometry')
const SwingingDoorFigure = require('../drawables/SwingingDoorFigure')
const Door = require('./Door')

const SWING_DIRECTIONS = require('shared/domain-models/option-sets/SWING_DIRECTIONS')

const InRightDoorStrategy = require('./door-strategies/InRightDoorStrategy')
const InLeftDoorStrategy = require('./door-strategies/InLeftDoorStrategy')
const OutRightDoorStrategy = require('./door-strategies/OutRightDoorStrategy')
const OutLeftDoorStrategy = require('./door-strategies/OutLeftDoorStrategy')
const EditableProperty = require('./EditableProperty')

class SwingingDoor extends Door {
  static defaultSwingAngle() {
    return (90).degreesToRadians()
  }

  static displayClassName() {
    return 'Swinging Door'
  }

  constructor(handedness, swingDirection = SWING_DIRECTIONS.In) {
    super(handedness)

    this._swingDirection = swingDirection
    this.selectDoorStrategy()
  }

  defaultDisplayProperties() {
    const swingDirections = this.constructor.SWING_DIRECTIONS

    return [
      ...super.defaultDisplayProperties(),
      new EditableProperty(
        this,
        'Swing Direction',
        {
          type: 'radioselect',
          choices: Object.keys(swingDirections).map(key => ({ label: key, value: swingDirections[key] }))
        }
      )
    ]
  }

  postRestorationAction() {
    this.selectDoorStrategy()
  }

  strategy() {
    return this._strategy
  }

  setHandedness(newHandedness) {
    super.setHandedness(newHandedness)
    this.selectDoorStrategy()
  }

  flipHandedness() {
    if (this.leftHanded()) {
      this.setHandedness(this.constructor.HANDEDNESS.Right)
    } else if (this.rightHanded()) {
      this.setHandedness(this.constructor.HANDEDNESS.Left)
    }
  }

  selectDoorStrategy() {
    if (this.swingsIn() && this.rightHanded()) {
      this._strategy = new InRightDoorStrategy(this)
    } else if (this.swingsIn() && this.leftHanded()) {
      this._strategy = new InLeftDoorStrategy(this)
    } else if (this.swingsOut() && this.leftHanded()) {
      this._strategy = new OutLeftDoorStrategy(this)
    } else if (this.swingsOut() && this.rightHanded()) {
      this._strategy = new OutRightDoorStrategy(this)
    }
  }

  depth() {
    return this.width() * Math.sin(this.swingAngle())
  }

  swingAngle() {
    return this.constructor.defaultSwingAngle()
  }

  swingAngle3D() {
    return (25).degreesToRadians()
  }

  swingAngleStart() {
    return this.strategy().swingAngleStart()
  }

  swingAngleEnd() {
    return this.strategy().swingAngleEnd()
  }

  swingAngleEnd3D() {
    return this.strategy().swingAngleEnd3D()
  }

  hingeLocator() {
    return this.strategy().hingeLocator()
  }

  swingDirection() {
    return this._swingDirection
  }

  setSwingDirection(swingDirection) {
    this._swingDirection = swingDirection
    this.selectDoorStrategy()
  }

  swingsIn() {
    return this.swingDirection() === SWING_DIRECTIONS.In
  }

  swingsOut() {
    return this.swingDirection() === SWING_DIRECTIONS.Out
  }

  outerLocator() {
    return this.hingeLocator().add(
      new PolarCoordinate(this.width(), this.swingAngleEnd())
    )
  }

  outerLocator3D() {
     return this.hingeLocator().add(
      new PolarCoordinate(this.width(), this.swingAngleEnd3D())
    )
  }

  vertices() {
    return this.strategy().vertices()
  }

  shape3D() {
    const edge1 = this.strategy().threeFigureEdge()
    const edge2 = edge1.shiftedAlongNormalBy(this.thickness().toInches())

    const polygon = new Polygon([
      edge1.begin(),
      edge1.end(),
      edge2.end(),
      edge2.begin()
    ])

    return new ExtrudedPolygon(polygon, -this.height())
  }

  defaultFigure() {
    return new SwingingDoorFigure(this, this.systemStrategy())
  }
}

SwingingDoor.SWING_DIRECTIONS = SWING_DIRECTIONS

module.exports = SwingingDoor
