const {
  ThreeMaterialLibrary,
  three: THREE
} = require("construction-designer-core/drawing-editor-3D")

const loadTexture = (name, loader) => {
  const texture = loader.load(require(`./hardwood-floor-textures/${name}.jpg`))
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping
  texture.repeat.set(0.05, 0.05)

  return texture
}

ThreeMaterialLibrary.instance().register('hardwood-floor', textureLoader => {
  const normalMap = loadTexture('normal', textureLoader)

  return new THREE.MeshPhysicalMaterial({
    aoMap: loadTexture('ambient_occlusion', textureLoader),
    map: loadTexture('color', textureLoader),
    normalMap,
    roughnessMap: loadTexture('roughness', textureLoader),
    clearcoat: 0.2,
    clearcoatRoughness: 0.5,
    clearcoatNormalMap: normalMap,
    color: 0xe0e0e0
  })
})
