const { CompositeFigure } = require('construction-designer-core/drawing-editor')
const { ConstructionComponentHandle } = require('construction-designer-core/construction')

class WallColumnFigure extends CompositeFigure {
  _drawSelf(context, _options) {
    context._drawPolygon(this.model().footprint())
  }

  zLevel() { return this.model().zLevel() }
  vertices() { return this.model().vertices() }
  contains(x, y) { return this.model().contains(x, y) }
  moveBy(x, y) { return this.model().moveBy(x, y) }
  layer() { return 'Walls' }

  boundingBox() {
    return this.model().footprint().boundingBox()
  }

  selfHandle() {
    return new ConstructionComponentHandle(this.model())
  }

  defaultProperties() {
    return {
      strokeStyle: '#00000000',
      fillStyle: '#CFCFCF88'
    }
  }
}
module.exports = WallColumnFigure
