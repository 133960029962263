const { Polygon } = require('construction-designer-core/geometry')
const Room = require('shared/domain-models/Room')
const ContourFinder = require('shared/operations/ContourFinder')

class RoomHelper {
  constructor(buildingLevel, tolerance = 1e-2) {
    this._buildingLevel = buildingLevel
    this._tolerance = tolerance
  }

  buildingLevel() { return this._buildingLevel }
  tolerance() { return this._tolerance }

  redistributeSpace(room) {
    if (room.surfaces().length <= 2) { return }

    const findMatchingSurfaces = (contour, surfaces) => {
      if (contour.first().equals(contour.last())) { contour.pop() }
      const edges = new Polygon(contour).edges()

      return edges.map(edge => surfaces.find(surface => surface.edge().equals(edge, 1e0))).filter(i => i)
    }

    const finder = new ContourFinder()
    const contours = finder.process(room.surfaces().map(s => s.edge().snapshot()))
    if (contours.length > 1) {
      const allSurfaces = room.surfaces()
      const masterContour = contours.shift()
      const masterSurfaces = findMatchingSurfaces(masterContour, allSurfaces)

      room._surfaces = [] // don't call removeSurface as it might delete itself when all surfaces are removed
      masterSurfaces.forEach(surface => room.addSurface(surface))

      contours.forEach(contour => {
        const surfaces = findMatchingSurfaces(contour, allSurfaces)

        const newRoom = new Room(surfaces)
        this.buildingLevel().addRoom(newRoom)
      })
    }
  }

  findOrCreateRoom(leftSurfaceEdge, rightSurfaceEdge) {
    const buildingLevel = this.buildingLevel()

    const wallVertices = [
      leftSurfaceEdge.begin(),
      leftSurfaceEdge.end(),
      rightSurfaceEdge.begin(),
      rightSurfaceEdge.end()
    ]

    // See if any rooms contain the new wall's vertices
    let room = buildingLevel.rooms().find(existingRoom => (
      wallVertices.some(vertex => existingRoom.shape().containsPoint(vertex, this.tolerance()))
    ))

    // If not, see if any rooms' surfaces contain the new wall's vertices
    if (!room) {
      const unfinishedRooms = buildingLevel.rooms()

      room = unfinishedRooms.find(unfinishedRoom => {
        const roomEdges = unfinishedRoom.surfaces().map(surface => surface.edge())
        return roomEdges.some(edge => (
          wallVertices.some(vertex => edge.containsPoint(vertex, this.tolerance()))
        ))
      })
    }

    // If neither of the above conditions are true, create a new room
    if (!room) {
      room = new Room()
      buildingLevel.addRoom(room)
    }
    return room
  }
}

module.exports = RoomHelper
