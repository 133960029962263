const { Polygon } = require('construction-designer-core/geometry')
const ReadableProperty = require('./ReadableProperty')
const EdgeWallSegment = require('./EdgeWallSegment')

class GableWallSegment extends EdgeWallSegment {
  static withLowerSegment(faceVertices, lowerSegment) {
    const segment = new this(faceVertices)
    segment.setLowerSegment(lowerSegment)
    return segment
  }

  constructor(faceVertices, thickness) {
    super()
    this._faceVertices = faceVertices
    this._thickness = thickness
  }

  defaultDisplayProperties() {
    return [
      new ReadableProperty(this, 'Thickness', { type: 'unit', defaultUnit: 'ft' })
    ]
  }

  lowerSegment() { return this._lowerSegment }
  setLowerSegment(segment) {
    this._lowerSegment = segment
  }
  faceVertices() { return this._faceVertices }
  doorways() { return [] }
  windows() { return [] }
  moveBy(_x, _y, _z) { return false }
  roofPanel() { return this.componentOf() }
  buildingLevel() { return this.componentOf() && this.componentOf().buildingLevel() }

  thickness() {
    if (this.lowerSegment()) return this.lowerSegment().thickness()
    return this._thickness
  }

  delete() {
    this.componentOf().removeSegment(this)
    super.delete()
  }

  edge() {
    const faceVertices = this.faceVertices()
    // Currently assumed that the first two faceVertices will be the edge
    return faceVertices[0].to(faceVertices[1])
  }

  upperEdge() {
    const faceVertices = this.faceVertices()
    // If we assume that the first two vertices are the bottom edge, then the
    // last two are the top edge
    return faceVertices[3].to(faceVertices[2])
  }

  faceShape() { return new Polygon(this.faceVertices()) }

  migrate(sourceVersion) {
    if (sourceVersion < 2) {
      this.componentOf()?.resetGableComponents?.()
    }
  }
}
module.exports = GableWallSegment
