const React = require('react')
const PropTypes = require('prop-types')
const CollapsablePanelSection = require('./CollapsablePanelSection')

class ProjectStatistics extends React.Component {
  static propTypes = {
    project: PropTypes.object.isRequired
  }

  componentDidMount() {
    this.props.project.addCurrentVersionChangedObserver(this, this.refresh)
  }

  componentWillUnmount() {
    this.props.project.removeCurrentVersionChangedObserver(this, this.refresh)
  }

  refresh() {
    this.forceUpdate()
  }

  project() {
    return this.props.project
  }

  _renderStatisticSection(title, value, unit) {
    return (
      <div className="side-panel__row">
        <span className="font-sm">{title}:</span>
        <span className="font-sm no-wrap"><strong>{value} {unit || ''}</strong></span>
      </div>
    )
  }

  render() {
    const headerTitle = <span className="selection-name">Project Statistics:</span>

    return (
      <CollapsablePanelSection headerTitle={headerTitle}>
        {this._renderStatisticSection(
          'Total Floor Area', this.project().totalFloorArea().toString()
        )}
        {this._renderStatisticSection(
          'Finished Floor Area', this.project().finishedFloorArea().toString()
        )}
        {this._renderStatisticSection(
          'Unfinished Floor Area', this.project().unfinishedFloorArea().toString()
        )}
        {this._renderStatisticSection(
          'Exterior Wall Surface Area', this.project().exteriorWallSurfaceArea().toString()
        )}
        {this._renderStatisticSection(
          'Interior Wall Surface Area', this.project().interiorWallSurfaceArea().toString()
        )}
        {this._renderStatisticSection(
          'Exterior Wall Linear Length', this.project().exteriorLinearLength().toString()
        )}
        {this._renderStatisticSection(
          'Total Interior Doorways', this.project().totalInteriorDoorways()
        )}
        {this._renderStatisticSection(
          'Total Exterior Doorways', this.project().totalExteriorDoorways()
        )}
        {this._renderStatisticSection(
          'Total Windows', this.project().totalWindows()
        )}
        {this._renderStatisticSection(
          'Roof Coverage', this.project().roofCoverage().toString()
        )}
        {this._renderStatisticSection(
          'Under Roof Exterior Area', this.project().underRoofExteriorArea().toString()
        )}
      </CollapsablePanelSection>
    )
  }
}

module.exports = ProjectStatistics
