const React = require('react')
const PropTypes = require('prop-types')
const classNames = require('classnames')
const RoomFinderTool = require('shared/tools/RoomFinderTool')
const RoomFinderToolPanel = require('components/tool-panels/RoomFinderToolPanel')
const RoofTool = require('shared/tools/RoofTool')
const RoofToolPanel = require('components/tool-panels/RoofToolPanel')
const {
  Icon,
  UndoControl,
  RedoControl
} = require('construction-designer-core/drawing-editor-react')
const {
  ComponentSelectionTool
} = require('construction-designer-core/drawing-editor')
const WallColumnTool = require('shared/tools/WallColumnTool')
const WallColumnToolPanel = require('./tool-panels/WallColumnToolPanel')
const PlaceWallTool = require('shared/tools/PlaceWallTool')
const PlaceWallToolPanel = require('./tool-panels/PlaceWallToolPanel')

ComponentSelectionTool.prototype.iconName = () => 'select'

const TOOL_PANEL_TYPES = new Map([
  [RoomFinderTool, RoomFinderToolPanel],
  [RoofTool, RoofToolPanel],
  [WallColumnTool, WallColumnToolPanel],
  [PlaceWallTool, PlaceWallToolPanel]
])

class ToolsPanel extends React.Component {
  static propTypes = {
    drawingController: PropTypes.object.isRequired,
    toolPalette: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this._toolPalette().addAvailableToolsChangedObserver(this, this._availableToolsChanged)
  }

  componentDidMount() {
    this._startObserving()
  }

  componentWillUnmount() {
    this._stopObserving()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.drawingController !== this.props.drawingController) {
      this._stopObserving(prevProps.drawingController)
      this._startObserving()
    }
  }

  _startObserving() {
    this.props.drawingController.toolHolder().addValueChangedObserver(this, this.forceUpdate)
    this.forceUpdate()
  }

  _stopObserving(drawingController = this.props.drawingController) {
    drawingController.toolHolder().removeValueChangedObserver(this, this.forceUpdate)
  }

  _availableToolsChanged() {
    this.setState({})
  }

  _availableTools() {
    return this._toolPalette().tools()
  }

  _toggleTool(tool) {
    const palette = this._toolPalette()
    if(palette.activeTool() === tool) {
      palette.setActiveTool(palette.defaultTool())
    } else {
      palette.setActiveTool(tool)
    }
  }

  _renderToolPanel(tool, isActive) {
    const Panel = TOOL_PANEL_TYPES.get(tool.constructor)
    const button = this._generateToolButton(tool, !!Panel, isActive)

    if(Panel && isActive) return this._renderToolSection(tool, button, <Panel tool={tool} />)
    return this._renderToolSection(tool, button)
  }

  _generateToolButton(tool, hasPanel, isActive) {
    const buttonClasses = classNames(
      'btn btn--tool full-width', { 'btn--active': isActive }
    )

    return (
      <button onClick={() => this._toggleTool(tool)} className={buttonClasses}>
        {tool.iconName ? <Icon name={tool.iconName()} className="btn__icon" /> : ''}
        <span className="btn__label">{tool.displayName()}</span>
        {hasPanel ? <Icon name="plus" className="indicator" /> : ''}
      </button>
    )
  }

  _renderToolSection(tool, button, panel) {
    const trayClasses = classNames(
      'side-panel__tool-tray', { 'side-panel__tool-tray--open': panel }
    )

    return (
      <div className={trayClasses} key={tool.displayName()}>
        {button}
        {tool.displayName() === 'Select' ? <hr className="select--divider" /> : ''}
        {panel ? <hr className="divider" /> : ''}
        {panel}
      </div>
    )
  }

  _renderTools() {
    const activeTool = this._toolPalette().activeTool()

    return this._toolPalette().tools().map(tool => {
      const isActive = activeTool === tool
      return this._renderToolPanel(tool, isActive)
    })
  }

  _renderUndoRedoControls() {
    return (
      <section className="margin-sm">
        <UndoControl versionedProject={this.props.drawingController.project()} />
        <RedoControl versionedProject={this.props.drawingController.project()} />
      </section>
    )
  }

  _toolPalette() {
    return this.props.toolPalette
  }

  render() {
    return (
      <section className="margin-sm side-panel__content">
        {this._renderUndoRedoControls()}
        {this._renderTools()}
      </section>
    )
  }
}

module.exports = ToolsPanel
