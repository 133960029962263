const WallSegmentTool = require('./WallSegmentTool')
const WallColumn = require('shared/domain-models/WallColumn')
const { Polygon } = require('construction-designer-core/geometry')

class WallColumnTool extends WallSegmentTool {
  displayName() { return 'Columns' }
  iconName() { return 'columns' }

  pendingVertices() { return this._pendingVertices }

  activate() {
    this._pendingVertices = []
    super.activate()
  }

  reset() {
    this._pendingVertices = []
    super.reset()
  }

  _addWallEdge(edgeAndMetadata) {
    const { edge, reversed } = edgeAndMetadata

    const start = reversed ? edge.end() : edge.begin()
    const end = reversed ? edge.begin() : edge.end()

    if (this.pendingVertices().length === 0 ) {
      this.pendingVertices().push(start)
    }
    if (!this._isNowClosed()) this.pendingVertices().push(end)
  }

  _finalizeLoop() {
    // NOTE: This assumes that the pending vertices all have a z of 0
    const vertices = this.pendingVertices().map(vertex => (
      this.buildingLevel().conformToZLevel(vertex)
    ))
    const shape = new Polygon(vertices)
    if (shape.isCounterClockwise()) shape.reverseVertices()

    const column = new WallColumn(shape)
    this.buildingLevel().addWallColumn(column)

    this._controller().setSelectedComponent(column)
    this._controller().snapshotProject()
    this.reset()
  }

  _conformEdge(inputEdge) {
    let reversed = false
    let edge = inputEdge

    const { direction, thickness } = this._findLikelyWallThickness(edge, this.planEdges())
    if (Math.sign(direction) === 1) { // TODO verify why this seems to be logically back-to-front
      reversed = true
      edge = edge.reversed()
    }

    return {
      edge,
      thickness,
      reversed
    }
  }

  draw(context, options) {
    context._alterAndRestoreAfter(() => {
      if (this.pendingVertices().length > 0) {
        const vertices = this.pendingVertices()
        context.beginPath()
        context.moveTo(vertices.first().x(), vertices.first().y())
        context._addPolygonToCurrentPath(new Polygon(vertices))
        context.stroke()
      }
    })

    super.draw(context, options)
  }
}
module.exports = WallColumnTool
