const { RelativeLocator } = require('construction-designer-core/geometry')
const ShellBuilderProject = require('shared/domain-models/ShellBuilderProject')

class IfcProjectBuilder {
  constructor(ifcJSON, projectRepository, baseUnit = 'mm') {
    this._ifcJSON = ifcJSON
    this._repository = projectRepository
    this._baseUnit = baseUnit
  }

  ifcJSON() { return this._ifcJSON }
  repository() { return this._repository }
  baseUnit() { return this._baseUnit }

  async createProject() {
    const repository = this.repository()

    const building = await repository.buildObjectFromJSON(this.ifcJSON())
    const levels = building.levels()
    levels.forEach(buildingLevel => {
      const ifcSlabs = buildingLevel.floorSlabs()
      buildingLevel.resetSlabs()

      ifcSlabs.forEach(ifcSlab => {
        ifcSlab.setBaseUnit(this.baseUnit())
        ifcSlab.convertToFloor()
      })

      const ifcSegments = buildingLevel.wallSegments()
      // NOTE: This has to happen before we convert the segments, because the
      // conversion process will look for nearby segments and connections

      // TODO: We should probably avoid this by creating an IfcBuildingLevel
      buildingLevel.resetSegments()
      ifcSegments.forEach(ifcSegment => {
        ifcSegment.setBaseUnit(this.baseUnit())
        ifcSegment.convertToSegment()
      })

      this._makeSlabsRelative(buildingLevel)
    })

    const project = new ShellBuilderProject()
    project.setBuilding(building)

    return project
  }

  _makeSlabsRelative(buildingLevel) {
    const surfaces = buildingLevel.wallSurfaces()
    const surfaceEdges = surfaces.map(surface => surface.edge())

    buildingLevel.floorSlabs().forEach(slab => {
      const edges = slab.shape().edges()

      edges.forEach(edge => {
        const overlappingEdge = this._findOverlappingEdge(edge, surfaceEdges)
        if (!overlappingEdge) return

        const endpoints = [edge.begin(), edge.end()]
        const relativeVertices = this._makePointsRelative(endpoints, overlappingEdge)
        endpoints.forEach((endpoint, index) => (
          slab.shape().vertices().replace(endpoint, relativeVertices[index])
        ))
      })
    })
  }

  _findOverlappingEdge(slabEdge, surfaceEdges) {
    const parallelEdges = surfaceEdges.filter(surfaceEdge => surfaceEdge.isParallelTo(slabEdge, 1e-3))

    // Find the surface edge that overlaps with the current edge of the
    // slab. If there are multiple overlapping surfaces, attach to the one
    // with the longest edge
    let overlappingEdge = parallelEdges
      .filter(surfaceEdge => slabEdge.xy().overlapsBySignificantDistance(surfaceEdge.xy(), 1e-3))
      .sort((edge1, edge2) => edge2.length() - edge1.length())
      .first()

    if (!overlappingEdge) {
      const nearnessThreshold = 2.5

      // However, if there aren't any overlapping edges, find a surface
      // within 2.5 inches of my edge
      overlappingEdge = parallelEdges.reduce((nearest, surfaceEdge) => {
        if (!this._projectedEdgesOverlap(slabEdge, surfaceEdge)) return nearest

        const distance = slabEdge.xy().shortestLineFrom(surfaceEdge.begin().xy(), true).length()
        if (distance < nearest.distance && distance < nearnessThreshold) {
          return { distance, surfaceEdge }
        }

        return nearest
      }, { distance: Infinity, surfaceEdge: undefined }).surfaceEdge
    }

    return overlappingEdge
  }

  // Duplicated from GableFinder
  _projectedEdgesOverlap(edge1, edge2) {
    const projectedEdge2 = edge1.closestPointTo(edge2.begin(), true).to(edge1.closestPointTo(edge2.end(), true))
    return edge1.overlapsBySignificantDistance(projectedEdge2, 1e-3)
  }

  _makePointsRelative(points, surfaceEdge) {
    return points.map(point => {
      let nearestEndpoint = surfaceEdge.begin()
      if (point.distanceTo(surfaceEdge.end()) < point.distanceTo(nearestEndpoint)) {
        nearestEndpoint = surfaceEdge.end()
      }

      return RelativeLocator.makeRelativeTo(point, nearestEndpoint)
    })
  }
}

module.exports = IfcProjectBuilder
