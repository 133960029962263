const { Shape3DFigure } = require("construction-designer-core/drawing-editor-3D");

class RoofPanelFigure3D extends Shape3DFigure {
  materialNames() { return ['shingle'] }

  shape3D() {
    return this.model().shape3D()
  }
}

module.exports = RoofPanelFigure3D
