const {
  GeometricComponent
} = require('construction-designer-core/construction')

const PolylineFigure = require('shared/drawables/PolylineFigure')
const PolylineFigureXZ = require('shared/drawables/PolylineFigureXZ')
const EmptyFigure3D = require('shared/drawables/EmptyFigure3D')

class PolylineComponent extends GeometricComponent {
  constructor(polyline) {
    super()
    this._polyline = polyline
  }

  shape() { return this._polyline }
  geometry() { return this.shape() }
  vertices() { return this.shape().vertices() }
  edges() { return this.shape().edges() }

  referencePoint() { return this.vertices()[0] }

  //TODO possibly derive default from all edges
  // defaultAnnotations() {
  //   return this.boundingShape().edges().map(edge => new DimensionAnnotation(edge, 20, 'mm'))
  // }

  defaultFigure() {
    return new PolylineFigure(this.shape())
  }

  defaultFigureXZ() {
    return new PolylineFigureXZ(this.shape())
  }

  threeFigure() {
    return new EmptyFigure3D()
  }

  moveBy(x, y, z) {
    return this.shape().moveBy(x, y, z)
  }
}

module.exports = PolylineComponent
