const GableWallSegment = require('shared/domain-models/GableWallSegment')
const GableWallConnection = require('shared/domain-models/GableWallConnection')
const GableWallSurface = require('shared/domain-models/GableWallSurface')
const EdgeConnectedSegmentFinder = require('shared/operations/EdgeConnectedSegmentFinder')

class GableFinder {
  constructor(roof) {
    this._roof = roof
  }

  roof() { return this._roof }
  buildingLevel() { return this.roof().buildingLevel() }
  roofPanels() { return this.roof().roofPanels() }
  segmentMappings() { return this._segmentMappings }

  connectedSegmentFinder() {
    if (!this._connectedSegmentFinder) {
      this._connectedSegmentFinder = new EdgeConnectedSegmentFinder()
    }
    return this._connectedSegmentFinder
  }

  process() {
    if (!this.segmentMappings()) this._generateSegmentMappings()
    if (this.segmentMappings().size === 0) return { gableSegments: [], gableSurfaces: [] }

    if (!this._components) {
      const gableSegments = this._generateGableSegments()
      const gableConnections = this._generateGableConnections(gableSegments)
      const gableSurfaces = this._generateGableSurfaces(gableSegments, gableConnections)
      this._components = { gableSegments, gableSurfaces }
    }

    return this._components
  }

  _generateGableSegments() {
    const connectedEdges = this.segmentMappings()
    const gableSegments = []

    Array.from(connectedEdges.keys()).forEach(edge => {
      const offsetEdge = this.buildingLevel().conformToZLevel(edge.begin().xy()).to(
        this.buildingLevel().conformToZLevel(edge.end().xy())
      )
      const panelsAboveEdge = this.roofPanels().filter(panel => panel.shapeXY().containsEdge(edge.xy(), 1e-3))
      const highestPanel = this.roof().findHighestPanel(edge, panelsAboveEdge)
      if (!highestPanel) return
      const vertices = highestPanel.generateGableVertices(offsetEdge)

      if (!vertices) return

      const { lowerSegment } = connectedEdges.get(edge)
      const segment = GableWallSegment.withLowerSegment(vertices, lowerSegment)
      gableSegments.push(segment)
    })

    return gableSegments
  }

  _generateGableConnections(gableSegments) {
    const connectionMappings = new Map()

    Array.from(this.segmentMappings().keys()).forEach(edge => {
      const { connections: lowerConnections, terminals } = this.segmentMappings().get(edge)
      const gableSegment = gableSegments.find(segment => (
        segment.edge().xy().equals(edge.xy(), 1e-3)
      ))

      if (!gableSegment) return

      lowerConnections.forEach((lowerConnection, index) => {
        const terminal = terminals[index]
        let upperConnection = connectionMappings.get(lowerConnection)

        if (upperConnection) {
          upperConnection.attach(gableSegment, terminal)
        } else {
          upperConnection = GableWallConnection.withSegments([gableSegment], [terminal])
          connectionMappings.set(lowerConnection, upperConnection)
        }
      })
    })

    return Array.from(connectionMappings.values()).filter(connection => connection.segments().length > 1)
  }

  _generateGableSurfaces(gableSegments, gableConnections) {
    const connectedRuns = this._findConnectedRuns(gableSegments)
    const surfaces = []

    connectedRuns.forEach(connectedRun => {
      const segmentOuterVertices = []
      const lowerSurfaces = []

      connectedRun.forEach(gableSegment => {
        const outerEdge = this._findOutermostEdge(gableSegment)
        segmentOuterVertices.fastMerge(this._segmentVerticesOnEdge(gableSegment, outerEdge, gableConnections))
        lowerSurfaces.fastMerge(gableSegment.lowerSegment().surfaces())
      })

      // This is the lower surface that spans across each of the gable segments,
      // if there is one
      const overallSurface = lowerSurfaces.find(surface => {
        const surfaceEdgeXY = surface.edge().xy()

        return connectedRun.every(segment => {
          const outerEdge = this._findOutermostEdge(segment)
          return surfaceEdgeXY.containsEdge(outerEdge.xy(), 1e-3)
        })
      })

      const edgeDirection = connectedRun.first().edge().vector().normalized()
      const extremePoints = this._findExtremePoints(edgeDirection, segmentOuterVertices)

      let farthest = extremePoints.farthest
      let nearest = extremePoints.nearest
      if (overallSurface) {
        // Move the lower surface's edge up to the correct level
        const edge = overallSurface.edge().xy()
        const relativeBegin = this.buildingLevel().conformToZLevel(edge.begin())
        const relativeEnd = this.buildingLevel().conformToZLevel(edge.end())

        const actualEdge = relativeBegin.to(relativeEnd)
        farthest = actualEdge.closestPointTo(farthest)
        nearest = actualEdge.closestPointTo(nearest)
      }

      let surfaceEdge = farthest.to(nearest)
      // The surface edge created is reversed from the segment master edge. If
      // this surface edge doesn't contain the master edge, we should reverse it
      // again to conform to the normal of the 'opposite' surface edge
      if (!surfaceEdge.containsEdge(connectedRun.first().edge(), 1e-3)) surfaceEdge = surfaceEdge.reversed()

      const vertices = this.generateSurfaceVertices(surfaceEdge, connectedRun)
      const newSurface = new GableWallSurface(vertices)
      // Copy the lower surface's material, if it exists
      if (overallSurface) newSurface.setMaterialName(overallSurface.materialName())

      connectedRun.forEach(gableSegment => gableSegment.addSurface(newSurface))
      surfaces.push(newSurface)
    })

    return surfaces
  }

  generateSurfaceVertices(surfaceEdge, attachedSegments) {
    if (attachedSegments.length === 0) return

    const edgeDirection = surfaceEdge.vector().normalized()
    attachedSegments.sort((segment1, segment2) => {
      const segment1Center = segment1.edge().center()
      const segment2Center = segment2.edge().center()

      return edgeDirection.dot(segment2Center) - edgeDirection.dot(segment1Center)
    })

    const upperVertices = attachedSegments.flatMap((segment, index) => {
      const nextSegment = attachedSegments[index + 1]
      let vertices = [segment.upperEdge().begin(), segment.upperEdge().end()]

      if (nextSegment) {
        const nextBegin = nextSegment.edge().begin().xy().addZ(segment.upperEdge().end().z())
        vertices = [...vertices, nextBegin]
      }

      if (segment.edge().vector().normalized().equals(edgeDirection, 1e-3)) vertices.reverse()
      return vertices
    })

    const endZOffset = upperVertices.first().z()
    const beginZOffset = upperVertices.last().z()

    // NOTE: This assumes the surface is oriented the opposite direction of the
    // sorted sequence of segments
    return [
      surfaceEdge.begin(),
      surfaceEdge.end(),
      surfaceEdge.end().xy().addZ(endZOffset),
      ...upperVertices,
      surfaceEdge.begin().xy().addZ(beginZOffset),
    ]
  }

  /**
   * Finds all connection vertices and segment vertices along the given edge
   *
   * @param {WallSegment} segment
   * @param {Edge} edge
   * @returns {Locator[]}
   */
  _segmentVerticesOnEdge(segment, edge, gableConnections) {
    const connections = segment.connections().filter(connection => gableConnections.includes(connection))
    const segmentVertices = segment.vertices()
    // NOTE: uses generateFootprint here to avoid lazily initializing a cached footprint before it is associated to the building level
    const connectionVertices = connections.flatMap(connection => connection.generateFootprint().vertices())

    return [...connectionVertices, ...segmentVertices].filter(vertex => (
      edge.shortestLineFrom(vertex, true).length().isNearTo(0, 1e-3)
    ))
  }

  /**
   * Finds the outermost edge of the segment with regard to the panels's shape
   *
   * @param {WallSegment} segment
   * @returns {Edge}
   */
  _findOutermostEdge(segment) {
    const panelsAbove = this.roofPanels().filter(panel => panel.shapeXY().containsEdge(segment.edge().xy(), 1e-3))
    const highestPanel = this.roof().findHighestPanel(segment.edge(), panelsAbove)
    const shape = highestPanel.shapeXY()

    const masterEdge = segment.edge()
    const masterCenter = masterEdge.center()
    const direction = masterEdge.normal()
    const masterEdgeOffset = direction.dot(masterCenter)

    const vertexOffsets = shape.vertices().map(vertex => Math.abs(direction.dot(vertex)))
    const maxOffset = Math.max(...vertexOffsets)
    const minOffset = Math.min(...vertexOffsets)
    const widthInDirection = maxOffset - minOffset

    const centerOffset = direction.dot(shape.centroid())
    const distanceFromCenter = Math.abs(masterEdgeOffset) - Math.abs(centerOffset)
    // This is percent of the total width the distance between the segment and the center is
    const offsetPercentOfWidth = Math.abs(distanceFromCenter) / widthInDirection

    // If that percentage is less than 20%, automatically return the master edge
    // NOTE: Assuming the master edge here may not always be correct
    if (offsetPercentOfWidth <= 0.2) return masterEdge

    // This tolerance will match other edges within ~0.5 degrees of the original edge
    const shapeEdges = shape.edges().filter(edge => edge.isParallelTo(masterEdge, 1e-2))
    // Finds the shape edges that would overlap with the master edge if they
    // were both on top of each other
    const overlappingEdges = shapeEdges.filter(shapeEdge => this._projectedEdgesOverlap(shapeEdge, masterEdge))

    // Find the offset of the closest shape edge with regard to the master edge
    let nearestDistance = Infinity
    overlappingEdges.forEach(edge => {
      const beginPoint = edge.closestPointTo(masterEdge.begin())
      const endPoint = edge.closestPointTo(masterEdge.end())
      const beginDistance = direction.dot(beginPoint) - masterEdgeOffset
      const endDistance = direction.dot(endPoint) - masterEdgeOffset

      if (Math.abs(beginDistance) < Math.abs(nearestDistance)) nearestDistance = beginDistance
      if (Math.abs(endDistance) < Math.abs(nearestDistance)) nearestDistance = endDistance
    })

    // Based on the sign of the nearest distance, we can determine which edge
    // should be returned
    if (Math.sign(nearestDistance) === 1 && !nearestDistance.isNearTo(0, 1e-3)) {
      return segment.oppositeEdge()
    }
    return masterEdge
  }

  /**
   * Determines if the given edge has significant overlap when projected on the other edge
   *
   * @param {Edge} edge1
   * @param {Edge} edge2
   * @returns {Boolean}
   */
  _projectedEdgesOverlap(edge1, edge2) {
    const projectedEdge2 = edge1.closestPointTo(edge2.begin(), true).to(edge1.closestPointTo(edge2.end(), true))
    return edge1.overlapsBySignificantDistance(projectedEdge2, 1e-3)
  }

  /**
   * Finds connected runs of segments from within the given array
   *
   * @param {WallSegment[]} segments
   * @returns {WallSegment[][]}
   */
  _findConnectedRuns(segments) {
    const connectedRuns = []
    const startSegments = segments.slice()

    while (startSegments.length > 0) {
      const currentSegment = startSegments.first()
      const connectedSegments = this.connectedSegmentFinder().process(currentSegment)
      const parallel = [currentSegment, ...connectedSegments['parallelSegments']]
      connectedRuns.push(parallel)

      parallel.forEach(segment => startSegments.remove(segment))
    }
    return connectedRuns
  }

  /**
   * Finds the points farthest and nearest in the passed in direction
   *
   * @param {Locator} direction A unit vector signifying the direction
   * @param {Locator[]} points The original points
   * @returns {Object} An object literal with properties of "farthest" and "nearest"
   */
  _findExtremePoints(direction, points) {
    const extremes = points.reduce((current, point) => {
      const distance = direction.dot(point)

      if (distance > current.farthest.distance) {
        current.farthest = { point, distance }
      }
      if (distance < current.nearest.distance) {
        current.nearest = { point, distance }
      }

      return current
    }, {
      farthest: { point: undefined, distance: -Infinity },
      nearest: { point: undefined, distance: Infinity }
    })

    return { farthest: extremes.farthest.point, nearest: extremes.nearest.point }
  }

  _generateSegmentMappings() {
    // Should contain a gable segment's edge as the key, and an object literal
    // with the pattern of { connections: [], terminals: [], lowerSegment } as
    // the value
    this._segmentMappings = new Map()

    const panels = this.roofPanels()
    const exteriorLowerSegments = this.buildingLevel().previousLevel().exteriorWallSegments()
    const interiorLowerSegments = this.buildingLevel().previousLevel().interiorWallSegments()

    exteriorLowerSegments.forEach(segment => {
      const panelsAboveSegment = panels.filter(panel => panel.containsSegment(segment))
      if (panelsAboveSegment.length === 0) {
        const partialPanels = panels.filter(panel => panel.partiallyContainsSegment(segment))

        // Sort panels lowest to highest, and then get their shapes
        const panelShapes = partialPanels.sort((panelA, panelB) => {
          const panelAMaxHeight = panelA.shapeMaxHeightOffset()
          const panelBMaxHeight = panelB.shapeMaxHeightOffset()

          return panelAMaxHeight - panelBMaxHeight
        }).map(panel => panel.shapeXY())

        panelShapes.reduce((subtractionShapes, currentShape) => {
          let shapeRemainders = [currentShape]
          subtractionShapes.forEach(subtractionShape => {
            shapeRemainders = shapeRemainders.flatMap(remainder => remainder.difference(subtractionShape))
          })

          shapeRemainders.forEach(remainder => this._addPartialSegmentMapping(segment, remainder))
          return [...subtractionShapes, ...shapeRemainders]
        }, [])

        return
      }

      this._createSegmentMapping(segment.edge(), segment, segment.connections())
    })

    interiorLowerSegments.forEach(segment => {
      const panelsAboveSegment = panels.filter(panel => panel.partiallyContainsSegment(segment))
      if (panelsAboveSegment.length === 0) return
      const highestPanel = this.roof().findHighestPanel(segment.edge(), panelsAboveSegment)

      // TODO: Remove the need for a segment here - we won't always have an
      // interior segment when generating seam segment
      const seamPanels = highestPanel.findSeamPanels(segment)
      if (seamPanels.length === 0) return

      seamPanels.forEach(seamPanel => this._addPartialSegmentMapping(segment, seamPanel.shapeXY()))
    })
  }

  _addPartialSegmentMapping(segment, panelShape) {
    const edge = segment.edge().xy()
    const oppositeEdge = segment.oppositeEdge().xy()

    let partialEdge = edge.segmentsInsidePolygon(panelShape, 1e-2).first()
    if (!partialEdge) {
      const newEdge = oppositeEdge.segmentsInsidePolygon(panelShape, 1e-2).first()
      if (!newEdge) return

      // We want to end up with our partialEdge within the segment's master edge
      partialEdge = edge.closestPointTo(newEdge.begin()).to(edge.closestPointTo(newEdge.end()))
    }
    const partialEdgeEndpoints = [partialEdge.begin(), partialEdge.end()]

    // TODO: Need to figure out how to handle 0 length segments
    // Find the connections that still connect to the partial edge
    const validConnections = segment.connections().filter(connection => (
      partialEdgeEndpoints.some(endpoint => endpoint.equals(connection.getEndpoint(segment).xy(), 1e-3))
    ))

    this._createSegmentMapping(partialEdge, segment, validConnections)
  }

  _createSegmentMapping(edge, lowerSegment, connections) {
    // TODO: Need to figure out how to handle 0 length segments
    if (edge.length().isNearTo(0, 1e-3)) return

    const terminals = connections.map(connection => connection.getTerminus(lowerSegment))
    this.segmentMappings().set(edge, { connections, lowerSegment, terminals })
  }
}

module.exports = GableFinder
