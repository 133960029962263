const React = require('react')
const PropTypes = require('prop-types')
const { observer } = require('mobx-react')
const classNames = require('classnames')
const {
  Icon,
  SaveOnServerController
} = require('construction-designer-core/drawing-editor-react')
const wrapObservable = require('shared/helpers/wrapObservable')

/**
 * A button for saving the project to the server and indicating the user has
 * local changes
 */
class ShellBuilderSaveButton extends React.Component {
  static propTypes = {
    projectRepository: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (state.controller.repository() !== props.projectRepository) {
      state.controller.stopObservingSaveStatus()
      return { controller: new SaveOnServerController(props.projectRepository) }
    }
    return null
  }

  constructor(props) {
    super(props)

    const project = props.projectRepository.project().current()
    wrapObservable(project)

    this.state = {
      controller: new SaveOnServerController(props.projectRepository),
      observedProject: project
    }
  }

  componentDidMount() {
    const versionedProject = this.props.projectRepository.project()
    versionedProject.addCurrentVersionReplacedObserver(this, this.updateObservedProject)
  }

  componentWillUnmount() {
    this.controller().stopObservingSaveStatus()
    const versionedProject = this.props.projectRepository.project()
    versionedProject.removeCurrentVersionReplacedObserver(this, this.updateObservedProject)
  }

  updateObservedProject() {
    const project = this.props.projectRepository.project().current()
    wrapObservable(project)

    this.setState({ observedProject: project })
  }

  controller() { return this.state.controller }

  updateOnServer() {
    this.controller().save()
  }

  renderWarningIcon() {
    if (this.state.observedProject.isValid() || this.controller().saveStatus) return ''

    return <Icon name="warning" title="warning" className="btn__icon margin-right-xs" />
  }

  render() {
    let specificClassName = 'action-bar__button-unsaved'
    let buttonLabel = 'Save'
    if (this.controller().saveStatus) {
      specificClassName = 'action-bar__button-saved'
      buttonLabel = 'Saved'
    }

    const classes = classNames(
      'action-bar__button',
      specificClassName
    )

    return (
      <>
        <button onClick={this.updateOnServer.bind(this)} className={classes}>
          {this.renderWarningIcon()}
          {buttonLabel}
        </button>
      </>
    )
  }
}

module.exports = observer(ShellBuilderSaveButton)
