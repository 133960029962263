const React = require('react')
const PropTypes = require('prop-types')
const { Icon } = require('construction-designer-core/drawing-editor-react')
const classNames = require('classnames')

const LayersPanel = require('./LayersPanel')

class LayersControl extends React.Component {
  static propTypes = {
    drawingController: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      isDropdownOpen: false
    }
  }

  _toggleDropdown() {
    this.setState(prevState => ({ isDropdownOpen: !prevState.isDropdownOpen }))
  }

  _isDisabled() {
    return this.props.drawingController.layerManager().layerNames().length === 0
  }

  _isDropdownVisible() {
    return this.state.isDropdownOpen && !this._isDisabled()
  }

  _renderLayersDropdown() {
    if (this._isDropdownVisible()) {
      return (
        <div className="layers-control__dropdown">
          <LayersPanel drawingController={this.props.drawingController} />
        </div>
      )
    }
  }

  _getButtonClasses() {
    return classNames({
      'action-bar__dropdown-button': true,
      active: this.state.isDropdownOpen
    })
  }

  _getIconClasses() {
    return classNames({
      'action-bar__item-icon': true,
      active: this._isDropdownVisible()
    })
  }

  render() {
    return (
      <div className="layers-control">
        <button disabled={this._isDisabled()} className={this._getButtonClasses()} onClick={this._toggleDropdown.bind(this)} data-testid="layers-button" >
          <Icon name="layers" className={this._getIconClasses()} />
        </button>

        {this._renderLayersDropdown()}
      </div>
    )
  }
}

module.exports = LayersControl
