const EdgeWallMovementStrategy = require('shared/strategies/EdgeWallMovementStrategy')
const WallSegment = require('./WallSegment')

class EdgeWallSegment extends WallSegment {
  constructor(edge, ...args) {
    super(...args)
    this._edge = edge
  }

  edge() {
    return this._edge
  }
  setEdge(newEdge) {
    this._edge = newEdge

    this.connections().forEach(connection => (
      connection.updateEndpoint(this)
    ))
  }

  defaultMovementStrategy() {
    return new EdgeWallMovementStrategy(this)
  }

  exteriorEdge() {
    return this.edge()
  }

  oppositeEdge() {
    return this.sides().find(side => !side.equals(this.edge()))
  }

  connections() {
    if(!this._connections) {
      this._connections = []
    }
    return this._connections
  }

  addConnection(connection) {
    this.connections().push(connection)
  }

  removeConnection(connection) {
    this.connections().remove(connection)
  }

  delete() {
    super.delete()
    this.connections().slice().forEach(connection => connection.detach(this) )
  }

  // bleagh, can we do better than this?
  equals(other) {
    return other.constructor === this.constructor &&
      other.edge().equals(this.edge()) &&
      other.thickness().equals(this.thickness()) &&
      other.connections().every((connection, index) => connection.equals(this.connections()[index]) ) &&
      other.surfaces().every((surface, index) => surface.edge().equals(this.surfaces()[index].edge()) )
  }
}

module.exports = EdgeWallSegment
