const {
  ThreeMaterialLibrary,
  three: THREE
} = require("construction-designer-core/drawing-editor-3D")

const loadTexture = (name, loader) => {
  const texture = loader.load(require(`./brick-textures/${name}.jpg`))
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping
  texture.repeat.set(0.024, 0.024)

  return texture
}

ThreeMaterialLibrary.instance().register('brick', textureLoader => {
  return new THREE.MeshStandardMaterial({
    aoMap: loadTexture('ambient_occlusion', textureLoader),
    bumpMap: loadTexture('bump', textureLoader),
    map: loadTexture('color', textureLoader),
    normalMap: loadTexture('normal', textureLoader),
    roughnessMap: loadTexture('roughness', textureLoader)
  })
})
