const { ConstructionComponent } = require('construction-designer-core/construction')
const { simpleObserver } = require('construction-designer-core/standard-utilities')
const SystemStrategyService = require('shared/helpers/SystemStrategyService')

class ShellBuilderConstructionComponent extends ConstructionComponent {
  displayProperties() {
    if (!this._displayProperties) {
      this._displayProperties = this.defaultDisplayProperties()
    }
    return this._displayProperties
  }

  defaultDisplayProperties() {
    return []
  }

  systemStrategy() {
    if (!this._systemStrategy) {
      this._systemStrategy = SystemStrategyService.strategy()
    }
    return this._systemStrategy
  }

  nonEssentialProperties() {
    return [
      ...super.nonEssentialProperties(),
      '_displayProperties',
      '_displayPropertiesChangedObservers',
      '_systemStrategy'
    ]
  }
}

simpleObserver(ShellBuilderConstructionComponent, 'displayPropertiesChanged')
module.exports = ShellBuilderConstructionComponent
