const React = require('react')
const PropTypes = require('prop-types')

class BooleanMapPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'booleanmap'

  constructor(props) {
    super(props)

    this.state = {
      property: props.property,
      booleanMap: props.property.get()
    }
  }

  _onMappingToggle(mappingKey, { target: { checked } }) {
    const { property, booleanMap } = this.state

    const mapping = {
      ...booleanMap,
      [mappingKey]: checked
    }

    property.set(mapping)
    this.setState({ booleanMap: property.get() })

    this.props.drawingController.snapshotProjectAndRedraw()
  }

  _renderMapping() {
    const { booleanMap: mapping, property } = this.state
    const keys = Object.keys(mapping).sort()

    return (
      <div className="grid--side-panel">
        {keys.map(key => (
          <label className="label--side-panel grid__item" key={key}>
            <input
              type="checkbox"
              checked={mapping[key]}
              disabled={property.disabled() || !property.editable()}
              onChange={this._onMappingToggle.bind(this, key)}
            />
            {key}
          </label>
        ))}
      </div>
    )
  }

  render() {
    const property = this.state.property

    return (
      <>
        <div className="side-panel__row">
          <span className="font-sm">{property.label()}:</span>
        </div>
        {this._renderMapping()}
      </>
    )
  }
}

module.exports = BooleanMapPropertyView
