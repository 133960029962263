const sendRequest = (method, url, body) => (
  fetch(url, {
    method: method,
    headers: {
      'X-CSRF-Token': railsAuthenticityToken(),
      'Accept': 'application/json'
    },
    credentials: 'same-origin',
    body
  })
)

const getRequest = url => (
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    credentials: 'same-origin'
  })
)

const railsAuthenticityToken = () => {
  const csrfToken = document.querySelector("meta[name='csrf-token']")
  return csrfToken?.content
}

module.exports = { sendRequest, getRequest, railsAuthenticityToken }
