const {
  ClosedShapeFigure,
  EdgeHandle,
  LocatorHandle
} = require('construction-designer-core/drawing-editor')

const {
  ProportionalEdgeLocator,
  NormalToEdgeLocator,
  Polygon
} = require('construction-designer-core/geometry')

class WindowFigureXZ extends ClosedShapeFigure {
  constructor(window) {
    super()
    this._model = window
    this._edge = window.edge()
  }

  model() {
    return this._model
  }

  shape() {
    return this._edge
  }

  yLevel() { return this._edge.center().y() }

  _draw(context) {
    context._drawPolygon(this.model().shapeXZ())
  }

  defaultHandles() {
    return [
      new EdgeHandle(this._edge.xz(), this)
    ]
  }

  defaultProperties() {
    return {
      strokeStyle: 'rgba(102,178,255,1.0)',
      fillStyle: '#B3D9FF44'
    }
  }
}
module.exports = WindowFigureXZ