const WallSegmentFinder = require('./WallSegmentFinder')
const GableWallSegment = require('shared/domain-models/GableWallSegment')
const GableWallSurface = require('shared/domain-models/GableWallSurface')

class GableSurfaceFinder extends WallSegmentFinder {
  wallSegmentClass() { return GableWallSegment }
  wallSurfaceClass() { return GableWallSurface }

  _findSurface(edge, segments) {
    const existingEndpoints = this.buildingLevel().wallSurfaces().flatMap(surface => (
      [surface.edge().begin(), surface.edge().end()]
    ))

    const conformedBegin = this._conformToBuildingLevel(edge.begin(), existingEndpoints)
    const conformedEnd = this._conformToBuildingLevel(edge.end(), existingEndpoints)
    const surfaceEdge = conformedBegin.to(conformedEnd)

    const roof = this.buildingLevel().roof()

    let faceVertices
    if (segments.length > 0) {
      faceVertices = roof.gableFinder().generateSurfaceVertices(surfaceEdge, segments)
    } else {
      const panels = roof ? roof.roofPanels() : []
      const panelsAboveEdge = panels.filter(panel => panel.shapeXY().containsEdge(surfaceEdge, 1e-3))
      const currentPanel = roof.findHighestPanel(surfaceEdge, panelsAboveEdge)
      if (!currentPanel) return

      faceVertices = currentPanel.generateGableVertices(surfaceEdge)
    }
    if (!faceVertices) return

    const newSurface = new GableWallSurface(faceVertices)
    segments.forEach(segment => segment.addSurface(newSurface))

    roof.addSurface(newSurface)
    return newSurface
  }

  // This tool shouldn't ever create a new segment
  _createNewSegments() {
    return []
  }

  // This tool shouldn't ever create a new connection
  _createNewConnection() {
    return undefined
  }

  _conformToBuildingLevel(locator, existingEndpoints = []) {
    const existingLocator = existingEndpoints.find(endPoint => endPoint.xy().equals(locator, 1))
    if (existingLocator) return existingLocator

    return this.buildingLevel().conformToZLevel(locator)
  }
}

module.exports = GableSurfaceFinder
