const {
  ThreeMaterialLibrary,
  three: THREE
} = require("construction-designer-core/drawing-editor-3D")

const textureCache = {}

const loadTexture = (name, loader) => {
  if (!textureCache[name]) {
    textureCache[name] = loader.load(require(`./concrete-textures/${name}.jpg`))
    textureCache[name].wrapS = THREE.RepeatWrapping
    textureCache[name].wrapT = THREE.RepeatWrapping
    textureCache[name].repeat.set(0.01, 0.01)
  }

  return textureCache[name]
}

ThreeMaterialLibrary.instance().register('concrete', textureLoader => {
  return new THREE.MeshStandardMaterial({
    aoMap: loadTexture('ambient_occlusion', textureLoader),
    bumpMap: loadTexture('bump', textureLoader),
    map: loadTexture('color', textureLoader),
    normalMap: loadTexture('normal', textureLoader),
    roughnessMap: loadTexture('roughness', textureLoader),
    color: 0xA9A9A9
  })
})
