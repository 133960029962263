const WallSegmentTool = require('./WallSegmentTool')
const WallSegmentFinder = require('shared/operations/WallSegmentFinder')
const ExteriorWallSurface = require('shared/domain-models/ExteriorWallSurface')
const ContourFinder = require('shared/operations/ContourFinder')
const WallSegment = require('shared/domain-models/WallSegment')

class ExteriorWallSegmentTool extends WallSegmentTool {
  displayName() { return 'Exterior Wall' }
  iconName() { return 'exterior-wall' }

  _existingWallEndpoints() {
    return this.buildingLevel().exteriorWallSurfaces().flatMap(surface => (
      [surface.edge().begin(), surface.edge().end()]
    ))
  }

  reset() {
    this.__finder = undefined
    super.reset()
  }

  _addWallEdge(edgeAndMetadata) {
    const { edge, reversed } = edgeAndMetadata

    const finder = this._finder()
    const allEdges = this.buildingLevel().floorPlan().edges()

    const { segments: newSegments, surfaces } = finder.find(edge, allEdges)

    newSegments.forEach(newSegment => {
      if (!newSegment.componentOf()) { this.buildingLevel().addExteriorWallSegment(newSegment) }
    })
    surfaces.forEach(newSurface => this.buildingLevel().addExteriorSurface(newSurface) )

    this._previouslyReversed = reversed
    this._controller().select(newSegments.last())
    this._controller().snapshotProject()
  }

  _minimumThickness() { return (2).inches() }
  _defaultThickness() { return this.project().defaultExteriorWallThickness() }

  _isNowClosed(edge) {
    const surfaceEdges = this.buildingLevel().exteriorWallSurfaces().map(surface => surface.edge().xy())

    const contours = new ContourFinder().process(surfaceEdges)
    // Find the contour that contains the new edge's begin and end
    const contour = contours.find(contour => (
      contour.some(locator => locator.equals(edge.begin(), 1e-3))
      && contour.some(locator => locator.equals(edge.end(), 1e-3))
    ))

    // Shouldn't really happen, but can't hurt to check
    if (!contour) return false

    const contourClosed = contour.first().equals(contour.last(), 1e0)
    if (contourClosed) return true

    // If the contour isn't closed, but the edge's begin or end isn't at one the
    // ends of the contour (e.g. the edge is in the middle of the contour), then
    // we want the tool to deactivate
    return [contour.first(), contour.last()].every(locator => (
      !locator.equals(edge.begin(), 1e-3) && !locator.equals(edge.end(), 1e-3)
    ))
  }

  _finalizeLoop() {
    super._finalizeLoop()
    this.__finder = undefined
  }

  _finder() {
    if(!this.__finder) {
      const exteriorType = WallSegment.TYPES.Exterior
      this.__finder = new WallSegmentFinder(this.project(), this.buildingLevel(), this.project().defaultExteriorWallThickness(), exteriorType, ExteriorWallSurface)
    }
    return this.__finder
  }
}
module.exports = ExteriorWallSegmentTool
