const {
  GeometricComponent
} = require('construction-designer-core/construction')
const {
  Polygon
} = require('construction-designer-core/geometry')

class FloorOpening extends GeometricComponent {
  constructor(shape) {
    super()
    this._shape = shape
  }

  shape() { return this._shape }
  setShape(shape) { this._shape = shape }

  boundingBox() { return this.shape().boundingBox() }

  isFinished() { return this.shape().constructor == Polygon }

  moveBy(x, y, z) {
    return this.shape().moveBy(x, y, z)
  }
}
module.exports = FloorOpening