const cutEdgeByShape = require('shared/helpers/cutEdgeByShape')
const WallSegment = require('shared/domain-models/WallSegment')
const makeSurfaceEdge = require('shared/helpers/makeSurfaceEdge')

// A service object to build an interior wall segment and surfaces, and manage
// its intersections with other wall segments/connections/surfaces, given a
// building level and a pre-aligned wall centerline

class WallAndSurfaceBuilder {
  // NOTE: This expects that the centerline is at the zLevel the walls and
  // connections should be created at
  constructor(buildingLevel, centerline) {
    this._buildingLevel = buildingLevel
    this._centerline = centerline
  }

  buildingLevel() { return this._buildingLevel }
  centerline() { return this._centerline }

  _defaultThickness() { return (3.5).inches() } // TODO: get this from the project somehow
  thickness() { return this._thickness ?? this._defaultThickness() }
  setThickness(newThickness) {
    this._thickness = newThickness
  }

  tolerance() { return 1e-2 }

  static defaultWallType() {
    return WallSegment.TYPES.Interior
  }

  wallType() { return this._wallType || this.constructor.defaultWallType() }
  setWallType(newType) { this._wallType = newType }

  /**
   * @returns {Object} - An object literal in the form of { segments: [...] }
   */
  process() {
    // Subclasses should override
  }

  _shortenEdge(edge, shapes) {
    let shortenedEdge = edge
    shapes.forEach(shape => shortenedEdge = cutEdgeByShape(shape, shortenedEdge).first() || shortenedEdge)

    return shortenedEdge
  }

  _handleSurface(nearSurface, checkingBox, { newSegment, existingSegment }) {
    if (newSegment) this._reassignExistingSurfaces(nearSurface, existingSegment, newSegment)
    if (!nearSurface) return

    // calculate intersections, to determine the 'remainder' once the wall footprint coverage is subtracted
    const surfaceEdge = nearSurface.edge()
    const wallVector = existingSegment.exteriorEdge().vector().normalized()
    const newSurfaceEdges = cutEdgeByShape(checkingBox, surfaceEdge, wallVector).map(edge => (
      makeSurfaceEdge(edge, this.buildingLevel())
    ))

    if (newSurfaceEdges.length > 0) {
      let truncatedSurfaceEdge = newSurfaceEdges[0]
      let remainderSurfaceEdge = newSurfaceEdges[1]

      // If my new surface is facing away from my original surface, then
      // reverse my edge so it ends up facing the correct direction
      if (truncatedSurfaceEdge.normal().dot(surfaceEdge.normal()) < 0) {
        truncatedSurfaceEdge = truncatedSurfaceEdge.reversed()
        remainderSurfaceEdge = remainderSurfaceEdge?.reversed()
      }

      // truncate existing surface
      nearSurface.setEdge(truncatedSurfaceEdge)

      if (remainderSurfaceEdge) {
        const newSurface = this._createSurfaceFromRemainder(nearSurface, remainderSurfaceEdge)
        if (newSegment) newSegment.addSurface(newSurface)
      }
    }
  }

  _reassignExistingSurfaces(editedSurface, revisedWall, remainderWall) {
    const revisedWallEdges = revisedWall.footprint().edges()
    const remainderWallEdges = remainderWall.footprint().edges()

    revisedWall.surfaces().slice().filter(surface => surface !== editedSurface).forEach(surface => {
      const surfaceEdge = surface.edge()
      const adjoinsRevised = revisedWallEdges.some(edge => edge.overlaps(surfaceEdge, this.tolerance()))
      const adjoinsRemainder = remainderWallEdges.some(edge => edge.overlaps(surfaceEdge, this.tolerance()))

      if (adjoinsRevised && adjoinsRemainder) { // spans both segments, needs to be added to the new wall
        remainderWall.addSurface(surface)
      } else if (adjoinsRemainder) { // needs to be moved from the original wall to the new wall
        revisedWall.removeSurface(surface)
        remainderWall.addSurface(surface)
      }
    })
  }

  _createSurfaceFromRemainder(patternSurface, remainderEdge) {
    const newSurface = new patternSurface.constructor(remainderEdge)
    newSurface.setColor(patternSurface.color())
    newSurface.setMaterialName(patternSurface.materialName())
    patternSurface.componentOf().addSurface(newSurface)

    const truncatedEdge = patternSurface.edge()

    const otherSurfaceSegments = patternSurface.attachedSegments().filter(segment => (
      !segment.footprint().edges().some(edge => edge.overlaps(truncatedEdge, this.tolerance()))
    ))

    otherSurfaceSegments.forEach(otherSegment => {
      otherSegment.removeSurface(patternSurface)
      otherSegment.addSurface(newSurface)
    })

    return newSurface
  }
}

module.exports = WallAndSurfaceBuilder
