const ShellSegmentHandle = require('shared/handles/ShellSegmentHandle')
const DoorwayTool = require('shared/tools/DoorwayTool')
const OpeningTool = require('shared/tools/OpeningTool')
const PlaceDoorwayTool = require('shared/tools/PlaceDoorwayTool')
const PlaceWindowTool = require('shared/tools/PlaceWindowTool')
const SystemStrategy = require('../SystemStrategy')

class SimpleShellStrategy extends SystemStrategy {
  defaultTools(drawingEditor) {
    const controller = drawingEditor.drawingController()
    const zoomPalette = drawingEditor.zoomPalette()

    if (drawingEditor.project().isTemplate()) {
      return [
        new OpeningTool(controller, zoomPalette),
        new DoorwayTool(controller, zoomPalette)
      ]
    } else {
      return [
        new PlaceWindowTool(controller, zoomPalette),
        new PlaceDoorwayTool(controller, zoomPalette)
      ]
    }
  }

  configOptions() {
    return {
      door: {
        visualProperties: {
          strokeStyle: '#939393'
        }
      },
      doorway: {
        visualProperties: {
          strokeStyle: '#000',
          fillStyle: '#FFFFFF00'
        }
      },
      window: {
        visualProperties: {
          strokeStyle: '#000'
        }
      },
      wallSurface: {
        selectable: false
      }
    }
  }

  supportsQuotes() { return true }

  segmentSelfHandle(segment, buildingLevel) {
    return new ShellSegmentHandle(segment, buildingLevel)
  }

  drawWindow(context, window) {
    const thickness = window.thickness().toInches()
    const windowCenterline = window.edge().shiftedAlongNormalBy(thickness / 2)

    context._drawLine(windowCenterline.begin(), windowCenterline.end())
  }
}

module.exports = SimpleShellStrategy
