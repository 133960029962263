const { PolygonWithHoles } = require('construction-designer-core/geometry')
const SurfaceBuilder = require('shared/operations/SurfaceBuilder')
const CenterlineWallMovementStrategy = require('shared/strategies/CenterlineWallMovementStrategy')
const WallSegment = require('./WallSegment')

class CenterlineWallSegment extends WallSegment {
  constructor(centerline, ...args) {
    super(...args)
    this._centerline = centerline
  }

  centerline() {
    return this._centerline
  }
  setCenterline(newCenterline) {
    this._centerline = newCenterline
    this._footprint = undefined
    this._exteriorEdge = undefined
  }

  defaultMovementStrategy() {
    return new CenterlineWallMovementStrategy(this)
  }

  moveAlone(x, y, z) {
    super.moveAlone(x, y, z)
    this._footprint = undefined
  }

  moveEndpoint(endpointLocation, x, y, z) {
    const endpoints = [this.centerline().begin(), this.centerline().end()]
    const endpoint = endpoints.find(endpoint => endpoint.equals(endpointLocation, 1e-3))

    if (!endpoint) {
      console.warn("Couldn't find an endpoint to move in WallSegment #moveEndpoint")
      return false
    }

    const result = endpoint.moveBy(x, y, z)
    if (result) this._footprint = undefined
    return result
  }

  delete() {
    const connectedSegments = this.connectedSegments()
    const buildingLevel = this.buildingLevel()

    // We only want to find colinear segments that aren't parallel to this segment
    const nonParallelSegments = connectedSegments.filter(segment => (
      !segment.centerline().isParallelTo(this.centerline(), 1e-3)
    ))

    nonParallelSegments.forEach(segment => {
      const surfaces = segment.surfaces().slice()
      surfaces.forEach(surface => {
        // NOTE: WallSegment #removeSurface automatically calls
        // #removeAttachedSegment on the surface, but doesn't modify the edge.
        // Because we want to modify the edge, we need to manually call it here
        surface.removeAttachedSegment(segment, { modifyEdge: true })
        segment.removeSurface(surface)
      })
    })

    // NOTE: It's important that this happens before the next line, otherwise
    // this segment (which is about to be deleted), would be included in the
    // collection of intersecting segments
    connectedSegments.slice().forEach(segment => segment.removeConnectedSegment(this))
    super.delete()

    nonParallelSegments.forEach(segment => {
      const intersectingSegments = segment.connectedSegments()
      const surfaceBuilder = new SurfaceBuilder(segment, intersectingSegments, buildingLevel)
      surfaceBuilder.createSurfaces()
    })
  }

  footprint() {
    if (!this._footprint) {
      this._footprint = this._defaultFootprint()
    }
    return this._footprint
  }

  _defaultFootprint() {
    const sides =  this.sides()

    const vertices = [
      sides.first().begin(),
      sides.first().end(),
      sides.last().end(),
      sides.last().begin()
    ]

    return new PolygonWithHoles(vertices)
  }

  extendedCenterline(distance) {
    // TODO: We might need to make this relative
    const centerline = this.centerline()

    const edgeDirection = centerline.vector().normalized()
    const movement = edgeDirection.multipliedBy(distance)

    const extendedBegin = centerline.begin().subtract(movement)
    const extendedEnd = centerline.end().add(movement)

    return extendedBegin.to(extendedEnd)
  }

  // TEMP: This is just here to satisfy existing assumptions
  edge() { return this.centerline() }
  setEdge(newEdge) { this.setCenterline(newEdge) }

  exteriorEdge() {
    if (!this._exteriorEdge) {
      this._exteriorEdge = this.centerline().shiftedAlongNormalBy(-this.thickness().toInches() / 2)
    }
    return this._exteriorEdge
  }

  equals(other) {
    return other.constructor === this.constructor &&
      other.edge().equals(this.edge()) &&
      other.thickness().equals(this.thickness()) &&
      other.surfaces().every((surface, index) => surface.edge().equals(this.surfaces()[index].edge()) )
  }

  connectedSegments() {
    if (!this._connectedSegments) {
      this._connectedSegments = []
    }
    return this._connectedSegments
  }

  addConnectedSegment(otherSegment) {
    if (!this.connectedSegments().includes(otherSegment)) {
      this.connectedSegments().push(otherSegment)
      this._footprint = undefined
    }

    if (!otherSegment.connectedSegments().includes(this)) {
      otherSegment.connectedSegments().push(this)
      this._footprint = undefined
    }
  }

  removeConnectedSegment(otherSegment) {
    if (this.connectedSegments().includes(otherSegment)) {
      this.connectedSegments().remove(otherSegment)
      this._footprint = undefined
    }

    if (otherSegment.connectedSegments().includes(this)) {
      otherSegment.connectedSegments().remove(this)
      this._footprint = undefined
    }
  }

  sharedEndpoint(otherSegment) {
    const centerline = this.centerline()
    const endpoints = [centerline.begin(), centerline.end()]
    const otherEndpoints = [otherSegment.centerline().begin(), otherSegment.centerline().end()]

    return endpoints.find(endpoint =>
      otherEndpoints.some(segmentEndpoint => segmentEndpoint.equals(endpoint, 1e-3))
    )
  }

  sides() {
    const thickness = this.thickness().toInches()
    return [
      this.centerline().shiftedAlongNormalBy(-thickness / 2),
      this.centerline().shiftedAlongNormalBy(thickness / 2)
    ]
  }
}

module.exports = CenterlineWallSegment
