const {
  Circle
} = require('construction-designer-core/geometry')
const {
  GeometricComponent
} = require('construction-designer-core/construction')

const DrawnArcFigure = require('shared/drawables/DrawnArcFigure')

const EmptyFigure3D = require('shared/drawables/EmptyFigure3D')

class DrawnArc extends GeometricComponent {
  constructor(arc) {
    super()
    this._arc = arc
  }

  shape() { return this._arc }
  geometry() { return this.shape() }
  referencePoint() { return this.geometry().locator() }

  boundingShape() {
    return new Circle(this.referencePoint(), this.shape().radius())
  }

  moveBy(x, y, z) {
    return this._arc.moveBy(x, y, z)
  }

  defaultFigure() {
    return new DrawnArcFigure(this)
  }

  defaultFigureXZ() {
    return new DrawnArcFigure(this, 'xz')
  }

  threeFigure() {
    return new EmptyFigure3D()
  }

  annotations() { return [] }
}
module.exports = DrawnArc