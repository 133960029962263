const Door = require("./Door")
const {
  ExtrudedPolygon,
  ProportionalEdgeLocator,
} = require("construction-designer-core/geometry")

const DoorFigure = require('../drawables/DoorFigure')

class PocketDoor extends Door {
  static displayClassName() {
    return 'Pocket Door'
  }

  _leftHandedDoorEdge() {
    const edge = super.edge()
    const begin = new ProportionalEdgeLocator(edge, -1.25)
    const end = new ProportionalEdgeLocator(edge, 0.75)
    return begin.to(end)
  }

  _rightHandedDoorEdge() {
    const edge = super.edge()
    const begin = new ProportionalEdgeLocator(edge, 1.25)
    const end = new ProportionalEdgeLocator(edge, 0.25)
    return begin.to(end)
  }

  edge() {
    if (this.leftHanded()) {
      return this._leftHandedDoorEdge()
    } else {
      return this._rightHandedDoorEdge()
    }
  }

  vertices() {
    const edge1 = this.edge().shiftedAlongNormalBy(-this.thickness().toInches() / 2)
    const edge2 = this.edge().shiftedAlongNormalBy(this.thickness().toInches() / 2)

    return [
      edge1.begin(),
      edge1.end(),
      edge2.end(),
      edge2.begin()
    ]
  }

  shape3D() {
    return new ExtrudedPolygon(this.shape(), -this.height())
  }

  defaultFigure() {
    return new DoorFigure(this, this.systemStrategy())
  }
}

module.exports = PocketDoor
