const WallConnection = require('shared/domain-models/WallConnection')
const EdgeWallAndSurfaceBuilder = require('shared/operations/EdgeWallAndSurfaceBuilder')
const WallPlacementStrategy = require('./WallPlacementStrategy')

class ConnectionBasedPlacementStrategy extends WallPlacementStrategy {
  makeWallBuilder(edge) {
    return new EdgeWallAndSurfaceBuilder(this.buildingLevel(), edge)
  }

  placeEdge(edge, constrainLocation) {
    // Process a connection first, if it exists (assumes single connection, or collinear)
    const snappedObjects = this.snappedObjects()
    const snappedConnectionEntity = snappedObjects.find(({ object }) => object instanceof WallConnection)
    const edgeDirection = edge.vector().normalized()

    const locators = {
      begin: edge.begin(),
      end: edge.end()
    }

    if (snappedConnectionEntity) {
      snappedObjects.remove(snappedConnectionEntity)
      const { terminal, object: connection } = snappedConnectionEntity
      const vector = terminal === 'begin' ? edgeDirection : edgeDirection.negated()
      const side = connection.footprint().edges().find(connectionEdge => (
        connectionEdge.normal().negated().equals(vector, 1e-3)
      ))

      const snappedPoint = side.center()
      locators[terminal] = snappedPoint
      const otherTerminal = terminal === 'begin' ? 'end' : 'begin'
      const otherLocator = locators[otherTerminal]

      locators[otherTerminal] = constrainLocation(otherLocator.x(), otherLocator.y(), snappedPoint)
    }

    // After processing the connection, process the wall segments
    snappedObjects.forEach(({ terminal, object: snappedSegment }) => {
      const vector = terminal === 'begin' ? edgeDirection : edgeDirection.negated()

      // Find the edge of the segment footprint that has opposite normal to the vector
      // NOTE: This assumes that each of segment's edges will be facing inward
      const side = snappedSegment.footprint().edges().find(wallEdge => wallEdge.normal().negated().equals(vector, 1e-3) )
      locators[terminal] = side.closestPointTo(locators[terminal])
    })

    return locators
  }

  snapToExisting(point, matcher, terminal) {
    const wallConnections = this.buildingLevel().wallConnections()

    const connection = wallConnections.find(connection => connection.footprint().boundingBox().intersects(matcher))
    if (connection) {
      const snapPoints = connection.footprint().edges().map(edge => edge.center().snapshot())
      const snapLocked = true
      this.snappedObjects().push({ terminal, object: connection })

      return { snappedPoint: this._findClosestPoint(this._initialPoint || point, snapPoints), snapLocked }
    }

    const wallSegments = this.buildingLevel().wallSegments()
    const wallSegment = wallSegments.find(segment => segment.footprint().boundingBox().intersects(matcher))
    if (wallSegment) {
      const edges = wallSegment.sides()
      this.snappedObjects().push({ terminal, object: wallSegment })

      return { snappedPoint: this._findClosestPoint(point, edges.map(edge => edge.closestPointTo(point))) }
    }

    return { snappedPoint: point }
  }

  _findClosestPoint(target, points) {
    return points.reduce((closestSoFar, point) => {
      const distance = target.distanceTo3D(point)
      const closestDistance = closestSoFar.distance
      return distance < closestDistance ? { point, distance } : closestSoFar
    }, { point: null, distance: Infinity }).point
  }
}

module.exports = ConnectionBasedPlacementStrategy
