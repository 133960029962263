const {
  CompositeFigure
} = require('construction-designer-core/drawing-editor')

class FloorPlanFigure extends CompositeFigure {
  moveBy(_x, _y, _z) {
    return false
  }

  handlesAt(_x, _y, _options) { return [] }
}
module.exports = FloorPlanFigure
