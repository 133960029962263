const {
  ThreeMaterialLibrary,
  three: THREE
} = require("construction-designer-core/drawing-editor-3D")

const textureCache = {}

const loadTexture = (name, loader) => {
  if (!textureCache[name]) {
    textureCache[name] = loader.load(require(`./drywall-textures/${name}.jpg`))
    textureCache[name].wrapS = THREE.RepeatWrapping
    textureCache[name].wrapT = THREE.RepeatWrapping
    textureCache[name].repeat.set(0.1, 0.1)
  }

  return textureCache[name]
}

ThreeMaterialLibrary.instance().register(`drywall`, textureLoader => {
  return new THREE.MeshStandardMaterial({
    normalMap: loadTexture('normal', textureLoader),
    normalScale: new THREE.Vector2(0.25, 0.25),
    roughnessMap: loadTexture('roughness', textureLoader),
    color: 0xe0e0e0
  })
})
