const math = require('mathjs')
const { Point: { $P } } = require('construction-designer-core/geometry')

const convertNumberTo = (number, baseUnitType, finalUnitType) => (
  math.unit(number, baseUnitType).toNumber(finalUnitType)
)

const convertPointTo = (point, baseUnitType, finalUnitType) => (
  $P(
    convertNumberTo(point.x(), baseUnitType, finalUnitType),
    convertNumberTo(point.y(), baseUnitType, finalUnitType),
    convertNumberTo(point.z(), baseUnitType, finalUnitType)
  )
)

module.exports = { convertNumberTo, convertPointTo }
