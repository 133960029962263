const PropTypes = require('prop-types')
const React = require('react')

class ColorPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'color'

  constructor(props) {
    super(props)

    this.state = {
      color: this.props.property.get()
    }
  }

  _updateColor(event) {
    const newColor = event.target.value
    this.props.property.set(newColor)
    this.props.drawingController.snapshotProjectAndRedraw()
    this.setState({ color: newColor })
  }

  render() {
    return (
      <div className="side-panel__row">
        <label className="label--side-panel flex full-width justify-between">
          {this.props.property.label()}:
          <input
            type="color"
            className="input--color"
            value={this.state.color} onChange={this._updateColor.bind(this)}
          />
        </label>
      </div>
    )
  }
}
module.exports = ColorPropertyView
