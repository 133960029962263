const { RelativeLocator } = require('construction-designer-core/geometry')

const snapLocatorToSurface = (locator, buildingLevel) => {
  const endpoints = buildingLevel.wallSurfaces().flatMap(s => [s.edge().begin(), s.edge().end()])

  let endpoint = endpoints.find(endpoint => endpoint.equals(locator, 1))
  if (endpoint) return endpoint

  endpoint = endpoints.find(endpoint => endpoint.xy().equals(locator.xy(), 1))
  if (endpoint) {
    const zDifference = locator.z() - endpoint.z()

    return endpoint.addZ(zDifference)
  }

  return RelativeLocator.makeRelativeTo(
    locator, buildingLevel.zLevelLocator()
  )
}

/**
 * Takes an edge, and returns a new edge suitable for use as a surface
 *
 * @param {Edge} edge The original edge
 * @param {BuildingLevel} buildingLevel
 * @return {Edge} A new edge, potentially sharing endpoints of existing surfaces
 */
const makeSurfaceEdge = (edge, buildingLevel) => {
  const snappedBegin = snapLocatorToSurface(edge.begin(), buildingLevel)
  const snappedEnd = snapLocatorToSurface(edge.end(), buildingLevel)

  return snappedBegin.to(snappedEnd)
}

module.exports = makeSurfaceEdge
