const { ConstructionComponentHandle } = require('construction-designer-core/construction')
const { CompositeFigure } = require('construction-designer-core/drawing-editor')

class DoorwayFigure extends CompositeFigure {
  constructor(model, systemStrategy) {
    super()
    this.setModel(model)
    this._systemStrategy = systemStrategy
  }

  edge() {
    return this.model().edge()
  }

  zLevel() { return this.edge().center().z() }

  selfHandle() {
    return new ConstructionComponentHandle(this.model())
  }

  systemStrategy() { return this._systemStrategy }
  layer() { return 'Doors' }

  defaultProperties() {
    return this.systemStrategy().configOptions()['doorway']['visualProperties']
  }

  _drawSelf(context, _options) {
    context._drawPolygon(this.model().footprint())
  }
}

module.exports = DoorwayFigure
