const { SmartObjectBuilder } = require('construction-designer-core/smartJSON')

let isWebpackEnvironment
// If fs can't be required or is stubbed out, we're in a webpack environment.
// Otherwise, we're in a node/test environment.
try {
  const fs = require('fs')
  // The glob package uses readdirSync, so that's a good one to check
  isWebpackEnvironment = (typeof fs.readdirSync !== 'function')
} catch {
  isWebpackEnvironment = true
}

let domainModels, geometryModels
if (isWebpackEnvironment) { // Usually browser
  const domainModelsRequireContext = require.context('shared/domain-models')
  domainModels = domainModelsRequireContext.keys().map(domainModelsRequireContext)

  // TODO: Remove this when pulling the geometry objects in core
  const geometryRequireContext = require.context('shared/geometry')
  geometryModels = geometryRequireContext.keys().map(geometryRequireContext)
} else { // Node/test environment
  const path = require('path')
  const glob = require('glob')
  const domainModelFiles = glob.sync('**/*.js', {
    cwd: path.resolve(path.join(__dirname, '../../shared/domain-models'))
  })
  const geometryModelFiles = glob.sync('**/*.js', {
    cwd: path.resolve(path.join(__dirname, '../../shared/geometry'))
  })

  domainModels = []
  domainModelFiles.forEach(file => {
    domainModels.push(require(`../../shared/domain-models/${file}`))
  })

  geometryModels = []
  geometryModelFiles.forEach(file => {
    geometryModels.push(require(`../../shared/geometry/${file}`))
  })
}

SmartObjectBuilder.configure(config => {
  config.classes.addClasses(...domainModels)
  config.classes.addClasses(...geometryModels)

  class RoofSectionPlaceholder {}
  config.classes.addClass(RoofSectionPlaceholder, 'RoofSection')

  class ExteriorWallPlaceholder {}
  config.classes.addClass(ExteriorWallPlaceholder, 'ExteriorWall')

  class WallSegmentPlaceholder {
    stableID() { return this._stableID }
    edge() { return this._edge }
    removeConnection() { }
  }
  config.classes.addClass(WallSegmentPlaceholder, 'ExteriorWallSegment')
  config.classes.addClass(WallSegmentPlaceholder, 'InteriorWallSegment')
})
