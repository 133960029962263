const ReadableProperty = require('./ReadableProperty')

class EditableProperty extends ReadableProperty {
  constructor(component, name, { disabled, set, toggle, validate, ...options } = {}) {
    super(component, name, options)

    this._disabled = disabled
    if (set) this.set = set
    if (toggle) this.toggle = toggle
    if (validate) this.validate = validate

    const nameParts = this.name().split(' ')
    this._setterName = `set${nameParts.join('')}`
    this._toggleName = `toggle${nameParts.join('')}`
    this._validateName = `validate${nameParts.join('')}`
  }

  editable() { return true }
  disabled() { return this._disabled }

  // region overridable
  set(value) {
    return this.component()[this._setterName](value)
  }

  toggle() {
    return this.component()[this._toggleName]()
  }

  validate(isValidUnit, unit) {
    if (this.component()[this._validateName]) {
      return this.component()[this._validateName](isValidUnit, unit)
    }
  }
  // endregion overridable
}

module.exports = EditableProperty
