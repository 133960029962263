const oneSecond = 1000

class AutosaveController {
  constructor(projectRepository) {
    this._projectRepository = projectRepository
    this._debounceTime = 4 * oneSecond
    this._maxWait = 25 * oneSecond
  }

  start() {
    this.project().addCurrentVersionChangedObserver(this, this.requestAutosave)
  }

  projectRepository() { return this._projectRepository }
  project() { return this.projectRepository().project() }

  requestAutosave() {
    if (this._debounceTimeoutId !== undefined) clearTimeout(this._debounceTimeoutId)

    this._debounceTimeoutId = setTimeout(() => this._performSave(), this._debounceTime)

    if (this._maxWaitTimeoutId === undefined) {
      this._maxWaitTimeoutId = setTimeout(() => this._performSave(), this._maxWait)
    }
  }

  cancelAutosave() {
    this._cancelTimers()
  }

  _performSave() {
    this._cancelTimers()

    if (this.project().isValid()) {
      this.projectRepository().saveToServer()
    } else {
      console.warn("NOTICE: Not saving because the project isn't in a valid state.")
    }
  }

  _cancelTimers() {
    clearTimeout(this._debounceTimeoutId)
    clearTimeout(this._maxWaitTimeoutId)

    this._debounceTimeoutId = undefined
    this._maxWaitTimeoutId = undefined
  }
}

module.exports = AutosaveController
