const { PersistentProjectRepository } = require('construction-designer-core/drawing-editor')
const AutosaveController = require('./AutosaveController')
const ShellBuilderVersionedProject = require('../editor-models/ShellBuilderVersionedProject')
const DXFPlanExternalObjectCache = require('../domain-models/DXFPlanExternalObjectCache')
const IfcProjectBuilder = require('shared/domain-models/ifc-models/IfcProjectBuilder')

class ShellBuilderProjectRepository extends PersistentProjectRepository {
  externalObjectCache() {
    if (!this._externalObjectCache) {
      this._externalObjectCache = new DXFPlanExternalObjectCache()
    }
    return this._externalObjectCache
  }

  async _loadFromJSON(projectPayload) {
    const { design, project_estimations: projectEstimations, ...metadata } = projectPayload

    const json = JSON.parse(design)
    let project
    if (json.fromIfc) {
      const builder = new IfcProjectBuilder(design, this)
      project = await builder.createProject()
    } else {
      project = await this.buildObjectFromJSON(design)
    }

    if (projectEstimations) this._estimations = await this.buildObjectFromJSON(projectEstimations)

    const versionedProject = new ShellBuilderVersionedProject(this.externalObjectCache(), project, metadata)
    versionedProject.addCurrentVersionReplacedObserver(this, this.setupCollections.bind(this))

    await this.setProject(versionedProject)
    this.setupCollections()

    this.setupAutosave()
  }

  setupCollections() {
    this.project().setAvailableProjectEstimations(this.estimations())
  }

  estimations() {
    return this._estimations || []
  }

  buildJSONFromObject(object) {
    return this.jsonBuilder().stringify(object, this.externalObjectCache())
  }

  buildObjectFromJSON(json) {
    return this.objectBuilder().parse(json, this.externalObjectCache())
  }

  projectsPath() {
    return this._projectsPath || super.projectsPath()
  }

  setProjectsPath(newProjectsPath) {
    this._projectsPath = newProjectsPath
  }

  _loadFromServerApiUrl(projectId) {
    // May not exist in guest mode
    if (projectId) return `${this.projectsPath()}/${projectId}`
    return this.projectsPath()
  }

  /**
   * Immediately save the project to the server, superseding any queued autosave
   */
  async updateOnServer() {
    // If the user manually clicks the save button, we assume the project is in
    // a valid state
    this.project().setValid(true)

    this.autosaveController().cancelAutosave()
    return this.saveToServer()
  }

  autosaveController() {
    if (!this._autosaveController) {
      this._autosaveController = new AutosaveController(this)
    }
    return this._autosaveController
  }

  /**
   * async saveToServer
   *
   * Perform an actual save to server. Intended to be private to the repository
   * and its autosave controller. If you want to queue an autosave, use
   * AutosaveController #requestAutosave. If you want to trigger a save _now_,
   * use #updateOnServer.
   */
  async saveToServer() {
    const { json: design } = await this.generateProjectJSON()
    const project = {
      ...this.project().metadata(),
      design
    }
    await this.apiClient().put(this._updateOnServerApiUrl(),
      {
        body: { project }
      }
    )
    await this.setLastPersistedState(design)
  }

  setupAutosave() {
    if (this.autosaveEnabled()) this.autosaveController().start()
  }

  autosaveEnabled() {
    if (this._autosaveEnabled === undefined) {
      this._autosaveEnabled = true
    }
    return this._autosaveEnabled
  }

  disableAutosave() {
    this._autosaveEnabled = false
  }
}

module.exports = ShellBuilderProjectRepository
