const {
  ComponentSelectionTool, Handle
} = require('construction-designer-core/drawing-editor')
const {
  Point: { $P }
} = require('construction-designer-core/geometry')

const Window = require('shared/domain-models/Window')

class OpeningTool extends ComponentSelectionTool {
  buildingLevel() {
    return this._controller().buildingLevel()
  }

  mouseDown(x, y, options) {
    this.panZoomTool().mouseDown(x, y, options)
  }

  _allHandlesUnderCursor(x, y, scale) {
    // We want want to see _all_ handles, including those that are VALID_FOR_SELECTED
    const validWhen = [Handle.VALID_FOR_HOVER, Handle.VALID_FOR_SELECTED]
    const allHandles = super._allHandlesUnderCursor(x, y, scale, validWhen)
    // This checks for anything that can have a window or door attached
    return allHandles.filter(handle => handle.object().openings)
  }

  displayName() {
    return 'Windows'
  }

  iconName() {
    return 'windows'
  }

  mouseUp(x, y, options) {
    this.panZoomTool().mouseUp(x, y, options)

    if (!this.didPan()) {
      const walls = this.buildingLevel().wallSegments().filter(part => part.contains(x, y))
      if (walls.length === 0) {
        // If you didn't click on a wall, and you weren't panning, then deselect
        // whatever's selected
        this.select(undefined)
        return false
      }

      this._findOpening(walls, $P(x, y))
      this._controller().redraw()
    }
  }

  _findOpening(walls, pointer) {
    walls.forEach(wall => {
      const wallEdge = wall.edge().xy()
      const pointOnWallSegment = wallEdge.closestRelativePointTo(pointer)
      const box = wall.footprint().boundingBox()
      const vector = wallEdge.vector().normalized()

      // find non-tiny edges that are perpendicular to the wall and overlap it
      const edges = this.buildingLevel().floorPlan().edges().filter(edge => (
        edge.boundingBox().intersects(box) && edge.length() > 1 && edge.vector().normalized().dot(vector).isNearTo(0, 1e-3)
      ))

      const snapPoints = edges.map(edge => wallEdge.closestRelativePointTo(edge.begin()).snapshot() )
      const endPoints = [wallEdge.begin(), wallEdge.end()].map(locator => locator.snapshot() )

      const sorted = [...snapPoints, ...endPoints, pointOnWallSegment].sort((a, b) => (
        wallEdge.begin().distanceTo(a) - wallEdge.begin().distanceTo(b)
      ))
      const targetIndex = sorted.indexOf(pointOnWallSegment)
      if(targetIndex === 0 || targetIndex === sorted.length - 1) { return false }

      const edgeBegin = this.buildingLevel().conformToFloorLevel(sorted[targetIndex - 1])
      const edgeEnd = this.buildingLevel().conformToFloorLevel(sorted[targetIndex + 1])
      const edge = edgeBegin.to(edgeEnd)

      const opening = this._addOpening(wall, edge)
      this._controller().setSelectedComponent(opening)
      this._controller().snapshotProject()
    })
  }

  _addOpening(wall, edge) {
    const newWindow = new Window(edge)
    wall.addWindow(newWindow)
    return newWindow
  }
}
module.exports = OpeningTool
