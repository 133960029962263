const {
  TextFigure
} = require('construction-designer-core/drawing-editor')

class LabelFigure extends TextFigure {
  constructor(model) {
    super(model.text(), model.locator())
    this._model = model
  }

  model() { return this._model }

  _scale() {
    return this._scaleValue
  }

  _setScale(newScale) {
    this._scaleValue = newScale
  }

  _draw(context) {
    const text = this.text()
    this._setScaleFromContext(context)
    this._setFontSizeOnContext(context)

    context._alterAndRestoreAfter(() => {
      this._positionText(context)
      this._orientText(context)
      this._drawText(context, text)
    })
  }

  _drawText(context, text) {
    context.fillText(text, 0, 0)
  }

  _positionText(context) {
    context.translate(this.locator().x(), this.locator().y())
  }

  _orientText(context) {
    let rotationAngle = this.rotation()
    if (this._isTextOrientedUpsideDown(rotationAngle)) {
      rotationAngle += Math.PI
    }
    context.rotate(rotationAngle)
  }

  _isTextOrientedUpsideDown(rotationAngle) {
    return rotationAngle > (Math.PI_2 - 0.001) &&
           rotationAngle < (Math.THREE_PI_2 - 0.001)
  }

  _setFontSizeOnContext(context) {
    context.font = `${this.fontSize()}px serif`
    context.textAlign = this.textAlign()
  }

  _setScaleFromContext(context) {
    this._setScale(context.runningScaleX())
  }

  text() { return this.model()._text }
  fontSize() { return this.model().fontSize() }
  rotation() { return this.model().rotation() }
  textAlign() { return this.model().textAlign() }

  handles() { return [] }

  defaultProperties() {
    return {
      fillStyle: '#0077CE'
    }
  }
}

module.exports = LabelFigure
