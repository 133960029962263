const { three: THREE } = require('construction-designer-core/drawing-editor-3D')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')
const {
  Polygon,
  Rectangle,
  Point: { $P }
} = require('construction-designer-core/geometry')
const math = require('mathjs')

const FloorFigure = require('shared/drawables/FloorFigure')
const FloorFigureXZ = require('shared/drawables/FloorFigureXZ')
const EmptyFigure3D = require('shared/drawables/EmptyFigure3D')
const FloorFigure3D = require('shared/drawables/FloorFigure3D')

class Floor extends ShellBuilderConstructionComponent {
  constructor(shape) {
    super()
    this._shape = shape
  }

  buildingLevel() { return this.componentOf() }

  zLevel() {
    const centroidZ = this.shape()?.centroid().z()
    if (centroidZ !== undefined) { return centroidZ }

    return this.buildingLevel().zLevel()
  }
  moveBy(x, y, z) { return this.buildingLevel().moveBy(x, y, z) }
  materialNames() { return ['hardwood-floor', 'drywall'] }

  thickness() {
    if (!this._thickness) {
      this._thickness = (1).feet()
    }
    return this._thickness
  }
  setThickness(newThickness) { this._thickness = newThickness }

  /**
   * @returns {number} The area of the floor in square feet
   */
  area() {
    if (!this.shape()) return math.unit(0, 'sqft')
    return math.unit(this.shape().area() / (12 ** 2), 'sqft')
  }

  defaultFigure() {
    return new FloorFigure(this)
  }

  defaultFigureXZ() {
    return new FloorFigureXZ(this)
  }

  threeFigure() {
    if(!this.shape()) {
      return new EmptyFigure3D()
    }
    return new FloorFigure3D(this)
  }

  shape() { return this._shape }
  setShape(shape) { this._shape = shape }

  shapeXZ() {
    const shape = this.shape()
    if (!shape) { return new Rectangle(0, 0, 0, 0) }

    const vertices = shape.vertices()
    const zLevel = this.zLevel()
    const thickness = this.thickness().toInches()
    let maxX = -Infinity, minX = Infinity
    vertices.forEach(v => {
      if(v.x() > maxX) {
        maxX = v.x()
      } else if(v.x() < minX) {
        minX = v.x()
      }
    })
    return new Polygon([
      $P(minX, zLevel),
      $P(maxX, zLevel),
      $P(maxX, zLevel + thickness),
      $P(minX, zLevel + thickness)
    ])
  }

  shape3D() {
    // convert to THREE.Shape
    const floorShape = this._convertShapeToThreeJS(this.shape())

    // extrude
    const curve = new THREE.LineCurve3(
      new THREE.Vector3(0, 0, this.zLevel()),
      new THREE.Vector3(0, 0, this.zLevel() + this.thickness().toInches())
    )
    const geometry = new THREE.ExtrudeBufferGeometry(
      floorShape,
      {
        extrudePath: curve,
        bevelEnabled: false
      }
    )
    geometry.rotateZ(Math.PI_2)
    return geometry
  }

  openings() {
    if(!this._openings) {
      this._openings = []
    }
    return this._openings
  }

  addOpening(opening) {
    this.openings().push(opening)
  }

  boundingBox() {
    return this.shape()?.boundingBox() || new Rectangle(0, 0, 0, 0)
  }

  _convertShapeToThreeJS(polygon) {
    const shape = new THREE.Shape(polygon.vertices().map(v => v.toThreeJS()))
    const holes = polygon.holes()

    holes.forEach(hole => {
      shape.holes.push(new THREE.Path(hole.vertices().map(v => v.toThreeJS())))
    })
    return shape
  }
}
module.exports = Floor
