const { Polygon } = require('construction-designer-core/geometry')
const { three: THREE } = require('construction-designer-core/drawing-editor-3D')
const WallColumn = require('./WallColumn')
const GableWallSurface = require('./GableWallSurface')
const earcut = require('earcut')
const EmptyFigure3D = require('shared/drawables/EmptyFigure3D')

class GableColumn extends WallColumn {
  constructor(shape, lowerColumn, roofPanel) {
    super(shape)
    this._lowerColumn = lowerColumn
    this._roofPanel = roofPanel
  }

  lowerColumn() { return this._lowerColumn }
  roofPanel() { return this._roofPanel }
  roof() { return this.componentOf() }

  sideFaces() {
    if (!this._sideFaces) {
      this._sideFaces = []
      this.edges().forEach(edge => {
        const faceVertices = this.roofPanel().generateGableVertices(edge)
        if (faceVertices) this._sideFaces.push(new Polygon(faceVertices))
      })
    }
    return this._sideFaces
  }

  delete() {
    this.roof().removeColumn(this)
    this.surfaces().forEach(surface => surface.delete())
  }

  materialName() {
    if (this._materialName) return this._materialName
    return this.lowerColumn().materialName()
  }

  _defaultSurfaces() {
    return this.sideFaces().map(face => {
      const surface = new GableWallSurface(face.vertices())
      surface.addAttachedSegment(this)
      surface.setComponentOf(this)
      surface.setMaterialName(this.materialName())
      return surface
    })
  }

  shape3D() {
    const lowerVertices = this.edges().map(edge => edge.begin().toThreeJS())
    // The last of the side vertices is the vertex above the begin of the lower edge
    const upperVertices = this.sideFaces().map(face => face.vertices().last().toThreeJS())
    const geometry = new THREE.Geometry()

    if (upperVertices.length !== lowerVertices.length) return

    geometry.vertices.fastMerge(lowerVertices)
    geometry.vertices.fastMerge(upperVertices)
    const upperIndexOffset = lowerVertices.length

    this._triangulateEndcapFaces(geometry, upperIndexOffset)
    this._triangulateSideFaces(geometry, upperIndexOffset)
    return geometry
  }

  _triangulateEndcapFaces(geometry, upperIndexOffset) {
    const lowerVertices = geometry.vertices.slice(0, upperIndexOffset)
    const triangles = earcut(lowerVertices.flatMap(vertex => vertex.toArray().slice(0, 2)))

    for (let index = 0; index < triangles.length; index += 3) {
      const vertex1 = triangles[index]
      const vertex2 = triangles[index + 1]
      const vertex3 = triangles[index + 2]

      geometry.faces.push(new THREE.Face3(vertex1, vertex2, vertex3))
      // Flipping the order of the vertices so that the face's normal is in the opposite direction
      geometry.faces.push(new THREE.Face3(vertex3 + upperIndexOffset, vertex2 + upperIndexOffset, vertex1 + upperIndexOffset))
    }
  }

  _triangulateSideFaces(geometry, upperIndexOffset) {
    const lastIndex = upperIndexOffset - 1
    for (let index = 0; index < lastIndex; index += 1) {
      const nextIndex = index + 1
      const upperIndex = index + upperIndexOffset
      const nextUpperIndex = nextIndex + upperIndexOffset

      geometry.faces.push(
        new THREE.Face3(upperIndex, nextIndex, index),
        new THREE.Face3(nextUpperIndex, nextIndex, upperIndex)
      )
    }

    geometry.faces.push(
      new THREE.Face3(lastIndex + upperIndexOffset, 0, lastIndex),
      new THREE.Face3(upperIndexOffset, 0, lastIndex + upperIndexOffset)
    )
  }

  threeFigure() {
    if (!this.shape3D()) return new EmptyFigure3D()
    return super.threeFigure()
  }
}
module.exports = GableColumn
