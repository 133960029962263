const React = require('react')
const PropTypes = require('prop-types')
const classNames = require('classnames')
const UploadInputStatus = require('./helpers/UploadInputStatus')
const FileUploadInput = require('./FileUploadInput')

class IFCFields extends React.Component {
  static propTypes = {
    conversionRequest: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      submittingForm: false,
      status: UploadInputStatus.Completed
    }

    this._abortController = new AbortController()
  }

  componentWillUnmount() {
    // Abort the file upload input if this component is unmounting, and
    // the form isn't being submitted (if the modal is being closed or something)
    if (!this.state.submittingForm) this._abortController.abort()
  }

  _setStatus(status) {
    this.setState({ status })
  }

  _renderInput() {
    const conversionRequest = this.props.conversionRequest
    const { project_design: formError } = conversionRequest?.errors || {}

    return (
      <div className="flex form__wrapper--project">
        <FileUploadInput
          resourcePath="/api/v1/ifc_conversion_requests"
          uploadPath="/api/v1/ifc_imports"
          labelText="BIM File (*.ifcxml):"
          inputName="project[ifc_conversion_request_attributes][id]"
          fileTypes=".ifcxml"
          abortSignal={this._abortController.signal}
          onStatusChange={newStatus => this._setStatus(newStatus)}
          formError={formError}
          initialResourceId={conversionRequest?.id}
        />
      </div>
    )
  }

  render() {
    const submitDisabled = this.state.status !== UploadInputStatus.Completed

    return (
      <>
        {this._renderInput()}

        <footer className="form__footer flex full-width justify-end">
          <a className="btn btn--link modal-close" href="#">Cancel</a>
          <input
            type="submit"
            name="commit"
            value="Create Project"
            className={classNames('btn', 'btn--primary', { 'btn--disabled': submitDisabled })}
            onClick={() => this.setState({ submittingForm: true })}
            data-disable-with="Create Project"
            disabled={submitDisabled}
          />
        </footer>
      </>
    )
  }
}
module.exports = IFCFields
