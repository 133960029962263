const {
  Drawable
} = require('construction-designer-core/drawing-editor')

const {
  FrontHandle
} = require('construction-designer-core/drawing-editor')

class FloorFigureXZ extends Drawable {
  constructor(floor) {
    super()
    this._model = floor
  }

  model() { return this._model }

  yLevel() { return 0 }

  _draw(context) {
    context._drawPolygon(this.model().shapeXZ())
  }

  contains(x, z) {
    return this.model().shapeXZ().contains(x, z)
  }

  defaultHandles() {
    const handle = new FrontHandle(this)
    handle.contains = (x, y, z) => this.contains(x, y)
    handle._draw = (context) => {
      context.fillStyle = "#FFD45D"
      context.strokeStyle = "rgba(255,255,255,0.0)"
      context.globalAlpha = 0.8
      context._drawPolygon(this.model().shapeXZ())
    }
    handle.selectableComponent = () => this._model
    handle.validWhen = () => 'hover'
    return [
      handle
    ]
  }

  moveBy(x, y, z) {
    return this.model().moveBy(x, y, z)
  }

  defaultProperties() {
    return {
      fillStyle: '#eeeeee22'
    }
  }
}
module.exports = FloorFigureXZ
