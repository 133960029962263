const PolylineFigure = require('./PolylineFigure')

class DrawnPolylineFigureXZ extends PolylineFigure {
  constructor(model) {
    super(model.shapeXZ())
    this._model = model
  }

  model() { return this._model }
  shape() { return this.model().shapeXZ() }
  handles() { return [] }

  defaultProperties() {
    return {
      strokeStyle: '#0077CE'
    }
  }
}
module.exports = DrawnPolylineFigureXZ