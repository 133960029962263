const { Drawable } = require('construction-designer-core/drawing-editor')
const WallSurfaceHandle = require('shared/handles/WallSurfaceHandle')

class WallSurfaceFigure extends Drawable {
  constructor(model) {
    super()
    this._model = model
  }

  model() { return this._model }
  edge() { return this.model().edge() }
  contains(x, y) { return this.edge().contains(x, y) }

  _draw(context) {
    const edges = this.model().edgeWithoutOpenings()
    edges.forEach(edge => context._drawLine(edge.begin(), edge.end()))
  }

  boundingBox() {
    return this.edge().boundingBox()
  }

  layer() { return 'Walls' }

  zLevel() { return this.model().edge().begin().z() }

  defaultProperties() {
    return {
      strokeStyle: '#000000FF'
    }
  }

  selfHandle() {
    return new WallSurfaceHandle(this.model(), this)
  }
}
module.exports = WallSurfaceFigure
