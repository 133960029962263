let isBrowser

try {
  const fs = require('fs')

  // fs.readFile should only exist in Node. If it's not a function, this is
  // running in a browser and Webpack has probably stubbed out the fs module.
  isBrowser = typeof fs.readFile !== 'function'
} catch {
  // If anything in the try block fails, it's probably because we're not in Node.
  isBrowser = true
}

if (isBrowser) {
  // Texture loading only works in the browser and these materials are only
  // used in that environment.

  const materialsRequireContext = require.context('shared/drawables/three-materials')
  materialsRequireContext.keys().map(materialsRequireContext)
}
