const AuthenticatedModal = require('components/AuthenticatedModal')
const DesignRelatedForm = require('components/DesignRelatedForm')
const React = require('react')
const ModeStrategy = require('./ModeStrategy')

class GuestModeStrategy extends ModeStrategy {
  projectsPath() {
    return '/guest'
  }

  autosaveable() { return false }
  showHomeButton() { return false }

  _requestQuoteSuccessUrl() {
    return "/project_quotes"
  }

  renderSaveButton(repository) {
    return (
      <AuthenticatedModal
        signedIn={this.app().state.signedIn}
        onSignIn={() => this.app().onSignIn()}
        renderChildren={closeModal => (
          <DesignRelatedForm
            repository={repository}
            closeModal={closeModal}
            formUrl="/projects/new?from_design=true"
            designInputName="project[design]"
            successUrl="/projects"
            formTitle="Save Project"
          />
        )}
        buttonClassName="action-bar__button action-bar__button-unsaved margin-left-md"
        buttonText="Save"
      />
    )
  }
}

module.exports = GuestModeStrategy
