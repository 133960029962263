const WallSurface = require('./WallSurface')
const ExteriorWallSurface = require('./ExteriorWallSurface')
const { Polygon } = require('construction-designer-core/geometry')
const EditableProperty = require('./EditableProperty')

class GableWallSurface extends WallSurface {
  constructor(faceVertices) {
    super()
    this._faceVertices = faceVertices
  }

  defaultDisplayProperties() {
    return [
      new EditableProperty(this, 'Material Name', {
        type: 'select',
        label: 'Material',
        choices: this.availableMaterialNames().map(name => ({ label: name, value: name }))
      })
    ]
  }

  faceVertices() { return this._faceVertices }
  edge() {
    const faceVertices = this.faceVertices()
    return faceVertices[0].to(faceVertices[1])
  }

  faceShape() {
    return new Polygon(this.faceVertices())
  }

  availableMaterialNames() {
    return [
      ...ExteriorWallSurface.availableMaterialNames(),
      ...WallSurface.availableMaterialNames()
    ]
  }

  migrate(sourceVersion) {
    if (sourceVersion < 2) {
      this.componentOf() && this.componentOf().resetGableComponents && this.componentOf().resetGableComponents()
    }
  }
}
module.exports = GableWallSurface
