const {
  Composite3DFigure,
  ThreeGeometryFigure
} = require('construction-designer-core/drawing-editor-3D')

class WallSegmentFigure3D extends Composite3DFigure {
  constructor(segment) {
    super()
    this.setModel(segment)
  }

  // TODO: refactor this figure to use a variant on the CompositeGroupFigure3D
  //   that respects a defaultSelfFigure3D the way CompositeFigure uses selfHandle at present
  selfFigure() {
    if(!this._selfFigure) {
      this._selfFigure = new ThreeGeometryFigure(this.model())
    }
    return this._selfFigure
  }

  addToScene(scene) {
    this.selfFigure().addToScene(scene) // draw main wall segment
    super.addToScene(scene) // draw supplementary components
  }

  update() {
    this.selfFigure().update()
    super.update()
  }

  removeFromScene(scene) {
    this.selfFigure().removeFromScene(scene)
    super.removeFromScene(scene)
  }
}

module.exports = WallSegmentFigure3D
