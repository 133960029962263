const { Polygon, Point: { $P }, Unit } = require('construction-designer-core/geometry')
const { Composite3DFigure } = require('construction-designer-core/drawing-editor-3D')
const RoomFigure = require('shared/drawables/RoomFigure')
const ContourFinder = require('shared/operations/ContourFinder')
const Label = require('./Label')
const EditableProperty = require('./EditableProperty')
const ReadableProperty = require('./ReadableProperty')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')

class Room extends ShellBuilderConstructionComponent {
  constructor(surfaces = []) {
    super()

    surfaces.map(surface => this.addSurface(surface))
  }

  defaultDisplayProperties() {
    return [
      new EditableProperty(this, 'Name', { type: 'text', placeholder: 'Room' }),
      new EditableProperty(this, 'Label Font Size', { type: 'number' }),
      new EditableProperty(
        this,
        'Finished',
        {
          type: 'radio',
          choices: [{ value: 'finished', label: 'Finished' }, { value: 'unfinished', label: 'Unfinished' }],
          set: value => this.setFinished(value === 'finished'),
          get: () => this.finished() ? 'finished' : 'unfinished'
        }
      ),
      new ReadableProperty(this, 'Area', { type: 'unit', defaultUnit: 'sqft', label: 'Room Area' }),
      new ReadableProperty(this, 'Dimensions', {
        type: 'text',
        get: () => `${this.boundsWidth().toString()} X ${this.boundsHeight().toString()}`
      }),
      new EditableProperty(this, 'Color', { type: 'color' })
    ]
  }

  surfaces() {
    if(!this._surfaces) {
      this._surfaces = []
    }
    return this._surfaces
  }

  addSurface(newSurface) {
    if (this.surfaces().includes(newSurface)) return

    newSurface.setComponentOf(this)
    this.surfaces().push(newSurface)
    this._resetShape()
  }

  removeSurface(surface) {
    this.surfaces().remove(surface)
    this._resetShape()

    if(this.surfaces().length === 0) this.delete()
  }

  components() {
    return [
      this.label(),
      ...this.surfaces()
    ].filter(item => item)
  }

  label() {
    // Intentionally checking if area is falsy. This will cover values like 0,
    // but will also cover things like NaN or undefined
    if (this.surfaces().length < 2 || !this.shape().area()) return

    if (!this._label) {
      const position = this.shape().centroid()
      const fontSize = this.labelFontSize()
      this._label = new Label(position, this.name(), 0, 'center', fontSize)
    }
    return this._label
  }

  name() {
    if (this._name === undefined) {
      this._name = ''
    }
    return this._name
  }
  setName(newName) {
    this._name = newName
    this.label().setText(newName)
  }

  labelFontSize() {
    if (!this._labelFontSize) {
      this._labelFontSize = Math.round((this.boundingBox().width() / 15).constrained(10, 20))
    }
    return this._labelFontSize
  }
  setLabelFontSize(newSize) {
    this._labelFontSize = newSize
    this.label().setFontSize(newSize)
  }

  boundsWidth() {
    return this.boundingBox().width().roundedTo(2).inches()
  }
  boundsHeight() {
    return this.boundingBox().height().roundedTo(2).inches()
  }

  vertices() { return this.shape().vertices() }
  contains(x, y) { return this.shape().contains(x, y) }
  moveBy(_x, _y, _z) { return false }
  boundingBox() { return this.shape().boundingBox() }
  zLevel() { return this.surfaces().first().zLevel() }

  edges() {
    return this.surfaces().map(surface => surface.edge())
  }

  shape() {
    if(!this._shape) {
      const centerPoint = this.surfaces().first().edge().center()
      // garbage triangle, but necessary when otherwise invalid
      const defaultShape = new Polygon([
        centerPoint, centerPoint.add($P(0, 1)), centerPoint.add($P(1, 1)), centerPoint.add($P(1, 0))
      ])

      if(this.surfaces().length < 2) { return defaultShape }

      const finder = new ContourFinder()
      // assumes single wall loop
      const contour = finder.process(this.edges()).first()
      if(contour.first().equals(contour.last())) { contour.pop() } // remove duplicated point
      if(contour.length < 3) { return defaultShape } // invalid shape
      this._shape = new Polygon(contour)
    }
    return this._shape
  }
  _resetShape() {
    this._shape = undefined
    this._label = undefined
  }
  geometry() { return this.shape() }

  area() {
    const shapeArea = this.shape().area()
    return new Unit(shapeArea ? shapeArea / (12 ** 2) : 0, 'sqft')
  }

  buildingLevel() {
    return this.componentOf()
  }

  /**
   * Whether the room is finished or unfinished. Used for calculating finished
   * floor area and unfinished floor area
   *
   * @returns {Boolean}
   */
  finished() {
    if (this._finished === undefined) {
      this._finished = true
    }
    return this._finished
  }
  setFinished(boolean) {
    this._finished = boolean
  }

  wallHeight() {
    const level = this.buildingLevel()
    return level ? level.wallHeight() : (8).feet()
  }

  delete() {
    // NOTE: This may in the future have to delete segments
    this.surfaces().forEach(surface => surface.delete())
    this.buildingLevel() && this.buildingLevel().removeRoom(this)
  }

  defaultFigure() {
    return RoomFigure.withModel(this)
  }

  threeFigure() {
    return Composite3DFigure.withModel(this)
  }

  colors() {
    const surfaceColors = new Set(this.surfaces().map(surface => surface.color()))
    return [...surfaceColors]
  }
  hasMultipleColors() { return this.colors().length > 1 }
  color() {
    return this.colors().first()
  }
  setColor(newColor) {
    this.surfaces().forEach(surface => surface.setColor(newColor) )
  }

  nonEssentialProperties() {
    return [
      ...super.nonEssentialProperties(),
      '_label',
      '_shape'
    ]
  }
}

module.exports = Room
