const React = require('react')
const { IconFactory } = require('construction-designer-core/drawing-editor-react')

const customIcons = {}
try {
  const iconsRequireContext = require.context('components/icons', true)
  iconsRequireContext.keys().forEach(key => customIcons[key] = iconsRequireContext(key).source)
} catch {
  // Non-webpack environment (tests). It's fine if we can't load custom icons in
  // this mode.
}

class ShellBuilderIconFactory extends IconFactory {
  constructor() {
    super()

    this._iconToSvgFilename = {
      ComponentSelectionTool: 'selection-tool',
      TextTool: 'text',
      SecondView: 'TopView',
      KeyboardArrowLeft: 'keyboard_arrow_left',
      KeyboardArrowRight: 'keyboard_arrow_right',
      KeyboardArrowDown: 'keyboard_arrow_down',
      KeyboardArrowUp: 'keyboard_arrow_up',
    }
  }

  makeIcon(name, otherProps) {
    const iconName = this._iconToSvgFilename[name] || name

    const iconProps = Object.assign(otherProps, {
      className: otherProps.className,
      title: otherProps.hoverText || otherProps.name
    })

    return this._customIcon(iconName, iconProps) || this._materialIcon(iconName, iconProps)
  }

  _customIcon(iconName, iconProps) {
    if (!(`./${iconName}` in customIcons)) return

    const source = customIcons[`./${iconName}`]

    // Setting innerHTML is not dangerous with these SVG files since we created
    // them and they are part of this app's code.
    return (
      <span
        {...iconProps}
        dangerouslySetInnerHTML={{ __html: source }}
      />
    )
  }

  _materialIcon(iconName, iconProps) {
    iconProps.className += ' material-icons'

    return (
      <span {...iconProps}>
        {iconName}
      </span>
    )
  }
}
module.exports = ShellBuilderIconFactory
