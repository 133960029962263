const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')
const { three: THREE } = require('construction-designer-core/drawing-editor-3D')
const ExteriorWallSurface = require('./ExteriorWallSurface')
const WallSurface = require('./WallSurface')
const WallColumnFigure = require('shared/drawables/WallColumnFigure')
const WallColumnFigure3D = require('shared/drawables/WallColumnFigure3D')
const EditableProperty = require('./EditableProperty')

class WallColumn extends ShellBuilderConstructionComponent {
  constructor(shape) {
    super()
    this._shape = shape
  }

  defaultDisplayProperties() {
    return [
      new EditableProperty(
        this,
        'Material Name',
        {
          type: 'select',
          label: 'Material',
          choices: this.availableMaterialNames().map(material => ({ value: material, label: material }))
        }
      )
    ]
  }

  shape() { return this._shape }
  geometry() { return this.shape() }
  footprint() { return this.shape() }
  edges() { return this.shape().edges() }
  buildingLevel() { return this.componentOf() }

  zLevel() { return this.footprint().zLevel() }
  contains(x, y) { return this.footprint().contains(x, y) }
  vertices() { return this.footprint().vertices() }
  height() {
    return this.buildingLevel() ? this.buildingLevel().height() : (8).feet()
  }

  openings() { return [] }

  delete() {
    this.componentOf().removeWallColumn(this)
    this.surfaces().forEach(surface => surface.delete())
  }

  moveBy(_x, _y, _z) { return false }

  availableMaterialNames() {
    return ExteriorWallSurface.availableMaterialNames()
  }

  materialName() {
    if (!this._materialName) {
      this._materialName = this.availableMaterialNames().first()
    }
    return this._materialName
  }

  setMaterialName(newMaterial) {
    this._materialName = newMaterial
    this.surfaces().forEach(surface => surface.setMaterialName(newMaterial))
  }

  components() {
    return this.surfaces()
  }

  surfaces() {
    if (!this._surfaces) {
      this._surfaces = this._defaultSurfaces()
    }
    return this._surfaces
  }

  removeSurface(surface) {
    this.surfaces().remove(surface)
    surface.setComponentOf(undefined)
  }

  _defaultSurfaces() {
    const edges = this.footprint().edges()
    return edges.map(edge => {
      const surface = new WallSurface(edge)
      surface.setAvailableMaterialNames(ExteriorWallSurface.availableMaterialNames())
      surface.addAttachedSegment(this)
      return surface
    })
  }

  shape3D() {
    const height = this.height().toInches()
    const shape = new THREE.Shape(this.footprint().vertices().map(v => v.toThreeJS()))

    const geometry = new THREE.ExtrudeBufferGeometry(
      shape,
      { bevelEnabled: false, depth: height }
    )

    geometry.translate(0, 0, -height)
    return geometry
  }

  defaultFigure() {
    return WallColumnFigure.withModel(this)
  }

  threeFigure() {
    return WallColumnFigure3D.withModel(this)
  }
}
module.exports = WallColumn
