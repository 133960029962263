class BuildingContext {
  constructor(buildingLevel, room) {
    this._buildingLevel = buildingLevel
    this._room = room
  }

  buildingLevel() {
    return this._buildingLevel
  }

  room() {
    return this._room
  }
}

module.exports = BuildingContext