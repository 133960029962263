const { Polygon, ExtrudedPolygon, RelativeLocator } = require('construction-designer-core/geometry')
const {
  NullDrawable
} = require('construction-designer-core/drawing-editor')
const { Shape3DFigure } = require('construction-designer-core/drawing-editor-3D')
const WallConnectionFigure = require('shared/drawables/WallConnectionFigure')

class WallConnection {
  static withSegments(segments, ends) {
    const connection = new this()
    segments.forEach((segment, index) => connection.attach(segment, ends[index]))
    return connection
  }

  segments() {
    if (!this._segments) {
      this._segments = []
    }
    return this._segments
  }

  locators() {
    return Object.values(this._segmentMap())
  }

  _segmentMap() {
    if(!this.__segmentMap) {
      this.__segmentMap = {}
    }
    return this.__segmentMap
  }

  vertices() {
    return this.footprint().vertices()
  }

  /**
   * Attaches a segment to the connection
   *
   * @param {WallSegment} segment
   * @param {String} terminal The end of the segment's edge that should be
   * connected - should be "begin" or "end"
   */
  attach(segment, terminal) {
    this.segments().push(segment)
    this._segmentMap()[segment.stableID()] = segment.edge()[terminal]()
    segment.addConnection(this)

    this._footprint = undefined
    this._height = undefined
  }

  detach(segment) {
    this.segments().remove(segment)
    delete this._segmentMap()[segment.stableID()]
    segment.removeConnection(this)

    this._footprint = undefined
    this._height = undefined
  }

  moveBy(x, y, z) {
    return this.footprint().moveBy(x, y, z)
  }

  updateEndpoint(segment) {
    const attachedTerminus = this.getTerminus(segment)
    const newEndpoint = segment.edge()[attachedTerminus]()

    this._segmentMap()[segment.stableID()] = newEndpoint
  }

  getEndpoint(segment) {
    const id = segment.stableID()
    return this._segmentMap()[id]
  }

  getTerminus(segment) {
    if(!this.hasAttachedSegment(segment)) { return }
    const edge = segment.edge()
    return this.getEndpoint(segment).equals(edge.begin(), 1e-10) ? 'begin' : 'end'
  }

  hasAttachedSegment(segment) {
    return !!this.getEndpoint(segment)
  }

  generateFootprint() {
    const edges = this.segments().map(segment => {
      const endpoint = this._segmentMap()[segment.stableID()]
      if(!endpoint) return
      const segmentOppositeEdge = segment.oppositeEdge()
      const terminus = this.getTerminus(segment)

      let section = endpoint.to(segmentOppositeEdge[terminus]())
      if(terminus === 'end') {
        section = segmentOppositeEdge[terminus]().to(endpoint)
      }

      return section
    }).filter(item => item)
    const jointEdges = this._removeParallelEdges(edges)

    let footprintVertices
    if (jointEdges.length === 1) {
      const jointEdge = jointEdges.first()
      const oppositeEdge = jointEdge.shiftedAlongNormalBy(jointEdge.length())

      footprintVertices = [jointEdge.begin(), jointEdge.end(), oppositeEdge.end(), oppositeEdge.begin()]
    } else if (jointEdges.length === 2) {
      const firstEdge = jointEdges.first()
      const lastEdge = jointEdges.last()

      const oppositeEdge = firstEdge.shiftedAlongNormalBy(lastEdge.length())
      footprintVertices = [firstEdge.begin(), firstEdge.end(), oppositeEdge.end(), oppositeEdge.begin()]
    } else {
      footprintVertices = jointEdges.map(edge => edge.begin())
    }

    // Remember, positive z is down
    const lowestZ = Math.max(...edges.map(edge => edge.begin().z()))
    // Move each vertex to the lowest z level
    footprintVertices = footprintVertices.map(vertex => (
      this._makeRelativeToBuildingLevel(vertex.snapshot().addZ(lowestZ - vertex.z()))
    ))

    return new Polygon(footprintVertices)
  }

  footprint() {
    if(!this._footprint) {
      this._footprint = this.generateFootprint()
    }
    return this._footprint
  }

  _removeParallelEdges(edges) {
    return edges.reduce((edges, currentEdge) => {
      const containsParallel = edges.some(edge => edge.isParallelTo(currentEdge, 1e-3))

      if (containsParallel) {
        return edges
      }
      return [...edges, currentEdge]
    }, [])
  }

  equals(other, tolerance = Math.DEFAULT_TOLERANCE) {
    const points = this.locators()
    return (
      other.constructor === this.constructor
      && other.locators().every((point, index) => point.equals(points[index], tolerance) )
    )
  }

  height() {
    if (!this._height) {
      this._height = this._defaultHeight()
    }
    return this._height
  }

  _defaultHeight() {
    const heights = this.segments().map(segment => segment.height().toInches())
    let mostCommonHeight = 0
    // An object literal where the key is the height, and the value is the number occurrences
    const occurrences = {}

    // Finds the mode of the connection's segments' heights, or the tallest one
    // if there is a tie
    heights.forEach(height => {
      occurrences[height] = (occurrences[height] || 0) + 1

      const mostCommonOccurrences = occurrences[mostCommonHeight]
      const currentOccurrences = occurrences[height]

      if (!mostCommonOccurrences || currentOccurrences > mostCommonOccurrences) {
        mostCommonHeight = height
      } else if (currentOccurrences === mostCommonOccurrences) {
        mostCommonHeight = Math.max(height, mostCommonHeight)
      }
    })

    return mostCommonHeight.inches()
  }

  /**
   * Finds the endpoint between two segments of different heights where the
   * wall surface should transition to the other segment's height
   *
   * @param {WallSegment} segment1
   * @param {WallSegment} segment2
   *
   * @returns {Locator}
   */
  heightTransitionPoint(segment1, segment2) {
    if (segment1.height().equals(this.height())) return this.getEndpoint(segment2)

    return this.getEndpoint(segment1)
  }

  defaultFigure() {
    return new WallConnectionFigure(this)
  }

  defaultFigureXZ() {
    return new NullDrawable()
  }

  threeFigure() {
    return new Shape3DFigure(new ExtrudedPolygon(this.footprint(), -this.height().toInches()))
  }

  postRestorationAction() {
    if (this.__segmentMap) {
      delete this.__segmentMap._id
      delete this.__segmentMap._className
    }
  }

  toString() {
    return `WallConnection({ _segments: [${this.segments().map(s => `${s.constructor.name}({ _stableID: ${s.stableID()} })` )}] })`
  }

  zLevel() {
    return this.segments().first().zLevel()
  }

  _makeRelativeToBuildingLevel(locator) {
    const buildingLevel = this.segments().first()?.buildingLevel()
    if (buildingLevel) {
      return RelativeLocator.makeRelativeTo(locator, buildingLevel.zLevelLocator())
    }
    return locator
  }

  nonEssentialProperties() {
    return [
      ...super.nonEssentialProperties(),
      '_footprint'
    ]
  }
}
module.exports = WallConnection
