const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')
const {
  PolygonWithHoles,
  Polygon,
  Point: { $P },
  RelativeLocator,
  LinearMeasurement,
  Unit,
  ThreeSourceRelativeLocator,
  NormalToEdgeLocator,
  ProportionalEdgeLocator
} = require('construction-designer-core/geometry')
const { three: THREE } = require('construction-designer-core/drawing-editor-3D')
const { StableID } = require('construction-designer-core/drawing-editor')
const { extend, MissingOverride } = require('construction-designer-core/standard-utilities')

const WallSegmentFigure = require('shared/drawables/WallSegmentFigure')
const WallSegmentFigureXZ = require('shared/drawables/WallSegmentFigureXZ')
const ExteriorWallSurface = require('shared/domain-models/ExteriorWallSurface')
const WallSegmentFigure3D = require('shared/drawables/WallSegmentFigure3D')
const Window = require('shared/domain-models/Window')
const EditableProperty = require('./EditableProperty')
const ReadableProperty = require('./ReadableProperty')
const generateGeometryUVCoordinates = require('shared/helpers/generateGeometryUVCoordinates')
const WallSurface = require('./WallSurface')
const { simpleObserver } = require('construction-designer-core/standard-utilities')

Polygon.prototype.withHoles = function() {
  return new PolygonWithHoles(this.vertices())
}
PolygonWithHoles.prototype.withHoles = function() {
  return this
}

class WallSegment extends ShellBuilderConstructionComponent {
  // TODO: Update this to use the project's default thickness
  static defaultThickness() { return (4).inches() }

  static TYPES = Object.freeze({
    Exterior: 'exterior',
    Interior: 'interior'
  })

  // NOTE: These methods assume a subclass that takes an edge as the first argument
  static createExteriorSegment(edge, thickness) {
    return new this(edge, this.TYPES.Exterior, thickness)
  }

  static createInteriorSegment(edge, thickness) {
    return new this(edge, this.TYPES.Interior, thickness)
  }

  constructor(type = WallSegment.TYPES.Interior, thickness) {
    super()
    this._type = type
    this._thickness = thickness
  }

  type() { return this._type }
  isExterior() { return this.type() === this.constructor.TYPES.Exterior }
  isInterior() { return this.type() === this.constructor.TYPES.Interior }

  displayName() {
    return `${this.isExterior() ? 'Exterior' : 'Interior'} Wall Segment`
  }

  defaultDisplayProperties() {
    return [
      new EditableProperty(this, 'Thickness', { type: 'unit', defaultUnit: 'in' }),
      new EditableProperty(this, 'Height', { type: 'unit', defaultUnit: 'ft' }),
      new ReadableProperty(this, 'Surface Area', { type: 'unit', defaultUnit: 'sqft' })
    ]
  }

  edge() {
    // Subclasses may wish to override
  }

  // TODO: Find a better name
  exteriorEdge() {
    throw new MissingOverride(this, 'exteriorEdge')
  }

  defaultFigure() {
    return WallSegmentFigure.withModel(this)
  }

  defaultFigureXZ() {
    return WallSegmentFigureXZ.withModel(this)
  }

  buildingLevel() {
    return this.componentOf()
  }

  height() {
    return this._height || (this.buildingLevel() ? this.buildingLevel().wallHeight() : (8).feet())
  }
  setHeight(newHeight) {
    if (newHeight?.scalar) {
      this._height = newHeight
    } else if (parseFloat(newHeight)) {
      this._height = LinearMeasurement.fromString(newHeight)
    }
  }

  thickness() {
    if(!this._thickness) { this._thickness = this.defaultThickness() }
    return this._thickness
  }
  setThickness(thickness) {
    if (thickness?.scalar) {
      this._thickness = thickness
    } else if (parseFloat(thickness)) {
      this._thickness = LinearMeasurement.fromString(thickness)
    }

    this.windows().forEach(window => window.resetFootprint())
    this.doorways().forEach(doorway => doorway.resetFootprint())
  }
  defaultThickness() { return this.constructor.defaultThickness() }

  zLevel() {
    return this.edge().begin().z()
  }

  /**
   * @param {Locator} locator
   * @returns {ThreeSourceRelativeLocator}
   */
  conformToZLevel(locator) {
    const baseLocator = this.edge().begin()
    if (locator.zSource && locator.zSource() === baseLocator) return baseLocator

    return new ThreeSourceRelativeLocator(
      locator, locator, baseLocator, 0, 0, 0
    )
  }

  threeFigure() {
    return new WallSegmentFigure3D(this)
  }

  windows() {
    if(!this._windows) {
      this._windows = []
    }
    return this._windows
  }

  _makeEdgeRelative(edge) {
    const relativeCenter = this.exteriorEdge().closestRelativePointTo(edge.center())

    return RelativeLocator.makeRelativeTo(edge.begin(), relativeCenter).to(
      RelativeLocator.makeRelativeTo(edge.end(), relativeCenter)
    )
  }

  addWindow(window) {
    window.setComponentOf(this)
    window.setEdge(this._makeEdgeRelative(window.edge()))
    this.windows().push(window)
  }

  removeWindow(window) {
    this.windows().remove(window)
    window.setComponentOf(undefined)
    window.setEdge(window.edge().snapshot())
  }

  doorways() {
    if(!this._doorways) {
      this._doorways = []
    }
    return this._doorways
  }

  addDoorway(doorway) {
    doorway.setComponentOf(this)
    doorway.setEdge(this._makeEdgeRelative(doorway.edge()))
    this.doorways().push(doorway)
  }

  removeDoorway(doorway) {
    this.doorways().remove(doorway)
    doorway.setComponentOf(undefined)
    doorway.setEdge(doorway.edge().snapshot())
  }

  openings() {
    return [...this.windows(), ...this.doorways()]
  }

  /**
   * presents a unified API to add/remove windows/doorways
   */
  addOpening(opening) {
    if(opening instanceof Window) {
      this.addWindow(opening)
    } else {
      this.addDoorway(opening)
    }
  }

  removeOpening(opening) {
    if(opening instanceof Window) {
      this.removeWindow(opening)
    } else {
      this.removeDoorway(opening)
    }
  }

  intersectsOpening(opening) {
    if (!opening) return false

    return this.footprint().boundingBox().intersects(opening.footprint().boundingBox())
  }

  reassignOpenings(otherSegments = []) {
    this.openings().forEach(opening => {
      const intersectingSegment = otherSegments.find(segment => segment.intersectsOpening(opening))

      this.removeOpening(opening)
      if (intersectingSegment) {
        intersectingSegment.addOpening(opening)
      } else {
        this.addOpening(opening)
      }
    })
  }

  components() {
    return this.openings()
  }

  surfaces() {
    if(!this._surfaces) {
      this._surfaces = []
    }
    return this._surfaces
  }

  addSurface(newSurface) {
    this.surfaces().push(newSurface)

    newSurface.addAttachedSegment(this)
  }

  removeSurface(surface) {
    this.surfaces().remove(surface)
    surface.removeAttachedSegment(this)
  }

  wallSurfaceClass() {
    return this.isExterior() ? ExteriorWallSurface : WallSurface
  }

  exteriorSurface() {
    return this.surfaces().find(surface => surface instanceof ExteriorWallSurface)
  }

  /**
   * a 3D polygon representing the outline of the exterior of the wall
   */
  faceShape() {
    const beginPoint = this.exteriorEdge().begin()
    const endPoint = this.exteriorEdge().end()
    const height = this.height().toInches()

    return new PolygonWithHoles([
      beginPoint,
      endPoint,
      endPoint.addZ(-height),
      beginPoint.addZ(-height)
    ])
  }

  /**
   * Returns a 2D polygon version of the wall's outside surface, where X is along the wall, and Y is the Z-height
   */
  surface() {
    const origin = this.exteriorEdge().begin()
    const convertToSurfaceSpace = shape => {
      const vertices = shape.vertices() // assumes Polygons
      return new PolygonWithHoles(vertices.map(point => $P(origin.distanceTo(point), point.z() - origin.z())))
    }

    const wallPolygon = convertToSurfaceSpace(this.faceShape())

    const openings = this.openings().map(opening => {
      return convertToSurfaceSpace(opening.faceShape())
    })

    return openings.reduce((final, opening) => {
      return final.difference(opening)[0]
    }, wallPolygon)
  }

  /**
   * @returns {Unit} The area of the wall in square inches
   */
  surfaceArea() {
    return new Unit(this.surface().area(), 'sqin')
  }

  /**
   * @returns {Unit} The length of the wall in inches
   */
  linearLength() {
    return this.edge().length().inches()
  }

  shapeXZ() {
    return new Polygon(this.faceShape().vertices().map(point => point.xz()))
  }

  shape3D() {
    const wallShape = this._convertShapeToThreeJS(this.surface())
    const exteriorEdge = this.exteriorEdge()
    const beginPoint = exteriorEdge.begin().toThreeJS()
    const extrusionPoint = new NormalToEdgeLocator(
      new ProportionalEdgeLocator(exteriorEdge, 0),
      this.thickness().toInches()
    ).toThreeJS()

    const curve = new THREE.LineCurve3(beginPoint, extrusionPoint)
    const geometry = new THREE.ExtrudeBufferGeometry(
      wallShape,
      {
        extrudePath: curve,
        bevelEnabled: false
      }
    )

    // geometry gets generated in a weird orientation, so hit it with a big hammer until it looks right
    const rotationNormal = extrusionPoint.clone().sub(beginPoint).normalize()
    this._rotateGeometryAroundArbitraryAxis(geometry, beginPoint, rotationNormal, Math.PI_2)

    // geometry UV coordinates are useless; use a bigger hammer than the above
    const mainAxis = this.edge().vector().normalized().toThreeJS()
    generateGeometryUVCoordinates(geometry, mainAxis)

    return geometry
  }

  sides() {
    const thickness = this.thickness().toInches()
    return [
      this.exteriorEdge(),
      this.exteriorEdge().shiftedAlongNormalBy(thickness)
    ]
  }

  endcaps() {
    const edges = this.sides()
    return [
      edges.last().begin().to(edges.first().begin()),
      edges.first().end().to(edges.last().end()),
    ]
  }

  footprint() {
    const edges = this.sides()
    const vertices = [
      edges.first().begin(),
      edges.first().end(),
      edges.last().end(),
      edges.last().begin()
    ]

    return new PolygonWithHoles(vertices)
  }

  extendedFootprint(extensionDistance) {
    const endcaps = this.endcaps()
    const shiftedEndcaps = endcaps.map(endcap => endcap.shiftedAlongNormalBy(-extensionDistance))

    return new Polygon(
      shiftedEndcaps.flatMap(shiftedEndcap => [shiftedEndcap.begin(), shiftedEndcap.end()])
    )
  }

  geometry() { return this.footprint() }

  /**
   * @returns {PolygonWithHoles[]} a list of polygons representing the remainder
   * of the wall footprint, less openings
   */
  footprintWithoutOpenings() {
    const footprintZ = this.footprint().surface().zLevel()
    const shape = this.footprint().xy().roundedTo(3)
    if(this.openings().length === 0) { return [shape] }

    const openingShapes = this.openings().map(opening => opening.footprint().roundedTo(3))
    const footprintShapes = openingShapes.reduce((polygons, hole) => {
      return polygons.flatMap(polygon => polygon.difference(hole.xy()) )
    }, [shape])

    return footprintShapes.map(shape => shape.movedBy(0, 0, footprintZ))
  }

  contains(x, y) {
    return this.footprint().contains(x, y)
  }

  movementStrategy() {
    if (!this._movementStrategy) {
      this._movementStrategy = this.defaultMovementStrategy()
    }
    return this._movementStrategy
  }

  defaultMovementStrategy() {
    throw new MissingOverride(this, 'defaultMovementStrategy')
  }

  moveBy(x, y, z) {
    const movementVector = $P(x, y, z)
    const normal = this.edge().normal()
    const distance = movementVector.dot(normal)
    const motion = normal.multipliedBy(distance)

    this.movementStrategy().move(motion.x(), motion.y(), motion.z())
    this.notifySegmentMoveObservers()
    return true
  }

  moveAlone(x, y, z) {
    this.edge().moveBy(x, y, z)
  }

  vertices() { return this.footprint().vertices() }

  _convertShapeToThreeJS(polygon) {
    const shape = new THREE.Shape(polygon.vertices().map(v => v.toThreeJS()))
    polygon.holes().forEach(hole => {
      shape.holes.push(new THREE.Path(hole.vertices().map(v => v.toThreeJS())))
    })
    return shape
  }

  _rotateGeometryAroundArbitraryAxis(geometry, point, normal, angle) {
    geometry.translate(-point.x, -point.y, -point.z)
    const transform = (new THREE.Matrix4()).makeRotationAxis(normal, angle)
    geometry.applyMatrix4(transform)
    geometry.translate(point.x, point.y, point.z)
    return geometry
  }

  delete() {
    // Subclasses should override and call super
    this.buildingLevel().removeSegment(this)

    this.surfaces().slice().forEach(surface => surface.removeAttachedSegment(this, { modifyEdge: true }))
  }

  toString() {
    return `${this.constructor.name}({ _stableID: ${this.stableID()}, _edge: ${this.edge().toString()}, _thickness: ${this.thickness().toString()} })`
  }

  // region smartJSON

  _buildingLevelAtVersion(sourceVersion) {
    if (sourceVersion < 2 && this.isExterior()) return this.componentOf()._componentOf

    return this.buildingLevel()
  }

  classVersion() { return 2 }
  migrate(sourceVersion) {
    if (sourceVersion < 2) {
      const edgeXY = this.edge().xy()

      const buildingLevel = this._buildingLevelAtVersion(sourceVersion)
      const conformedBegin = buildingLevel.conformToFloorLevel(edgeXY.begin())
      const conformedEnd = buildingLevel.conformToFloorLevel(edgeXY.end())
      this._edge = conformedBegin.to(conformedEnd)

      this.connections().slice().forEach(connection => {
        const previousEndpoint = connection.getEndpoint(this)
        const attachedTerminus = previousEndpoint.xy().equals(edgeXY.begin()) ? 'begin' : 'end'
        connection.detach(this)
        connection.attach(this, attachedTerminus)
      })

      this.openings().forEach(opening => {
        const openingBegin = buildingLevel.conformToFloorLevel(opening.edge().begin().xy())
        const openingEnd = buildingLevel.conformToFloorLevel(opening.edge().end().xy())

        opening.setEdge(this._makeEdgeRelative(openingBegin.to(openingEnd)))
        opening.resetFootprint()
      })
    }
  }

  nonEssentialProperties() {
    return [
      ...super.nonEssentialProperties(),
      '_movementStrategy',
      '_segmentMoveObservers'
    ]
  }

  // endregion smartJSON
}

extend(WallSegment, StableID)
simpleObserver(WallSegment, 'segmentMove')
module.exports = WallSegment
