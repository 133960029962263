const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')
const { ProportionalEdgeLocator } = require('construction-designer-core/geometry')
const SwingingDoor = require('./SwingingDoor')
const { Composite3DFigure } = require('construction-designer-core/drawing-editor-3D')
const { CompositeFigure } = require('construction-designer-core/drawing-editor')

const SWING_DIRECTIONS = require('shared/domain-models/option-sets/SWING_DIRECTIONS')
const EditableProperty = require('./EditableProperty')

class DoubleDoor extends ShellBuilderConstructionComponent {
  static displayClassName() {
    return 'Double Doors'
  }

  constructor(swingDirection = SWING_DIRECTIONS.In) {
    super()
    this._swingDirection = swingDirection
  }

  defaultDisplayProperties() {
    const swingDirections = this.constructor.SWING_DIRECTIONS

    return [
      new EditableProperty(
        this,
        'Swing Direction',
        {
          type: 'radioselect',
          choices: Object.keys(swingDirections).map(key => ({ label: key, value: swingDirections[key] }))
        }
      )
    ]
  }

  swingDirection() {
    return this._swingDirection
  }

  setSwingDirection(swingDirection) {
    const flip = this._swingDirection !== swingDirection
    this._swingDirection = swingDirection
    this.doors().forEach(door => {
      door.setSwingDirection(swingDirection)
      if(flip) door.flipHandedness()
    })
  }

  height() {
    return this.componentOf().height()
  }

  thickness() {
    return this.componentOf().thickness()
  }

  edge() {
    return this.componentOf().edge()
  }

  components() {
    return [
      ...this.doors()
    ]
  }

  doors() {
    if (!this._doors) {
      const edge = this.edge().shiftedAlongNormalBy((this.thickness().toInches() / 2))
      const edge1 = edge.begin().to(new ProportionalEdgeLocator(edge, 0.5))
      const door1 = new SwingingDoor(SwingingDoor.HANDEDNESS.Right, this.swingDirection())
      door1.setEdge(edge1)
      door1.setComponentOf(this)

      const edge2 = new ProportionalEdgeLocator(edge, 0.5).to(edge.end())
      const door2 = new SwingingDoor(SwingingDoor.HANDEDNESS.Left, this.swingDirection())
      door2.setEdge(edge2)
      door2.setComponentOf(this)

      this._doors = [door1, door2]
    }
    return this._doors
  }

  threeFigure() {
    return Composite3DFigure.withModel(this)
  }

  defaultFigure() {
    const figure = CompositeFigure.withModel(this)
    figure.setLayer('Doors')
    return figure
  }
}

DoubleDoor.SWING_DIRECTIONS = SWING_DIRECTIONS

module.exports = DoubleDoor
