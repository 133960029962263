/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Add honeybadger client
const Honeybadger = require('honeybadger-js')
// Honeybadger won't report errors in development because the API key is typically absent
Honeybadger.configure({
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.HONEYBADGER_ENV,
  revision: process.env.SOURCE_VERSION,
  breadcrumbsEnabled: true
})

// Polyfills per docs: https://github.com/rails/webpacker/blob/master/docs/es6.md#babel
import "core-js/stable"
import "regenerator-runtime/runtime"

const Rails = require('@rails/ujs')
Rails.start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

const initializersRequireContext = require.context('config/initializers', true)
initializersRequireContext.keys().forEach(initializersRequireContext)

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

const makeFormsRemote = (bodyElement, containerId) => {
  document.getElementById(containerId).querySelectorAll('form').forEach(element => {
    element.setAttribute('data-remote', 'true')

    element.addEventListener('ajax:success', e => {
      const xhrRequest = e.detail[2]

      if (xhrRequest.getResponseHeader('location')) { return }

      const newModalBody = e.detail[0].body
      bodyElement.innerHTML = newModalBody.innerHTML
      ReactRailsUJS.mountComponents(bodyElement)
      makeFormsRemote(bodyElement, containerId)
    })
  })
}

// remote modal
document.addEventListener('turbolinks:load', () => {
  const modal = document.getElementById('modal')
  const modalBody = document.getElementById('modal-body')
  // NOTE: If new close elements are created _after_ load, this binding won't
  // attach the close handlers
  const closeModalElements = document.getElementsByClassName('modal-close')

  Rails.delegate(document, 'a[data-custom-remote-modal]', 'ajax:send', () => {
    modal?.classList.add('modal--active')
  })

  Rails.delegate(document, 'a[data-custom-remote-modal]', 'ajax:success', event => {
    const newModalBody = event.detail[0].body
    if (modalBody) {
      modalBody.innerHTML = newModalBody?.innerHTML
      ReactRailsUJS.mountComponents(modalBody)

      makeFormsRemote(modalBody, 'modal')
    }
  })

  // Prevent trying to attach an event listener when the page doesn't have the modal.
  if (closeModalElements.length > 0) {
    closeModalElements.forEach(element => (
      element.addEventListener('click', e => {
        e.preventDefault()
        ReactRailsUJS.unmountComponents(modalBody)
        modal.classList.remove('modal--active')
      })
    ))
  }
})

// NOTE: This is needed because lcopy will initialize dependencies that were
// installed as a dev dependency
if (process.env.NODE_ENV === 'development') {
  require('../../../playgrounds/lcopy')
}

