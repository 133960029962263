const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')
const { Point: { $P }, PolygonWithHoles } = require('construction-designer-core/geometry')
const { NullDrawable } = require('construction-designer-core/drawing-editor')
const { three: THREE } = require('construction-designer-core/drawing-editor-3D')
const generateGeometryUVCoordinates = require('shared/helpers/generateGeometryUVCoordinates')
const WallSurfaceFigure = require('shared/drawables/WallSurfaceFigure')
const DrywallFigure3D = require('shared/drawables/DrywallFigure3D')
const EditableProperty = require('./EditableProperty')
const cutEdgeByShape = require('shared/helpers/cutEdgeByShape')
const makeSurfaceEdge = require('shared/helpers/makeSurfaceEdge')

class WallSurface extends ShellBuilderConstructionComponent {
  _color = undefined

  // Subclasses may wish to override
  static availableMaterialNames() {
    return ['drywall']
  }

  constructor(edge) {
    super()
    this._edge = edge
  }

  buildingLevel() { return this.componentOf().buildingLevel() }

  defaultDisplayProperties() {
    return [
      new EditableProperty(this, 'Color', { type: 'color' })
    ]
  }

  // TODO: Add a domain model material concept that has name and id (three.js
  // material name) and possibly other things.
  materialName() {
    if (!this._materialName) {
      this._materialName = this.availableMaterialNames().first()
    }
    return this._materialName
  }

  setMaterialName(name) {
    this._materialName = name
  }

  materialNames() {
    return [this.materialName()]
  }

  availableMaterialNames() {
    return this._availableMaterialNames || this.constructor.availableMaterialNames()
  }
  setAvailableMaterialNames(names) {
    this._availableMaterialNames = names
  }

  color() {
    if(!this._color) {
      this._color = '#e0e0e0'
    }
    return this._color
  }

  setColor(newColor) {
    this._color = newColor
  }

  /**
   * @returns {number} The area of the wall surface in square feet
   */
  area() { return this.surfaceShape().area() / 12**2 }
  edge() { return this._edge }
  setEdge(newEdge) { this._edge = newEdge }

  /**
   * @return {Edge[]}
   */
  edgeWithoutOpenings() {
    const edge = this.edge()
    const openings = this.attachedSegments().flatMap(segment => segment.openings())

    return openings.reduce((edges, opening) => (
      edges.flatMap(edge => cutEdgeByShape(opening.footprint(), edge))
    ), [edge])
  }

  boundingBox() { return this.edge().boundingBox() }
  boundingShape() { return this.boundingBox().toPolygon() }

  defaultFigure() {
    return new WallSurfaceFigure(this)
  }

  defaultFigureXZ() {
    return new NullDrawable(this)
  }

  // TODO: Should this be cached?
  faceShape() {
    const edge = this.edge()
    const segments = this.attachedSegments()

    // These are the vertices between the ends of the wall
    // NOTE: This could break if the segments stopped being sorted
    const middleVertices = segments.flatMap((segment, index) => {
      const previousSegment = segments[index - 1]
      if (!previousSegment) return []

      const currentHeight = segment.height().toInches()
      const previousHeight = previousSegment.height().toInches()
      if (currentHeight === previousHeight) return []

      const sharedConnection = segment.connections().find(connection => connection.hasAttachedSegment(previousSegment))

      if (sharedConnection) {
        const transitionPoint = sharedConnection.heightTransitionPoint(segment, previousSegment)

        return [
          transitionPoint.addZ(-previousHeight),
          transitionPoint.addZ(-currentHeight)
        ]
      }

      return [
        segment.edge().begin().addZ(-currentHeight),
        segment.edge().end().addZ(-currentHeight)
      ]
    })

    let edgeEndHeight, edgeBeginHeight
    if (segments.length > 0) {
      edgeEndHeight = segments.first().height().toInches()
      edgeBeginHeight = segments.last().height().toInches()
    } else {
      // TODO: Be smarter about determining height when there aren't any segments
      const defaultWallHeight = this.buildingLevel().wallHeight().toInches()
      edgeEndHeight = defaultWallHeight
      edgeBeginHeight = defaultWallHeight
    }

    // NOTE: This assumes that the surface is oriented the opposite direction of
    // the sequence of the segments
    return new PolygonWithHoles([
      edge.begin(),
      edge.end(),
      edge.end().addZ(-edgeEndHeight),
      ...middleVertices,
      edge.begin().addZ(-edgeBeginHeight),
    ])
  }

  _convertShapeToThreeJS(polygon) {
    const shape = new THREE.Shape(polygon.vertices().map(v => v.toThreeJS()))
    polygon.holes().forEach(hole => {
      shape.holes.push(new THREE.Path(hole.vertices().map(v => v.toThreeJS())))
    })
    return shape
  }

  /**
   * Creates a polygon representing the surface's shape, where X is along the
   * face, and Y is the z-height
   */
  surfaceShape() {
    const origin = this.edge().begin()
    const convertToSurfaceSpace = shape => {
      const vertices = shape.vertices() // assumes Polygons

      return new PolygonWithHoles(vertices.map(point => {
        const closestPoint = this.edge().closestPointTo(point)
        return $P(origin.distanceTo(closestPoint), point.z() - origin.z())
      }))
    }

    const wallPolygon = convertToSurfaceSpace(this.faceShape())

    const segmentOpenings = this.attachedSegments().flatMap(segment => segment.openings())
    const openings = segmentOpenings.map(opening => (
      convertToSurfaceSpace(opening.faceShape())
    ))

    return openings.reduce((final, opening) => (
      final.difference(opening).first()
    ), wallPolygon)
  }

  _rotateGeometryToEdge(geometry, rotationPoint, edge, originalDirection) {
    geometry.translate(-rotationPoint.x(), -rotationPoint.y(), -rotationPoint.z())

    const edgeDirection = edge.vector().normalized()
    const rotation = new THREE.Quaternion().setFromUnitVectors(
      originalDirection.toThreeJS(),
      edgeDirection.toThreeJS()
    )

    geometry.applyMatrix4(new THREE.Matrix4().makeRotationFromQuaternion(rotation))
    geometry.translate(rotationPoint.x(), rotationPoint.y(), rotationPoint.z())
    return geometry
  }

  shape3D() {
    const edge = this.edge()
    const beginPoint = edge.begin()
    // We want our rendering of the surface to have a little bit of thickness
    const endPoint = beginPoint.add(edge.normal().multipliedBy(0.25))

    const wallShape = this._convertShapeToThreeJS(this.surfaceShape())
    const curve = new THREE.LineCurve3(beginPoint.toThreeJS(), endPoint.toThreeJS())

    const geometry =  new THREE.ExtrudeBufferGeometry(wallShape, {
      extrudePath: curve,
      bevelEnabled: false
    })

    // By default, our geometry is facing along the z axis
    const geometryAxis = $P(0, 0, -1)
    // Our geometry is genenerated in the xy plane, so hit it with a third hammer
    // to rotate it along the edge and into 3D space
    const rotatedGeometry = this._rotateGeometryToEdge(
      geometry,
      beginPoint,
      edge,
      geometryAxis
    )

    // Geometry UV coordinates are useless; use a jackhammer this time
    const mainAxis = this.edge().vector().normalized().toThreeJS()
    generateGeometryUVCoordinates(rotatedGeometry, mainAxis)
    return rotatedGeometry
  }

  threeFigure() {
    return new DrywallFigure3D(this)
  }

  moveBy(_x, _y, _z) { return false }
  moveAlone(x, y, z) {
    return this.edge().moveBy(x, y, z)
  }

  _sortAttachedSegments() {
    const edgeDirection = this.edge().vector().normalized()
    this.attachedSegments().sort((segment1, segment2) => (
      edgeDirection.dot(segment2.edge().center()) - edgeDirection.dot(segment1.edge().center())
    ))
  }

  attachedSegments() {
    if (!this._attachedSegments) {
      this._attachedSegments = []
    }
    return this._attachedSegments
  }

  addAttachedSegment(segment) {
    this.attachedSegments().push(segment)
    this._sortAttachedSegments()
  }

  removeAttachedSegment(segment, { modifyEdge } = {}) {
    this.attachedSegments().remove(segment)

    // Only modify the edge if specified
    if (!modifyEdge) return

    // If the last attached segment was just removed, the surface should be deleted
    if (this.attachedSegments().length === 0) {
      this.delete()
      return
    }

    const footprint = segment.footprint().roundedTo(2)
    const newEdges = cutEdgeByShape(footprint, this.edge().roundedTo(2))
      // NOTE: This filter removes small parts of the surface that are
      // negligible. This most often occurs on outside corners, where the
      // surface has to extend past the end of the segment's footprint, but
      // isn't connected to any other segments
      .filter(edge => edge.length() > segment.thickness().toInches())
      .map(edge => makeSurfaceEdge(edge, this.buildingLevel()))

    const truncatedEdge = newEdges[0]
    const remainderEdge = newEdges[1] // may not exist

    this.setEdge(truncatedEdge)

    if (!remainderEdge) return

    const newSurface = new this.constructor(remainderEdge)
    // NOTE: This assumes that any object containing a surface should have an
    // #addSurface method
    this.componentOf()?.addSurface(newSurface)

    // The new surface should be attached to all segments that _aren't_
    // between my edge's endpoints
    const otherSegments = this.attachedSegments().filter(segment => (
      !this.edge().isLocatorBetweenEndpoints(segment.edge().center())
    ))

    otherSegments.forEach(other => {
      other.addSurface(newSurface)
      other.removeSurface(this)
    })
  }

  addToContext(buildingContext) {
    this.componentOf()?.removeSurface(this)

    const room = buildingContext.room()
    room?.addSurface(this)
  }

  delete() {
    // We're spreading this into an array because WallSegment #removeSurface
    // will remove itself from the surface's attached segments. If we just
    // looped over the attached segments directly, each iteration would modify
    // the array, which would mess up the forEach loop
    [...this.attachedSegments()].forEach(segment => segment.removeSurface(this))
    this.componentOf()?.removeSurface(this)
    this.setComponentOf(undefined)
  }

  // region smartJSON

  _buildingLevelAtVersion(_sourceVersion) {
    // Subclasses may wish to override
    return this.buildingLevel()
  }

  classVersion() { return 2 }
  migrate(sourceVersion) {
    if (sourceVersion < 2) {
      if (!this.componentOf()) return
      const buildingLevel = this._buildingLevelAtVersion(sourceVersion)
      const edgeXY = this.edge().xy()

      const conformedBegin = buildingLevel.conformToFloorLevel(edgeXY.begin())
      const conformedEnd = buildingLevel.conformToFloorLevel(edgeXY.end())
      this._edge = conformedBegin.to(conformedEnd)
    }
  }

  // endregion smartJSON
}
module.exports = WallSurface
