const {
  LinearMeasurement,
  Polygon
} = require('construction-designer-core/geometry')
const { three: THREE } = require('construction-designer-core/drawing-editor-3D')
const generateGeometryUVCoordinates = require('shared/helpers/generateGeometryUVCoordinates')
const WindowFigure = require('shared/drawables/WindowFigure')
const WindowFigureXZ = require('shared/drawables/WindowFigureXZ')
const WindowFigure3D = require('shared/drawables/WindowFigure3D')
const WallOpening = require('./WallOpening')
const EditableProperty = require('./EditableProperty')

class Window extends WallOpening {
  static defaultWidth() { return (28).inches() }

  defaultFigure() {
    return new WindowFigure(this, this.systemStrategy())
  }

  defaultFigureXZ() {
    return new WindowFigureXZ(this)
  }

  threeFigure() {
    return new WindowFigure3D(this)
  }

  materialNames() {
    return ['glass']
  }

  defaultHeight() {
    return (3).feet()
  }

  defaultDisplayProperties() {
    return [
      new EditableProperty(this, 'Width', { type: 'unit', defaultUnit: 'ft' }),
      new EditableProperty(this, 'Height', { type: 'unit', defaultUnit: 'ft' }),
      new EditableProperty(this, 'Sill Height', { type: 'unit', defaultUnit: 'ft' })
    ]
  }

  sillHeight() {
    if(!this._sillHeight) {
      this._sillHeight = (3).feet()
    }
    return this._sillHeight
  }
  setSillHeight(newSillHeight) {
    if (newSillHeight.scalar) {
      this._sillHeight = newSillHeight
    } else if (parseFloat(newSillHeight)) {
      this._sillHeight = LinearMeasurement.fromString(newSillHeight)
    }
  }

  delete() {
    this.componentOf().removeWindow(this)
  }

  faceShape() {
    const height = this.height().toInches()
    const sill = this.sillHeight().toInches()

    return new Polygon([
      this.edge().begin().addZ(-sill),
      this.edge().end().addZ(-sill),
      this.edge().end().addZ(-sill - height),
      this.edge().begin().addZ(-sill - height)
    ])
  }

  shape3D() {
    const edge = this.edge()
    const sillHeight = this.sillHeight().toInches()
    const height = this.height().toInches()
    const wallThickness = this.thickness().toInches()
    const windowWidth = this.edge().length()

    // These are the vertices for the window, as if it was laying on its side.
    // For some reason, when using ExtrudeBufferGeometry, ThreeJS seems to flip
    // the original geometry by 90 degrees, which makes this be in the correct position
    const vertices = [
      new THREE.Vector2(0, 0),
      new THREE.Vector2(height, 0),
      new THREE.Vector2(height, windowWidth),
      new THREE.Vector2(0, windowWidth),
    ]

    const shape = new THREE.Shape(vertices)

    const glassThickness = 0.5
    const thicknessVector = edge.normal().multipliedBy((wallThickness / 2) - (glassThickness / 2))
    const thicknessEdge = edge.begin().to(edge.begin().add(thicknessVector))

    const start = thicknessEdge.end().addZ(-sillHeight)
    const end = start.add(edge.normal().multipliedBy(glassThickness))
    const path = new THREE.LineCurve3(start.toThreeJS(), end.toThreeJS())

    const geometry = new THREE.ExtrudeBufferGeometry(shape, { extrudePath: path })

    const mainAxis = this.edge().vector().normalized().toThreeJS()
    generateGeometryUVCoordinates(geometry, mainAxis)
    return geometry
  }
}
module.exports = Window
