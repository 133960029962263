const {
  CompositePanZoomTool
} = require('construction-designer-core/drawing-editor')

const {
  Point: { $P },
  Polygon,
  PolarCoordinate,
  DimensionAnnotation
} = require('construction-designer-core/geometry')
const WallAndSurfaceBuilder = require('shared/operations/WallAndSurfaceBuilder')
const ConnectionBasedPlacementStrategy = require('../strategies/wall-placement/ConnectionBasedPlacementStrategy')

// Unit vectors in each of the allowed directions. Angles >= 180deg are implied
// because we allow going "backwards" in each of these directions.
const ALLOWED_DIRECTIONS = [
  0,
  // Enable to allow 45-degree cases
  // Math.PI_4,
  Math.PI_2,
  // Enable to allow 45-degree cases
  // Math.THREE_PI_4
].map(angle => new PolarCoordinate(1, angle))

class PlaceWallTool extends CompositePanZoomTool {
  placementStrategy() {
    if (!this._placementStrategy) {
      this._placementStrategy = new ConnectionBasedPlacementStrategy(this.buildingLevel.bind(this))
    }
    return this._placementStrategy
  }
  setPlacementStrategy(strategy) {
    this._placementStrategy = strategy
  }

  project() { return this._controller().project() }
  buildingLevel() { return this._controller().buildingLevel() }

  displayName() { return 'Walls' }
  iconName() { return 'walls' }

  reset() {
    this.placementStrategy().resetSnappedObjects()
    this._resetCachedPoints()
    this._controller().redraw()
  }

  activate() {
    this.placementStrategy().resetSnappedObjects()
    this.project().addCurrentVersionReplacedObserver(this, this.reset)
  }

  deactivate() {
    this.project().removeCurrentVersionReplacedObserver(this, this.reset)
    this.reset()
  }

  panTool() {
    if (!this._panTool) {
      this._panTool = super.panTool()
      this._panTool.setMouseTolerance(2)
    }
    return this._panTool
  }

  mouseMove(x, y) {
    super.mouseMove(x, y)

    if (this._pendingPoint) { //already clicked once
      this._pendingPoint = this._constrain(x, y, this._initialPoint)
      this._controller().redraw()
    }
  }

  wallType() {
    if (!this._wallType) {
      this._wallType = WallAndSurfaceBuilder.defaultWallType()
    }
    return this._wallType
  }
  setWallType(newType) {
    this._wallType = newType
  }

  // NOTE: This assumes that rawEndpoint is at the building level's floor level
  _constrain(x, y, rawEndpoint) {
    const requestedDestination = $P(x, y, this.buildingLevel().floorLevel())
    if(!rawEndpoint) { return requestedDestination }
    const otherEndpoint = rawEndpoint.snapshot()
    const requestedVector = requestedDestination.subtract(otherEndpoint)
    const bestDirection = this._findBestDirection(requestedVector)
    const constrainedVector = bestDirection.multipliedBy(requestedVector.dot(bestDirection))
    const constrainedDestination = otherEndpoint.add(constrainedVector)
    return constrainedDestination
  }

  _findBestDirection(requestedVector) {
    return ALLOWED_DIRECTIONS.reduce((bestVector, vector) => {
      const distanceAlongVector = Math.abs(requestedVector.dot(vector))
      const distanceAlongBestVector = Math.abs(requestedVector.dot(bestVector))
      return distanceAlongVector > distanceAlongBestVector ? vector : bestVector
    })
  }

  mouseUp(x, y, options) {
    super.mouseUp(x, y, options)

    if (!this.didPan()) {
      const originalPoint = this._constrain(x, y, this._initialPoint)
      const locked = this._snapLocked // previously locked, this is the second point
      const point = this._snapToExisting(originalPoint) || originalPoint

      if (!locked) {
        if (this._initialPoint) {
          this._initialPoint = this._constrain(this._initialPoint.x(), this._initialPoint.y(), point)
        }
        this._addWallPoint(point)
      } else {
        this._addWallPoint(this._constrain(point.x(), point.y(), this._initialPoint))
      }
    }

    this._controller().redraw()
  }

  pendingEdge() {
    if(this._pendingPoint) {
      return this._initialPoint.to(this._pendingPoint)
    }
  }

  annotation() {
    if (!this._annotation) {
      this._annotation = new DimensionAnnotation()
    }
    return this._annotation
  }

  draw(context, options) {
    super.draw(context, options)

    const edge = this.pendingEdge()
    if(edge) {
      context._drawLine(edge.begin(), edge.end())
      this.annotation().setEdge(edge)
      this.annotation().defaultFigure().draw(context)
    }
  }

  _addWallPoint(rawPoint) {
    const point = rawPoint

    if(this._pendingPoint) {
      this._pendingPoint = this.buildingLevel().conformToFloorLevel(point)

      const finalizedEdge = this._finalizeEdge(this.pendingEdge())
      this._addWallEdge({ edge: finalizedEdge })
    } else { // initial setup
      this._firstPoint = this.buildingLevel().conformToFloorLevel(point)
      this._setUpForNextPoint(this._firstPoint)
    }
  }

  _finalizeEdge(edge) {
    const locators = this.placementStrategy().placeEdge(edge, this._constrain.bind(this))

    this.placementStrategy().resetSnappedObjects()
    const buildingLevel = this.buildingLevel()
    return buildingLevel.conformToFloorLevel(locators.begin).to(buildingLevel.conformToFloorLevel(locators.end))
  }

  _addWallEdge(edgeAndMetadata) {
    const { edge } = edgeAndMetadata

    const level = this.buildingLevel()
    const conformedBegin = level.conformToFloorLevel(edge.begin())
    const conformedEnd = level.conformToFloorLevel(edge.end())

    // The edge we are given is the centerline of the new wall, not the master edge
    // builder handles splitting/remapping segments and surfaces, even rooms
    const builder = this.placementStrategy().makeWallBuilder(conformedBegin.to(conformedEnd))
    builder.setWallType(this.wallType())

    const { segments } = builder.process()

    this._controller().setSelectedComponent(segments.first())
    this._controller().snapshotProject()

    this._resetCachedPoints()
  }

  _snapToExisting(point) {
    let matcher
    if (this._snapLocked) { // already has one immovable point determining this line
      // If we already have a point determining the line, we want to only find a connection if it's squarely on the path,
      //   so the way we do this is instead of a box all around our point, we want to extend a rectangle forward,
      //   sweeping out the implied path of the wall if extended, and check if THAT intersects the connection
      // We do *not* want to find an off-axis connection in that case, when we cannot actually connect to it properly

      // desired: A box sized 12 in the direction of travel, and <width> in width

      const putativeEdge = this._initialPoint.to(point)
      const vector = putativeEdge.vector().normalized()
      const normal = putativeEdge.normal()
      const endpoint = point.add(vector.multipliedBy(12))
      const width = 3.5 // TODO: configure this

      const polygon = new Polygon([
        point.add(normal.multipliedBy(-width / 2)),
        point.add(normal.multipliedBy(width / 2)),
        endpoint.add(normal.multipliedBy(width / 2)),
        endpoint.add(normal.multipliedBy(-width / 2))
      ])
      matcher = polygon.boundingBox() // this works because under current constraints the walls will be axis-aligned, and so will be the connections
    } else {
      matcher = point.expandedBy(12)
    }

    const terminal = this._initialPoint ? 'end' : 'begin'
    const { snappedPoint, snapLocked } = this.placementStrategy().snapToExisting(point, matcher, terminal)

    if (snapLocked !== undefined) this._snapLocked = snapLocked
    return snappedPoint
  }

  _setUpForNextPoint(locator) {
    this._initialPoint = locator
    this._pendingPoint = locator.snapshot()
  }

  _resetCachedPoints() {
    this._initialPoint = undefined
    this._pendingPoint = undefined
    this._firstPoint = undefined
    this._snapLocked = false
  }
}
module.exports = PlaceWallTool
