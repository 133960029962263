const React = require('react')
const PropTypes = require('prop-types')

class RadioPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'radio'

  constructor(props) {
    super(props)

    this.state = {
      property: props.property,
      selectedValue: props.property.get()
    }
  }

  selectedChoiceLabel() {
    const { property, selectedValue } = this.state
    const selectedChoice = property.options().choices.find(choice => (
      choice.value === selectedValue
      || (choice.value?.equals?.(selectedValue))
    ))

    return selectedChoice?.label ?? ''
  }

  _updateProperty(newValue) {
    this.state.property.set(newValue)
    this.props.drawingController.snapshotProjectAndRedraw()
    this.setState({ selectedValue: this.state.property.get() })
  }

  _renderRadioButtons() {
    const property = this.state.property

    return (
      <>
        {property.options().choices.map(({ label, value }) => (
          <label className="font-sm flex items-center grid__item" key={value}>
            <input
              name={property.name()}
              type="radio"
              checked={this.state.selectedValue === value}
              onChange={this._updateProperty.bind(this, value)}
              disabled={property.disabled() || !property.editable()}
              value={value}
            />
            {label}
          </label>
        ))}
      </>
    )
  }

  render() {
    return (
      <div className="grid--side-panel">
        {this._renderRadioButtons()}
      </div>
    )
  }
}

module.exports = RadioPropertyView
