const PolylineComponent = require('./PolylineComponent')
const Polyline = require('../geometry/Polyline')
const DrawnPolylineFigure = require('../drawables/DrawnPolylineFigure')
const DrawnPolylineFigureXZ = require('../drawables/DrawnPolylineFigureXZ')

class DrawnPolyline extends PolylineComponent {
  defaultFigure() {
    return new DrawnPolylineFigure(this)
  }

  defaultFigureXZ() {
    return new DrawnPolylineFigureXZ(this)
  }

  shapeXZ() {
    return new Polyline(this._polyline.vertices().map(v => v.xz()))
  }
}
module.exports = DrawnPolyline
