const { Polygon, RelativeLocator } = require('construction-designer-core/geometry')
const WallAndSurfaceBuilder = require('./WallAndSurfaceBuilder')
const CenterlineWallSegment = require('shared/domain-models/CenterlineWallSegment')
const SurfaceBuilder = require('./SurfaceBuilder')

// A service object to build an interior wall segment and surfaces, and manage
// its intersections with other wall segments/connections/surfaces, given a
// building level and a pre-aligned wall centerline

class CenterlineWallAndSurfaceBuilder extends WallAndSurfaceBuilder {
  process() {
    const centerlines = this._splitCenterline(this.centerline())
    const newSegments = centerlines.map(centerline => this.processSegmentCenterline(centerline))

    return {
      segments: newSegments
    }
  }

  processSegmentCenterline(centerline) {
    const buildingLevel = this.buildingLevel()
    const edgeDirection = centerline.vector().normalized()

    // May or may not exist
    const existingSegments = [
      ...this._shapeExistingWalls(centerline.begin(), edgeDirection.negated()),
      ...this._shapeExistingWalls(centerline.end(), edgeDirection)
    ]

    const segment = new CenterlineWallSegment(centerline.snapshot(), this.wallType(), this.thickness())
    existingSegments.forEach(existingSegment => segment.addConnectedSegment(existingSegment))

    buildingLevel.addSegment(segment)
    const surfaceBuilder = new SurfaceBuilder(segment, existingSegments, buildingLevel, this.tolerance())
    surfaceBuilder.createSurfaces()

    return segment
  }

  // This will splice up the supplied centerline into pieces that
  // should correspond to new wall segments
  _splitCenterline(centerline) {
    const extendedCenterlines = []
    this.buildingLevel().wallSegments().forEach(wallSegment => {
      const extendedCenterline = wallSegment.extendedCenterline(this.thickness().toInches() / 2)
      const intersects = extendedCenterline.intersectionsWithEdgeIn2D(centerline, this.tolerance()).length > 0

      if (!intersects) return
      extendedCenterlines.push(extendedCenterline)
    })

    const finalEdges = extendedCenterlines.reduce((centerlines, extendedCenterline) => (
      centerlines.flatMap(centerline => {
        const edges = this._cutEdgeByEdge(extendedCenterline, centerline)
        const significantEdges = edges.filter(edge => edge.length() >= this.thickness().toInches() * 2)

        return significantEdges.length === 0 ? [centerline] : significantEdges
      })
    ), [centerline])

    return finalEdges
  }

  // TODO: Find a better place for this
  _cutEdgeByEdge(cuttingEdge, remainingEdge) {
    const intersection = cuttingEdge.intersectionsWithEdgeIn2D(remainingEdge, this.tolerance()).first()

    if (!intersection) return [remainingEdge]

    // NOTE: The snapshot is important to make sure segments don't share the same Locator instance
    return [
      remainingEdge.begin().to(intersection),
      intersection.snapshot().to(remainingEdge.end())
    ].filter(edge => edge.length() > 0)
  }

  /**
   * @param {Locator} edgeEndpoint
   * @param {Locator} edgeDirection
   * @return {Polygon}
   */
  _generateCheckingBox(edgeEndpoint, edgeDirection) {
    const thickness = this.thickness().toInches()

    const centerlineBegin = edgeEndpoint
    const centerlineEnd = edgeEndpoint.add(edgeDirection.negated().multipliedBy(thickness))
    const boxCenterline = centerlineBegin.to(centerlineEnd)

    const normal = boxCenterline.normal()

    return new Polygon([
      boxCenterline.begin().add(normal.multipliedBy(-thickness / 2)),
      boxCenterline.begin().add(normal.multipliedBy(thickness / 2)),
      boxCenterline.end().add(normal.multipliedBy(thickness / 2)),
      boxCenterline.end().add(normal.multipliedBy(-thickness / 2))
    ])
  }

  /**
   * Split a long segment into two, and return the new segment created from the remainderEdge
   *
   * @param {Edge} remainderEdge
   * @param {WallSegment} existingSegment
   */
  _generateSplitSegment(remainderEdge, existingSegment) {
    const newSegment = new CenterlineWallSegment(remainderEdge, existingSegment.type(), existingSegment.thickness())
    newSegment.setHeight(existingSegment.height())

    existingSegment.connectedSegments().slice().forEach(connectedSegment => {
      const disconnected = !existingSegment.sharedEndpoint(connectedSegment)
      if (disconnected) {
        // If the previously connected segment no longer shares an endpoint with
        // the existing segment, then disconnect them
        existingSegment.removeConnectedSegment(connectedSegment)

        // However, if it now shares an endpoint with the new segment, then
        // attach those two
        if (newSegment.sharedEndpoint(connectedSegment)) {
          newSegment.addConnectedSegment(connectedSegment)
        }
      }
    })

    existingSegment.buildingLevel().addSegment(newSegment)
    existingSegment.reassignOpenings([newSegment])

    // NOTE: #addConnectedSegments will automatically make sure both segments
    // have a reference to the other, so we only need add to one segment
    existingSegment.addConnectedSegment(newSegment)

    return { newSegment }
  }

  /**
   * @param {Locator} edgeEndpoint
   * @param {Locator} edgeDirection - A normalized vector representing an extension of the edge
   * @returns {WallSegment[]} - The existing wall segments that were shaped
   */
  _shapeExistingWalls(edgeEndpoint, edgeDirection) {
    const checkingBox = this._generateCheckingBox(edgeEndpoint, edgeDirection)

    const existingSegments = this.buildingLevel().wallSegments().filter(wallSegment => {
      const extendedCenterline = wallSegment.extendedCenterline(this.thickness().toInches())
      return extendedCenterline.segmentsInsidePolygon(checkingBox, this.tolerance()).length > 0
    })

    if (existingSegments.length === 0) return []

    // NOTE: Assuming the first overlapping segment _seems_ dangerous...
    const existingSegment = existingSegments.first()
    const segmentCenterline = existingSegment.centerline()

    const buildingLevel = this.buildingLevel()
    const cuttingEdge = edgeEndpoint.to(edgeEndpoint.add(
      edgeDirection.negated().multipliedBy(existingSegment.thickness().toInches()))
    )

    const newWallEdges = this._cutEdgeByEdge(cuttingEdge, segmentCenterline)
      // We only want edges with a length of at least twice the thickness
      .filter(edge => edge.length() >= this.thickness().toInches() * 2)
      .map(edge => {
        const relativeBegin = RelativeLocator.makeRelativeTo(edge.begin(), buildingLevel.zLevelLocator())
        const relativeEnd = RelativeLocator.makeRelativeTo(edge.end(), buildingLevel.zLevelLocator())

        return relativeBegin.to(relativeEnd)
      })

    let modifiedWallEdge = newWallEdges[0]
    const remainderWallEdge = newWallEdges[1] // may not exist, if wall was truncated, not split

    // use existing centerline to construct modified wall edge
    if (modifiedWallEdge.equals(segmentCenterline, this.tolerance())) {
      // We want the endpoints of the new segments to meet at the same point.
      // So, we'll adjust the existing segment's edge to end at the
      // edgeEndpoint, which is where the new segment will be created
      modifiedWallEdge = segmentCenterline.withNearestEndpointReplaced(edgeEndpoint)
    }

    // NOTE: This needs to happen before #_generateSplitSegment might be called
    existingSegment.setEdge(modifiedWallEdge)

    let newSegment
    if (remainderWallEdge) {
      ({ newSegment } = this._generateSplitSegment(remainderWallEdge, existingSegment))
    } else {
      existingSegment.reassignOpenings()
    }

    // handle the surfaces...
    const surfaces = existingSegments.flatMap(segment => segment.surfaces())
    const nearSurfaces = surfaces.filter(surface => (
      surface.edge().segmentsInsidePolygon(checkingBox, this.tolerance()).length > 0
    ))

    // This finds the surface closest in the direction of the edgeDirection
    const nearSurface = nearSurfaces.reduce((closestSurface, surface) => {
      const offset = surface.edge().begin().dot(edgeDirection)
      if (offset < closestSurface.offset) return { surface, offset }

      return closestSurface
    }, { surface: undefined, offset: Infinity }).surface

    this._handleSurface(nearSurface, checkingBox, { newSegment, existingSegment })

    return [...existingSegments, newSegment].filter(item => item)
  }
}
module.exports = CenterlineWallAndSurfaceBuilder
