const {
  PolygonFigure
} = require('construction-designer-core/drawing-editor')

class DrawnPolygonFigure extends PolygonFigure {
  constructor(model, shapeMethod = 'shape') {
    super(model[shapeMethod]())
    this._model = model
    this._shapeMethod = shapeMethod
  }
  model() { return this._model }
  shape() { return this.model()[this._shapeMethod]() }
  handles() {
    return []
  }

  defaultProperties() {
    return {
      lineWidth: 1,
      strokeStyle: '#0077CE',
      fillStyle: '#E1EFFA00'
    }
  }
}

module.exports = DrawnPolygonFigure