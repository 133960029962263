const React = require('react')
const PropTypes = require('prop-types')
const BooleanPropertyView = require('./BooleanPropertyView')
const UnitPropertyView = require('./UnitPropertyView')
const TextPropertyView = require('./TextPropertyView')
const SelectPropertyView = require('./SelectPropertyView')
const ColorPropertyView = require('./ColorPropertyView')
const BooleanMapPropertyView = require('./BooleanMapPropertyView')
const RadioPropertyView = require('./RadioPropertyView')
const NumberPropertyView = require('./NumberPropertyView')
const TraySelectPropertyView = require('./TraySelectPropertyView')
const RadioSelectPropertyView = require('./RadioSelectPropertyView')

const ALL_VIEWS = [
  BooleanPropertyView,
  UnitPropertyView,
  TextPropertyView,
  SelectPropertyView,
  ColorPropertyView,
  BooleanMapPropertyView,
  RadioPropertyView,
  NumberPropertyView,
  TraySelectPropertyView,
  RadioSelectPropertyView
]

class PropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired,
    propertyViews: PropTypes.array
  }

  static defaultProps = {
    propertyViews: []
  }

  constructor(props) {
    super(props)

    this.state = {
      fieldKey: 0
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.property !== prevProps.property) {
      this._reset()
    }
  }

  componentDidMount() {
    this.props.property.addExternalChangeObserver(this, this._reset)
  }

  componentWillUnmount() {
    this.props.property.removeExternalChangeObserver(this, this._reset)
  }

  _reset() {
    // When a property updates, we want to automatically update the property
    // panel. This is accomplished by updating the key prop passed to the view,
    // which notifies React that the view should be rerendered
    this.setState(previousState => ({ fieldKey: previousState.fieldKey + 1 }))
  }

  propertyViews() {
    if (!this._propertyViews) {
      this._propertyViews = this.defaultPropertyViews()
    }
    return this._propertyViews
  }
  defaultPropertyViews() {
    const propertyViews = this.props.propertyViews

    ALL_VIEWS.forEach(view => {
      const alreadyIncluded = propertyViews.some(otherView => otherView.forType === view.forType)
      if (!alreadyIncluded) propertyViews.push(view)
    })

    return propertyViews
  }

  render() {
    const property = this.props.property

    const View = this.propertyViews().find(view => view.forType === property.type())
    return (
      <View
        key={`property-view-${this.state.fieldKey}`}
        property={property}
        drawingController={this.props.drawingController}
      />
    )
  }
}

module.exports = PropertyView
