const {
  NormalToEdgeLocator,
} = require('construction-designer-core/geometry')

class OutLeftDoorStrategy {
  constructor(model) {
    this._model = model
  }

  model() {
    return this._model
  }

  swingAngleStart() {
    return this.model().edge().angle()
  }

  swingAngleEnd() {
    const directionalSwingAngle = -this.model().swingAngle()
    return this.model().swingAngleStart() + directionalSwingAngle
  }

  swingAngleEnd3D() {
    const directionalSwingAngle = -this.model().swingAngle3D()
    return this.model().swingAngleStart() + directionalSwingAngle
  }

  threeFigureEdge() {
    return this.model().outerLocator3D().to(this.hingeLocator())
  }

  vertices() {
    const hingeLocator = this.hingeLocator()
    const depth = this.model().depth()
    const width = this.model().width()
    const distanceAlongNormal = depth

    return [
      hingeLocator,
      new NormalToEdgeLocator(hingeLocator, 0, width),
      new NormalToEdgeLocator(hingeLocator, distanceAlongNormal, width),
      new NormalToEdgeLocator(hingeLocator, distanceAlongNormal)
    ]
  }

  hingeLocator() {
    return this.model().edge().shiftedAlongNormalBy(this.model().componentOf().thickness().toInches() / 2).begin()
  }
}

module.exports = OutLeftDoorStrategy
