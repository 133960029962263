const { Point: { $P } } = require('construction-designer-core/geometry')
const SWING_DIRECTIONS = require('../option-sets/SWING_DIRECTIONS')
const IfcConstructionComponent = require('./IfcConstructionComponent')

class IfcDoor extends IfcConstructionComponent {
  ifcDoorway() { return this.componentOf() }
  properties() { return this._properties }

  getDoorProperties() {
    let swingDirection = SWING_DIRECTIONS.Out
    if (this.referenceAxes()) {
      const referenceZAxis = this.referenceAxes().z
      const referenceXAxis = this.referenceAxes().x
      const referenceYAxis = referenceZAxis.cross(referenceXAxis)

      if (referenceYAxis.equals($P(0, -1, 0))) { swingDirection = SWING_DIRECTIONS.In }
    }

    const handedness = this.properties().handedness
    return { handedness, swingDirection, type: this.properties().type }
  }
}

module.exports = IfcDoor
