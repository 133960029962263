const EdgeWallAndSurfaceBuilder = require('shared/operations/EdgeWallAndSurfaceBuilder')
const IfcConstructionComponent = require('./IfcConstructionComponent')
const { Point: { $P } } = require('construction-designer-core/geometry')

class IfcWallSegment extends IfcConstructionComponent {
  /**
   * An array of 2 locators
   * @returns {Array}
   */
  centerlineArray() { return this._centerlineArray }
  openings() { return this._openings || [] }

  buildingLevel() { return this.componentOf() }
  // NOTE: This should be undefined until set manually
  centerline() { return this._centerline }

  referencePoint() {
    return this.translation().addZ(
      this.buildingLevel().baseOffset().toNumber(this.baseUnit())
    )
  }

  /**
   * Converts the centerlineArray to an actual Edge object
   */
  generateCenterline() {
    const rotatedEdge = this._rotateVertices(this.centerlineArray(), this.rotation())
    const translatedEdge = this._translateToLCADSpace(
      rotatedEdge.map(vertex => vertex.add(this.referencePoint()))
    )

    this._centerline = translatedEdge.first().to(translatedEdge.last())
  }

  convertToSegment() {
    this.generateCenterline()

    const rotatedVertices = this._rotateVertices(this.vertices(), this.rotation())
    const translatedVertices = rotatedVertices.map(vertex => vertex.add(this.referencePoint()))
    const lcadPoints = this._translateToLCADSpace(translatedVertices)

    const builder = new EdgeWallAndSurfaceBuilder(this.buildingLevel(), this.centerline())
    builder.setThickness(this._findThickness(lcadPoints))

    const { segments } = builder.process()
    segments.forEach(segment => {
      segment.setHeight(this._findHeight(lcadPoints))
    })

    // first assign all openings to the first segment
    this.openings().forEach(ifcOpening => {
      ifcOpening.setComponentOf(this)
      const opening = ifcOpening.convertToOpening()
      segments.first().addOpening(opening)
    })

    // now reassign openings
    segments.first().reassignOpenings(segments)

    return segments
  }

  _findHeight(vertices) {
    // NOTE: The expectation is that these vertices have already been translated
    // to LCAD space, and -z is "up"
    return this._findExtent(vertices, $P(0, 0, -1)).inches()
  }

  _findThickness(vertices) {
    const normal = this.centerline().normal()
    return this._findExtent(vertices, normal).inches()
  }
}

module.exports = IfcWallSegment
