const React = require('react')
const PropTypes = require('prop-types')
const CollapsablePanelSection = require('./CollapsablePanelSection')

class ProjectCost extends React.Component {
  static propTypes = {
    project: PropTypes.object.isRequired,
    drawingController: PropTypes.object
  }

  componentDidMount() {
    this.props.project.addCurrentVersionReplacedObserver(this, this.refresh)
  }

  componentWillUnmount() {
    this.props.project.removeCurrentVersionReplacedObserver(this, this.refresh)
  }

  refresh() {
    this.forceUpdate()
  }

  project() {
    return this.props.project
  }

  _renderStatisticSection(title, value, unit) {
    return (
      <div className="side-panel__row">
        <span className="font-sm">{title}:</span>
        <span className="font-sm no-wrap"><strong>{value} {unit || ''}</strong></span>
      </div>
    )
  }

  async setEstimation(event) {
    this.props.project.setEstimationId(parseInt(event.target.value))
    const drawingController = this.props.drawingController
    if(drawingController) {
      await drawingController.snapshotProjectAndRedraw()
      this.refresh()
    }
  }

  render() {
    const headerTitle = <span className="selection-name">Project Cost:</span>

    return (
      <CollapsablePanelSection headerTitle={headerTitle}>
        <div className="side-panel__row">
          <label className="font-sm label--dropdown">
            Estimation:
            <select
              value={this.project().estimationId()}
              onChange={this.setEstimation.bind(this)}
              className="form__dropdown font-sm margin-left-sm"
            >
              {this.props.project.availableProjectEstimations().map(estimation => (
                <option value={estimation.id()} key={estimation.title()}>{estimation.title()}</option>
              ))}
            </select>
          </label>
        </div>
        {this._renderStatisticSection(
          'Estimated Cost', this.project().projectEstimation().formattedEstimate(this.project())
        )}
      </CollapsablePanelSection>
    )
  }
}

module.exports = ProjectCost
