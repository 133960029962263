const {
  ExtrudedPolygon,
  Polygon,
} = require('construction-designer-core/geometry')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')
const GarageDoorFigure3D = require('../drawables/GarageDoorFigure3D')
const DoorFigure = require('shared/drawables/DoorFigure')

class GarageDoor extends ShellBuilderConstructionComponent {
  static displayClassName() {
    return 'Garage Door'
  }

  width() {
    return this.edge().length()
  }

  geometry() {
    return this.shape()
  }

  vertices() {
    const edge1 = this.edge().shiftedAlongNormalBy(-this.thickness().toInches() / 2)
    const edge2 = this.edge().shiftedAlongNormalBy(this.thickness().toInches() / 2)

    return [
      edge1.begin(),
      edge1.end(),
      edge2.end(),
      edge2.begin()
    ]
  }

  shape() {
    return new Polygon(this.vertices())
  }

  shape3D() {
    const offset = (1).feet().toInches()
    const height = this.height() - offset
    const vertices = this.shape().vertices().map(v => v.addZ(-offset))
    return new ExtrudedPolygon(new Polygon(vertices), -height)
  }

  height() {
    return this.componentOf().height().toInches()
  }

  primaryDirection() {
    return this.edge().vector().normalized()
  }

  thickness() {
    return (1.5).inches()
  }

  edge() {
    return this.componentEdge().shiftedAlongNormalBy((this.componentOf().thickness().toInches() / 2))
  }

  componentEdge() {
    return this.componentOf().edge()
  }

  threeFigure() {
    return new GarageDoorFigure3D(this)
  }

  defaultFigure() {
    return new DoorFigure(this, this.systemStrategy())
  }
}

module.exports = GarageDoor
