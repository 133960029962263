/**
 * A service object finding connected contours out of an unorderd array of consistently oriented edges
 * NOTE: Vertices are not required to be identical, but are required to be equal within Math.DEFAULT_TOLERANCE
 *
 * @class ContourFinder
 */
class ContourFinder {
  /**
   * Takes an array of edges, and returns an array of connected contours in a consistient winding order
   *
   * @param {Edge[]} edges An array of edges
   * @returns {Locator[][]} An array containing connected contours represented by sequences of vertices
   */
  process(edges) {
    const connectedPoints = new Map()
    edges.forEach(edge => (
      connectedPoints.set(edge.begin(), edge.end())
    ))

    let currentEdgeBegin = edges.first().begin()
    const contours = [[currentEdgeBegin]]

    while (connectedPoints.size > 0) {
      const currentEdgeEnd = connectedPoints.get(currentEdgeBegin)
      connectedPoints.delete(currentEdgeBegin)

      if (currentEdgeEnd) {
        const edge = currentEdgeBegin.to(currentEdgeEnd)
        const connectedContours = this._findConnectedContours(contours, edge)
        this._buildContours(edge, connectedContours, contours)
        // We're setting this so that we can continue finding connected edge
        // segments in the next iteration of the loop
        currentEdgeBegin = currentEdgeEnd
      } else {
        // This happens when we hit the end of one contour
        // This gets the first key from the connectedPoints map
        currentEdgeBegin = connectedPoints.keys().next().value
      }
    }
    return contours
  }

  /**
   * Finds contours that will connect to the given edge
   *
   * @param {Locator[][]} contours An array containing the vertices of individual contours
   * @param {Edge} edge
   *
   * @returns {Locator[][]} An array of contours that would connect to the given edge
   */
  _findConnectedContours(contours, edge) {
    const edgeBegin = edge.begin()
    const edgeEnd = edge.end()

    return contours.filter(contour => {
      const loopBegin = contour.first()
      const loopEnd = contour.last()

      return edgeBegin.equals(loopEnd) || edgeEnd.equals(loopBegin)
    })
  }

  /**
   * Merges two contours into one based on a connecting edge, and returns that new contour
   *
   * @param {Locator[]} contour1 An array of vertices that form a continuous contour
   * @param {Locator[]} contour2 An array of vertices that form a continuous contour
   * @param {edge} connectingEdge An edge that will connect the two contours
   *
   * @returns {Locator[]} The merged contour
   */
  _mergeContours(contour1, contour2, connectingEdge) {
    const edgeBegin = connectingEdge.begin()

    // NOTE: This assumes that the connecting edge can't have inverted direction - this
    // works for now, but may need to be more robust in other places
    if (edgeBegin.equals(contour1.last())) {
      return contour1.fastConcat(contour2)
    }

    return contour2.fastConcat(contour1)
  }

  /**
   * Extends the contour with the given edge
   *
   * @param {Locator[]} contour An array of vertices that form a continuous contour
   * @param {Edge} edge An edge that will connect to the contour
   */
  _extendContour(contour, edge) {
    const edgeBegin = edge.begin()
    const edgeEnd = edge.end()

    if (edgeBegin.equals(contour.last())) {
      contour.push(edgeEnd)
    } else {
      // We know that the edge will connect to the contour in one way or another, so
      // in this case we don't have to check that the edge connects to the
      // beginning of the contour
      contour.unshift(edgeBegin)
    }
  }

  /**
   * Builds the existing contours by either merging two contours, extending a contour,
   * or creating a new one
   *
   * @param {Edge} edge
   * @param {Locator[][]} connectedContours An array of 0-2 contours that should be
   * connected to the given edge
   * @param {Locator[][]} allContours An array of all existing contours
   */
  _buildContours(edge, connectedContours, allContours) {
    if (connectedContours.length === 2) {
      const contour1 = connectedContours.first()
      const contour2 = connectedContours.last()

      const mergedShape = this._mergeContours(contour1, contour2, edge)
      // Now that we have a contour that contains both original contours, we can
      // remove the original contours from the array of all contours
      allContours.remove(contour1)
      allContours.remove(contour2)
      allContours.push(mergedShape)
    } else if (connectedContours.length === 1) {
      const connectedContour = connectedContours.first()
      this._extendContour(connectedContour, edge)
    } else {
      // When the new edge doesn't build on an existing contour or connect
      // contours, then we need to create a new contour
      allContours.push([edge.begin(), edge.end()])
    }
  }
}
module.exports = ContourFinder
