class ProxyLocator {
  constructor(source, emulatedMethod, valueMethod) {
    this._source = source
    this._emulatedMethod = emulatedMethod
    this._valueMethod = valueMethod
  }

  x() {
    return this._proxyValue('x')
  }

  y() {
    return this._proxyValue('y')
  }

  z() {
    return this._proxyValue('z')
  }

  _proxyValue(methodName) {
    if(this._emulatedMethod == methodName) {
      return this._source[this._valueMethod]()
    }
    return 0
  }

  referenceFrame() {
    return 0
  }
}
module.exports = ProxyLocator