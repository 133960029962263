const React = require('react')
const PropTypes = require('prop-types')
const RadioSelect = require('components/RadioSelect')

class RadioSelectPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'radioselect'

  _updateProperty(value) {
    this.props.property.set(value)
    this.props.drawingController.snapshotProjectAndRedraw()
  }

  render() {
    const property = this.props.property

    return (
      <div className="side-panel__row">
        <RadioSelect
          getProperty={() => property.get()}
          setProperty={this._updateProperty.bind(this)}
          label={property.label()}
          options={property.options().choices}
        />
      </div>
    )
  }
}

module.exports = RadioSelectPropertyView
