const React = require('react')
const PropTypes = require('prop-types')
const LayerToggle = require('./LayerToggle')

class LayersPanel extends React.Component {
  static propTypes = {
    drawingController: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const layerManager = props.drawingController.layerManager()

    if (state.layerManager === layerManager) return null

    return { layerManager: layerManager }
  }

  constructor(props) {
    super(props)

    const layerManager = props.drawingController.layerManager()
    this.state = { layerManager }
  }

  redrawView() {
    this.props.drawingController.redraw()
  }

  renderSeparator(index) {
    if (index === 0) return <hr className="divider--header" />
  }

  render() {
    const layerNames = this.state.layerManager.layerNames()
    if (layerNames.length === 0) return ''

    return (
      <>
        <header data-testid="layers-panel" className="flex justify-left layers__header">Layers</header>
        <section className="layers">
          {layerNames.map((layerName, index) => (
            <React.Fragment key={layerName}>
              {this.renderSeparator(index)}

              <LayerToggle
                layerName={layerName}
                layerManager={this.state.layerManager}
                redrawView={this.redrawView.bind(this)}
              />
            </React.Fragment>
          ))}
        </section>
      </>
    )
  }
}

module.exports = LayersPanel
