const React = require('react')
const PropTypes = require('prop-types')
const TraySelect = require('components/TraySelect')

class TraySelectPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'trayselect'

  _updateProperty(value) {
    this.props.property.set(value)
    this.props.drawingController.snapshotProjectAndRedraw()
  }

  render() {
    const property = this.props.property

    return (
      <TraySelect
        getProperty={() => property.get()}
        setProperty={this._updateProperty.bind(this)}
        options={property.options().choices}
        wrapperClassName="margin-y-sm"
        withDivider={true}
      />
    )
  }
}

module.exports = TraySelectPropertyView
