const {
  ConstructionComponentHandle
} = require('construction-designer-core/construction')
const { CompositeFigure } = require('construction-designer-core/drawing-editor')

class SimpleShellBuildingLevelFigure extends CompositeFigure {
  _drawSelf(context) {
    // NOTE: This should eventually draw interior segments also
    const segmentShapes = this.model().exteriorWallSegments().flatMap(s => s.footprintWithoutOpenings())
    segmentShapes.forEach(shape => context._drawPolygon(shape))
  }

  layer() { return 'Walls' }

  vertices() { return this.model().vertices() }
  boundingBox() { return this.model().boundingBox() }
  contains(x, y) { return this.model().contains(x, y) }

  selfHandle() { return new ConstructionComponentHandle(this.model()) }

  defaultProperties() {
    return {
      strokeStyle: '#00000000',
      fillStyle: '#000'
    }
  }
}

module.exports = SimpleShellBuildingLevelFigure
