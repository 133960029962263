const ModeStrategy = require('./ModeStrategy')

class ReadOnlyModeStrategy extends ModeStrategy {
  showPropertyPanel() {
    return false
  }

  showToolPanel() {
    return false
  }

  isViewOnly() {
    return true
  }

  renderSaveButton(_repository) { }
  renderRequestQuoteButton() { }
  autosaveable() { return false }
}
module.exports = ReadOnlyModeStrategy
