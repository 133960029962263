const {
  CompositePanZoomTool
} = require('construction-designer-core/drawing-editor')

const {
  Point: { $P },
  Edge
} = require('construction-designer-core/geometry')
const EdgeWallSegment = require('shared/domain-models/EdgeWallSegment')

const WallSegment = require('shared/domain-models/WallSegment')

Edge.prototype.isParallelTo = function(edge, tolerance = Math.DEFAULT_TOLERANCE) {
  if(!edge) return false
  const thisNormalizedAngle = this.theta()
  const edgeNormalizedAngle = edge.theta()
  const angularDifference = (thisNormalizedAngle - edgeNormalizedAngle).normalizedAngle()

  return (
    angularDifference.isNearTo(0, tolerance)
    || angularDifference.isNearTo(Math.PI, tolerance)
    || angularDifference.isNearTo(Math.TWO_PI, tolerance)
  )
}

class WallSegmentTool extends CompositePanZoomTool {
  project() { return this._controller().project() }
  buildingLevel() { return this._controller().buildingLevel() }
  floorPlan() { return this.buildingLevel().floorPlan() }

  displayName() {
    return 'Wall Segments'
  }

  reset() {
    this._resetCachedPoints()
    this._controller().redraw()
  }

  activate() {
    this.project().addCurrentVersionReplacedObserver(this, this.reset)
  }

  deactivate() {
    this.project().removeCurrentVersionReplacedObserver(this, this.reset)
    this.reset()
  }

  // Subclasses may wish to override
  constrainedToSnapPoints() { return false }
  snapPoints() {
    // Subclasses may wish to override
    return this.buildingLevel().snapPoints()
  }
  planEdges() { return this.floorPlan().edges() }

  panTool() {
    if (!this._panTool) {
      this._panTool = super.panTool()
      this._panTool.setMouseTolerance(2)
    }
    return this._panTool
  }

  mouseUp(x, y, options) {
    super.mouseUp(x, y, options)

    if (!this.didPan()) {
      const originalPoint = $P(x, y)
      const point = this._snapRelativePointTo(
        originalPoint,
        this.snapPoints()
      )

      if (point) {
        this._addWallPoint(point.snapshot())
      } else if (!this.constrainedToSnapPoints()) {
        this._addWallPoint(originalPoint)
      }
    }

    this._controller().redraw()
  }

  mouseMove(x, y, options) {
    super.mouseMove(x, y, options)
    if (this._pendingPoint) { // already clicked once
      const originalPoint = $P(x, y)
      this._pendingPoint = this._snapRelativePointTo(originalPoint, this.snapPoints()) || originalPoint
      this._controller().redraw()
    }
  }

  draw(context, options) {
    super.draw(context, options)

    const edge = this.pendingEdge()
    if(edge) context._drawLine(edge.begin(), edge.end())
  }

  pendingEdge() {
    if(this._pendingPoint) {
      return this._initialPoint.to(this._pendingPoint)
    }
  }

  _addWallPoint(rawPoint) {
    const point = this._conformToExistingEndPoints(rawPoint)

    if(this._pendingPoint) {
      this._pendingPoint = this._conformToBuildingLevel(point)

      const edgeAndMetadata = this._conformEdge(this.pendingEdge())
      this._addWallEdge(edgeAndMetadata)

      if (this._isNowClosed(edgeAndMetadata.edge)) {
        // closed a polygon
        this._finalizeLoop()
      } else {
        this._setUpForNextPoint(this._pendingPoint)
      }
    } else { // initial setup
      this._firstPoint = this._conformToBuildingLevel(point)
      this._setUpForNextPoint(this._firstPoint)
    }
  }

  _isNowClosed(_edge) {
    // Subclasses may wish to override
    return this._firstPoint.equals(this._pendingPoint, 1e0)
  }

  _snapRelativePointTo(target, points, tolerance = 24) {
    const closestPoint = this._findClosestPoint(target, points)

    if (closestPoint.distance <= tolerance) {
      return closestPoint.point.snapshot()
    }
  }

  _findClosestPoint(target, points) {
    return points.reduce((closestSoFar, point) => {
      const distance = target.distanceTo(point)
      const closestDistance = closestSoFar.distance
      return distance < closestDistance ? { point, distance }  : closestSoFar
    }, { point: null, distance: Infinity })
  }

  _conformToBuildingLevel(locator) {
    const possibleConformedPoint = this._conformToExistingEndPoints(locator)
    if (possibleConformedPoint === locator) return possibleConformedPoint
    return this.buildingLevel().conformToFloorLevel(locator)
  }

  _conformToExistingEndPoints(locator) {
    return this._existingWallEndpoints().find(endPoint => endPoint.equals(locator, 1)) || locator
  }

  _existingWallEndpoints() { return [] } // subclasses might want to override

  _setUpForNextPoint(locator) {
    this._initialPoint = locator
    this._pendingPoint = locator.snapshot() // this._buildRelativePoint(locator.x(), locator.y())
  }

  _finalizeLoop() {
    this._previouslyReversed = undefined
    this._resetCachedPoints()
  }

  _resetCachedPoints() {
    this._initialPoint = undefined
    this._pendingPoint = undefined
    this._firstPoint = undefined
  }

  // should return an edge, a direction, and a thickness in an object
  _conformEdge(edge, originallyReversed = false) {
    let segmentThickness
    let reversed = originallyReversed

    const orient = targetEdge => {
      const { direction, thickness } = this._findLikelyWallThickness(targetEdge, this.planEdges())
      segmentThickness = thickness
      if(Math.sign(direction) === 1) { // TODO verify why this seems to be logically back-to-front
        reversed = !reversed
        return targetEdge.reversed()
      }
      return targetEdge
    }
    edge = orient(edge)

    const originalEdge = edge.snapshot()

    const beginPoint = edge.begin()
    const endPoint = edge.end()
    const nearbyPoints = this.snapPoints().filter(point => point.distanceTo(beginPoint) < 1 || point.distanceTo(endPoint) < 1)

    // resnap
    const normal = edge.normal()
    const resnap = masterPoint => {
      nearbyPoints.forEach(p => {
        const vector = masterPoint.to(p).vector()
        if(vector.r() < 1 && vector.dot(normal) < 0) {
          masterPoint.moveBy(vector.x(), vector.y())
        }
      })
    }
    resnap(beginPoint)
    resnap(endPoint)
    if(!originalEdge.equals(edge)) { // resnap changed edge, recurse through algorithm again
      return this._conformEdge(edge, reversed)
    }

    return {
      edge,
      thickness: segmentThickness,
      reversed
    }
  }

  previouslyReversed() {
    return this._previouslyReversed
  }

  _addWallEdge(edgeAndMetadata) {
    const { thickness, edge, reversed } = edgeAndMetadata

    const buildingLevel = this.buildingLevel()
    const segment = new EdgeWallSegment(edge, EdgeWallSegment.TYPES.Interior, thickness.inches())
    const segments = buildingLevel.interiorWallSegments()
    reversed ? segments.unshift(segment) : segments.push(segment)

    this._previouslyReversed = reversed

    this._controller().setSelectedComponent(segment)
    this._controller().snapshotProject()
  }

  _minimumThickness() { return (2).inches() }
  _maximumThickness() { return (24).inches() }
  _defaultThickness() {
    // Subclasses should override
    return (4).inches()
  }

  _findLikelyWallThickness(edge, planEdges) {
    const masterLength = edge.length()
    const minimumThickness = this._minimumThickness().toInches()
    const maximumThickness = this._maximumThickness().toInches()
    // project into floorspace
    const masterEdge = edge.xy()

    // find each parallel line...
    // compute the implicit thickness...
    // determine what projected overlap there is...
    // return the implicit thickness of the greatest overlap sum
    const sums = planEdges.reduce((distances, e) => {
      if(!e.isParallelTo(masterEdge, 1e-1)) { return distances }

      const line = masterEdge.shortestLineFrom(e.begin(), true)
      const thickness = line.length()
      if(thickness > maximumThickness || thickness < minimumThickness) { return distances }

      const overlap = masterEdge.closestPointTo(e.begin()).distanceTo(masterEdge.closestPointTo(e.end()))
      if(overlap > 0) {
        const direction = Math.sign(line.vector().dot(masterEdge.normal()))
        const key = [thickness.roundedTo(3), direction]
        distances[key] = distances[key] || { thickness, overlap: 0, direction }
        distances[key]['overlap'] = distances[key]['overlap'] + overlap
      }
      return distances
    }, {})

    const potentials = Object.values(sums).sort((a, b) => b['overlap'] - a['overlap'] )

    const defaultThickness = this._defaultThickness().toInches()
    const direction = this.previouslyReversed() ? 1 : -1

    if(potentials.length === 0) {
      return {
        direction,
        thickness: defaultThickness
      }
    }

    const match = potentials.find(potential => (
      potential.thickness.isNearTo(defaultThickness, 10e-3) && ((potential.overlap / masterLength) > 0.2)
    )) || potentials.first()

    return {
      direction: match.direction,
      thickness: match.thickness
    }
  }
}
module.exports = WallSegmentTool
