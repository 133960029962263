const React = require('react')
const PropTypes = require('prop-types')
const classNames = require('classnames')

class BooleanPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'boolean'

  constructor(props) {
    super(props)

    this.state = {
      isSelected: props.property.get()
    }
  }

  _toggle() {
    this.props.property.toggle()
    this.props.drawingController.snapshotProjectAndRedraw()
    this.setState({ isSelected: this.props.property.get() })
  }

  _renderCheckbox() {
    const property = this.props.property
    const classes = classNames('margin-right-sm', { 'disabled': property.disabled?.() })

    return (
      <input
        id={property.key()}
        name={property.key()}
        className={classes}
        type="checkbox"
        disabled={property.disabled?.()}
        checked={!!this.state.isSelected}
        onChange={this._toggle.bind(this)}
      />
    )
  }

  _renderPropertyValue() {
    return (
      <span>{this.state.isSelected ? 'True' : 'False'}</span>
    )
  }

  render() {
    const property = this.props.property

    const renderFunction = property.editable() ? this._renderCheckbox.bind(this) : this._renderPropertyValue.bind(this)
    return (
      <>
        <label htmlFor={property.key()} className="label--side-panel margin-top-sm">
          {property.label()}:
        </label>
        {renderFunction()}
      </>
    )
  }
}

module.exports = BooleanPropertyView
