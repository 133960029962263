const Doorway = require('../Doorway')
const IfcOpening = require('./IfcOpening')

class IfcDoorway extends IfcOpening {
  ifcDoor() { return this._door }

  convertToOpening() {
    const doorway = new Doorway(this._findEdge())
    if (this.height()) doorway.setHeight(this.height().mm())

    if (this.ifcDoor()) {
      const { type, handedness, swingDirection } = this.ifcDoor().getDoorProperties()
      doorway.setDoorType(type, handedness, swingDirection)
    }

    return doorway
  }
}

module.exports = IfcDoorway
