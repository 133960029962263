const ShellBuilderConstructionComponent = require('../ShellBuilderConstructionComponent')
const { three: THREE } = require('construction-designer-core/drawing-editor-3D')
const { Point: { $P } } = require('construction-designer-core/geometry')
const { convertPointTo } = require('shared/helpers/unitConverters')

class IfcConstructionComponent extends ShellBuilderConstructionComponent {
  referenceAxes() { return this._referenceAxes }
  translation() { return this._translation }
  vertexData() { return this._vertexData }

  /**
   * @return {String}
   */
  baseUnit() { return this._baseUnit || 'mm' }
  setBaseUnit(newUnit) { this._baseUnit = newUnit }

  // Used by children to determine their positioning
  referencePoint() {
    const parentReferencePoint = this.componentOf()?.referencePoint?.()
    const translation = this.translation()
    return parentReferencePoint ? parentReferencePoint.add(translation) : translation
  }

  referenceRotation() {
    const parentRotation = this.componentOf()?.referenceRotation?.()

    if (parentRotation) {
      return new THREE.Quaternion().premultiply(parentRotation).premultiply(this.rotation())
    }
    return this.rotation()
  }

  vertices() {
    if (!this._vertices) { this._vertices = this._defaultVertices() }
    return this._vertices
  }
  _defaultVertices() {
    const rawVertices = this.vertexData().vertices
    const vertexTranslation = this.vertexData().translation
    const vertexReferenceAxes = this.vertexData().referenceAxes

    const vertexRotation = this._generateRotation(vertexReferenceAxes)
    const rotatedVertices = this._rotateVertices(rawVertices, vertexRotation)

    if (!vertexTranslation) return rotatedVertices
    return rotatedVertices.map(vertex => vertex.add(vertexTranslation))
  }

  rotation() {
    if (!this._rotation) {
      this._rotation = this._generateRotation(this.referenceAxes())
    }
    return this._rotation
  }
  resetRotation() { this._rotation = undefined }

  _translateToLCADSpace(vertices) {
    // This rotates the vertex 180 degrees around the x-axis, which effectively
    // negates the y and z components of the vertex (remember, -z is up and -y is forward in LCAD)
    const reverseYRotation = new THREE.Quaternion().setFromAxisAngle(
      new THREE.Vector3(1, 0, 0), Math.PI
    )

    const rotatedVertices = this._rotateVertices(vertices, reverseYRotation)
    return rotatedVertices.map(vertex => (
      convertPointTo($P(vertex.x(), vertex.y(), vertex.z()), this.baseUnit(), 'inch')
    ))
  }

  _generateRotation(referenceAxes) {
    // Identity quaternion, no actual rotation
    let rotation = new THREE.Quaternion(0, 0, 0, 1)

    if (referenceAxes) {
      const referenceXAxis = referenceAxes.x
      const referenceZAxis = referenceAxes.z
      const referenceYAxis = referenceZAxis.cross(referenceXAxis)

      const rotationMatrix = new THREE.Matrix4().makeBasis(
        referenceXAxis.toThreeJS(),
        referenceYAxis.toThreeJS(),
        referenceZAxis.toThreeJS(),
      )

      rotation = new THREE.Quaternion().setFromRotationMatrix(rotationMatrix)
    }

    return rotation
  }

  /**
   * @param {Point[]} vertices
   * @param {THREE.Quaternion} rotation
   */
  _rotateVertices(vertices, rotation) {
    return vertices.map(vertex => {
      const threeVertex = vertex.toThreeJS()
      threeVertex.applyQuaternion(rotation)

      return threeVertex.toPoint()
    })
  }

  /**
   * Finds the farthest extent of the vertices along the given axis
   *
   * @param {Locator[]} vertices
   * @param {Locator} axis
   * @return {number}
   */
  _findExtent(vertices, axis) {
    if (vertices.length === 0) return

    const offsets = vertices.map(vertex => axis.dot(vertex))
    return Math.max(...offsets) - Math.min(...offsets)
  }
}

module.exports = IfcConstructionComponent
