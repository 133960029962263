const { ConstructionComponentFigure } = require('construction-designer-core/construction')

class RoofPanelFigure extends ConstructionComponentFigure {
  _draw(context, _options) {
    context._drawPolygon(this.model().shapeXY())
  }

  contains(x, y) {
    return this.model().contains(x, y)
  }

  layer() { return 'Roof Panels' }

  defaultProperties() {
    return {
      ...super.defaultProperties(),
      fillStyle: "rgba(0, 0, 0, 0.5)"
    }
  }
}

module.exports = RoofPanelFigure
