const WallSegmentTool = require('./WallSegmentTool')
const {
  Point: { $P },
  Polygon
} = require('construction-designer-core/geometry')
const RoofPanel = require('shared/domain-models/RoofPanel')
const Roof = require('shared/domain-models/Roof')

class RoofTool extends WallSegmentTool {
  displayName() { return 'Roofs' }
  iconName() { return 'roofs' }
  roof() { return this.buildingLevel().roof() }

  activate() {
    this._pendingVertices = []
    super.activate()
  }

  reset() {
    this._pendingVertices = []
    this._pendingEdge = undefined
    this._previewEdge = undefined
    this._hoveredEdge = undefined
    super.reset()
  }

  pendingVertices() { return this._pendingVertices }
  pendingEdge() { return this._pendingEdge }

  undoLastEdge() {
    if (this.pendingVertices().length > 1) {
      const removedPoint = this.pendingVertices().pop()
      this._pendingEdge = this.pendingVertices().last().to(removedPoint)
      this._controller().redraw()
    }
  }

  mouseUp(x, y, options) {
    // TODO: Consider creating some sort of "Polygon Drawing" tool, and subclass
    // that instead of WallSegmentTool

    // This is the default behavior of #mouseUp in CompositePanZoomTool. We're
    // doing it here because calling super would call #mouseUp on
    // WallSegmentTool, which has behavior we don't want
    this._didPan = this.panTool().mouseUp(x, y, options)
    this._didZoom = this.zoomTool().mouseUp(x, y, options)

    if (!this.didPan()) {
      if (this.pendingVertices().length === 0) {
        const point = this._snapRelativePointTo($P(x, y), this.buildingLevel().snapPoints(), 12)
        if (point) this._addWallPoint(point.snapshot())
        return
      }

      if (!this._hoveredEdge) return
      this._controller().setSelectedComponent(undefined)

      if (this._pendingEdge) {
        const intersection = this._hoveredEdge.intersectionsWithLineIn2D(this._pendingEdge).first()
        if (intersection) this._addWallPoint(intersection.snapshot())
      }

      this._pendingEdge = this._hoveredEdge
      this._controller().redraw()
    }
  }

  _addWallPoint(rawPoint) {
    const point = this._conformToExistingEndPoints(rawPoint)
    if (!point) return
    this.pendingVertices().push(point)

    if (this._isNowClosed()) this._finalizeLoop()
  }

  _isNowClosed() {
    if (this.pendingVertices().length < 2) return false
    return this.pendingVertices().first().equals(this.pendingVertices().last())
  }

  mouseMove(x, y, options) {
    super.mouseMove(x, y, options)

    let hoverableEdges = []
    if (this._pendingEdge) {
      hoverableEdges = this._nonParallelEdges(this._pendingEdge)
    } else if (this.pendingVertices().length === 1) {
      const pendingPoint = this.pendingVertices().first()
      // Make the hoverable edges be the edges connected the pending point
      hoverableEdges = this._findNearbyEdges(pendingPoint, this.buildingLevel().floorPlan().edges(), 1e-3)
    }

    this._hoveredEdge = this._findNearestEdge($P(x, y), hoverableEdges)
    this._previewEdge = undefined

    if (this._hoveredEdge && this._pendingEdge) {
      const intersection = this._pendingEdge.intersectionsWithLineIn2D(this._hoveredEdge).first()
      this._previewEdge = this.pendingVertices().last().to(intersection)
    }

    this._controller().redraw()
  }

  _finalizeLoop() {
    if (!this.roof()) this.buildingLevel().setRoof(new Roof())

    const vertices = this.pendingVertices()
    // The first and last point of the vertices are the same, so we remove the last one
    vertices.pop()
    const shape = new Polygon(vertices)
    if (shape.isCounterClockwise()) shape.reverseVertices()

    const roofPanel = new RoofPanel(shape)
    this.roof().addRoofPanel(roofPanel)

    this._controller().setSelectedComponent(roofPanel)
    this._controller().snapshotProject()
    this.reset()
  }

  _nonParallelEdges(edge) {
    const planEdges = this.buildingLevel().floorPlan().edges()
    if (!edge) return planEdges
    return planEdges.filter(planEdge => !planEdge.isParallelTo(edge, 1e-4))
  }

  _findNearbyEdges(point, edges, tolerance = 12) {
    const targetBox = point.expandedBy(tolerance, tolerance)
    return edges.filter(edge => edge.boundingBox().intersects(targetBox))
  }

  _findNearestEdge(point, edges, tolerance = 12) {
    if (edges.length === 0) return undefined
    const nearbyEdges = this._findNearbyEdges(point, edges, tolerance)

    return nearbyEdges.reduce((closest, edge) => {
      const closestPoint = edge.closestPointTo(point)
      const distance = point.distanceTo(closestPoint)

      if (distance < closest.distance && distance <= tolerance) {
        return { distance, edge }
      }
      return closest
    }, { distance: Infinity, edge: undefined }).edge
  }

  draw(context, _options) {
    context._alterAndRestoreAfter(() => {
      if (this.pendingVertices().length > 0) {
        const vertices = this.pendingVertices()
        context.beginPath()
        context.moveTo(vertices.first().x(), vertices.first().y())
        context._addPolygonToCurrentPath(new Polygon(vertices))
        context.stroke()
      }

      context.strokeStyle = '#F5EA25'
      context.lineWidth = 2
      if (this._hoveredEdge) context._drawLine(this._hoveredEdge.begin(), this._hoveredEdge.end())
      if (this._previewEdge) context._drawDashedLine(this._previewEdge.begin(), this._previewEdge.end())
    })
  }
}

module.exports = RoofTool
