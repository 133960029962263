const DoorFigure3D = require('./DoorFigure3D')
const { three: THREE } = require("construction-designer-core/drawing-editor-3D")
class GarageDoorFigure3D extends DoorFigure3D {
  materialNames() { return ['garage-door', 'garage-door-back'] }

  /*
    We had to override this method so that we could:
    - start the texture at the begin of the shape
    - set the horizontal scale to be based on the width of the model
  */
  _generateUVCoordinates(geometry, face, mainAxis, normalAxis) {
    const crossAxis = new THREE.Vector3().crossVectors(mainAxis, normalAxis).normalize()
    const panelWidth = 30 // arbitrary
    const panelsOnImage = 3
    // width / panel width = number of panels needed
    //  $_ / Panels on image = number of images to use
    // $_ / width = scale
    // we divide by width so that when we multiply the scale by the width
    // to get the furthest point out it
    // scale * 1 or w/w             scale * 0
    //   width                         0
    //     *---------------------------*
    const horizontalScale = (Math.ceil(this.model().width() / panelWidth) / panelsOnImage) / this.model().width()
    // finding the points on the bottom of the geometry
    const highestZ = Math.max(...this.model().shape3D().vertices().map(v => v.z()))
    const bottomVertices = this.model().shape3D().vertices().filter(v => v.z() === highestZ)

    // These offsets shift the texture from  (0, 0) to the objects coordinates
    const crossAxisOffset = Math.min(...bottomVertices.map(v => crossAxis.dot(v.toThreeJS())))
    const mainAxisOffset = Math.min(...bottomVertices.map(v => mainAxis.dot(v.toThreeJS())))

    const uvCoordinates = [
      geometry.vertices[face.a],
      geometry.vertices[face.b],
      geometry.vertices[face.c]
    ].map(vector3 => new THREE.Vector2((vector3.dot(mainAxis) - mainAxisOffset) * horizontalScale, vector3.dot(crossAxis) - crossAxisOffset))

    geometry.faceVertexUvs.first().push(uvCoordinates)
  }

  /*
    We had to overrde this method to put the garage door back texture on the back faces
  */
  threeGeometry() {
    const geometry = new THREE.Geometry()

    const shape3D = this.shape3D()

    shape3D.polygonFaces().forEach(polygonFace => {
      this._triangulateAndAddFace(polygonFace, geometry, { materialIndex: 1 })
    })

    const mainAxis = this.model().primaryDirection().toThreeJS()

    // had to override to change the texture on the back side of the geometry
    const faces = shape3D.fillerFaces()
    const face = faces.first()
    faces.remove(face)
    this._triangulateAndAddFace(face, geometry, { materialIndex: 0, mainAxis})

    faces.forEach(polygonFace => {
      this._triangulateAndAddFace(polygonFace, geometry, { materialIndex: 1 })
    })

    const bufferGeometry = new THREE.BufferGeometry().fromGeometry(geometry)
    // Compute normals so that lighting works
    bufferGeometry.computeFaceNormals()
    bufferGeometry.computeVertexNormals()
    bufferGeometry.computeBoundsTree()

    return bufferGeometry
  }
}

module.exports = GarageDoorFigure3D
