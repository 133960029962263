const React = require('react')
const PropTypes = require('prop-types')

class NumberPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'number'

  constructor(props) {
    super(props)
    this.state = {
      propertyValue: props.property.get()
    }
  }

  _updateProperty(value) {
    const newValue = parseFloat(value)

    if (isNaN(newValue)) {
      this.setState({ propertyValue: '' })
    } else {
      this.props.property.set(newValue)
      this.setState({ propertyValue: newValue })
      this._isSaved = false
    }

    this.props.drawingController.redraw()
  }

  _saveProperty(value) {
    this._updateProperty(parseFloat(value))
    this.props.drawingController.snapshotProjectAndRedraw()
    this._isSaved = true

    this.setState({ propertyValue: this.props.property.get() })
  }

  componentWillUnmount() {
    if (!this._isSaved) this.props.drawingController.snapshotProjectAndRedraw()
  }

  _renderNumberInput() {
    const property = this.props.property

    return (
      <input
        className="input--side-panel"
        id={property.key()}
        type="number"
        value={this.state.propertyValue}
        onChange={event => this._updateProperty(event.target.value)}
        onBlur={event => this._saveProperty(event.target.value)}
      />
    )
  }

  _renderPropertyValue() {
    return <span className="font-sm">{this.props.property.get()}</span>
  }

  render() {
    const property = this.props.property
    const renderProperty = property.editable() ? this._renderNumberInput.bind(this) : this._renderPropertyValue.bind(this)

    return (
      <div className="side-panel__row">
        <div className="full-width flex justify-between items-center">
          <label className="label--side-panel margin-right-xs" htmlFor={property.key()}>
            {property.label()}:
          </label>
          {renderProperty()}
        </div>
      </div>
    )
  }
}

module.exports = NumberPropertyView
