const { Point: { $P } } = require('construction-designer-core/geometry')

const Window = require('shared/domain-models/Window')
const OpeningTool = require('./OpeningTool')

class PlaceWindowTool extends OpeningTool {
  mouseUp(x, y, options) {
    this.panZoomTool().mouseUp(x, y, options)

    if (!this.didPan()) {
      const wall = this.buildingLevel().wallSegments().find(part => part.contains(x, y))
      if (!wall) {
        // If you didn't click on a wall, and you weren't panning, then deselect
        // whatever's selected
        this.select(undefined)
        return false
      }

      this._findOpening(wall, $P(x, y))
      this._controller().redraw()
    }
  }

  width() {
    return Window.defaultWidth()
  }

  _findOpening(wallSegment, pointer) {
    const wallEdge = wallSegment.exteriorEdge()
    const pointOnWallSegment = wallEdge.closestRelativePointTo(pointer)

    const vector = wallEdge.vector().normalized()

    const width = this.width().toInches()
    const windowBegin = pointOnWallSegment.subtract(vector.multipliedBy(width/2))
    const windowEnd = pointOnWallSegment.add(vector.multipliedBy(width/2))

    const edge = wallSegment.conformToZLevel(windowBegin).to(
      wallSegment.conformToZLevel(windowEnd)
    )
    const opening = this._addOpening(wallSegment, edge)
    this._controller().setSelectedComponent(opening)
    this._controller().snapshotProject()
  }

  _addOpening(segment, edge) {
    const newWindow = new Window(edge)
    segment.addWindow(newWindow)
    return newWindow
  }
}
module.exports = PlaceWindowTool
