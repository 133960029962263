const React = require('react')
const PropTypes = require('prop-types')

const {
  Icon,
} = require('construction-designer-core/drawing-editor-react')

class TraySelect extends React.Component {
  static propTypes = {
    getProperty: PropTypes.func.isRequired,
    setProperty: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    wrapperClassName: PropTypes.string,
    withDivider: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedValue: props.getProperty().toString()
    }
  }

  _setProperty(value) {
    this.props.setProperty(value)
    this.setState({ selectedValue: this.props.getProperty().toString() })
  }

  /**
   * @param {Object} option An option object with "value" and "label" keys
   * @param {Boolean} selected Whether this option is selected or not
   */
  _renderOption(option, selected) {
    return (
      <label key={option.value} className={selected ? 'selected' : ''} title={option.label}>
        <input
          type="radio"
          value={option.value}
          className="hidden"
          selected={selected}
          onClick={() => this._setProperty(option.value)}
        />
        <Icon name={option.icon} />
      </label>
    )
  }

  render() {
    const selectedValue = this.state.selectedValue
    const currentOption = this.props.options.find(option => selectedValue === option.value.toString() )

    return (
      <>
        <fieldset className={`tray-select ${this.props.wrapperClassName}`}>
          <legend className="tray-select__legend">Selected: <b>{currentOption?.label}</b></legend>
          <div className="flex flex-wrap">
            {this.props.options.map(option => this._renderOption(option, currentOption === option))}
          </div>
        </fieldset>

        {this.props.withDivider ? <hr className="divider" /> : ''}
      </>
    )
  }
}
module.exports = TraySelect
