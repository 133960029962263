const PropTypes = require('prop-types')
const React = require('react')

class FormFromServer extends React.Component {
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    renderExtraElements: PropTypes.func,
    renderHeader: PropTypes.func,
    onLoadFromServer: PropTypes.func,
    renderFlash: PropTypes.func,
    onFailure: PropTypes.func
  }

  static defaultProps = {
    renderExtraElements: () => { },
    renderHeader: () => { },
    onLoadFromServer: () => { },
    renderFlash: () => { },
    onFailure: () => { }
  }

  constructor(props) {
    super(props)

    this.formRef = React.createRef()
  }

  componentDidMount() {
    this.getFormHtml()
  }

  async getFormHtml() {
    const response = await fetch(this.props.url, {
      method: 'GET',
      credentials: 'same-origin',
    })
    await this.parseForm(response)
  }

  async parseHtml(response) {
    const text = await response.text()
    return new DOMParser().parseFromString(text, 'text/html')
  }

  async parseForm(response) {
    const doc = await this.parseHtml(response)
    const form = doc.querySelector('form')
    form.addEventListener('submit', this.onSubmit.bind(this))

    await this.props.onLoadFromServer(form)
    if (this.formRef.current.firstChild) this.formRef.current.removeChild(this.formRef.current.firstChild)
    this.formRef.current.appendChild(form)
    return form
  }

  async onSubmit(event) {
    event.preventDefault()
    const formData = new FormData(event.target)

    const response = await fetch(event.target.action, {
      method: 'POST',
      credentials: 'same-origin',
      body: formData
    })

    const successful = response.redirected
    if (successful) {
      const doc = await this.parseHtml(response)
      this.props.onSuccess(doc, response)
    } else {
      const form = await this.parseForm(response)
      this.props.onFailure(form)
    }
  }

  renderForm() {
    return <div ref={this.formRef} />
  }

  render() {
    return (
      <>
        {this.props.renderFlash()}
        <div className="flex flex-col items-start">
          {this.props.renderHeader()}
          <div className="full-width">
            {this.renderForm()}
            {this.props.renderExtraElements()}
          </div>
        </div>
      </>
    )
  }
}

module.exports = FormFromServer
