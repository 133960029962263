const React = require('react')
const PropTypes = require('prop-types')
const ProjectStatistics = require('./ProjectStatistics')
const ProjectCost = require('./ProjectCost')
const PropertyView = require('./property-panels/PropertyView')
const CollapsablePanelSection = require('./CollapsablePanelSection')
const CompositeEditableProperty = require('shared/domain-models/CompositeEditableProperty')

class PropertyPanel extends React.Component {
  /**
  * @prop {Object} drawingController - The drawing controller
  * @prop {Map} propertyPanelLookup - A lookup with a model's class as the key,
  *   and a property panel class as the value. Used for setting a specific
  *   property panel for a model, instead of dynamically generating it
  * @prop {PropertyView[]} propertyViews - An array of custom property views to
  *   be used, instead of the defaults
  */
  static propTypes = {
    drawingController: PropTypes.object.isRequired,
    propertyPanelLookup: PropTypes.instanceOf(Map),
    propertyViews: PropTypes.array
  }

  static defaultProps = {
    propertyPanelLookup: new Map(),
    propertyViews: []
  }

  constructor(props) {
    super(props)

    this.state = {
      selection: props.drawingController.selection(),
      project: props.drawingController.project()
    }
  }

  static getDerivedStateFromProps(props, _state) {
    return {
      selection: props.drawingController.selection(),
      project: props.drawingController.project()
    }
  }

  componentDidMount() { this._startObservingSelection() }
  componentWillUnmount() { this._stopObservingSelection() }

  componentDidUpdate(prevProps) {
    if (prevProps.drawingController !== this.props.drawingController) {
      this._stopObservingSelection(prevProps.drawingController)
      this._startObservingSelection()
    }
  }

  _startObservingSelection() {
    this.props.drawingController.addSelectionChangedObserver(this, this._updateSelection)
  }

  _stopObservingSelection(drawingController = this.props.drawingController) {
    drawingController.removeSelectionChangedObserver(this, this._updateSelection)
  }

  _startObservingModels(models) {
    models.forEach(model => (
      model.addDisplayPropertiesChangedObserver(this, this._availablePropertiesChanged)
    ))
  }

  _stopObservingModels(models) {
    models.forEach(model => (
      model.removeDisplayPropertiesChangedObserver(this, this._availablePropertiesChanged)
    ))
  }

  _availablePropertiesChanged() { this.forceUpdate() }

  _getComponents(selection) {
    let components = [selection?.component?.()].filter(item => item)
    if (components.length === 0) {
      components = selection?.components?.()
    }

    return components ?? []
  }

  _updateSelection() {
    const previousSelection = this.state.selection
    const previouslySelectedComponents = this._getComponents(previousSelection)
    this._stopObservingModels(previouslySelectedComponents)

    const selection = this.props.drawingController.selection()
    const selectedComponents = this._getComponents(selection)
    this._startObservingModels(selectedComponents)

    this.setState({ selection: selection })
  }

  _delete() {
    this.props.drawingController.deleteSelection()
  }

  _renderDeleteButton(models) {
    // TODO: Consider adding a disabled button if not all models can be deleted
    if (models.every(model => model.delete)) {
      return (
        <button
          type="button"
          onClick={this._delete.bind(this)}
          className="btn btn--danger--outline full-width"
        >
          Delete
        </button>
      )
    }
  }

  _generateCompositeProperties(models) {
    const allProperties = models.flatMap(model => model?.displayProperties?.() || [])
    const editableProperties = allProperties.filter(property => property.editable())
    const usedProperties = []
    const compositeProperties = []

    editableProperties.forEach(property => {
      const propertyExists = usedProperties.includes(property)

      // If the current property has already been used, then we shouldn't continue
      if (propertyExists) return

      const overlappingProperties = editableProperties.filter(otherProperty => (
        otherProperty.equals(property)
      ))

      usedProperties.fastMerge(overlappingProperties)
      // If the number of overlapping properties is the same as my number of
      // models, than each model must have had that property
      if (overlappingProperties.length === models.length) {
        compositeProperties.push(
          new CompositeEditableProperty(property.name(), property.type(), overlappingProperties)
        )
      }
    })

    return compositeProperties
  }

  _renderDisplayProperties(models) {
    let properties = models.first().displayProperties()

    if (models.length > 1) { properties = this._generateCompositeProperties(models) }

    if (properties.length > 0) {
      return properties.map(property => (
        <PropertyView
          key={property.key()}
          property={property}
          drawingController={this.props.drawingController}
          propertyViews={this.props.propertyViews}
        />
      ))
    }
  }

  _selectionName(model) {
    let name
    if (model.name?.()) {
      name = model.name()
    } else if (model.displayName()) {
      name = model.displayName()
    } else {
      name = model.constructor.name
    }

    return name.replace(/([a-z])([A-Z])/g, '$1 $2')
  }

  _openByDefault(model) {
    const modelsOpenByDefault = ['Floor Plan']
    return modelsOpenByDefault.includes(this._selectionName(model))
  }

  _renderPanelSection(selectionName, components, title, isOpen) {
    const headerTitle = title ?? (
      <span className="selection-title">
        Properties: <span className="selection-name">{selectionName}</span>
      </span>
    )

    let panelContent
    if (components.length === 1) {
      const component = components.first()
      const SpecializedPropertyPanel = this.props.propertyPanelLookup.get(component.constructor)

      if (SpecializedPropertyPanel) {
        panelContent = <SpecializedPropertyPanel
          drawingController={this.props.drawingController}
          model={component}
        />
      }
    }

    if (!panelContent) { panelContent = this._renderDisplayProperties(components) }
    const deletable = components.some(component => component.delete)

    return (
      <CollapsablePanelSection
        headerTitle={headerTitle}
        key={selectionName}
        collapsed={!isOpen}
      >
        {deletable ? this._renderDeleteButton(components) : ''}
        {deletable && panelContent ? <hr className="divider" />: ''}
        {panelContent}
      </CollapsablePanelSection>
    )
  }

  _renderStaticProperties() {
    const controller = this.props.drawingController
    const buildingLevel = controller.buildingLevel?.()
    const floorPlan = buildingLevel?.floorPlan?.()
    const staticModels = [floorPlan, buildingLevel, this.state.project].filter(item => item && !item.selectable?.())

    return (
      <>
        {staticModels.map(model => this._renderPanelSection(
          this._selectionName(model), [model], undefined, this._openByDefault(model)
        ))}
      </>
    )
  }

  _renderSelection() {
    const selection = this.state.selection
    const components = this._getComponents(selection)
    if (components.length === 0) return

    let selectionName = this._selectionName(components.first())
    let headerTitle

    if (components.length > 1) {
      selectionName = 'Multi Selection'
      headerTitle = <span className="selection-name">{selectionName}</span>
    }

    const isOpen = true
    return this._renderPanelSection(selectionName, components, headerTitle, isOpen)
  }

  render() {
    return (
      <div className="overflow-scroll">
        {this._renderSelection()}
        {this._renderStaticProperties()}
        <ProjectStatistics project={this.state.project} />
        <ProjectCost project={this.state.project} drawingController={this.props.drawingController} />
      </div>
    )
  }
}

module.exports = PropertyPanel
