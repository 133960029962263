const BuildingContext = require('shared/domain-models/BuildingContext')
const WallSurface = require('shared/domain-models/WallSurface')
const RoomHelper = require('shared/helpers/RoomHelper')
const makeSurfaceEdge = require('shared/helpers/makeSurfaceEdge')

class SurfaceBuilder {
  constructor(segment, intersectingSegments, buildingLevel, tolerance = 1e-2) {
    this._segment = segment
    this._intersectingSegments = intersectingSegments
    this._buildingLevel = buildingLevel
    this._tolerance = tolerance
  }

  buildingLevel() { return this._buildingLevel }
  segment() { return this._segment }
  intersectingSegments() { return this._intersectingSegments }
  tolerance() { return this._tolerance }

  createSurfaces() {
    const segment = this.segment()
    const centerline = segment.centerline()
    const wallThickness = segment.thickness().toInches()

    const leftEdge = centerline.shiftedAlongNormalBy(-wallThickness / 2).reversed().snapshot()
    const rightEdge = centerline.shiftedAlongNormalBy(wallThickness / 2).snapshot()

    const DynamicWallSurfaceClass = segment.wallSurfaceClass()
    const leftSurface = this._createSurface(leftEdge, WallSurface)
    const rightSurface = this._createSurface(rightEdge, DynamicWallSurfaceClass)

    const roomHelper = new RoomHelper(this.buildingLevel(), this.tolerance())
    const room = roomHelper.findOrCreateRoom(leftSurface.edge(), rightSurface.edge())

    const buildingContext = new BuildingContext(this.buildingLevel(), room);

    [leftSurface, rightSurface].forEach(surface => {
      segment.addSurface(surface)
      surface.addToContext(buildingContext)
    })

    roomHelper.redistributeSpace(room)
  }

  /**
   * @param {Edge} baseSurfaceEdge - the initial guess at a surface edge for the new segment
   * @param {WallSurface|ExteriorWallSurface} WallSurfaceClass
   * @returns {WallSurface} The created wall surface
   */
  _createSurface(baseSurfaceEdge, WallSurfaceClass) {
    const thickness = this.segment().thickness().toInches()
    const intersectingSegments = this.intersectingSegments()

    const centerlineEdge = baseSurfaceEdge.shiftedAlongNormalBy(-thickness / 2)
    const allCenterlines = [...intersectingSegments.map(segment => segment.centerline()), centerlineEdge]
    const buildingLevel = this.buildingLevel()
    const tolerance = this.tolerance()
    const significantDigits = Math.abs(Math.log10(tolerance))
    const existingSurfaces = [...new Set(intersectingSegments.flatMap(segment => segment.surfaces()))]
    let newSurfaceEdge = baseSurfaceEdge.roundedTo(significantDigits)

    // This is the collection of existing surfaces that should be merged/extended
    const mergedSurfaces = []

    existingSurfaces.forEach(surface => {
      const existingEdge = surface.edge().roundedTo(significantDigits)

      // If the two edges overlap, then extend the the existing edge to
      // cover the new surface edge.
      if (existingEdge.overlaps(newSurfaceEdge, tolerance)) {
        newSurfaceEdge = existingEdge.merge(newSurfaceEdge)
        if (!newSurfaceEdge.normal().equals(existingEdge.normal(), tolerance)) newSurfaceEdge = newSurfaceEdge.reversed()
        mergedSurfaces.push(surface)
        return
      }

      const intersection = newSurfaceEdge.intersectionsWithLineIn2D(existingEdge).first()
      if (!intersection) return

      const possibleNewEdge = newSurfaceEdge.withNearestEndpointReplaced(intersection)
      // If the intersection resulted in an edge that's too long, then
      // short-circuit the algorithm
      if (possibleNewEdge.length() > newSurfaceEdge.length() * 1.5) return

      const adjustedEdge = surface.edge().withNearestEndpointReplaced(intersection)

      const edgeIntersectsCenterlines = allCenterlines.some(centerline => {
        const atEndpoints = (intersection, edge) => (
          edge.begin().equals(intersection, tolerance) || edge.end().equals(intersection, tolerance)
        )

        const newEdgeIntersection = possibleNewEdge.intersectionsWithEdgeIn2D(centerline).first()
        // We only want to "count" this as an intersection if it isn't at the edge's endpoint
        if (newEdgeIntersection && !atEndpoints(newEdgeIntersection, possibleNewEdge)) return true

        const adjustedEdgeIntersection = adjustedEdge.intersectionsWithEdgeIn2D(centerline).first()
        return adjustedEdgeIntersection && !atEndpoints(adjustedEdgeIntersection, adjustedEdge)
      })

      // The general theory of this method is that there's only one possible
      // combination of connected surfaces that don't intersect any segment
      // centerlines. Basically, think about a given segment's centerline as a
      // fence, and a given surface can't run through that fence.

      // Therefore, if an edge intersects _any_ centerline, then this can't be
      // the combination of surfaces we want
      if (edgeIntersectsCenterlines) return

      surface.setEdge(makeSurfaceEdge(adjustedEdge, buildingLevel))
      newSurfaceEdge = possibleNewEdge
    })

    if (mergedSurfaces.length > 0) {
      const keptSurface = mergedSurfaces.shift()
      keptSurface.setEdge(makeSurfaceEdge(newSurfaceEdge, buildingLevel))

      mergedSurfaces.forEach(mergedSurface => {
        const segments = mergedSurface.attachedSegments()
        segments.forEach(segment => keptSurface.addAttachedSegment(segment))
        mergedSurface.delete()
      })

      return keptSurface
    } else {
      return new WallSurfaceClass(makeSurfaceEdge(newSurfaceEdge, buildingLevel))
    }
  }
}

module.exports = SurfaceBuilder
