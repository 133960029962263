class SystemStrategy {
  /**
   * @returns {Tool[]}
   */
  defaultTools() { return [] }

  /**
   * Returns a JSON store of various configuration options
   *
   * @return {Object}
   */
  configOptions() {
    return {}
  }

  /**
   * Whether or not this strategy should be able to have quotes requested
   * @returns {Boolean}
   */
  supportsQuotes() { }

  /**
   * @param {BuildingLevel} buildingLevel
   * @returns {Handle}
   */
  segmentSelfHandle(_buildingLevel) {}

  /**
   * Draw a window, given the CanvasRenderingContext and a Window
   *
   * @param {CanvasRenderingContext2D} context
   * @param {Window} window
   */
  drawWindow(_context, _window) {}
}

module.exports = SystemStrategy
