const {
  CompositeFigure
} = require('construction-designer-core/drawing-editor')

class CompositeFigureXZ extends CompositeFigure {
  _getAnnotationsFromModel() {
    if (this.model() && this.model().annotationsXZ) {
      return this.model().annotationsXZ()
    }

    return []
  }

  _componentRenderer(component) {
    return component.defaultFigureXZ()
  }

  _componentSorter(a, b) {
    return this._yLevelComparator(a, b)
  }

  _yLevelComparator(a, b) {
    return b.yLevel() - a.yLevel()
  }
}
module.exports = CompositeFigureXZ
