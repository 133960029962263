const {
  NormalToEdgeLocator,
  Edge,
  RelativeLocator,
  EdgeIntersectionLocator,
  FixedEdgeLocator,
  Polygon,
  Point: { $P },
  General: {
    locatorX,
    locatorY,
    locatorZ
  }
} = require('construction-designer-core/geometry')

const {
  NullDrawable
} = require('construction-designer-core/drawing-editor')

const {
  three: THREE,
  ThreeGeometryFigure
} = require('construction-designer-core/drawing-editor-3D')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')


class Stairs extends ShellBuilderConstructionComponent {
  constructor(edge, totalRise = (9 * 12)) {
    super()
    this._edge = edge
    this._totalRise = totalRise
  }

  edge() { return this._edge }
  totalRise() { return this._totalRise }
  totalRun() { return this.numberOfSteps()*this.run() }
  run() { return 10 }
  rise() { return 7 }

  locator() {
    if (!this._locator) {
      this._locator = new FixedEdgeLocator(this.edge(), 0)
    }
    return this._locator
  }

  numberOfSteps() {
    if (!this._numberOfSteps) {
      this._numberOfSteps = Math.ceil((this.totalRise() - 1) / this.rise()) - 1
    }
    return this._numberOfSteps
  }

  defaultFigure() {
    return new NullDrawable(this)
  }

  defaultFigureXZ() {
    return new NullDrawable(this)
  }

  threeFigure() {
    return new ThreeGeometryFigure(this)
  }

  sideVertices() {
    if (!this._sideVertices) {
      const locator = this.locator()
      const numberOfSteps = this.numberOfSteps()
      const run = this.run()
      const rise = this.rise()
      const vertices = new Array(numberOfSteps*2) // just get the steps in, we'll grow/shrink from there

      for (let i=0, j=0; i < numberOfSteps; i++, j+=2) {
        vertices[j] = new NormalToEdgeLocator(locator, i*run, 0, this._angle).addZ((i+1)*rise)
        vertices[j+1] = new NormalToEdgeLocator(locator, (i+1)*run, 0, this._angle).addZ((i+1)*rise)
      }

      const topOfStairsEnd = vertices[vertices.length-1]
      const totalRise = this.totalRise()
      const topOfStairsBegin = locator
      const topEdge = new Edge(topOfStairsBegin,topOfStairsEnd)
      const phi = topEdge.phi() - Math.PI_2
      const theta = topEdge.theta()
      const depth = 12
      const delta = $P(locatorX(depth, theta, phi), locatorY(depth, theta, phi), locatorZ(depth, phi))
      const edge = new Edge(new RelativeLocator(topOfStairsEnd, delta.x(), delta.y(), delta.z()), new RelativeLocator(topOfStairsBegin, delta.x(), delta.y(), delta.z()))
      const topAtGrade = topOfStairsBegin.addZ(totalRise)
      const frameEdge = new Edge(topOfStairsBegin, topAtGrade)
      const bottomAtGrade = topOfStairsEnd.addZ(this.rise())
      const gradeEdge = new Edge(topAtGrade, bottomAtGrade)

      vertices.push(bottomAtGrade)
      vertices.push(new EdgeIntersectionLocator(gradeEdge,edge))
      vertices.push(new EdgeIntersectionLocator(frameEdge,edge))
      this._sideVertices = vertices
    }
    return this._sideVertices
  }

  surface() {
    const origin = this.edge().begin()
    const vertices = this.sideVertices() // assumes Polygons
    return new Polygon(vertices.map(point => $P(origin.distanceTo(point), point.z() - origin.z())))
  }

  shape3D() {
    const wallShape = new THREE.Shape(this.surface().vertices().map(v => $P(-v.y(), v.x()).toThreeJS()))

    const curve = new THREE.LineCurve3(this.edge().begin().toThreeJS(), this.edge().end().toThreeJS())
    const geometry = new THREE.ExtrudeBufferGeometry(
      wallShape,
      {
        extrudePath: curve,
        bevelEnabled: false
      }
    )
    return geometry
  }
}

module.exports = Stairs
