const {
  ThreeMaterialLibrary,
  three: THREE
} = require("construction-designer-core/drawing-editor-3D")

const loadTexture = (name, loader) => {
  const texture = loader.load(require(`./stone-textures/${name}.jpg`))
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping
  texture.repeat.set(0.01, 0.01)

  return texture
}

ThreeMaterialLibrary.instance().register('stone', textureLoader => {
  return new THREE.MeshStandardMaterial({
    aoMap: loadTexture('ambient_occlusion', textureLoader),
    map: loadTexture('color', textureLoader),
    normalMap: loadTexture('normal', textureLoader),
    roughnessMap: loadTexture('roughness', textureLoader)
  })
})
