const React = require('react')
const PropTypes = require('prop-types')
const { Icon } = require('construction-designer-core/drawing-editor-react')
const classNames = require('classnames')

class CollapsablePanelSection extends React.Component {
  static propTypes = {
    headerTitle: PropTypes.node.isRequired,
    children: PropTypes.node,
    collapsed: PropTypes.bool
  }

  static defaultProps = {
    collapsed: true
  }

  constructor(props) {
    super(props)

    this.state = {
      collapsed: props.collapsed
    }
  }

  toggleCollapse() {
    const currentStatus = this.state.collapsed
    this.setState({ collapsed: !currentStatus })
  }

  _renderHeader() {
    let iconName = 'KeyboardArrowUp'
    if (this.state.collapsed) iconName = 'KeyboardArrowDown'

    return (
      <header onClick={this.toggleCollapse.bind(this)} className={classNames({
        'side-panel__header': true,
        'collapsed': this.state.collapsed
      })}>
        {this.props.headerTitle}
        <Icon name={iconName} />
      </header>
    )
  }

  _renderBody() {
    return (
      <section className={classNames({
        'side-panel__section': true,
        'collapsed': this.state.collapsed
      })}>
        <hr className="divider--header" />
        <div className="margin-sm">
          { this.props.children }
        </div>
      </section>
    )
  }

  render() {
    return (
      <div className={classNames({
        'dropdown--section': true,
        'collapsed': this.state.collapsed
      })}>
        {this._renderHeader()}
        {this._renderBody()}
      </div>
    )
  }
}

module.exports = CollapsablePanelSection
