const { CompositeFigure } = require('construction-designer-core/drawing-editor')
const { ConstructionComponentHandle } = require('construction-designer-core/construction')

class RoomFigure extends CompositeFigure {
  selfHandle() {
    return new ConstructionComponentHandle(this.model())
  }

  contains(x, y) { return this.model().contains(x, y) }
}
module.exports = RoomFigure
