const React = require('react')
const PropTypes = require('prop-types')
const { UnitField } = require('construction-designer-core/drawing-editor-react')

const getKey = property => `unit-key-${property.get().toString().split(' ').join('-').toString()}`

class UnitPropertyView extends React.Component {
  static propTypes = {
    property: PropTypes.object.isRequired,
    drawingController: PropTypes.object.isRequired
  }

  static forType = 'unit'

  constructor(props) {
    super(props)
    this.state = { key: getKey(props.property) }
  }

  _updateProperty(value) {
    this.props.property.set(value)
    this.props.drawingController.redraw()
  }

  _saveProperty(value) {
    this._updateProperty(value)
    this.props.drawingController.snapshotProjectAndRedraw()
    this.setState({ key: getKey(this.props.property) })
  }

  _renderUnitField() {
    const property = this.props.property
    const setOnUpdate = !this.props.property.options().ignoreIntermediateEdits

    return (
      <UnitField
        key={this.state.key}
        name={property.key()}
        label={`${property.label()}:`}
        value={property.get().toString()}
        onSave={this._saveProperty.bind(this)}
        onValidate={(isValid, unit) => property.validate(isValid, unit)}
        defaultUnit={property.options().defaultUnit}
        wrapperClassName="side-panel__row"
        labelClassName="label--side-panel"
        inputClassName="input--side-panel"
        {...(setOnUpdate ? { onUpdate: this._updateProperty.bind(this) } : {} )}
      />
    )
  }

  _renderPropertyValue() {
    const property = this.props.property

    let value = property.get()
    if (property.options().defaultUnit) value = value.to(property.options().defaultUnit)

    return (
      <div className="side-panel__row">
        <span className="label--side-panel">{property.label()}:</span>
        <span className="font-sm">{value.toString()}</span>
      </div>
    )
  }

  render() {
    const property = this.props.property
    if (property.editable()) {
      return this._renderUnitField()
    }

    return this._renderPropertyValue()
  }
}

module.exports = UnitPropertyView
