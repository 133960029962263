const {
  Polygon,
  Rectangle,
  Point
} = require("construction-designer-core/geometry")

Point.prototype.snapToEdge = function(edges, tolerance = 3) {
  const targetData = edges.reduce((closestSoFar, edge) => {
    const closestPoint = edge.closestPointTo(this)
    const delta = closestPoint.subtract(this)
    const closestData = { edge: edge, delta: delta, distance: delta.r() }
    return closestData.distance < closestSoFar.distance ? closestData : closestSoFar
  }, { edge: null, delta: null, distance: Number.POSITIVE_INFINITY })

  if (targetData.distance <= tolerance) {
    return this.moveBy(targetData.delta.x(), targetData.delta.y()) // && this.attachToEdge(targetData.edge)
  }
  return false
}

/**
 * A contiguous set of line segments defined by an ordered list of vertices
 * TODO: This should probably implement the Handleable interface and be extracted to ConD.
 */
class Polyline extends Polygon {
  constructor(vertices) {
    super()
    this._vertices = vertices || []
  }

  vertices() {
    return this._vertices
  }

  edges() {
    return this.vertices().reduce((edges, vertex, index, vertices) => {
      const nextVertex = vertices[index + 1]
      if (nextVertex) {
        edges.push(vertex.to(nextVertex))
      }
      return edges
    }, [])
  }

  zLevel() {
    return this.vertices()[0].z()
  }

  length() {
    return this.edges().sum(edge => edge.length())
  }

  intersectsShape(shape) {
    return (
      !shape.containsShape(this) &&
      (this.vertices().some(locator => shape.containsPoint(locator)) ||
        this.edges().some(edge =>
          shape
            .edges()
            .some(
              shapeEdge => edge.intersectionsWithEdge(shapeEdge).length > 0
            )
        ))
    );
  }

  tiles() {
    throw new Error('not implemented')
  }

  boundingBox() {
    if (this.edges().length === 0) return new Rectangle(0, 0, 0, 0)

    return this.edges()
      .map(edge => edge.boundingBox())
      .reduce((allBoxes, box) => allBoxes.merge(box))
  }

  xy() {
    return new this.constructor(
      this.vertices().map(vertex => vertex.xy())
    );
  }

  contains(x, y) {
    const location = new Point(x, y)
    return this.vertices().some((v) => v.equals(location)) || this.edges().some((e) => e.contains(x, y))
  }

  signedArea() { return this.area() }
  area() { return 0 }

  isCounterClockwise() { return true }

  moveBy(x, y, z) {
    return this.vertices().forEach(v => v.moveBy(x, y, z) )
  }
}

module.exports = Polyline
