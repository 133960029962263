const {
  Point: { $P },
  Polygon
} = require('construction-designer-core/geometry')
const WallConnection = require('shared/domain-models/WallConnection')
const ExteriorWallSurface = require('shared/domain-models/ExteriorWallSurface')
const cutEdgeByShape = require('shared/helpers/cutEdgeByShape')
const WallSegment = require('shared/domain-models/WallSegment')
const EdgeWallSegment = require('shared/domain-models/EdgeWallSegment')

class WallSegmentFinder {
  constructor(project, buildingLevel, defaultThickness, wallSegmentType = WallSegment.TYPES.Exterior, wallSurfaceClass = ExteriorWallSurface) {
    this._project = project
    this._buildingLevel = buildingLevel
    this.__defaultThickness = defaultThickness
    this._wallSegmentType = wallSegmentType
    this._wallSurfaceClass = wallSurfaceClass
    this.__maximumThickness = project.maximumDefaultWallThickness().toInches()
  }

  find(currentEdge, edges) {
    const { segmentEdges, gapEdges } = this._analyzePlanAlongEdge(currentEdge, edges)
    return this._generateWallComponents(segmentEdges, gapEdges, currentEdge, edges)
  }

  buildingLevel() { return this._buildingLevel }
  wallSegmentType() { return this._wallSegmentType }
  wallSurfaceClass() { return this._wallSurfaceClass }

  _defaultThickness() { return this.__defaultThickness }
  _minimumThickness() { return 2 }
  _maximumThickness() { return this.__maximumThickness }

  // previous segment found was reversed, used in heuristics and defaults
  previouslyReversed() { return this._previouslyReversed }

  // analyze the underlying plan and find likely segments (parallel lines) and gaps (no parallel found)
  _analyzePlanAlongEdge(edge, allEdges) {
    if (allEdges.length === 0) {
      return {
        segmentEdges: [edge],
        gapEdges: []
      }
    }

    const minimum = this._minimumThickness()
    const maximum = this._maximumThickness()
    const normal = edge.normal()

    // sort out edges that are roughly parallel and roughly on the edge, to one side, or the other
    const nearEdges = []
    const oppositeEdges = []
    const farEdges = []
    allEdges.forEach(e => {
      if(!e.isParallelTo(edge, 1e-3)) {
        return
      }
      const line = edge.shortestLineFrom(e.begin(), true)
      const thickness = line.length()
      e.thickness = thickness.roundedTo(3)

      if(thickness > maximum) {
        return
      }

      if(thickness < minimum) {
        nearEdges.push(e)
      } else if(line.vector().dot(normal) < 0) {
        oppositeEdges.push(e)
      } else {
        farEdges.push(e)
      }
    })

    // map all sorts of edges into edge-space (aligned with the original edge, zeroed at the beginning)
    const transformedInput = [$P(0, 0, 0).to($P(edge.length(), 0, 0))]
    const transformedNear = this._projectOnEdge(edge, nearEdges)
    const transformedOpposite = this._projectOnEdge(edge, oppositeEdges)
    const transformedFar = this._projectOnEdge(edge, farEdges)

    // nibble away at the original span with the identified possible segment coverage, yielding likely gaps
    const findGaps = transformed => {
      if(transformed.length === 0) { return [] }

      const coverage = transformed.reduce((collection, wallEdge) => {
        // Set collection[wallEdge.thickness] to an empty array if it doesn't already exist
        collection[wallEdge.thickness] = collection[wallEdge.thickness] || []
        // Then, push the wallEdge to the collection
        collection[wallEdge.thickness].push(wallEdge)
        return collection
      }, {})

      if(transformedInput.first().length() > maximum) { // skip this check if initial edge is very short
        Object.keys(coverage).forEach(thickness => {
          const edges = coverage[thickness]
          const length = edges.sum(edge => edge.length())
          if(length < maximum) {
            coverage[thickness] = []
          }
        })
      }

      const coverageEdges = Object.keys(coverage).flatMap(thickness => coverage[thickness])

      return coverageEdges.reduce((remainders, wallEdge) => {
        return remainders.flatMap(remainder => this._nibble(remainder, wallEdge) )
      }, transformedInput).filter(e => (e.length() >= minimum && e.length() <= maximum))
    }

    const transformedGaps = [...findGaps(transformedNear), ...findGaps(transformedOpposite), ...findGaps(transformedFar)]

    // subtract all identified gaps from baseline to yield likely segments
    const transformedSegments = transformedGaps.reduce((remainders, wallEdge) => {
      return remainders.flatMap(remainder => this._nibble(remainder, wallEdge))
    }, transformedInput)

    // convert back from edge-space into original-space
    const segmentEdges = this._unprojectFromEdge(edge, transformedSegments.sort((a, b) => a.begin().x() - b.begin().x()))
    const gapEdges = this._unprojectFromEdge(edge, transformedGaps.sort((a, b) => a.begin().x() - b.begin().x()))

    // no likely segments found in the span?! then return the 'gaps' instead
    if (segmentEdges.length === 0) {
      return {
        segmentEdges: gapEdges,
        gapEdges: []
      }
    }

    return {
      segmentEdges: segmentEdges,
      gapEdges: gapEdges
    }
  }

  // take likely walls and likely gaps, and look for walls and connections
  _generateWallComponents(wallEdges, gapEdges, masterEdge, allEdges) {
    // sort the wall and gap edges out and initialize a sequence of entities for the algorithm
    const origin = masterEdge.begin()
    const orderedZones = [
      ...wallEdges.map(e => ({ type: 'wall', distance: e.begin().distanceTo3D(origin), edge: e, object: undefined, reversed: false }) ),
      ...gapEdges.map(e => ({ type: 'gap', distance: e.begin().distanceTo3D(origin), edge: e, object: undefined, reversed: false }) )
    ].sort((a, b) => (a.distance - b.distance) )
    // find existing segments and connections, before the algorithm starts
    const otherConnections = this.buildingLevel().wallConnections()
    const otherSegments = this.buildingLevel().wallSegments()

    // set up a results-holding object
    const results = { sequence: [], segments: [], connections: [], surfaces: [] }

    // iterate the sequence of entities, and solve each one for one or more existing or new components
    orderedZones.forEach(entity => {
      if(entity.type === 'gap') { // if current object is tagged as a gap
        const resultEntity = this._findOrCreateConnection(entity, otherConnections, otherSegments)

        if(resultEntity) {
          results.sequence.push(resultEntity)
          // #findOrCreateConnectino may return a wallEnd if there's a detected connection where there's already an existing wall.
          if(resultEntity.type === 'gap') { results.connections.push(resultEntity.object) }
        }
      } else { // this should be a wall or walls (may solve as a sequence of walls with an intermediate connection)
        const subresults = this._findOrCreateWallSegments(entity.edge, allEdges)

        results.sequence.push(...subresults)
        results.connections.push(...subresults.filter(result => result.type === 'gap').map(result => result.object))
        results.segments.push(...subresults.filter(result => result.type === 'wall').map(result => result.object))
      }
    })

    // Resolve each of the wallEnd features by truncating the segment and adding
    // connections. Then, replace the sequence entry with a new gap/connection entity
    results.sequence.forEach((entity, index) => {  // collection may be mutated during iteration, but must maintain offset
      if(entity.type === 'wallEnd') {
        this._handleSegmentToTruncate(entity, results.sequence[index - 1], results.sequence[index + 1], results)
      }
    })

    // Iterate over the sequence of solved entities, and take each segment and associate it to adjoining connections
    results.sequence.forEach((entity, index) => {
      // TODO: Handle sequences that have multiple connections adjacent to each other.
      if(entity.type === 'wall') {
        this._associateSegment(entity, results.sequence[index - 1], results.sequence[index + 1], results, otherSegments, otherConnections)
      }
    })

    // Create the surface for this wall-run
    const surface = this._findSurface(masterEdge.reversed(), results.segments)
    // Subclasses may not always return a surface
    if (surface) results.surfaces.push(surface)

    return results
  }

  _handleSegmentToTruncate(entity, previousEntity, nextEntity, results) {
    const segmentToTruncate = entity.object

    const adjoiningThicknesses = [previousEntity, nextEntity].filter(entity => entity && entity.type === 'wall').map(entity => entity.object.thickness().toInches())
    const maximumThickness = adjoiningThicknesses.length > 0 ? Math.max(...adjoiningThicknesses) : segmentToTruncate.thickness().toInches()

    const connection = this._truncateSegmentAndCreateConnection(segmentToTruncate, entity.edge, maximumThickness)

    const newEntity = { type: 'gap', object: connection, distance: entity.distance, reversed: false }
    results.sequence.replace(entity, newEntity)
    results.connections.push(connection)
  }

  // associate a segment to adjacent connections
  _associateSegment(entity, previousEntity, nextEntity, results, otherSegments, otherConnections) {
    const segment = entity.object
    // orient and figure out which endpoint is which based on whether the segment was solved as reversed
    let firstTerminal = 'begin'
    let lastTerminal = 'end'
    if(entity.reversed) {
      firstTerminal = 'end'
      lastTerminal = 'begin'
    }

    if(previousEntity) {
      if(previousEntity.object.hasAttachedSegment && !previousEntity.object.hasAttachedSegment(segment)) {
        previousEntity.object.attach(segment, firstTerminal)
      }
    } else { // no previous entity, this must be an initial segment
      const connection = this._findNearbyConnection(segment, firstTerminal, otherConnections, otherSegments)
      if(connection) results.connections.push(connection)
    }

    if(nextEntity) {
      if(nextEntity.object.hasAttachedSegment && !nextEntity.object.hasAttachedSegment(segment)) {
        nextEntity.object.attach(segment, lastTerminal)
      }
    } else { // no next entity, this must be a final segment
      const connection = this._findNearbyConnection(segment, lastTerminal, otherConnections, otherSegments)
      if(connection) results.connections.push(connection)
    }
  }

  _findOrCreateConnection(entity, otherConnections, existingSegments) {
    return this._findExistingConnection(entity, otherConnections) || this._createNewConnection(entity, existingSegments)
  }

  /**
   * @param {FinderEdgeResult} entity
   * @param {WallConnection[]} otherConnections
   * @returns {undefined|FinderEdgeResult} - A new finder edge result representing the found connection
   */
  _findExistingConnection(entity, otherConnections) {
    const edge = entity.edge
    // construct the possible volume of the connection, and figure out if that overlaps an existing connection
    const opposite = edge.shiftedAlongNormalBy(this._maximumThickness())
    const edgeCenterBox = new Polygon(
      [edge.begin(), edge.end(), opposite.end(), opposite.begin()]
    ).boundingBox().roundedTo(3)

    const existing = otherConnections.find(other => other.footprint().boundingBox().intersects(edgeCenterBox))
    if(existing) return { type: 'gap', object: existing, distance: entity.distance, reversed: false }
  }

  _createNewConnection(entity, existingSegments) {
    const edge = entity.edge
    // Now find if there are any preexisting segments to attach to
    const center = edge.center().add(edge.normal().multipliedBy(edge.length() / 2))
    const segmentFindingBox = center.expandedBy(edge.length() / 2).roundedTo(3)
    const intersectingSegments = existingSegments.filter(segment => segment.footprint().boundingBox().intersects(segmentFindingBox) )

    // TODO: This may not handle a case where a wall exists and is adjacent but not overlapping this new connection.
    // If it is not in scope of the new wall, or intersecting it, it will not be linked to this connection

    // NB: This assumes there's only one intersecting segment, at most
    if(intersectingSegments.length > 0) {
      return { type: 'wallEnd', object: intersectingSegments.first(), distance: entity.distance, reversed: false, edge }
    }
    return { type: 'gap', object: new WallConnection(), distance: entity.distance, reversed: false }
  }

  _findOrCreateWallSegments(edge, allPlanEdges) {
    // get all joint/connection edges
    // find all overlapping connection and wall edges (and collect said overlapping elements)
    // subtract wall/joint/connection edges from the seeking edges
    // if there's any remaining line segments, then create said segments
    // return overlapping segments, and new segments (ignore the need for connections in between, if that's a thing)
    const { connections, wallSegments, wallEnds } = this._findExistingSegments(edge)
    const edges = [
      ...wallSegments.edges,
      ...connections.edges,
      ...wallEnds.edges
    ]

    const newSegments = this._createNewSegments(edge, edges, allPlanEdges)
    const results = [...newSegments, ...wallSegments.existing, ...connections.existing, ...wallEnds.existing].sort((a, b) => a.distance - b.distance)
    // TODO: consider if we need to detect adjacent wall segments and add a connection in between them
    return results
  }

  _findExistingSegments(edge) {
    const level = this.buildingLevel()
    const origin = edge.begin()
    const box = edge.boundingBox().roundedTo(3)

    const connections = level.wallConnections().reduce((results, connection) => {
      const edges = connection.footprint().xy().edges().filter(connectionEdge => (
        connectionEdge.overlapsBySignificantDistance(edge, 1)
      ))

      if (edges.length > 0) {
        results.existing.push(
          { type: 'gap', object: connection, distance: edges.first().center().distanceTo3D(origin), reversed: false }
        )
      }
      results.edges.fastMerge(edges)
      return results
    }, { existing: [], edges: [] })

    const wallEnds = { existing: [], edges: [] }

    const wallSegments = level.wallSegments().filter(segment => segment.footprint().boundingBox().roundedTo(3).intersects(box)).reduce((results, segment) => {
      const wallEdge = segment.sides().map(side => side.xy()).find(segmentEdge => segmentEdge.overlapsBySignificantDistance(edge, 1))

      if (wallEdge) {
        const reversed = Math.sign(segment.edge().vector().dot(edge.normal())) === 1
        results.existing.push(
          { type: 'wall', object: segment, distance: wallEdge.center().distanceTo3D(origin), reversed }
        )
        results.edges.push(wallEdge)
      } else {
        const endEdge = segment.endcaps().find(endcap => endcap.xy().overlapsBySignificantDistance(edge, 1))
        if(endEdge) {
          wallEnds.existing.push(
            { type: 'wallEnd', object: segment, distance: endEdge.center().distanceTo3D(origin), reversed: false, edge: endEdge }
          )
          wallEnds.edges.push(endEdge)
        }
      }
      return results
    }, { existing: [], edges: [] })

    return { connections, wallSegments, wallEnds }
  }

  /**
   * @typedef {Object} FinderEdgeResult
   * @property {string} type - 'wall' or 'gap' (or other types as defined)
   * @property {WallSegment|WallConnection} object - the matching domain object found/created
   * @property {Number} distance - distance along the line (measured from center of this result to the origin of the master edge)
   * @property {boolean} reversed - which orientation this object has along the line
   * @property {Edge} [edge] - the edge representing this span
   */

  /**
   * build out segments to fill in the parts of the drawn edge that do not have existing wall/connection coverage
   * @param {Edge} drawnEdge - path of wall/surface run
   * @param {Edge[]} edges - existing wall/connection edges
   * @param {Edge[]} allPlanEdges - all edges from the plan (used for autodetecting wall thickness)
   * @return {FinderEdgeResult[]} - result objects for each wall segment created
   */
  _createNewSegments(drawnEdge, edges, allPlanEdges) {
    const origin = drawnEdge.begin()
    const transformed = this._projectOnEdge(drawnEdge, edges)
    const transformedInput = [$P(0, 0, 0).to($P(drawnEdge.length(), 0, 0))]

    // nibble away at the original span with the existing edges
    const finalRemainders = transformed.reduce((remainders, wallEdge) => {
      return remainders.flatMap(remainder => this._nibble(remainder, wallEdge))
    }, transformedInput)

    // convert back from edge-space into original-space
    const missingWallEdges = this._unprojectFromEdge(drawnEdge, finalRemainders)

    // build new segments for the areas that do not have existing walls or connections
    const newSegments = missingWallEdges.map(missingEdge => {
      const { direction, thickness } = this._findLikelyWallThickness(missingEdge, allPlanEdges)
      const reversed = Math.sign(direction) === 1
      if (reversed) { missingEdge = missingEdge.reversed() }

      const conformedBegin = this._conformToBuildingLevel(missingEdge.begin())
      const conformedEnd = this._conformToBuildingLevel(missingEdge.end())
      const segmentEdge = conformedBegin.to(conformedEnd)

      const segment = new EdgeWallSegment(segmentEdge, this.wallSegmentType(), thickness.inches())
      return { type: 'wall', object: segment, distance: segmentEdge.center().distanceTo3D(origin), reversed }
    })

    return newSegments
  }

  // set up a surface along a given edge. Does not look for existing surfaces in that place, but
  // attempts to snap to and share existing surface vertices
  _findSurface(edge, segments) {
    const existingEndpoints = this.buildingLevel().wallSurfaces().flatMap(surface => (
      [surface.edge().begin(), surface.edge().end()]
    ))

    const conformedBegin = this._conformToBuildingLevel(edge.begin(), existingEndpoints)
    const conformedEnd = this._conformToBuildingLevel(edge.end(), existingEndpoints)
    const surfaceEdge = conformedBegin.to(conformedEnd)

    const ConfiguredWallSurface = this.wallSurfaceClass()
    const newSurface = new ConfiguredWallSurface(surfaceEdge)
    segments.forEach(segment => segment.addSurface(newSurface))

    return newSurface
  }

  // at the beginning or ending of a collinear run of walls, look for a connection or wall to connect to
  _findNearbyConnection(segment, terminal, connections, segments) {
    const locator = segment.edge()[terminal]()

    // consider alternative ways of checking for realistic configurations
    const node = locator.expandedBy(segment.thickness().toInches()).roundedTo(3)
    // TODO: select nearest, not just first-intersecting
    let connection = connections.find(other => other.footprint().boundingBox().intersects(node) )
    if(connection) {
      this._attachSegment(connection, segment, terminal)
    } else { // no connection found, maybe there's a nearby segment
      const previousSegment = segments.find(other => other !== segment && other.footprint().boundingBox().intersects(node) )
      if(previousSegment) {
        // okay, so we know that there's a segment nearby somewhere. And we'd like to connect to it
        // if we're only sharing a corner vertex, then we want to just abracadabra the connection into existence (example: inside corners)
        // but if we're overlapping by any significant margin (butt joint) then we want to MAKE A HOLE and CONNECT THIS UP

        const endcap = segment.endcaps().find(edge => edge.containsPoint(locator))
        if(previousSegment.footprint().edges().some(edge => edge.overlapsBySignificantDistance(endcap, 1))) {
          // this is a butt-joint against the previous segment
          connection = this._truncateSegmentAndCreateConnection(previousSegment, endcap.snapshot().reversed())
          this._attachSegment(connection, segment, terminal)
        } else {
          // handle 'outboard' connection
          connection = new WallConnection()
          this._attachSegment(connection, segment, terminal)

          const oldEdge = previousSegment.edge()
          const previousTerminal = oldEdge.begin().distanceTo3D(locator) < oldEdge.end().distanceTo3D(locator) ? 'begin' : 'end'
          this._attachSegment(connection, previousSegment, previousTerminal)
        }
      }
    }
    return connection
  }

  /**
   * @param {WallSegment} wall - The original wall segment
   * @param {Edge} connectionEdge - An edge used with the given width to create the new connection
   * @param {Number} [width=wall.thickness().toInches()] - The width used to create the new connection
   * @returns {WallConnection}
   */
  _truncateSegmentAndCreateConnection(wall, connectionEdge, width = wall.thickness().toInches()) {
    const originalWallEdge = wall.edge()
    const oppositeEdge = connectionEdge.shiftedAlongNormalBy(width).snapshot()

    const connectionFootprint = new Polygon([
      connectionEdge.begin(),
      connectionEdge.end(),
      oppositeEdge.end(),
      oppositeEdge.begin()
    ])

    const newWallEdges = cutEdgeByShape(connectionFootprint, originalWallEdge).map(edge => this._conformToBuildingLevel(edge.begin()).to(this._conformToBuildingLevel(edge.end())))
    let truncatedWallEdge = newWallEdges[0]
    const remainderWallEdge = newWallEdges[1] // may not exist, if wall was truncated, not split

    if(truncatedWallEdge) {
      wall.setEdge(truncatedWallEdge)
    } else { // zero length case
      const existingConnection = wall.connections().first()
      const connectedTerminus = existingConnection.getTerminus(wall)
      const basisLocator = originalWallEdge[connectedTerminus]()
      const edgeDirection = originalWallEdge.vector().normalized()
      const directionVector = connectedTerminus === 'begin' ? edgeDirection : edgeDirection.negated()

      // below the minimum distance, so technically zero, but also not of zero length, so retains orientation!
      const endLocator = basisLocator.snapshot().add(directionVector.multipliedBy(1e-7))

      // zero length edge
      truncatedWallEdge = basisLocator.to(this._conformToBuildingLevel(endLocator))
      wall.setEdge(connectedTerminus === 'begin' ? truncatedWallEdge : truncatedWallEdge.reversed())
    }

    let newWall, newConnection
    if(remainderWallEdge) { // split case
      const endingConnection = wall.connections().find(connection => connection.getTerminus(wall) === 'end')

      newWall = new EdgeWallSegment(remainderWallEdge, wall.type(), wall.thickness())
      if(endingConnection) {
        endingConnection.detach(wall)
        endingConnection.attach(newWall, 'end')
      }

      wall.componentOf().addSegment(newWall)

      newConnection = WallConnection.withSegments([wall, newWall], ['end', 'begin'])
      wall.reassignOpenings([newWall])
    } else { // truncated case
      wall.reassignOpenings()
      const truncatedTerminal = truncatedWallEdge.containsPoint(originalWallEdge.begin()) ? 'end' : 'begin'
      newConnection = WallConnection.withSegments([wall], [truncatedTerminal])
    }

    // NOTE: if surface editing becomes necessary, look at WallAndSurfaceBuilder#_createWallConnectionForEndpoint for algorithm

    return newConnection
  }

  // project edges against a master edge, to convert to 1D edge-space
  _projectOnEdge(base, edges) {
    const vector = base.vector().normalized()
    const origin = base.begin()

    return edges.map(wallEdge => {
      const a = $P(origin.to(wallEdge.begin()).vector().dot(vector), 0, 0)
      const b = $P(origin.to(wallEdge.end()).vector().dot(vector), 0, 0)
      let projectedEdge
      if(a.x() > b.x()) {
        projectedEdge = b.to(a)
      } else {
        projectedEdge = a.to(b)
      }
      projectedEdge.thickness = wallEdge.thickness
      return projectedEdge
    })
  }

  // reverse projection on master edge, to convert back to 3D space
  _unprojectFromEdge(base, edges) {
    const vector = base.vector().normalized()
    const origin = base.begin()

    return edges.map(transformedSegment => {
      const beginPoint = origin.add(vector.multipliedBy(transformedSegment.begin().x()))
      const endPoint = origin.add(vector.multipliedBy(transformedSegment.end().x()))
      return beginPoint.to(endPoint)
    })
  }

  // boolean subtraction of edges from an edge
  _nibble(target, edge) {
    if(target.length() === 0) {
      console.log('already consumed')
      return []
    }
    if(!target.overlapsBySignificantDistance(edge)) {
      return [target]
    }

    const overlapBegin = $P(Math.max(target.begin().x(), edge.begin().x()), 0, 0)
    const overlapEnd = $P(Math.min(target.end().x(), edge.end().x()), 0, 0)

    if(overlapBegin.distanceTo3D(overlapEnd).isNearTo(0, 1e-3) && target.length() > 0) {
      return [target]
    }

    const remainders = []
    if(!target.begin().equals(overlapBegin, 1e-3)) {
      remainders.push(target.begin().to(overlapBegin))
    }
    if(!target.end().equals(overlapEnd, 1e-3)) {
      remainders.push(overlapEnd.to(target.end()))
    }
    return remainders
  }

  // search for parallel lines on the plan and figure out likely wall thickness and orientation
  _findLikelyWallThickness(edge, allEdges) {
    const masterLength = edge.length()
    const minimumThickness = this._minimumThickness()
    const maximumThickness = this._maximumThickness()
    // project into floorspace
    const masterEdge = $P(edge.begin().x(), edge.begin().y()).to($P(edge.end().x(), edge.end().y()))

    // find each parallel line...
    // compute the implicit thickness...
    // determine what projected overlap there is...
    // return the implicit thickness of the greatest overlap sum
    const sums = allEdges.reduce((distances, e) => {
      if(!e.isParallelTo(masterEdge, 1e-1)) { return distances }

      const line = masterEdge.shortestLineFrom(e.begin(), true)
      const thickness = line.length()
      if(thickness > maximumThickness || thickness < minimumThickness) { return distances }

      const overlap = masterEdge.closestPointTo(e.begin()).distanceTo3D(masterEdge.closestPointTo(e.end()))
      if(overlap > 0) {
        const direction = Math.sign(line.vector().dot(masterEdge.normal()))
        const key = [thickness.roundedTo(3), direction]
        distances[key] = distances[key] || { thickness, overlap: 0, direction }
        distances[key]['overlap'] = distances[key]['overlap'] + overlap
      }
      return distances
    }, {})

    const potentials = Object.values(sums).sort((a, b) => b['overlap'] - a['overlap'] )

    // if no likely results found, then return the most recently used direction and default thickness
    const defaultThickness = this._defaultThickness().toInches()
    const direction = this.previouslyReversed() ? 1 : -1
    if(potentials.length === 0) {
      return {
        direction,
        thickness: defaultThickness
      }
    }

    // use default thickness if it is represented at more than 20% of the overall edge length, else use the most prevalent thickness
    const match = potentials.find(potential => (
      potential.thickness.isNearTo(defaultThickness, 1e-3) && ((potential.overlap / masterLength) > 0.2)
    )) || potentials.first()

    return {
      direction: match.direction,
      thickness: match.thickness
    }
  }

  /**
   * @param {Locator} locator
   * @param {Locator[]} [existingEndpoints=[]]
   * @returns {ThreeSourceRelativeLocator}
   */
  _conformToBuildingLevel(locator, existingEndpoints = []) {
    const existingLocator = existingEndpoints.find(endPoint => endPoint.xy().equals(locator, 1))
    if (existingLocator) return existingLocator

    return this.buildingLevel().conformToFloorLevel(locator)
  }

  // attach segment to connection idempotentially--only attach if it is not already attached
  _attachSegment(connection, segment, terminal) {
    if(!connection.hasAttachedSegment(segment)) {
      connection.attach(segment, terminal)
    }
  }
}
module.exports = WallSegmentFinder
