const { CompositeFigure } = require('construction-designer-core/drawing-editor')

class BuildingLevelFigure extends CompositeFigure {
  _drawSelf(context, _options) {
    if (this.model().wallSegments().length > 0) {
      const polygons = this._compositeFootprint()
      const drawPolygonWithHoles = (context, polygon) => {
        context.beginPath()
        context._addPolygonToCurrentPath(polygon)
        context.closePath()
        polygon.holes().forEach(hole => {
          context.moveTo(hole.vertices().first().x(), hole.vertices().first().y())
          context._addPolygonToCurrentPath(hole)
          context.closePath()
        })
        context.fill()
        context.stroke()
      }
      polygons.forEach(polygon => drawPolygonWithHoles(context, polygon))
    }
  }

  _compositeFootprint() {
    const segments = this.model().wallSegments()
    const connections = this.model().wallConnections()

    const segmentPolygons = segments.flatMap(component => (
      component.footprintWithoutOpenings ? component.footprintWithoutOpenings() : [component.footprint()]
    )).map(f => f.xy())
    const connectionPolygons = connections.map(connection => connection.footprint()).map(f => f.xy())

    const polygons = [...segmentPolygons, ...connectionPolygons]
    const polygonsWithHoles = polygons.map(polygon => polygon.withHoles())

    if (polygonsWithHoles.length === 1) {
      return polygonsWithHoles
    }

    const first = polygons.shift()
    return first.union(...polygons)
  }

  contains(_x, _y) { return false }

  layer() { return 'Walls' }

  defaultProperties() {
    return {
      strokeStyle: '#00000000',
      fillStyle: '#AEB4B888'
    }
  }
}

module.exports = BuildingLevelFigure
