const { DrawingEditor, CompositePanZoomTool } = require('construction-designer-core/drawing-editor')
const BuildingLevelDrawingController = require('./BuildingLevelDrawingController')

class BuildingLevelDrawingEditor extends DrawingEditor {
  constructor(project, buildingLevel, editorModeStrategy) {
    super(project)
    this._buildingLevel = buildingLevel
    this._editorModeStrategy = editorModeStrategy
  }

  buildingLevel() {
    return this._buildingLevel
  }

  editorModeStrategy() {
    return this._editorModeStrategy
  }

  defaultDrawingControllerClass() {
    return BuildingLevelDrawingController
  }

  defaultDrawingController() {
    const controller = super.defaultDrawingController()
    controller.setBuildingLevel(this.buildingLevel())
    return controller
  }

  activate() {
    super.activate()
    this.toolPalette().setActiveTool(this.toolPalette().defaultTool())
  }

  drawing() {}

  defaultTools() {
    if (this.editorModeStrategy().isViewOnly()) {
      return [new CompositePanZoomTool(this.drawingController(), this.zoomPalette())]
    }

    return [
      ...super.defaultTools(),
      ...this.project().systemStrategy().defaultTools(this)
    ]
  }
}

module.exports = BuildingLevelDrawingEditor
