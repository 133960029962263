const {
  Point: { $P },
  Polygon
} = require("construction-designer-core/geometry")
let DxfParser = require('dxf-parser')
if(DxfParser.default) { DxfParser = DxfParser.default }
const Polyline = require('shared/geometry/Polyline')
const Arc = require('shared/geometry/Arc')
const Label = require('shared/domain-models/Label')
const { convertPointTo } = require('shared/helpers/unitConverters')

class DXFImport {
  constructor(file, baseUnit = 'inch', mapping = (x, y, z) => [x, y, z]) {
    this._file = file
    this._baseUnit = baseUnit
    this._mapping = mapping
  }

  static SUPPORTED_TYPES = new Set(['POLYLINE', 'LINE', 'LWPOLYLINE', 'ARC', 'TEXT', 'INSERT', 'CIRCLE'])

  process(layerMap = this.layers()) {
    const dxf = this.parsedDXF()
    return dxf.entities.filter(entity => (
      this.constructor.SUPPORTED_TYPES.has(entity.type) && layerMap[entity.layer]
    ) ).flatMap(entity => {
      return this._mapEntity(dxf, entity, this._mapping)
    }).filter(item => item)
  }

  layers() {
    const dxf = this.parsedDXF()
    const layersWithContent = new Set()
    dxf.entities.forEach(entity => {
      if(this.constructor.SUPPORTED_TYPES.has(entity.type)) {
        layersWithContent.add(entity.layer)
      }
    })
    return Object.values(dxf.tables.layer.layers).reduce((flags, layer) => {
      if(layersWithContent.has(layer.name)) {
        flags[layer.name] = layer.visible
      }
      return flags
    }, {})
  }

  parsedDXF() {
    if(!this._parsedDXF) {
      const parser = new DxfParser()
      this._parsedDXF = parser.parseSync(this._file)
    }
    return this._parsedDXF
  }

  _mapEntity(dxf, entity, mapping, rotation = 0) {
    if (entity.type === 'ARC' || entity.type === 'CIRCLE') {
      return [Arc.fromDXF(entity, this.baseUnit(), mapping, rotation)]
    } else if (entity.type === 'TEXT') {
      return [Label.fromDXF(entity, this.baseUnit(), mapping)]
    } else if (entity.type === 'INSERT') {
      return this._handleBlock(dxf, entity, mapping)
    } else {
      // handle lines and polylines and lightweight polylines, oh my!
      return [this._buildLine(entity, mapping)]
    }
  }

  // INSERT entity is a block reference, to a blob of embedded sub-entities
  _handleBlock(dxf, entity, globalMapping) {
    // TODO: improve arc transformations, and handle inconsistent/idiomatic blockspace transforms
    const basePoint = $P(...globalMapping(entity.position.x, entity.position.y, entity.position.z))
    const rotation = (entity.rotation || 0).degreesToRadians()
    const { xScale = 1, yScale = 1, zScale = 1 } = entity
    const mapping = (x, y, z) => {
      const p = $P(...globalMapping(x*xScale, y*yScale, z*zScale)).rotatedBy($P(0, 0, 0), -rotation).add(basePoint)
      return [p.x(), p.y(), p.z()]
    }
    const block = dxf.blocks[entity.name]
    if(block.entities) {
      return block.entities.filter(entity => this.constructor.SUPPORTED_TYPES.has(entity.type) ).flatMap(entity => this._mapEntity(dxf, entity, mapping, rotation) )
    }
    return []
  }

  baseUnit() { return this._baseUnit }

  _buildLine(line, mapping) {
    const vertices = line.vertices.map(vertex => {
      const [x, y, z] = mapping(vertex.x, vertex.y, vertex.z)
      return convertPointTo($P(x, y, z || 0), this.baseUnit(), 'inch')
    })

    if(line.shape) {
      return new Polygon(vertices)
    } else {
      return new Polyline(vertices)
    }
  }
}

module.exports = DXFImport
