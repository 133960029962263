const {
  ClosedShapeFigure
} = require('construction-designer-core/drawing-editor')

class FloorFigure extends ClosedShapeFigure {
  constructor(floor) {
    super()
    this._object = floor
  }
  model() {
    return this._object
  }

  zLevel() { return 1 }

  boundingBox() { return this.model().boundingBox() }

  _draw(context) {
    this.model().openings().forEach(opening => {
      context._drawPolygon(opening.shape())
    })
  }
  contains(x, y) { return false }

  defaultProperties() {
    return {
      fillStyle: '#aaaaaa22'
    }
  }
}
module.exports = FloorFigure
