const {
  Polygon,
  Point: { $P }
} = require('construction-designer-core/geometry')

class RoomFinder {
  constructor(point, allEdges) {
    this._center = point
    this._allEdges = allEdges
      .filter(edge => edge.length() > 3)
      .map(edge => ({ edge, bounds: edge.boundingBox() }))
  }

  center() {
    return this._center
  }

  allEdges() {
    return this._allEdges
  }

  process() {
    const center = this.center()
    const distance = 500
    const directions = [$P(1, 0), $P(0, 1), $P(-1, 0), $P(0, -1)]
    const finderEnds = directions.map(direction => direction.multipliedBy(distance).add(center))
    const finderEdges = finderEnds.map(end => center.to(end))

    const roomEdges = finderEdges.map(findingEdge => {
      const findingBox = findingEdge.boundingBox()

      return this.allEdges().reduce((nearest, { edge, bounds }) => {
        const intersects = findingBox.intersects(bounds)
        if (!intersects) return nearest

        const distance = center.distanceTo(edge.closestPointTo(center))
        if (distance < nearest.distance) {
          nearest.edge = edge
          nearest.distance = distance
        }
        return nearest
      }, { edge: undefined, distance: Infinity }).edge
    }).filter(edge => edge)

    if (roomEdges.length < 4) { return }

    const corners = roomEdges.map((edge, index) => {
      const nextEdge = roomEdges[index + 1] || roomEdges[0]

      return edge.intersectionsWithLineIn2D(nextEdge).first()
    }).filter(i => i)
    if(corners.length < 4) { return }

    return new Polygon(corners.map(corner => corner.snapshot()))
  }
}
module.exports = RoomFinder
