const React = require('react')
const PropTypes = require('prop-types')
const classNames = require('classnames')
const { Icon } = require('construction-designer-core/drawing-editor-react')

class LayerToggle extends React.Component {
  static propTypes = {
    layerName: PropTypes.string.isRequired,
    layerManager: PropTypes.object.isRequired,
    redrawView: PropTypes.func.isRequired
  }

  visible() {
    return this.props.layerManager.isVisible(this.props.layerName)
  }

  classes() {
    return classNames(
      'layers__item',
      'justify-between',
      {
        'layers__item--hidden': !this.visible()
      }
    )
  }

  iconName() {
    return this.visible() ? 'eye-outline' : 'eye-off-outline'
  }

  _toggleLayer() {
    this.props.layerManager.toggle(this.props.layerName)
    this.props.redrawView()
    this.forceUpdate()
  }

  render() {
    const { layerName } = this.props

    return (
      <li className={this.classes()}>
        <span>{layerName}</span>
        <button
          data-testid="layer-button"
          onClick={() => this._toggleLayer()}
        >
          <Icon name={this.iconName()} className="icon" />
        </button>
      </li>
    )
  }
}

module.exports = LayerToggle
