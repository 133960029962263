const {
  CompositePanZoomTool
} = require('construction-designer-core/drawing-editor')

const {
  Point: { $P }
} = require('construction-designer-core/geometry')
const RoomFinder = require('shared/operations/RoomFinder')
const Room = require('shared/domain-models/Room')
const ExteriorSpace = require('shared/domain-models/ExteriorSpace')
const WallSegmentFinder = require('shared/operations/WallSegmentFinder')
const WallSurface = require('shared/domain-models/WallSurface')
const WallSegment = require('shared/domain-models/WallSegment')

class RoomFinderTool extends CompositePanZoomTool {
  // Set up observable properties
  _buildingMode = undefined
  _manualMode = undefined

  project() { return this._controller().project() }
  buildingLevel() { return this._controller().buildingLevel() }
  floorPlan() { return this.buildingLevel().floorPlan() }

  displayName() { return 'Rooms' }
  iconName() { return 'rooms' }

  _detectShape(point) {
    const finder = new RoomFinder(point, this.floorPlan().edges())
    return finder.process()
  }

  _createRoom(roomShape) {
    let room
    if (this.roomType() === 'interior') {
      const finder = new WallSegmentFinder(
        this.project(),
        this.buildingLevel(),
        this.project().defaultInteriorWallThickness(),
        WallSegment.TYPES.Interior,
        WallSurface
      )
      const floorPlanEdges = this.floorPlan().edges()
      // important to go ahead and create the room and associate it to the
      // building level so that we can associate surfaces with adjacent ones in
      // this loop
      room = new Room([])
      this.buildingLevel().addRoom(room)
      roomShape.edges().forEach(edge => {
        const finderResults = finder.find(edge, floorPlanEdges)
        finderResults.segments.forEach(segment => !segment.componentOf() ? this.buildingLevel().addInteriorWallSegment(segment) : undefined)
        finderResults.surfaces.forEach(surface => room.addSurface(surface))
      })
    } else {
      room = new ExteriorSpace(roomShape)
      this.buildingLevel().addExteriorSpace(room)
    }

    this._controller().setSelectedComponent(room)
    this._controller().snapshotProject()
  }

  pendingRoomShape() { return this._pendingRoomShape }
  previewRoomShape() { return this._previewRoomShape }

  _buildShape(point) {
    const manualMode = this.manualMode()
    const shape = this._detectShape(point)

    if (!shape) {
      return
    }

    if (manualMode) {
      const buildingMode = this.buildingMode()

      if (buildingMode === 'add') {
        this.addShape(shape)
      } else {
        this.removeShape(shape)
      }
    } else {
      this._createRoom(shape)
    }
  }

  addShape(newShape) {
    const existingShape = this.pendingRoomShape()
    if(existingShape) {
      this._pendingRoomShape = existingShape.union(newShape).first()
    } else {
      this._pendingRoomShape = newShape
    }
  }

  removeShape(newShape) {
    const existingShape = this.pendingRoomShape()
    if (existingShape) {
      this._pendingRoomShape = existingShape.difference(newShape).first()
    }
  }

  previewPoint(point) {
    this._previewRoomShape = this._detectShape(point)
  }

  manualMode() {
    return this._manualMode || false
  }
  setManualMode(newMode) {
    this.reset()
    this._manualMode = newMode
  }

  /**
   * The current mode to build out a complex polygon - should be either "add" or
   * "subtract"
   *
   * @returns {String}
   */
  buildingMode() {
    if (!this._buildingMode) {
      this._buildingMode = 'add'
    }
    return this._buildingMode
  }
  setBuildingMode(newMode) {
    this._buildingMode = newMode
  }

  /**
   * The type of room that should be built - should be "interior" or "exterior"
   *
   * @returns {String}
   */
  roomType() {
    if (!this._roomType) {
      this._roomType = 'interior'
    }
    return this._roomType
  }
  setRoomType(newType) {
    this._roomType = newType
  }

  // region actions

  confirm() {
    const roomShape = this.pendingRoomShape()
    if(roomShape) {
      this._createRoom(roomShape)
    }
    this.setBuildingMode('add')
    this.reset()
  }

  _resetShapes() {
    this._pendingRoomShape = undefined
    this._previewRoomShape = undefined
  }

  reset() {
    this._resetShapes()
    this._controller().redraw()
  }

  // region lifecycle

  deactivate() {
    this.reset()
  }

  // region mouse handlers

  mouseUp(x, y, options) {
    super.mouseUp(x, y, options)

    if (!this.didPan()) {
      const point = $P(x, y)
      this._buildShape(point)
      this._controller().redraw()
    }
  }

  mouseMove(x, y, options) {
    super.mouseMove(x, y, options)

    if (!this.didPan()) {
      const point = $P(x, y)
      this.previewPoint(point)
      this._controller().redraw()
    }
  }

  draw(context, options) {
    super.draw(context, options)
    context.updateProperties(this.modeColorProperties())

    if (!this.didPan()) {
      const preview = this.previewRoomShape()
      if(preview) context._drawPolygon(preview)
    }

    const pending = this.pendingRoomShape()
    // No matter what mode we're in, we want the pending shape to be in the add color
    context.updateProperties(this._addModeColorProperties())

    if (pending) context._drawPolygon(pending)
  }

  modeColorProperties() {
    if (this.buildingMode() === 'add') {
      return this._addModeColorProperties()
    }

    return this._removeModeColorProperties()
  }

  _addModeColorProperties() {
    return {
      strokeStyle: '#F5EA25',
      fillStyle: '#FFF86E44'
    }
  }

  _removeModeColorProperties() {
    return {
      strokeStyle: '#e14e4e',
      fillStyle: '#e2727244'
    }
  }
}
module.exports = RoomFinderTool
