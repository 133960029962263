class EdgeConnectedSegmentFinder {
  /**
   * Finds all parallel, connected segments to the given segment and the
   * non-parallel endpoints of the connected run of segments
   *
   * @param {WallSegment} segment
   */
  process(segment) {
    const results = { parallelSegments: [], nonParallelEndpoints: [] }
    const connectedSegments = segment.connections().flatMap(connection => this._findConnected(connection, segment))

    connectedSegments.forEach(({ parallelSegments, nonParallelEndpoints }) => {
      results['parallelSegments'].fastMerge(parallelSegments)
      results['nonParallelEndpoints'].fastMerge(nonParallelEndpoints)
    })

    return results
  }

  _findConnected(connection, currentSegment) {
    const finalSegments = { parallelSegments: [], nonParallelEndpoints: [] }

    const currentEdge = currentSegment.edge()
    const connectedSegments = connection.segments().filter(connectedSegment => connectedSegment !== currentSegment)

    connectedSegments.forEach(connectedSegment => {
      const connectedEdge = connectedSegment.edge()

      if (connectedEdge.isParallelTo(currentEdge, 1e-3)) {
        const otherConnections = connectedSegment.connections().filter(otherConnection => otherConnection !== connection)
        finalSegments['parallelSegments'].push(connectedSegment)

        otherConnections.forEach(nextConnection => {
          const connected = this._findConnected(nextConnection, connectedSegment)
          finalSegments['parallelSegments'].fastMerge(connected['parallelSegments'])
          finalSegments['nonParallelEndpoints'].fastMerge(connected['nonParallelEndpoints'])
        })
      } else {
        const connectedEndpoint = connection.getEndpoint(connectedSegment)
        finalSegments['nonParallelEndpoints'].push(connectedEndpoint)
      }
    })

    return finalSegments
  }
}

module.exports = EdgeConnectedSegmentFinder
