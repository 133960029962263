const OpeningTool = require('./OpeningTool')
const Doorway = require('shared/domain-models/Doorway')

class DoorwayTool extends OpeningTool {
  displayName() { return 'Doorways' }
  iconName() { return 'doors' }

  _addOpening(wall, edge) {
    const doorway = new Doorway(edge)
    wall.addDoorway(doorway)
    return doorway
  }
}
module.exports = DoorwayTool
