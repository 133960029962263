const {
  ThreeMaterialLibrary,
  three: THREE
} = require("construction-designer-core/drawing-editor-3D")

const loadTexture = (name, loader) => {
  const texture = loader.load(require(`./glass-textures/${name}.jpg`))
  texture.wrapS = THREE.RepeatWrapping
  texture.wrapT = THREE.RepeatWrapping
  texture.repeat.set(0.01, 0.01)

  return texture
}

ThreeMaterialLibrary.instance().register('glass', textureLoader => {
  return new THREE.MeshPhysicalMaterial({
    transparent: true,
    transmission: 0.7,
    opacity: 1,
    reflectivity: 0.9,
    normalMap: loadTexture('normal', textureLoader)
  })
})
