const { CountLimitedMemoryVersioningStrategy } = require('construction-designer-core/drawing-editor')

class ExternalObjectVersioningStrategy extends CountLimitedMemoryVersioningStrategy {
  externalObjectCache() {
    return this.versionedProject().externalObjectCache()
  }

  async createSnapshot() {
    const { json } = await this.jsonBuilder().stringify(this.current(), this.externalObjectCache())
    this.undoVersions().push(json)
    this.limitVersions()
    this._redoVersions = undefined
    await this.versionedProject().notifyCurrentVersionChangedObservers()
  }

  async _restoreSnapshot(snapshot) {
    this.versionedProject().setCurrent(await this.objectBuilder().parse(snapshot, this.externalObjectCache()))
    await Promise.all([
      this.versionedProject().notifyCurrentVersionChangedObservers(),
      this.versionedProject().notifyCurrentVersionReplacedObservers()
    ])
  }

  async updateLatestSnapshot() {
    const { json } = await this.jsonBuilder().stringify(this.current(), this.externalObjectCache())
    const lastUndoVersionIndex = this.undoVersions().length - 1
    this.undoVersions().splice(lastUndoVersionIndex, 1, json)
    await this.versionedProject().notifyCurrentVersionChangedObservers(json)
    return json
  }
}

module.exports = ExternalObjectVersioningStrategy
