const EmptyFigure3D = require("shared/drawables/EmptyFigure3D")
const { NullDrawable } = require("construction-designer-core/drawing-editor")
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')

class CaseOpening extends ShellBuilderConstructionComponent {
  static displayClassName() {
    return 'Case Opening'
  }

  defaultFigure() {
    return new NullDrawable(this)
  }

  threeFigure() {
    return new EmptyFigure3D(this)
  }
}

module.exports = CaseOpening
