const React = require('react')
const PropTypes = require('prop-types')
const FormFromServer = require('./FormFromServer')

class DesignRelatedForm extends React.Component {
  static propTypes = {
    repository: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    formUrl: PropTypes.string.isRequired,
    designInputName: PropTypes.string.isRequired,
    successUrl: PropTypes.string,
    formTitle: PropTypes.string
  }

  async onLoadFromServer(form) {
    const designInput = form.querySelector(`input[name='${this.props.designInputName}']`)
    const { json: design } = await this.props.repository.generateProjectJSON()

    designInput.value = design
  }

  onSuccess(_doc, response) {
    const completionUrl = this.props.successUrl || response.url
    if (window.location.href.includes(completionUrl)) {
      this.props.closeModal()
    } else {
      window.location.assign(completionUrl)
    }
  }

  render() {
    const formTitle = this.props.formTitle
    const headerSection = formTitle && (
      <>
        <header className="form__header">{formTitle}</header>
        <hr className="full-width"></hr>
      </>
    )

    return (
      <FormFromServer
        url={this.props.formUrl}
        onSuccess={(doc, response) => this.onSuccess(doc, response)}
        onLoadFromServer={form => this.onLoadFromServer(form)}
        renderHeader={() => headerSection}
      />
    )
  }
}

module.exports = DesignRelatedForm
