class CenterlineConnectedSegmentFinder {
  /**
   * Finds all parallel, connected segments to the given segment and the
   * non-parallel endpoints of the connected run of segments
   *
   * @param {WallSegment} segment
   */
  process(segment) {
    const results = this._findConnected(segment)
    return results
  }

  _findConnected(currentSegment, previousSegment) {
    const finalSegments = { parallelSegments: [], nonParallelSegments: [] }

    const currentEdge = currentSegment.edge()
    const connectedSegments = currentSegment.connectedSegments().filter(segment => segment !== previousSegment)

    connectedSegments.forEach(connectedSegment => {
      const connectedEdge = connectedSegment.edge()

      if (connectedEdge.isParallelTo(currentEdge, 1e-3)) {
        this._addToCollection(finalSegments['parallelSegments'], connectedSegment)

        const connected = this._findConnected(connectedSegment, currentSegment)
        this._addToCollection(finalSegments['parallelSegments'], ...connected['parallelSegments'])
        this._addToCollection(finalSegments['nonParallelSegments'], ...connected['nonParallelSegments'])
      } else {
        this._addToCollection(finalSegments['nonParallelSegments'], connectedSegment)
      }
    })

    return finalSegments
  }

  _addToCollection(collection, ...segments) {
    segments.forEach(segment => {
      if (!collection.includes(segment)) collection.push(segment)
    })
  }
}

module.exports = CenterlineConnectedSegmentFinder
