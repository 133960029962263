const Window = require('../Window')
const IfcOpening = require('./IfcOpening')

class IfcWindow extends IfcOpening {
  convertToOpening() {
    const offsetEdge = this._findEdge()
    // NOTE: This assumes the edge is flat
    const edgeZ = offsetEdge.center().z()
    const segmentZ = this.ifcSegment().centerline().center().z()

    const sillHeight = Math.abs(edgeZ - segmentZ).inches()
    // Move the offset edge down by the sill height, putting it at the bottom of
    // the segment
    const windowEdge = offsetEdge.addZ(sillHeight.toInches())

    const window = new Window(windowEdge)
    window.setSillHeight(sillHeight)
    if (this.height()) { window.setHeight(this.height().mm()) }

    return window
  }
}

module.exports = IfcWindow
