const {
  ExtrudedPolygon,
  Point: { $P },
  Polygon,
  RelativeLocator,
  RelativeZLocator,
  LinearMeasurement
} = require('construction-designer-core/geometry')
const RoofPanelFigure = require('shared/drawables/RoofPanelFigure')
const RoofPanelFigure3D = require('shared/drawables/RoofPanelFigure3D')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')

class RoofPanel extends ShellBuilderConstructionComponent {
  static pitchOptions = [
    { label: '0.25/12', value: 0.25 / 12 }, { label: '1/12', value: 1/12 },
    { label: '2/12', value: 2/12 }, { label: '3/12', value: 3/12 },
    { label: '4/12', value: 4/12 }, { label: '5/12', value: 5/12 },
    { label: '6/12', value: 6/12 }, { label: '7/12', value: 7/12 },
    { label: '8/12', value: 8/12 }, { label: '9/12', value: 9/12 },
    { label: '10/12', value: 10/12 }, { label: '11/12', value: 11/12 },
    { label: '12/12', value: 12/12 },
  ]

  constructor(shapeXY) {
    super()
    this._shapeXY = shapeXY
  }

  thickness() { return (4).inches() } // arbitrary
  shapeXY() { return this._shapeXY }
  vertices() { return this.shapeXY().vertices() }
  roof() { return this.componentOf() }
  moveBy(_x, _y, _z) { return false }
  buildingLevel() { return this.roof().buildingLevel() }
  previousFloor() { return this.buildingLevel().previousLevel() }
  coverage() {
    return this.shape().area() / (12 ** 2)
  }

  pitch() {
    if (this._pitch === undefined) {
      this._pitch = 4 / 12
    }
    return this._pitch
  }

  reset() {
    this._shape = undefined
    this._shape3D = undefined
    this._exteriorVerticesBeneath = undefined
    this.roof().resetGableComponents()
  }

  setPitch(newPitch) {
    this._pitch = newPitch
    this.reset()
  }

  slopeDirection() {
    if (!this._slopeDirection) {
      this._slopeDirection = $P(-1, 0, 0)
    }
    return this._slopeDirection
  }

  /**
   * Sets the panel's slope in the direction specified - should be either "left",
   * "right", "up", or "down"
   *
   * @param {String} direction
   */
  setSlopeDirection(direction) {
    if (direction === 'left') {
      this._slopeDirection = $P(-1, 0, 0)
    } else if (direction === 'right') {
      this._slopeDirection = $P(1, 0, 0)
    } else if (direction === 'up') {
      this._slopeDirection = $P(0, -1, 0)
    } else if (direction === 'down') {
      this._slopeDirection = $P(0, 1, 0)
    }
    this.reset()
  }

  slopeDirectionName() {
    if (this.slopeDirection().equals($P(-1, 0, 0))) {
      return 'left'
    } else if (this.slopeDirection().equals($P(1, 0, 0))) {
      return 'right'
    } else if (this.slopeDirection().equals($P(0, -1, 0))) {
      return 'up'
    } else if (this.slopeDirection().equals($P(0, 1, 0))) {
      return 'down'
    }
  }

  heightOffset() {
    if (!this._heightOffset) {
      this._heightOffset = (0).inches()
    }
    return this._heightOffset
  }
  setHeightOffset(heightOffset) {
    if (heightOffset.scalar) {
      this._heightOffset = heightOffset
    } else if (!isNaN(parseFloat(heightOffset))) {
      this._heightOffset = LinearMeasurement.fromString(heightOffset)
    }

    this.reset()
  }

  primaryDirection() {
    return this.shape().normal().cross(this.slopeDirection()).normalized()
  }

  shape() {
    if (!this._shape) {
      this._shape = this._defaultShape()
    }
    return this._shape
  }

  _defaultShape() {
    const shapeXY = this.shapeXY()
    const levelOffset = this.buildingLevel().baseOffset().toInches()

    const vertices = shapeXY.vertices().map(vertex => (
      new RelativeZLocator(vertex, this.heightOffsetForVertex(vertex) - levelOffset)
    ))

    return new Polygon(vertices)
  }

  heightOffsetForVertex(vertex) {
    const slope = this.pitch()
    const direction = this.slopeDirection()
    const segmentVertices = this.exteriorVerticesBeneath()

    if (segmentVertices.length === 0) return 0
    const pivotDistance = Math.max(...segmentVertices.map(segmentVertex => direction.dot(segmentVertex)))

    return (slope * (direction.dot(vertex) - pivotDistance)) - this.heightOffset().toInches()
  }

  generateGableVertices(edge) {
    const beginHeightOffset = this.heightOffsetForVertex(edge.begin())
    const endHeightOffset = this.heightOffsetForVertex(edge.end())

    if (Math.abs(beginHeightOffset) < 1 && Math.abs(endHeightOffset) < 1) return

    return [
      edge.begin(),
      edge.end(),
      edge.end().addZ(endHeightOffset),
      edge.begin().addZ(beginHeightOffset),
    ]
  }

  maxHeightOffset(edge) {
    const vertices = this.generateGableVertices(edge.xy())
    if (!vertices) return this.heightOffset().toInches()
    const zLevels = vertices.map(vertex => Math.abs(vertex.z()))
    return Math.max(...zLevels)
  }

  shapeMaxHeightOffset() {
    return Math.max(
      ...this.shape().vertices().map(vertex => Math.abs(vertex.z()))
    )
  }

  nearbyPanels() {
    const shape = this.shapeXY()
    return this.roof().roofPanels().filter(panel => (
      panel !== this && shape.intersectsShape(panel.shapeXY())
    ))
  }

  exteriorVerticesBeneath() {
    if (!this._exteriorVerticesBeneath) {
      const shape = this.shapeXY()
      const shapeBox = shape.boundingBox()

      this._exteriorVerticesBeneath = []
      this.previousFloor().exteriorWallSegments().forEach(segment => {
        const segmentBox = segment.footprint().boundingBox()
        if (!shapeBox.intersects(segmentBox)) return

        const vertices = segment.footprint().vertices()
        let underPanelVertices = vertices.filter(vertex => shape.containsPoint(vertex, 1e-3))

        if (underPanelVertices.length === 0) {
          underPanelVertices = shape.edges().flatMap(edge => [
            edge.intersectionsWithEdgeIn2D(segment.edge()).first(),
            edge.intersectionsWithEdgeIn2D(segment.oppositeEdge()).first()
          ]).filter(i => i)
        }
        this._exteriorVerticesBeneath.fastMerge(underPanelVertices)
      })
    }

    return this._exteriorVerticesBeneath
  }

  _boundingBoxesIntersect(shape1, shape2) {
    const shape1Box = shape1.boundingBox()
    const shape2Box = shape2.boundingBox()

    return shape1Box.intersects(shape2Box)
  }

  shapeXYContainsShape(polygon) {
    const shape = this.shapeXY()
    if (!this._boundingBoxesIntersect(shape, polygon)) return false

    return polygon.vertices().every(vertex => shape.containsPoint(vertex, 1))
  }

  containsSegment(segment) {
    const shape = this.shapeXY()
    const edge = segment.edge().xy()
    const oppositeEdge = segment.oppositeEdge().xy()

    if (!this._boundingBoxesIntersect(shape, segment.footprint())) return
    return shape.containsEdge(edge, 1e-3) || shape.containsEdge(oppositeEdge, 1e-3)
  }

  partiallyContainsSegment(segment) {
    const shape = this.shapeXY()

    if (!this._boundingBoxesIntersect(shape, segment.footprint())) return
    const containsVertices = segment.vertices().some(vertex => shape.containsPoint(vertex.xy(), 1e-3))

    // TODO: Should I check against the opposite edge here?
    return containsVertices || shape.edges().some(edge => edge.intersectionsWithEdgeIn2D(segment.edge()).length > 0)
  }

  findSeamPanels(segment) {
    const shape = this.shapeXY()

    return this.nearbyPanels().filter(panel => {
      const validPanelEdges = panel.shapeXY().edges().filter(nearbyEdge => (
        shape.containsPoint(nearbyEdge.begin()) || shape.containsPoint(nearbyEdge.end())
      ))

      const edge = segment.edge()
      const parallelEdges = validPanelEdges.filter(nearbyEdge => nearbyEdge.isParallelTo(edge, 1e-3))
      return parallelEdges.some(parallelEdge => (
        segment.vertices().some(vertex => parallelEdge.containsPoint(vertex.xy(), 1e-3))
      ))
    })
  }

  shape3D() {
    if (!this._shape3D) {
      this._shape3D = new ExtrudedPolygon(this.shape(), this.thickness().toInches(), this._extrudeAlongNormal.bind(this), false)
    }
    return this._shape3D
  }

  _extrudeAlongNormal(vertices, distance) {
    const direction = this.shape().normal().negated()
    const extrusion = direction.multipliedBy(distance)

    return vertices.map(vertex => new RelativeLocator(vertex, extrusion.x(), extrusion.y(), extrusion.z()))
  }

  geometry() { return this.shape() }
  delete() { return this.roof().removeRoofPanel(this) }

  defaultFigure() {
    return new RoofPanelFigure(this)
  }

  threeFigure() {
    return new RoofPanelFigure3D(this)
  }

  nonEssentialProperties() {
    return [
      ...super.nonEssentialProperties(),
      '_shape3D',
      '_shape',
      '_exteriorVerticesBeneath'
    ]
  }
}
module.exports = RoofPanel
