const DoorFigure = require('./DoorFigure')

class SwingingDoorFigure extends DoorFigure {
  _draw(context) {
    context._drawLine(this.model().hingeLocator(), this.model().outerLocator())

    context.beginPath()
    context.setLineDash([5, 2])

    const drawArcCounterclockwise = this.model().swingAngleStart() > this.model().swingAngleEnd()
    context.arc(
      this.model().hingeLocator().x(),
      this.model().hingeLocator().y(),
      this.model().width(),
      this.model().swingAngleStart(),
      this.model().swingAngleEnd(),
      drawArcCounterclockwise
    )
    context.stroke()
  }
}

module.exports = SwingingDoorFigure
