const { VersionedProject } = require('construction-designer-core/drawing-editor')
const ExternalObjectVersioningStrategy = require('shared/domain-models/ExternalObjectVersioningStrategy')

class ShellBuilderVersionedProject extends VersionedProject {
  constructor(externalObjectCache, ...args) {
    super(...args)
    this._externalObjectCache = externalObjectCache
  }

  externalObjectCache() {
    return this._externalObjectCache
  }

  versionManager() {
    if (!this._versionManager) {
      this._versionManager = new ExternalObjectVersioningStrategy(this)
    }
    return this._versionManager
  }

  isTemplate() {
    return this._metadata.template
  }
}

module.exports = ShellBuilderVersionedProject
