const React = require('react')
const PropTypes = require('prop-types')
const RadioSelect = require('components/RadioSelect')
const WallSegment = require('shared/domain-models/WallSegment')

class PlaceWallToolPanel extends React.Component {
  static propTypes = {
    tool: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    const tool = props.tool

    this.state = { tool }
  }

  tool() { return this.state.tool }

  render() {
    return (
      <div>
        <RadioSelect
          getProperty={() => this.tool().wallType()}
          setProperty={newType => this.tool().setWallType(newType)}
          label="Wall Type"
          options={[{ value: WallSegment.TYPES.Interior, label: 'Interior' }, { value: WallSegment.TYPES.Exterior, label: 'Exterior' }]}
        />
      </div>
    )
  }
}
module.exports = PlaceWallToolPanel
