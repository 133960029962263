const { Point: { $P }, PolygonWithHoles } = require('construction-designer-core/geometry')
const Floor = require('../Floor')
const IfcConstructionComponent = require('./IfcConstructionComponent')

class IfcSlab extends IfcConstructionComponent {
  buildingLevel() { return this.componentOf() }

  referencePoint() {
    // NOTE: Again, this assumes that the plan's units are in mm.
    return this.translation().addZ(this.buildingLevel().baseOffset().toMM())
  }

  convertToFloor() {
    const rotatedVertices = this._rotateVertices(this.vertices(), this.rotation())
    const translatedVertices = rotatedVertices.map(vertex => vertex.add(this.referencePoint()))

    const finalVertices = this._translateToLCADSpace(translatedVertices)
    const thickness = this._findThickness(finalVertices)

    const footprintVertices = this._findHighestVertices(finalVertices)

    // NOTE: The floor expects the upper vertices for the floor, and then
    // extrudes downward to form the 3D shape
    const floor = new Floor(new PolygonWithHoles(footprintVertices))
    floor.setThickness(thickness)
    this.buildingLevel().addFloorSlab(floor)
    return floor
  }

  _findThickness(vertices) {
    return this._findExtent(vertices, $P(0, 0, -1)).inches()
  }

  _findHighestVertices(vertices) {
    // NOTE: The expectation is that these vertices have already been translated
    // to LCAD space, and -z is "up"
    return vertices.reduce((lowest, vertex) => {
      if (vertex.z().isNearTo(lowest.z, 1e-3)) {
        lowest.vertices.push(vertex)
        return lowest
      }

      if (vertex.z() < lowest.z) {
        return { z: vertex.z(), vertices: [vertex] }
      }
      return lowest
    }, { z: Infinity, vertices: [] }).vertices
  }

}

module.exports = IfcSlab
