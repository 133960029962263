const { Polygon } = require('construction-designer-core/geometry')
const { CompositeFigure } = require('construction-designer-core/drawing-editor')
const { Composite3DFigure } = require('construction-designer-core/drawing-editor-3D')
const GableFinder = require('shared/operations/GableFinder')
const GableColumn = require('shared/domain-models/GableColumn')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')

class Roof extends ShellBuilderConstructionComponent {
  buildingLevel() { return this.componentOf() }

  components() {
    return [
      ...this.roofPanels(),
      ...this.gableSegments(),
      ...this.gableConnections(),
      ...this.gableSurfaces(),
      ...this.gableColumns()
    ]
  }

  roofPanels() {
    if (!this._roofPanels) {
      this._roofPanels = []
    }
    return this._roofPanels
  }

  addRoofPanel(panel) {
    this.roofPanels().push(panel)
    panel.setComponentOf(this)
    this.resetGableComponents()
  }

  removeRoofPanel(panel) {
    this.roofPanels().remove(panel)
    panel.setComponentOf(undefined)
    this.resetGableComponents()
  }

  coverage() { return this.roofPanels().sum(panel => panel.coverage()) }
  gableFinder() {
    if (!this._gableFinder) {
      this._gableFinder = new GableFinder(this)
    }
    return this._gableFinder
  }

  _findGableComponents() {
    const components = this.gableFinder().process()
    this._gableSegments = components['gableSegments']
    this._gableSegments.forEach(segment => segment.setComponentOf(this))

    this._gableSurfaces = components['gableSurfaces']
    this._gableSurfaces.forEach(surface => surface.setComponentOf(this))
  }

  gableSegments() {
    if (!this._gableSegments) {
      this._findGableComponents()
    }
    return this._gableSegments
  }

  addSegment(segment) {
    this.gableSegments().push(segment)
    segment.setComponentOf(this)
  }

  removeSegment(segment) {
    this.gableSegments().remove(segment)
  }

  gableConnections() {
    const uniqueConnections = new Set(this.gableSegments().flatMap(segment => segment.connections()))
    return [...uniqueConnections].filter(connection => connection.segments().length > 1)
  }

  gableSurfaces() {
    if (!this._gableSurfaces) {
      this._findGableComponents()
    }
    return this._gableSurfaces
  }

  addSurface(newSurface) {
    newSurface.setComponentOf(this)
    this.gableSurfaces().push(newSurface)
  }

  removeSurface(surface) {
    surface.setComponentOf(undefined)
    this.gableSurfaces().remove(surface)
  }

  gableColumns() {
    if (!this._gableColumns) {
      this._gableColumns = this._defaultGableColumns()
    }
    return this._gableColumns
  }
  _defaultGableColumns() {
    const wallColumns = this.buildingLevel().previousLevel().wallColumns()

    const gableColumns = []
    wallColumns.forEach(wallColumn => {
      const shape = wallColumn.shape()
      const panelsAbove = this.roofPanels().filter(panel => panel.shapeXYContainsShape(shape))
      const panel = this.findHighestPanel(shape.edges().first(), panelsAbove)

      if (!panel) return

      const offsetShape = new Polygon(
        shape.vertices().map(vertex => this.buildingLevel().conformToZLevel(vertex))
      )
      const gableColumn = new GableColumn(offsetShape, wallColumn, panel)
      gableColumn.setComponentOf(this)
      gableColumns.push(gableColumn)
    })
    return gableColumns
  }

  removeColumn(column) {
    this.gableColumns().remove(column)
  }

  reset() {
    this.resetGableComponents()
    this.roofPanels().forEach(panel => panel.reset())
  }

  resetGableComponents() {
    this._gableSegments = undefined
    this._gableSurfaces = undefined
    this._gableColumns = undefined
    this._gableFinder = undefined
  }

  shapeXY() {
    const panelShapes = this.roofPanels().map(panel => panel.shapeXY())
    const firstShape = panelShapes.shift()

    let shapeXY = [firstShape]
    if (panelShapes.length > 0) shapeXY = firstShape.union(...panelShapes)

    return shapeXY
  }

  findHighestPanel(edge, panels) {
    let panel = panels.first()
    if (panels.length > 1) {
      panel = panels.reduce((highest, current) => {
        const currentZ = current.maxHeightOffset(edge)

        if (currentZ > highest.zLevel) return { panel: current, zLevel: currentZ }
        return highest
      }, { panel: undefined, zLevel: -Infinity }).panel
    }

    return panel
  }

  defaultFigure() {
    return CompositeFigure.withModel(this)
  }

  threeFigure() {
    return Composite3DFigure.withModel(this)
  }

  nonEssentialProperties() {
    return [
      ...super.nonEssentialProperties(),
      '_gableFinder'
    ]
  }
}

module.exports = Roof
