const React = require('react')
const PropTypes = require('prop-types')

class RoofToolPanel extends React.Component {
  static propTypes = {
    tool: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    const tool = props.tool

    this.state = { tool }
  }

  tool() { return this.state.tool }
  _reset() { this.tool().reset() }
  _undoLastEdge() { this.tool().undoLastEdge() }

  _renderActionButtons() {
    return (
      <>
        <div className="flex justify-center">
          <button className="btn btn--small btn--outline full-width" onClick={this._undoLastEdge.bind(this)}>Undo Last Edge</button>
        </div>
        <div className="flex justify-center">
          <button className="btn btn--small btn--outline full-width" onClick={this._reset.bind(this)}>Reset All Edges</button>
        </div>
      </>
    )
  }

  render() {
    return (
      <>
        {this._renderActionButtons()}
      </>
    )
  }
}

module.exports = RoofToolPanel
