const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')
const {
  Point: { $P },
  Polygon
} = require('construction-designer-core/geometry')

const DoorFigure3D = require('../drawables/DoorFigure3D')

const HANDEDNESS = require('shared/domain-models/option-sets/HANDEDNESS')
const EditableProperty = require('./EditableProperty')

class Door extends ShellBuilderConstructionComponent {
  static defaultSwingAngle() {
    return (90).degreesToRadians()
  }

  static displayClassName() {
    // subclasses should override this
    return 'Generic Door'
  }

  constructor(handedness = HANDEDNESS.Right) {
    super()

    this._handedness = handedness
  }

  defaultDisplayProperties() {
    const handedness = this.constructor.HANDEDNESS

    return [
      new EditableProperty(
        this,
        'Handedness',
        {
          type: 'radioselect',
          choices: Object.keys(handedness).map(key => ({ label: key, value: handedness[key] }))
        }
      )
    ]
  }

  width() {
    return this.edge().length()
  }

  handedness() {
    return this._handedness
  }

  setHandedness(newHandedness) {
    this._handedness = newHandedness
  }

  leftHanded() {
    return this.handedness() === HANDEDNESS.Left
  }

  rightHanded() {
    return this.handedness() === HANDEDNESS.Right
  }

  geometry() {
    return this.shape()
  }

  shape() {
    return new Polygon(this.vertices())
  }

  height() {
    return this.componentOf().height().toInches()
  }

  primaryDirection() {
    return $P(0, 0, 1)
  }

  thickness() {
    return (1.5).inches()
  }

  setEdge(edge) {
    this._edge = edge
  }

  edge() {
    return this._edge || this.componentEdge().shiftedAlongNormalBy((this.componentOf().thickness().toInches() / 2))
  }

  componentEdge() {
    return this.componentOf().edge()
  }

  threeFigure() {
    return new DoorFigure3D(this)
  }
}

Door.HANDEDNESS = HANDEDNESS

module.exports = Door
