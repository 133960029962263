const DoorFigure3D = require('./DoorFigure3D')
const {
  three: THREE,
} = require('construction-designer-core/drawing-editor-3D')

class SlidingGlassDoorFigure3D extends DoorFigure3D {
  constructor(slidingGlassDoor) {
    super(slidingGlassDoor)

    this._frameGeometries = slidingGlassDoor.frameShapes3D()
    this._paneGeometries = slidingGlassDoor.paneShapes3D()
  }
  materialNames() { return ['sgd-frame', 'glass'] }

  boundingCube() { return this.model().boundingCube() }

  _prepareGeometry(bufferGeometry) {
    bufferGeometry.computeFaceNormals()
    bufferGeometry.computeVertexNormals()
    bufferGeometry.computeBoundsTree()
  }

  _threeMeshFigure() {
    const [frameMaterial, glassMaterial] = this._materials()

    const group = new THREE.Group()

    this._frameGeometries.forEach(frameGeometry => {
      this._prepareGeometry(frameGeometry)
      const mesh = new THREE.Mesh(frameGeometry, frameMaterial)
      group.add(mesh)
    })

    this._paneGeometries.forEach(paneGeometry => {
      this._prepareGeometry(paneGeometry)
      const mesh = new THREE.Mesh(paneGeometry, glassMaterial)
      mesh.receiveShadow = false
      mesh.castShadow = false
      group.add(mesh)
    })

    group.figure = this
    this._mesh = group
    return group
  }
}

module.exports = SlidingGlassDoorFigure3D
