const { Shape3DFigure } = require("construction-designer-core/drawing-editor-3D")

class DoorFigure3D extends Shape3DFigure {
  materialNames() { return ['wood-door'] }

  shape3D() {
    return this.model().shape3D()
  }

  boundingCube() {
    return this.model().boundingCube()
  }
}
module.exports = DoorFigure3D
