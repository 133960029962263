const {
  three: THREE,
  ThreeGeometryFigure
} = require('construction-designer-core/drawing-editor-3D')

class DrywallFigure3D extends ThreeGeometryFigure {
  materialNames() { return this.model().materialNames() }

  _materials() {
    const material = super._materials().clone() // returns a single material
    material.color = new THREE.Color(this.model().color())
    return material
  }
}

module.exports = DrywallFigure3D
