const { simpleObserver } = require('construction-designer-core/standard-utilities')

class CompositeEditableProperty {
  constructor(name, type, editableProperties) {
    this._name = name
    this._type = type
    this._editableProperties = editableProperties
  }

  name() { return this._name }
  type() { return this._type }

  label() {
    if (!this._label) {
      this._label = this.name()

      const editableProperties = this.editableProperties()
      const firstLabel = editableProperties.first().label()

      if (editableProperties.every(property => property.label() === firstLabel)) {
        this._label = firstLabel
      }
    }
    return this._label
  }

  key() {
    if (!this._key) {
      this._key = this.name().split(' ').join('-').toLowerCase()
    }
    return this._key
  }

  editableProperties() { return this._editableProperties }
  // Used to keep the class signature the same
  displayProperties() { return this.editableProperties() }
  editable() { return true }
  update() { this.notifyExternalChangeObservers() }

  disabled() {
    return this.editableProperties().some(property => property.disabled())
  }

  get() {
    const values = this.editableProperties().map(property => property.get())
    const uniqueValues = values.reduce((uniqueValues, currentValue) => {
      // If the current value is included in my unique values, then don't add it agin
      if (uniqueValues.some(uniqueValue => this._isEqual(uniqueValue, currentValue))) {
        return uniqueValues
      }

      return [currentValue, ...uniqueValues]
    }, [])

    // Only return something if all the values are the same
    if (uniqueValues.length === 1) return uniqueValues.first()
    return ''
  }

  set(value) {
    const propertiesSet = this.editableProperties().map(property => property.set(value))
    return propertiesSet.every(sucessfullySet => sucessfullySet === true)
  }

  toggle() {
    const propertiesToggled = this.editableProperties().map(property => property.toggle())
    return propertiesToggled.every(toggled => toggled === true)
  }

  validate(isValidUnit, unit) {
    const propertiesValid = this.editableProperties().map(property => property.validate(isValidUnit, unit))
    return propertiesValid.every(validState => validState === true)
  }

  // region options

  options() {
    if (!this._options) {
      this._options = this._defaultOptions()
    }
    return this._options
  }

  _defaultOptions() {
    const optionSets = this.editableProperties().map(property => property.options())
    const sharedProperties = {}

    optionSets.forEach(set => {
      Object.entries(set).forEach(([optionName, optionValue]) => {
        // If the given option has already been set in the list of shared
        // options, then move on to the next option
        if (sharedProperties[optionName] !== undefined) return

        const allEqual = optionSets.every(set => this._isEqual(set[optionName], optionValue))
        // If the properties all have _direct_ equality, then we add the current
        // option to sharedProperties and move on to the next option
        if (allEqual) {
          sharedProperties[optionName] = optionValue
          return
        }

        if (Array.isArray(optionValue)) {
          const intersection = this._findArrayIntersection(optionSets.map(set => set[optionName]))
          if (intersection.length > 0) { sharedProperties[optionName] = intersection }
          return
        }
      })
    })

    return sharedProperties
  }

  _isEqual(thing1, thing2) {
    if (thing1 === thing2) return true
    if (thing1.equals?.(thing2)) return true

    if (typeof thing1 === 'object' && typeof thing2 === 'object') {
      const properties1 = Object.keys(thing1)
      const properties2 = Object.keys(thing2)

      if (properties1.length === properties2.length) {
        return properties1.every(property => (
          this._isEqual(thing1[property], thing2[property])
        ))
      }
    }

    return false
  }

  _findArrayIntersection(arrays) {
    const totalArrays = arrays.length
    const values = arrays.flat()

    const intersection = []

    values.forEach(currentValue => {
      // NOTE: Can't use #includes here because intersection might include objects
      if (intersection.some(value => this._isEqual(value, currentValue))) return

      const occurrences = values.sum(value => this._isEqual(value, currentValue) ? 1 : 0)
      if (occurrences === totalArrays) { intersection.push(currentValue )}
    })

    return intersection
  }

  // endregion options
}

simpleObserver(CompositeEditableProperty, 'externalChange')
module.exports = CompositeEditableProperty
