const { Drawing } = require('construction-designer-core/drawing-editor')

class FloorPlanDrawing extends Drawing {
  // This drawing gets set up with a building level figure and a floor plan
  // figure, which by default uses the StaticFigureStrategy. However, in our
  // case, we do want the descendant figures to update, so we override this to
  // ignore the strategy's way of updating
  updateFigures() {
    this.figures().forEach(figure => figure.updateFigures())
  }
}

module.exports = FloorPlanDrawing
