const FloorPlan = require('./FloorPlan')
const {
  Cube
} = require('construction-designer-core/geometry')

const ElevationFigureXZ = require('shared/drawables/ElevationFigureXZ')

class ElevationXZ extends FloorPlan {
  static withParts(parts) {
    const elevationPlan = new this()
    elevationPlan.setParts(parts)
    elevationPlan.setYLevel(0)
    return elevationPlan
  }

  static mapping() {
    return (x, y, _z) => [x, 0, -y]
  }

  yLevel() { return this._yLevel }
  setYLevel(y) {
    this.vertices().forEach(v => v._y = y )
    this._yLevel = y
  }

  setZLevel() {}

  defaultFigureXZ() {
    return ElevationFigureXZ.withModel(this)
  }

  boundingCube() {
    return new Cube(0, 0, 0, 0, 0, 0)
  }
}
module.exports = ElevationXZ
