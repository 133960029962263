const { Polygon } = require('construction-designer-core/geometry')
const IfcConstructionComponent = require('./IfcConstructionComponent')

class IfcOpening extends IfcConstructionComponent {
  ifcSegment() { return this.componentOf() }
  height() { return this._height }

  _findEdge() {
    const vertices = this.vertices()
    // These are the vertices rotated and translated, but all relative to the segment
    const locallyRotated = this._rotateVertices(vertices, this.rotation())
    const locallyTranslated = locallyRotated.map(vertex => vertex.add(this.translation()))

    const ifcSegment = this.ifcSegment()
    const rotatedVertices = this._rotateVertices(locallyTranslated, ifcSegment.referenceRotation())
    const translatedVertices = rotatedVertices.map(vertex => vertex.add(ifcSegment.referencePoint()))
    const finalVertices = this._translateToLCADSpace(translatedVertices)

    const centerline = this.ifcSegment().centerline()
    const segmentNormal = centerline.normal()

    const zOffset = Math.max(...finalVertices.map(vertex => vertex.z()))
    // NOTE: If the doorway isn't an axis-aligned shape, this will break
    const footprint = new Polygon(finalVertices).boundingBox()

    const edges = footprint.edges()
    return edges.find(edge => edge.normal().equals(segmentNormal.negated(), 1e-3)).addZ(zOffset).reversed()
  }

  convertToOpening() {
    // Subclasses should override
  }
}

module.exports = IfcOpening
