const WallSegmentTool = require('./WallSegmentTool')
const WallSegmentFinder = require('shared/operations/WallSegmentFinder')
const WallSurface = require('shared/domain-models/WallSurface')
const Room = require('shared/domain-models/Room')
const WallSegment = require('shared/domain-models/WallSegment')

class WallSurfaceTool extends WallSegmentTool {
  displayName() { return 'Interior Walls' }
  iconName() { return 'interior-wall' }

  _existingWallEndpoints() {
    return this.buildingLevel().interiorWallSurfaces().flatMap(surface => [surface.edge().begin(), surface.edge().end()])
  }

  _defaultThickness() { return this.project().defaultInteriorWallThickness() }

  finder() {
    if (!this._finder) {
      this._finder = new WallSegmentFinder(
        this.project(),
        this.buildingLevel(),
        this.project().defaultInteriorWallThickness(),
        WallSegment.TYPES.Interior,
        WallSurface
      )
    }
    return this._finder
  }

  _resetCachedPoints() {
    super._resetCachedPoints()
    // TODO: Implement some smartness to figure out when to wipe the finder,
    // instead of just deleting it every time reset is called
    this._finder = undefined
    this._pendingRoom = undefined
  }

  _addWallEdge(edgeAndMetadata) {
    const { edge, reversed } = edgeAndMetadata

    const allEdges = this.buildingLevel().floorPlan().edges()
    const { segments, surfaces } = this.finder().find(edge, allEdges)
    const surface = surfaces.first() // only one
    this._updateRooms(surface)

    segments.forEach(newSegment => {
      if (!newSegment.componentOf()) { this.buildingLevel().addInteriorWallSegment(newSegment) }
    })

    this._controller().setSelectedComponent(surface)
    this._controller().snapshotProject()
  }

  _finalizeLoop() {
    this._controller().setSelectedComponent(this._pendingRoom)
    super._finalizeLoop()
  }

  _updateRooms(newSurface) {
    if (this._pendingRoom) {
      this._pendingRoom.addSurface(newSurface)
    } else {
      const newEdge = newSurface.edge()
      const existingSurfaces = this.buildingLevel().interiorWallSurfaces()
      const connectedSurface = existingSurfaces.find(surface => (
        surface.edge().end().equals(newEdge.begin()) || surface.edge().begin().equals(newEdge.end())
      ))

      if (connectedSurface) {
        const room = connectedSurface.componentOf()
        this._pendingRoom = room
        room.addSurface(newSurface)
      } else {
        this._pendingRoom = new Room([newSurface])
        this.buildingLevel().addRoom(this._pendingRoom)
      }
    }

    return newSurface
  }
}

module.exports = WallSurfaceTool
