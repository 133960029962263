const {
  Point: { $P },
  Rectangle
} = require('construction-designer-core/geometry')
const LabelFigure = require('shared/drawables/LabelFigure')
const {
  ConstructionComponent
} = require('construction-designer-core/construction')
const EmptyFigure3D = require('shared/drawables/EmptyFigure3D')
const { convertPointTo, convertNumberTo } = require('shared/helpers/unitConverters')

class Label extends ConstructionComponent {
  constructor(locator, text, rotation = 0, align = 'left', fontSize = 10) {
    super()
    this._locator = locator
    this._text = text
    this._rotation = rotation
    this._align = align
    this._fontSize = fontSize
  }

  locator() { return this._locator }
  text() { return this._text }
  setText(newText) {
    this._text = newText
  }
  rotation() { return this._rotation }
  fontSize() { return this._fontSize }
  setFontSize(newSize) {
    this._fontSize = newSize
  }
  textAlign() { return this._align }

  // TODO: Make this more accurate
  boundingBox() { return new Rectangle(this.locator(), undefined, 0, 0) }

  static fromDXF(entity, baseUnit = 'inch', mapping) {
    const [referenceX, referenceY, referenceZ] = mapping(entity.startPoint.x, entity.startPoint.y, entity.startPoint.z)
    const referencePoint = convertPointTo($P(referenceX, referenceY, referenceZ), baseUnit, 'inch')

    const rotation = (entity.rotation || 0).degreesToRadians()
    let point, alignment
    // commonly labels are positioned top-center with a corresponding reference point.
    // Eventually we may need to support other positioning modes--the DXF format supports
    // fifteen alignment combinations, even just for simple TEXT entities.
    if(entity.endPoint && entity.hAlign === 1 && entity.vAlign === 3) {
      const translation = $P(0, convertNumberTo(entity.textHeight, baseUnit, 'inch')).rotatedBy($P(0, 0, 0), -rotation)

      const [x, y, z] = mapping(entity.endPoint.x, entity.endPoint.y, entity.endPoint.z)
      point = convertPointTo($P(x, y, z), baseUnit, 'inch').add(translation)

      alignment = 'center'
    } else {
      point = referencePoint
      alignment = 'left'
    }

    return new this(
      point,
      entity.text,
      -rotation,
      alignment,
      convertNumberTo(entity.textHeight, baseUnit, 'inch') * 1.4
    )
  }

  defaultFigure() {
    return new LabelFigure(this)
  }

  threeFigure() {
    return new EmptyFigure3D()
  }
}
module.exports = Label
