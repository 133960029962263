const { Polygon, RelativeLocator } = require('construction-designer-core/geometry')
const WallSurface = require('shared/domain-models/WallSurface')
const WallConnection = require('shared/domain-models/WallConnection')
const cutEdgeByShape = require('shared/helpers/cutEdgeByShape')
const BuildingContext = require('shared/domain-models/BuildingContext')
const WallAndSurfaceBuilder = require('./WallAndSurfaceBuilder')
const EdgeWallSegment = require('shared/domain-models/EdgeWallSegment')
const RoomHelper = require('shared/helpers/RoomHelper')
const makeSurfaceEdge = require('shared/helpers/makeSurfaceEdge')

// A service object to build an interior wall segment and surfaces, and manage
// its intersections with other wall segments/connections/surfaces, given a
// building level and a pre-aligned wall centerline

class EdgeWallAndSurfaceBuilder extends WallAndSurfaceBuilder {
  process() {
    const centerline = this.centerline()
    const wallThickness = this.thickness().toInches()

    const wallEdge = centerline.shiftedAlongNormalBy(-wallThickness / 2)
    const leftSurfaceEdge = wallEdge.reversed().snapshot()
    const rightSurfaceEdge = wallEdge.shiftedAlongNormalBy(wallThickness).snapshot()

    // build box from wall edges
    const proposedFootprint = new Polygon([
      leftSurfaceEdge.begin(),
      leftSurfaceEdge.end(),
      rightSurfaceEdge.begin(),
      rightSurfaceEdge.end()
    ])

    const centerlines = this._splitCenterline(proposedFootprint, centerline)
    const newSegments = centerlines.map(centerline => this.processSegmentCenterline(centerline))

    return {
      segments: newSegments
    }
  }

  processSegmentCenterline(centerline) {
    const buildingLevel = this.buildingLevel()
    const wallThickness = this.thickness().toInches()

    const wallEdge = centerline.shiftedAlongNormalBy(-wallThickness / 2)
    const leftSurfaceEdge = wallEdge.reversed().snapshot()
    const rightSurfaceEdge = wallEdge.shiftedAlongNormalBy(wallThickness).snapshot()
    const edgeDirection = centerline.vector().normalized()

    const roomHelper = new RoomHelper(buildingLevel, this.tolerance())
    // important that this happens before we start changing walls/connections/surfaces
    const room = roomHelper.findOrCreateRoom(leftSurfaceEdge, rightSurfaceEdge)

    const beginConnection = (
      this._findExistingConnectionForEndpoint(centerline.begin(), edgeDirection.negated()) ||
      this._createWallConnectionForEndpoint(centerline.begin(), edgeDirection.negated())
    )
    const endConnection = (
      this._findExistingConnectionForEndpoint(centerline.end(), edgeDirection) ||
      this._createWallConnectionForEndpoint(centerline.end(), edgeDirection)
    )

    const connections = [beginConnection, endConnection].filter(item => item)
    const connectionFootprints = connections.map(connection => connection.footprint())

    const attachedSegments = connections.flatMap(connection => connection.segments())
    const attachedFootprints = attachedSegments.map(segment => segment.footprint().surface())

    const shortenedWallEdge = this._shortenEdge(wallEdge.snapshot(), connectionFootprints)
    const shortenedLeftEdge = this._shortenEdge(leftSurfaceEdge, attachedFootprints)
    const shortenedRightEdge = this._shortenEdge(rightSurfaceEdge, attachedFootprints)

    // TODO: if we don't have a connection at one of those ends... we need to shorten the wall and place a connection.
    const segment = new EdgeWallSegment(shortenedWallEdge, this.wallType(), this.thickness())
    buildingLevel.addSegment(segment)

    if (beginConnection) { beginConnection.attach(segment, 'begin') }
    if (endConnection) { endConnection.attach(segment, 'end') }

    const buildingContext = new BuildingContext(buildingLevel, room)

    const DynamicWallSurfaceClass = segment.wallSurfaceClass();
    [
      // We always want the surface edge opposite the center line's normal to be an interior surface
      new WallSurface(makeSurfaceEdge(shortenedLeftEdge, buildingLevel)),
      new DynamicWallSurfaceClass(makeSurfaceEdge(shortenedRightEdge, buildingLevel))
    ].forEach(surface => {
      segment.addSurface(surface)
      surface.addToContext(buildingContext)
    })

    roomHelper.redistributeSpace(room)
    return segment
  }

  // This will splice up the supplied centerline into pieces that
  // should correspond to new wall segments
  _splitCenterline(proposedFootprint, centerline) {
    const extendedFootprints = []
    this.buildingLevel().wallSegments().forEach(wallSegment => {
      const segmentFootprint = wallSegment.footprint().surface()
      const segmentIntersects = segmentFootprint.xy().intersectsShape(
        proposedFootprint.xy()
      )

      if (!segmentIntersects) return

      extendedFootprints.push(wallSegment.extendedFootprint(this.thickness().toInches()))
    })

    const finalEdges = extendedFootprints.reduce((edges, footprint) => (
      edges.flatMap(edge => {
        const edges = cutEdgeByShape(footprint, edge)
        const significantEdges = edges.filter(edge => edge.length() > 2)

        return significantEdges.length === 0 ? [edge] : significantEdges
      })
    ), [centerline])

    return finalEdges
  }

  /**
   * @param {Locator} edgeEndpoint
   * @param {Locator} edgeDirection - A normalized vector representing an extension of the edge
   * @returns {WallConnection|undefined}
   */
  _findExistingConnectionForEndpoint(edgeEndpoint, edgeDirection) {
    const halfThickness = this.thickness().toInches() / 2
    const checkingBox = edgeEndpoint.expandedBy(halfThickness)

    const connection = this.buildingLevel().wallConnections().find(connection => (
      connection.footprint().intersectsShape(checkingBox, this.tolerance())
    ))

    if (!connection) return

    const attachedSurfaces = connection.segments().flatMap(segment => segment.surfaces())
    const nearSurface = attachedSurfaces.find(surface => (
      surface.edge().containsPoint(edgeEndpoint, this.tolerance())
    ))

    if (nearSurface) {
      const width = this.thickness().toInches()
      const depth = width // may be too little or too much, but it's the default

      const connectionCenterline = edgeEndpoint.to(
        edgeEndpoint.add(edgeDirection.multipliedBy(depth))
      )
      const normal = connectionCenterline.normal()

      const connectionFootprint = new Polygon([
        connectionCenterline.begin().add(normal.multipliedBy(-width / 2)),
        connectionCenterline.begin().add(normal.multipliedBy(width / 2)),
        connectionCenterline.end().add(normal.multipliedBy(width / 2)),
        connectionCenterline.end().add(normal.multipliedBy(-width / 2))
      ])

      const surfaceEdge = nearSurface.edge()
      const newSurfaceEdges = cutEdgeByShape(connectionFootprint, surfaceEdge).map(edge => (
        makeSurfaceEdge(edge, this.buildingLevel())
      ))

      // don't need to check orientation, since we're using the surface's orientation as edge-space basis
      const truncatedSurfaceEdge = newSurfaceEdges[0]
      const remainderSurfaceEdge = newSurfaceEdges[1] // may not exist, if surface was truncated instead of split

      nearSurface.setEdge(truncatedSurfaceEdge)

      if (remainderSurfaceEdge) {
        this._createSurfaceFromRemainder(nearSurface, remainderSurfaceEdge)
      }
    }

    return connection
  }

  /**
   * This generates a shape to contains the different possible connection
   * positions
   *
   * @param {number} depth
   * @param {Locator} edgeEndpoint
   * @param {Locator} edgeDirection
   * @return {Polygon}
   */
  _generateConnectionBox(depth, edgeEndpoint, edgeDirection, biDirectional = true) {
    // There are two different ways the connection could be positioned, given an
    // endpoint and direction.
    // One looks like this, where the connection is placed at the end of the edge:

    // |- - - - -|
    // |         |  Direction is "up" ^
    // |- - * - -|
    //      ^
    //    Endpoint

    // The other position looks like this, where the connection would end up
    // shortening the edge:

    //   Endpoint
    //      v
    // |- - * - -|
    // |         |  Like before, direction is "up" ^
    // |- - - - -|

    // We want to generate a shape that would contain both of these possible connections

    const width = this.thickness().toInches()

    const centerlineBegin = edgeEndpoint.add(edgeDirection.multipliedBy(biDirectional ? -depth : 0))
    const centerlineEnd = edgeEndpoint.add(edgeDirection.multipliedBy(depth))
    const boxCenterline = centerlineBegin.to(centerlineEnd)

    const normal = boxCenterline.normal()

    return new Polygon([
      boxCenterline.begin().add(normal.multipliedBy(-width / 2)),
      boxCenterline.begin().add(normal.multipliedBy(width / 2)),
      boxCenterline.end().add(normal.multipliedBy(width / 2)),
      boxCenterline.end().add(normal.multipliedBy(-width / 2))
    ])
  }

  /**
   * Split a long segment into two, and return the new segment created from the remainderEdge
   *
   * @param {Edge} remainderEdge
   * @param {WallSegment} existingSegment
   */
  _generateSplitSegment(remainderEdge, existingSegment) {
    const endingConnection = existingSegment.connections().find(connection => connection.getTerminus(existingSegment) === 'end')

    const newSegment = new existingSegment.constructor(remainderEdge, existingSegment.type(), existingSegment.thickness())
    newSegment.setHeight(existingSegment.height())

    if (endingConnection) {
      endingConnection.detach(existingSegment)
      endingConnection.attach(newSegment, 'end')
    }

    existingSegment.buildingLevel().addSegment(newSegment)
    existingSegment.reassignOpenings([newSegment])

    const newConnection = WallConnection.withSegments([existingSegment, newSegment], ['end', 'begin'])
    return { newSegment, newConnection }
  }

  /**
   * @param {Locator} edgeEndpoint
   * @param {Locator} edgeDirection - A normalized vector representing an extension of the edge
   * @returns {WallConnection | undefined}
   */
  _createWallConnectionForEndpoint(edgeEndpoint, edgeDirection) {
    const halfThickness = this.thickness().toInches() / 2
    const checkingBox = edgeEndpoint.expandedBy(halfThickness)

    const existingSegment = this.buildingLevel().wallSegments().find(wallSegment => {
      const extendedFootprint = wallSegment.extendedFootprint(this.thickness().toInches())
      return extendedFootprint.intersectsShape(checkingBox, this.tolerance())
    })

    if (!existingSegment) return

    const wallEdge = existingSegment.edge()
    const depth = existingSegment.thickness().toInches()

    const connectionBox = this._generateConnectionBox(depth, edgeEndpoint, edgeDirection)
    const buildingLevel = this.buildingLevel()

    const newWallEdges = cutEdgeByShape(connectionBox, wallEdge).map(edge => {
      const relativeBegin = RelativeLocator.makeRelativeTo(edge.begin(), buildingLevel.zLevelLocator())
      const relativeEnd = RelativeLocator.makeRelativeTo(edge.end(), buildingLevel.zLevelLocator())

      return relativeBegin.to(relativeEnd)
    })

    const truncatedWallEdge = newWallEdges[0] || wallEdge
    const remainderWallEdge = newWallEdges[1] // may not exist, if wall was truncated, not split

    existingSegment.setEdge(truncatedWallEdge) // truncated

    let newSegment, newConnection
    if (remainderWallEdge) {
      ({ newSegment, newConnection } = this._generateSplitSegment(remainderWallEdge, existingSegment))
    } else {
      existingSegment.reassignOpenings()
      const connectionTerminal = connectionBox.containsPoint(wallEdge.begin(), this.tolerance()) ? 'begin' : 'end'

      newConnection = WallConnection.withSegments([existingSegment], [connectionTerminal])
    }

    // handle the surfaces...
    const nearSurfaces = existingSegment.surfaces().filter(surface => (
      surface.edge().segmentsInsidePolygon(connectionBox, this.tolerance()).length > 0
    ))

    // This finds the surface closest in the direction of the edgeDirection
    const nearSurface = nearSurfaces.reduce((closestSurface, surface) => {
      const offset = surface.edge().begin().dot(edgeDirection)
      if (offset < closestSurface.offset) return { surface, offset }

      return closestSurface
    }, { surface: undefined, offset: Infinity }).surface

    this._handleSurface(nearSurface, connectionBox, { newSegment, existingSegment })

    return newConnection
  }
}
module.exports = EdgeWallAndSurfaceBuilder
