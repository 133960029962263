const React = require('react')
const PropTypes = require('prop-types')
const {
  Icon,
  IconFactoryContext,
} = require('construction-designer-core/drawing-editor-react')
const { sendRequest, getRequest } = require('./helpers/FetchHelper')
const ShellBuilderIconFactory = require('./ShellBuilderIconFactory')
const UploadInputStatus = require('./helpers/UploadInputStatus')
const classNames = require('classnames')

class FileUploadInput extends React.Component {
  static propTypes = {
    resourcePath: PropTypes.string.isRequired,
    uploadPath: PropTypes.string.isRequired,
    labelText: PropTypes.string.isRequired,
    inputName: PropTypes.string.isRequired,
    fileTypes: PropTypes.string.isRequired,
    abortSignal: PropTypes.object,
    removable: PropTypes.bool,
    formError: PropTypes.string,
    initialResourceId: PropTypes.number,
    onRemove: PropTypes.func,
    onStatusChange: PropTypes.func
  }

  static defaultProps = {
    onRemove: () => { },
    onStatusChange: () => { }
  }

  constructor(props) {
    super(props)

    this.state = {
      resourceId: props.initialResourceId,
      status: undefined
    }

    props.abortSignal?.addEventListener('abort', () => {
      this._deleteResourceOnServer()
    })

    this._intervalId = undefined
  }

  iconFactory() {
    if (!this._iconFactory) {
      this._iconFactory = new ShellBuilderIconFactory()
    }
    return this._iconFactory
  }

  _stopPolling() {
    clearInterval(this._intervalId)
  }

  async _delete(event) {
    event?.preventDefault()
    this._deleteResourceOnServer()

    this.setState({ resourceId: undefined })
    this.props.onRemove()
  }

  async _deleteResourceOnServer() {
    const { resourceId } = this.state
    if (resourceId) {
      this._stopPolling()
      await sendRequest('DELETE', `${this.props.resourcePath}/${resourceId}`)
    }
  }

  _setStatus(newStatus) {
    this.setState({ status: newStatus })
    this.props.onStatusChange(newStatus)
  }

  _pollStatus() {
    const pollServer = async () => {
      try {
        const { resourceId } = this.state

        // TODO: alert user if there's an error
        const response = await getRequest(`${this.props.resourcePath}/${resourceId}/status`)
        const { status } = await response.json()
        if (status !== UploadInputStatus.Processing) {
          this._stopPolling()
        }

        this._setStatus(status)
      } catch (error) {
        this._stopPolling()
      }
    }

    this._intervalId = setInterval(pollServer, 2000)
  }

  async _uploadFile(rawFile) {
    // If there isn't a resource, this doesn't do anything. If there is one,
    // it'll clean up before creating a new one
    this._deleteResourceOnServer()

    const formData = new FormData()
    formData.append('raw_file', rawFile)

    const response = await sendRequest('POST', this.props.uploadPath, formData)
    return response.json()
  }

  async _handleChangeFile(event) {
    const rawFile = event.target.files[0]
    if (!rawFile) return

    this._setStatus(UploadInputStatus.Uploading)

    const { id: resourceId, status } = await this._uploadFile(rawFile)
    this.setState({ resourceId })
    this._setStatus(status)

    if (status === UploadInputStatus.Processing) {
      this._pollStatus()
    }
  }

  _renderDeleteButton() {
    if (!this.props.removable) return ''
    const disabled = this.state.status === UploadInputStatus.Uploading

    return (
      <button
        type="button"
        title="Remove"
        className="margin-left-sm"
        disabled={disabled}
        onClick={event => this._delete(event)}
      >
        <Icon name="trash" className={classNames("flex form__remove", { 'form__remove--disabled': disabled })} />
      </button>
    )
  }

  _renderSpinner() {
    const { status } = this.state
    if (status !== UploadInputStatus.Uploading & status !== UploadInputStatus.Processing) return

    return (
      <div title="spinner" className="spinner margin-left-sm" />
    )
  }

  render() {
    const { labelText, formError, inputName } = this.props
    const { resourceId } = this.state

    const inputDisabled = this.state.status === UploadInputStatus.Uploading

    return (
      <IconFactoryContext.Provider value={this.iconFactory()}>
        <div className={classNames('input file required full-width', formError ? 'field_with_errors' : 'field_without_errors')}>
          <label className="required full-width">
            <div className="form__label">
              <abbr title="required">*</abbr>
              {` ${labelText}`}
            </div>

            <div className="flex items-center">
              <input
                className="file required form__input"
                type="file"
                accept={this.props.fileTypes}
                title={`${labelText} File Upload`}
                defaultValue={''}
                disabled={inputDisabled}
                onChange={event => this._handleChangeFile(event)}
              />
              {this._renderSpinner()}
              {this._renderDeleteButton()}
            </div>

            {formError ? <span className="error">{formError}</span> : undefined}
          </label>

          <input
            type="hidden"
            name={inputName}
            value={resourceId || ''}
          />
        </div>
      </IconFactoryContext.Provider>
    )
  }
}

module.exports = FileUploadInput
