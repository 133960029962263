const {
  Point: { $P },
  PolarCoordinate,
  Rectangle
} = require('construction-designer-core/geometry')
const { convertPointTo, convertNumberTo } = require('shared/helpers/unitConverters')

class Arc {
  constructor(locator, radius, begin, end) {
    this._locator = locator
    this._radius = radius
    this._begin = begin // integer radians
    this._end = end // integer radians
  }

  static fromDXF(entity, baseUnit = 'inch', mapping = (x, y, z) => [x, y, z], rotation = 0) {
    const [x, y, z] = mapping(entity.center.x, entity.center.y, entity.center.z)

    const locator = convertPointTo($P(x, y, z), baseUnit, 'inch')

    let beginAngle = (entity.startAngle || 0) + rotation
    let endAngle = (entity.endAngle || Math.TWO_PI) + rotation

    if(Math.sign(mapping(0, 1, 0)[1]) === -1) { // flipped Y axis
      [beginAngle, endAngle] = [-endAngle, -beginAngle]
    }

    return new Arc(
      locator,
      convertNumberTo(entity.radius, baseUnit, 'inch'),
      beginAngle,
      endAngle
    )
  }

  // TODO: calculate properly. This is the circle's bounding box
  boundingBox() {
    if(!this._boundingBox) {
      const radius = this._radius
      const center = this._locator
      const beginAngle = new PolarCoordinate(radius, this._begin)
      const endAngle = new PolarCoordinate(radius, this._end)
      const coordinates = [beginAngle.add(center), endAngle.add(center)]

      const startQuadrant = Math.ceil(beginAngle.theta() / Math.PI_2)
      const endQuadrant = Math.floor(endAngle.theta() / Math.PI_2)
      for(let i = startQuadrant; i <= endQuadrant; i++) {
        coordinates.push(new PolarCoordinate(radius, i * Math.PI_2).add(center))
      }

      const xValues = coordinates.map(v => v.x())
      const yValues = coordinates.map(v => v.y())

      const originX = Math.min(...xValues)
      const width = Math.max(...xValues) - originX
      const originY = Math.min(...yValues)
      const height = Math.max(...yValues) - originY
      this._boundingBox = new Rectangle(originX, originY, width, height)
    }
    return this._boundingBox
  }

  x() { return this.locator().x() }
  y() { return this.locator().y() }

  locator() { return this._locator }
  radius() { return this._radius }
  begin() { return this._begin }
  end() { return this._end }

  contains(x, y) {
    return false
  }

  moveBy(x, y, z) {
    return this.locator().moveBy(x, y, z)
  }
}
module.exports = Arc
