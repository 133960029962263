const EdgeConnectedSegmentFinder = require('shared/operations/EdgeConnectedSegmentFinder')

class EdgeWallMovementStrategy {
  constructor(segment) {
    this._segment = segment
  }

  segment() {
    return this._segment
  }

  finder() {
    if (!this._finder) {
      this._finder = new EdgeConnectedSegmentFinder()
    }
    return this._finder
  }

  move(x, y, z) {
    const startSegment = this.segment()
    const masterEdge = startSegment.edge()

    const { parallelSegments, nonParallelEndpoints } = this.finder().process(startSegment)

    const parallelSurfaces = [
      ...startSegment.surfaces(),
      ...parallelSegments.flatMap(startSegment => startSegment.surfaces())
    ].filter(surface => surface.edge().isParallelTo(masterEdge, 1e-3))

    const uniqueSurfaces = new Set(parallelSurfaces)

    const segments = [...parallelSegments, startSegment]
    segments.forEach(segment => segment.moveAlone(x, y, z))
    uniqueSurfaces.forEach(surface => surface.moveAlone(x, y, z))

    const uniqueConnections = new Set(segments.flatMap(segment => segment.connections()))
    uniqueConnections.forEach(connection => connection.moveBy(x, y, z))

    nonParallelEndpoints.forEach(endpoint => endpoint.moveBy(x, y, z))
  }
}

module.exports = EdgeWallMovementStrategy
