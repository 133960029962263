const { EdgeHandle } = require("construction-designer-core/drawing-editor")
const {
  NormalToEdgeLocator,
  Polygon,
  ProportionalEdgeLocator
} = require('construction-designer-core/geometry')
const SystemStrategyService = require('shared/helpers/SystemStrategyService')

class WallSurfaceHandle extends EdgeHandle {
  constructor(wallSurface, figure) {
    super(wallSurface.edge(), figure)
    this._model = wallSurface
  }

  selectableComponent() {
    return this._model
  }

  tolerance(scale) {
    return Math.min(Math.sqrt(scale), 1)
  }

  systemStrategy() {
    if (!this._systemStrategy) this._systemStrategy = SystemStrategyService.strategy()
    return this._systemStrategy
  }

  contains(x, y, scale) {
    const { selectable } = this.systemStrategy().configOptions()['wallSurface']
    if (!selectable) return false

    return super.contains(x, y, scale)
  }

  draw(context) {
    const scale = context.runningScaleX()
    const beginLocator = new ProportionalEdgeLocator(this.object(), 0)
    const endLocator = new ProportionalEdgeLocator(this.object(), 1)
    const polygon = new Polygon([
      new NormalToEdgeLocator(beginLocator, -this.tolerance(scale)),
      new NormalToEdgeLocator(beginLocator, this.tolerance(scale)),
      new NormalToEdgeLocator(endLocator, this.tolerance(scale)),
      new NormalToEdgeLocator(endLocator, -this.tolerance(scale))
    ])
    // TODO: Add defaultProperties/properties to handles
    context.fillStyle = "#FFD45D"
    context.strokeStyle = "rgba(255,255,255,0.0)"
    context.globalAlpha = 0.8
    context._drawPolygon(polygon)
  }

  drawSelected(context, _options) {
    this.draw(context)
  }

  snapToGrid() {}
  moveBy() { return false }
}

module.exports = WallSurfaceHandle
