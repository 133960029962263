const {
  NullDrawable
} = require('construction-designer-core/drawing-editor')

const {
  Composite3DFigure
} = require('construction-designer-core/drawing-editor-3D')

const { Polygon } = require('construction-designer-core/geometry')

const DXFImport = require('shared/operations/DXFImport')
const DrawnPolyline = require('shared/domain-models/DrawnPolyline')
const DrawnPolygon = require('shared/domain-models/DrawnPolygon')
const Polyline = require('shared/geometry/Polyline')
const Arc = require('shared/geometry/Arc')
const DrawnArc = require('shared/domain-models/DrawnArc')
const Label = require('shared/domain-models/Label')

const FloorPlanFigure = require('shared/drawables/FloorPlanFigure')
const EditableProperty = require('./EditableProperty')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')

class FloorPlan extends ShellBuilderConstructionComponent {
  static withParts(parts) {
    const plan = new this()
    plan.setParts(parts)
    return plan
  }

  static mapping() {
    return (x, y, z) => [x, -y, 0]
  }

  constructor(dxfPlan) {
    super()
    this._dxfPlan = dxfPlan
  }

  defaultDisplayProperties() {
    const unitOptions = this.possibleUnits().map(unit => ({ label: unit, value: unit }))

    return [
      new EditableProperty(
        this,
        'Base Unit',
        { type: 'select', choices: unitOptions }
      ),
      new EditableProperty(
        this,
        'Layer Mapping',
        { type: 'booleanmap', label: 'Imported Layer Visibility' }
      )
    ]
  }

  vertices() {
    if(!this._vertices) {
      this._vertices = this._findVerticesAndIntersections()
    }
    return this._vertices
  }

  edges() {
    if (!this._edges) {
      this._edges = this.components().flatMap(c => c.edges ? c.edges() : [])
    }
    return this._edges
  }

  _findVerticesAndIntersections() {
    const vertices = this.components().flatMap(c => c.vertices ? c.vertices() : [])
    const edges = this.edges()
    const intersections = edges.reduce((sArr, subject, sIndex) => {
      return edges.reduce((pArr, predicate, pIndex) => {
        if(pIndex > sIndex && subject.boundingBox().intersects(predicate.boundingBox())) {
          return pArr.concat(subject.intersections(predicate))
        } else {
          return pArr
        }
      }, sArr)
    }, [])

    return [...vertices, ...intersections]
  }

  dxfPlan() { return this._dxfPlan }

  baseUnit() {
    if (!this._baseUnit) {
      this._baseUnit = this.dxfPlan().unit()
    }
    return this._baseUnit
  }
  setBaseUnit(newUnit) {
    this._baseUnit = newUnit
    this._importer = undefined
    this._parts = undefined
    this._edges = undefined
  }

  possibleUnits() {
    return ['inches', 'feet', 'mm', 'meters', 'yards']
  }

  parts() {
    if (!this._parts) {
      this._parts = this.processDXF()
    }
    return this._parts
  }
  setParts(parts) {
    this._parts = parts
  }

  components() { return this.parts() }

  importer() {
    if(!this._importer) {
      const raxDXF = this.dxfPlan().object()
      this._importer = new DXFImport(raxDXF, this.baseUnit(), this.constructor.mapping())
    }
    return this._importer
  }

  layerMapping() {
    if(!this._layerMapping) {
      this._layerMapping = this.importer().layers()
    }
    return this._layerMapping
  }

  setLayerMapping(newMapping) {
    this._layerMapping = newMapping
    this._parts = undefined
    this._edges = undefined
  }

  processDXF() {
    const primitives = this.importer().process(this.layerMapping())

    if (primitives.length === 0) {
      return []
    }

    return primitives.map(object => {
      if (object instanceof Polyline) {
        return new DrawnPolyline(object)
      } else if (object instanceof Arc) {
        return new DrawnArc(object)
      } else if (object instanceof Polygon) {
        return new DrawnPolygon(object)
      } else if (object instanceof Label) {
        return object
      }
    })
  }

  zLevel() { return 1000 }

  defaultFigure() {
    return FloorPlanFigure.withModel(this)
  }

  defaultFigureXZ() {
    return new NullDrawable(this)
  }

  threeFigure() {
    return Composite3DFigure.withModel(this)
  }

  nonEssentialProperties() {
    return [
      ...super.nonEssentialProperties(),
      '_parts',
      '_importer',
      '_edges'
    ]
  }

  postRestorationAction() {
    if (this._layerMapping) {
      delete this._layerMapping._id
      delete this._layerMapping._className
    }
  }

  moveBy(_x, _y, _z) {
    return false
  }
}

module.exports = FloorPlan
