const {
  ClosedShapeFigure,
  EdgeHandle
} = require('construction-designer-core/drawing-editor')

class DoorwayFigureXZ extends ClosedShapeFigure {
  constructor(object) {
    super()
    this._object = object
    this._edge = object.edge()
  }

  model() {
    return this._object
  }

  shape() {
    return this._edge
  }

  yLevel() { return this._edge.center().y() }

  _draw(context) {
    context._drawPolygon(this.model().shapeXZ())
  }

  defaultHandles() {
    return [
      new EdgeHandle(this._edge.xz(), this)
    ]
  }

  defaultProperties() {
    return {
      strokeStyle: '#F5EA25',
      fillStyle: '#FFF86E44'
    }
  }
}
module.exports = DoorwayFigureXZ
