const { simpleObserver } = require('construction-designer-core/standard-utilities')

class ReadableProperty {
  constructor(component, name, { type, label, get, ...options } = {}) {
    this._component = component
    this._name = name
    this._type = type
    this._label = label ?? name

    if (get) this.get = get

    const nameParts = name.split(' ')
    this._getterName = `${nameParts.first().toLowerCase()}${nameParts.slice(1).join('')}`

    this._options = options
  }

  name() { return this._name }
  label() { return this._label }
  type() { return this._type }
  options() { return this._options }

  key() {
    if (!this._key) {
      this._key = this.name().split(' ').join('-').toLowerCase()
    }
    return this._key
  }

  component() { return this._component }
  editable() { return false }
  update() { this.notifyExternalChangeObservers() }

  // begin overridable via options
  get() {
    const value = this.component()[this._getterName]()
    if (this.type() === 'boolean') {
      return !!value
    }

    return value
  }
  // end overrideable

  equals(otherProperty) {
    return (
      this.name() === otherProperty.name()
      && this.type() === otherProperty.type()
      && this.label() === otherProperty.label()
      && this.editable() === otherProperty.editable()
    )
  }
}

simpleObserver(ReadableProperty, 'externalChange')
module.exports = ReadableProperty
