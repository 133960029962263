const React = require('react')
const classNames = require('classnames')
const PropTypes = require('prop-types')
const uuid = require('uuid-random')
const {
  Icon,
  IconFactoryContext,
} = require('construction-designer-core/drawing-editor-react')
const ShellBuilderIconFactory = require('./ShellBuilderIconFactory')
const UploadInputStatus = require('./helpers/UploadInputStatus')
const FileUploadInput = require('./FileUploadInput')

class PlanFields extends React.Component {
  static propTypes = {
    plans: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      plans: props.plans.map(plan => ({ ...plan, planKey: uuid() })),
      submittingForm: false
    }

    this._abortController = new AbortController()
  }

  componentWillUnmount() {
    // Abort each of the file upload inputs if this component is unmounting, and
    // the form isn't being submitted (if the modal is being closed or something)
    if (!this.state.submittingForm) this._abortController.abort()
  }

  iconFactory() {
    if (!this._iconFactory) {
      this._iconFactory = new ShellBuilderIconFactory()
    }
    return this._iconFactory
  }

  _addPlan(event) {
    event.preventDefault()
    this.setState(({ plans }) => {
      const index = plans.length
      plans.push({
        name: `Level ${index + 1}`,
        status: UploadInputStatus.Completed,
        planKey: uuid() // used for uniqueness, since new plans don't have IDs until they're sent to the server
      })
      return { plans }
    })
  }

  async _removePlan(plan) {
    this.setState(({ plans }) => {
      plans.remove(plan)
      return { plans }
    })
  }

  _setName(plan, name) {
    this.setState(({ plans }) => {
      const planToUpdate = plans.find(oldPlan => oldPlan === plan)
      if (planToUpdate) planToUpdate.name = name
      return { plans }
    })
  }

  _setStatus(plan, status) {
    this.setState(({ plans }) => {
      const planToUpdate = plans.find(oldPlan => oldPlan === plan)
      if (planToUpdate) planToUpdate.status = status
      return { plans }
    })
  }

  _renderPlan(plan, index) {
    const { name: nameError, raw_dxf: formError } = plan.errors || {}

    return (
      <div className="flex full-width justify-center" key={plan.planKey}>
        <div className={classNames('input', 'string', 'required', 'form__wrapper--project', 'flex-basis-0', nameError ? 'field_with_errors' : 'field_without_errors')}>
          <label className="string required form__label" htmlFor={`project_plans_attributes_${index}_name`}>
            <abbr title="required">*</abbr>
            &nbsp;Level Name:
          </label>
          <input
            type="text"
            id={`project_plans_attributes_${index}_name`}
            name={`project[plans_attributes][${index}][name]`}
            value={plan.name || ''}
            onChange={event => this._setName(plan, event.target.value)}
            className="string required form__input"
          />
          {nameError ? <span className="error">{nameError}</span> : undefined}
        </div>

        <div className="flex flex-basis-0 form__wrapper--project">
          <FileUploadInput
            resourcePath="api/v1/plans"
            uploadPath="/api/v1/plan_imports"
            labelText={`${plan.name} Plan (*.dxf, *.pdf):`}
            inputName={`project[plans_attributes][${index}][id]`}
            fileTypes=".dxf,.pdf"
            abortSignal={this._abortController.signal}
            removable={true}
            onRemove={() => this._removePlan(plan)}
            onStatusChange={newStatus => this._setStatus(plan, newStatus)}
            formError={formError}
            initialResourceId={plan.id}
          />
        </div>
      </div>
    )
  }

  _plansCompleted() {
    return this.state.plans.every(plan => (
      plan.status === UploadInputStatus.Completed
    ))
  }

  render() {
    const submitDisabled = !this._plansCompleted()

    return (
      <IconFactoryContext.Provider value={this.iconFactory()}>
        { this.state.plans.map((plan, index) => this._renderPlan(plan, index)) }

        <button type="button" className="btn btn--outline form__row-add" onClick={event => this._addPlan(event)}>
          <Icon name="add" className="btn__icon" />
          &nbsp;Add Level
        </button>

        <footer className="form__footer flex full-width justify-end">
          <a className="btn btn--link modal-close" href="#">Cancel</a>
          <input
            type="submit"
            name="commit"
            value="Create Project"
            className={classNames('btn', 'btn--primary', { 'btn--disabled': submitDisabled })}
            onClick={() => this.setState({ submittingForm: true })}
            data-disable-with="Create Project"
            disabled={submitDisabled}
          />
        </footer>
      </IconFactoryContext.Provider>
    )
  }
}
module.exports = PlanFields
