const PolylineFigure = require('./PolylineFigure')

class DrawnPolylineFigure extends PolylineFigure {
  constructor(model) {
    super(model.shape())
    this._model = model
  }
  model() { return this._model }
  shape() { return this.model().shape() }
  handles() {
    return []
  }

  defaultProperties() {
    return {
      lineWidth: 0.5,
      strokeStyle: '#0077CE' //'#003080'
    }
  }
}

module.exports = DrawnPolylineFigure