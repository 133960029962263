const {
  Composite3DFigure,
  ThreeGeometryFigure
} = require('construction-designer-core/drawing-editor-3D')

class WallColumnFigure3D extends Composite3DFigure {
  addToScene(scene) {
    super.addToScene(scene)
    this.selfFigure().addToScene(scene)
  }

  removeFromScene(scene) {
    super.removeFromScene(scene)
    this.selfFigure().removeFromScene(scene)
  }

  update() {
    super.update()
    this.selfFigure().update()
  }

  selfFigure() {
    if (!this._selfFigure) {
      this._selfFigure = new ThreeGeometryFigure(this.model())
    }
    return this._selfFigure
  }
}

module.exports = WallColumnFigure3D
