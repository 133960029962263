const PolylineFigure = require('./PolylineFigure')

class PolylineFigureXZ extends PolylineFigure {
  _draw(context) {
    this.shape().edges().forEach(edge => {
      const edgeXZ = edge.xz()
      context._drawLine(edgeXZ.begin(), edgeXZ.end())
    })
  }
}

module.exports = PolylineFigureXZ