const {
  Polygon,
  NormalToEdgeLocator,
  ProportionalEdgeLocator,
  LinearMeasurement,
  Point: { $P }
} = require('construction-designer-core/geometry')
const ShellBuilderConstructionComponent = require('./ShellBuilderConstructionComponent')

class WallOpening extends ShellBuilderConstructionComponent {
  /**
   * Creates a new opening from a locator, edgeDirection, and width
   *
   * @static
   * @param {Locator} locator - The position of the opening's center
   * @param {Locator} direction - A normalized vector representing the direction of the edge
   * @param {Unit} width - The intended width of the window
   * @return {WallOpening}
   */
  static fromLocator(locator, edgeDirection, width) {
    const edgeBegin = locator.add(edgeDirection.multipliedBy(-width.toInches() / 2))
    const edgeEnd = locator.add(edgeDirection.multipliedBy(width.toInches() / 2))

    const edge = edgeBegin.to(edgeEnd)
    return new this(edge)
  }

  constructor(edge) {
    super()
    this._edge = edge
  }

  contains(x, y) { return this.footprint().contains(x, y) }
  vertices() { return this.footprint().vertices() }

  moveBy(x, y) {
    const change = this._movementAlongEdge(x, y)
    return this.edge().moveBy(change.x(), change.y())
  }

  _movementAlongEdge(x, y) {
    const originalMovement = $P(x, y)
    const edgeDirection = this.edge().vector().normalized()

    const distance = edgeDirection.dot(originalMovement)

    return edgeDirection.multipliedBy(distance)
  }

  edge() {
    return this._edge
  }

  setEdge(newEdge) {
    this._edge = newEdge
    this.resetFootprint()
  }

  footprint() {
    if (!this._footprint) {
      const edge = this.edge()
      const thickness = this.thickness().toInches()
      this._footprint = new Polygon([
        edge.begin(),
        edge.end(),
        new NormalToEdgeLocator(new ProportionalEdgeLocator(edge, 1), thickness),
        new NormalToEdgeLocator(new ProportionalEdgeLocator(edge, 0), thickness)
      ])
    }
    return this._footprint
  }
  resetFootprint() { this._footprint = undefined }
  geometry() { return this.footprint() }

  width() {
    return LinearMeasurement.fromString(this.edge().length())
  }

  setWidth(newWidth) {
    const modifyEdgeLengthBy = delta => {
      const vector = this.edge().vector().normalized().multipliedBy(delta / 2)

      this.edge().begin().moveBy(-vector.x(), -vector.y(), -vector.z())
      this.edge().end().moveBy(vector.x(), vector.y(), vector.z())
    }

    if (newWidth.scalar) {
      modifyEdgeLengthBy(Math.abs(newWidth.toInches()) - this.edge().length())
    } else if (parseFloat(newWidth)) {
      modifyEdgeLengthBy(Math.abs(LinearMeasurement.fromString(newWidth).toInches()) - this.edge().length())
    }
  }

  height() {
    if (!this._height) {
      this._height = this.defaultHeight()
    }
    return this._height
  }
  defaultHeight() {
    // Subclasses should override - should be a MathJS Unit or LinearMeasurement
  }

  setHeight(newHeight) {
    if (newHeight.scalar) {
      this._height = newHeight
    } else if (parseFloat(newHeight)) {
      this._height = LinearMeasurement.fromString(newHeight)
    }
  }

  thickness() {
    return this.componentOf() ? this.componentOf().thickness() : (12).inches()
  }

  faceShape() {
    // Subclasses should override
  }

  shapeXZ() {
    return new Polygon(this.faceShape().vertices().map(point => point.xz()))
  }
}

module.exports = WallOpening
