const React = require('react')
const PropTypes = require('prop-types')

class ModelPropertyField extends React.Component {
  /**
   * @prop {Object} model - The base model that this component updates - This must contain a method for
   *                        the property passed in and  a set"PropertyName"(value) method to update that property
   * @prop {String} propertyName - The name of the property to create a field for
   * @prop {String} displayName - The optional display name to be shown in the field
   * @prop {String} type - The type of the input - defaults to 'number'
   * @prop {String} min - The min property of the input
   * @prop {String} max - The max property of the input
   * @prop {String} step - The step property of the input
   * @prop {String} wrapperClassName - The classes to be applied to the div surrounding the property field
   * @prop {String} inputClassName - The classes to be applied to the input itself
   * @prop {Object} drawingController - The current drawingController
   * @prop {Function} onChange - The function to be called when this property is updated
   */
  static propTypes = {
    model: PropTypes.object.isRequired,
    propertyName: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    type: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    step: PropTypes.string,
    wrapperClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    drawingController: PropTypes.object,
    onChange: PropTypes.func
  }

  static defaultProps = {
    type: 'number',
    wrapperClassName: '',
    inputClassName: '',
    onChange: () => {}
  }

  static getDerivedStateFromProps(props, state) {
    if (state.model !== props.model) {
      const propName = props.propertyName
      const propertyValue = props.model[propName]()

      return {
        model: props.model,
        propertyValue
      }
    }

    return null
  }

  constructor(props) {
    super(props)
    const propName = this.props.propertyName

    this.state = {
      propertyValue: props.model[propName](),
      model: props.model
    }
  }

  updateProperty(propName, event) {
    const target = event.target
    const inputType = this.props.type

    let value = inputType === 'checkbox' ? target.checked : target.value
    this.setState({ propertyValue: value })

    if (inputType === 'number') {
      value = parseFloat(value)
      if (isNaN(value)) return
    }

    // If the property name is width, this would call this.props.model.setWidth(value)
    const capitalizedProperty = this._capitalizeFirstLetter(propName)
    this.props.model[`set${capitalizedProperty}`](value)

    this.props.onChange()

    const drawingController = this.props.drawingController
    if (drawingController) {
      const figure = drawingController.drawing().figureFor(this.props.model)
      figure && figure.update && figure.update()
      drawingController.snapshotProjectAndRedraw()
    }
  }

  _generateLabel(string) {
    // Splits on capital letters, but keeps those letters in the split string
    const words = string.split(/(?=[A-Z])/)
    words.splice(0, 1, this._capitalizeFirstLetter(words.first()))
    return words.join(' ')
  }

  _capitalizeFirstLetter(string) {
    return string.replace(/^\w/, character => character.toUpperCase())
  }

  render() {
    const {
      propertyName,
      displayName = propertyName,
      wrapperClassName,
      inputClassName,
      drawingController: _drawingController,
      onChange: _onChange,
      model: _model,
      ...otherProps
    } = this.props

    const isCheckbox = this.props.type === "checkbox"
    if (isCheckbox) {
      otherProps.checked = this.state.propertyValue
    }

    return (
      <div key={propertyName} className={wrapperClassName}>
        <label
          htmlFor={propertyName} className="label--side-panel margin-right-xs">
          {this._generateLabel(displayName)}:
        </label>

        <input
          {...otherProps}
          id={propertyName}
          className={`input--side-panel ${inputClassName}`}
          value={this.state.propertyValue}
          onChange={this.updateProperty.bind(this, propertyName)}
        />
      </div>
    )
  }
}

module.exports = ModelPropertyField
