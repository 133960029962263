const React = require('react')
const PropTypes = require('prop-types')
const FormFromServer = require('./FormFromServer')
const { 'react-router-dom': { Link } } = require('construction-designer-core/drawing-editor-react')

class SignInForm extends React.Component {
  static propTypes = {
    onSignIn: PropTypes.func.isRequired,
    signUpUrl: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.state = {
      error: undefined
    }
  }

  onSuccessfulSignIn(doc) {
    const updatedCSRFToken = doc.querySelector('meta[name="csrf-token"]')?.content
    const metaTag = document.querySelector('meta[name="csrf-token"]')
    if (metaTag) metaTag.content = updatedCSRFToken
    this.props.onSignIn()
  }

  onFailureToSignIn() {
    this.setState({ error: 'Invalid email or password.' })
  }

  renderErrorMessages() {
    if (!this.state.error) return
    return <p className="flash flash--alert">{this.state.error}</p>
  }

  renderSignUpLink() {
    return (
      <>
        <hr></hr>
        <div className="margin-top-md flex justify-center">
          <span className="margin-right-xs">Don't have an account?</span>
          <Link to={this.props.signUpUrl} className="link">Sign Up</Link>
        </div>
      </>
    )
  }

  render() {
    return (
      <FormFromServer
        onSuccess={this.onSuccessfulSignIn.bind(this)}
        onFailure={this.onFailureToSignIn.bind(this)}
        url="/users/sign_in"
        renderExtraElements={this.renderSignUpLink.bind(this)}
        renderFlash={this.renderErrorMessages.bind(this)}
        renderHeader={() => <>
          <h2 className="margin-none">Sign In</h2>
          <hr className="full-width"></hr>
        </>}
      />
    )
  }
}

module.exports = SignInForm
