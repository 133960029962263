const { Project } = require('construction-designer-core/drawing-editor')
const { LinearMeasurement, Unit } = require('construction-designer-core/geometry')
const Building = require('./Building')
const ElevationXZ = require('./ElevationXZ')
const ProjectEstimation = require('shared/domain-models/ProjectEstimation')
const EditableProperty = require('./EditableProperty')
const math = require('mathjs')
const SystemStrategyService = require('shared/helpers/SystemStrategyService')

class ShellBuilderProject extends Project {
  _availableProjectEstimations = undefined
  _estimationId = undefined
  _isValid = undefined

  systemStrategy() {
    if (!this._systemStrategy) {
      this._systemStrategy = SystemStrategyService.strategy()
    }
    return this._systemStrategy
  }

  displayName() { return 'Project' }

  building() {
    if (!this._building) {
      this._building = new Building()
    }
    return this._building
  }
  setBuilding(building) {
    this._building = building
  }

  /**
   * Whether or not the project is in a valid state - used for determining if it
   * be saved automatically
   *
   * @returns {Boolean}
   */
  isValid() {
    if (this._isValid === undefined) {
      this._isValid = true
    }
    return this._isValid
  }
  setValid(value) {
    this._isValid = value
  }

  displayProperties() {
    if (!this._displayProperties) {
      this._displayProperties = [
        new EditableProperty(this, 'Default Exterior Wall Thickness', { type: 'unit', defaultUnit: 'in' }),
        new EditableProperty(this, 'Default Interior Wall Thickness', { type: 'unit', defaultUnit: 'in' }),
        new EditableProperty(this, 'Maximum Default Wall Thickness', { type: 'unit', defaultUnit: 'in' })
      ]
    }
    return this._displayProperties
  }

  // region statistics

  totalFloorArea() {
    return this.buildingLevels().reduce((totalArea, level) => (
      math.add(totalArea, level.totalFloorArea())
    ), new Unit(0, 'sqft'))
  }

  finishedFloorArea() {
    return this.buildingLevels().reduce((finishedArea, level) => (
      math.add(finishedArea, level.finishedFloorArea())
    ), new Unit(0, 'sqft'))
  }

  unfinishedFloorArea() {
    return this.buildingLevels().reduce((unfinishedArea, level) => (
      math.add(unfinishedArea, level.unfinishedFloorArea())
    ), new Unit(0, 'sqft'))
  }

  interiorWallSurfaceArea() {
    const totalArea = this.buildingLevels().sum(level => level.interiorWallSurfaceArea()).roundedTo(2)
    return new Unit(totalArea, 'sqft')
  }

  exteriorWallSurfaceArea() {
    const totalArea = this.buildingLevels().sum(level => level.exteriorWallSurfaceArea()).roundedTo(2)
    return new Unit(totalArea, 'sqft')
  }

  exteriorLinearLength() {
    return this.buildingLevels().sum(level => (
      level.exteriorLinearLength().toInches() / 12
    )).feet()
  }

  totalWindows() {
    return this.buildingLevels().sum(level => level.numberOfWindows())
  }

  totalInteriorDoorways() {
    return this.buildingLevels().sum(level => level.numberOfInteriorDoorways())
  }

  totalExteriorDoorways() {
    return this.buildingLevels().sum(level => level.numberOfExteriorDoorways())
  }

  roofCoverage() {
    const totalArea = this.buildingLevels().sum(level => level.roofCoverage()).roundedTo(2)
    return new Unit(totalArea, 'sqft')
  }

  underRoofExteriorArea() {
    const totalArea = this.buildingLevels().sum(level => level.underRoofExteriorArea()).roundedTo(2)
    return new Unit(totalArea, 'sqft')
  }

  // endregion statistics

  elevation() {
    // Currently, this isn't used - a different DXF Plan will need to be uploaded to use this
    if (!this._elevation) {
      this._elevation = new ElevationXZ()
    }
    return this._elevation
  }
  setElevation(newElevation) {
    this._elevation = newElevation
  }

  buildingLevels() {
    return this.building().levels()
  }

  components() {
    return [
      this.building()
    ]
  }

  // HACK: we should have a better way of storing/handling a separately retrieved dataset/store. Maybe a frontend store?
  availableProjectEstimations() { return this._availableProjectEstimations || [] }
  setAvailableProjectEstimations(projectEstimations) {
    this._availableProjectEstimations = projectEstimations
  }

  projectEstimation() {
    const id = this.estimationId()
    return this.availableProjectEstimations().find(estimation => estimation.id() === id ) || new ProjectEstimation()
  }

  estimationId() {
    if(this._estimationId === undefined) {
      const estimation = this.availableProjectEstimations().first()
      this._estimationId = estimation && estimation.id && estimation.id()
    }
    return this._estimationId
  }
  setEstimationId(newEstimationId) {
    this._estimationId = newEstimationId
  }

  parts() {
    return this.components()
  }

  defaultExteriorWallThickness() {
    if (!this._defaultExteriorWallThickness) {
      this._defaultExteriorWallThickness = (3.5).inches()
    }
    return this._defaultExteriorWallThickness
  }
  setDefaultExteriorWallThickness(newThickness) {
    if (newThickness.scalar) {
      this._defaultExteriorWallThickness = newThickness
    } else if (parseFloat(newThickness)) {
      this._defaultExteriorWallThickness = LinearMeasurement.fromString(newThickness)
    }
  }

  defaultInteriorWallThickness() {
    if (!this._defaultInteriorWallThickness) {
      this._defaultInteriorWallThickness = (3.5).inches()
    }
    return this._defaultInteriorWallThickness
  }
  setDefaultInteriorWallThickness(newThickness) {
    if (newThickness.scalar) {
      this._defaultInteriorWallThickness = newThickness
    } else if (parseFloat(newThickness)) {
      this._defaultInteriorWallThickness = LinearMeasurement.fromString(newThickness)
    }
  }

  /**
   * maximum autodetected thickness for the automatic tool algorithms
   **/
  maximumDefaultWallThickness() {
    if (!this._maximumDefaultWallThickness) {
      this._maximumDefaultWallThickness = (12).inches()
    }
    return this._maximumDefaultWallThickness
  }
  setMaximumDefaultWallThickness(newThickness) {
    if (newThickness.scalar) {
      this._maximumDefaultWallThickness = newThickness
    } else if (parseFloat(newThickness)) {
      this._maximumDefaultWallThickness = LinearMeasurement.fromString(newThickness)
    }
  }

  _essentialProperties() {
    // By default, properties in #nonEssentialProperties get set as
    // non-enumerable. This breaks the behavior needed for observing this
    // property, so we're overriding #_essentialProperties to not include
    // _isValid
    return super._essentialProperties().remove('_isValid')
  }

  nonEssentialProperties() {
    return [
      ...super.nonEssentialProperties(),
      '_availableProjectEstimations',
      '_displayProperties',
      '_systemStrategy'
    ]
  }
}

module.exports = ShellBuilderProject
