const { Drawable } = require('construction-designer-core/drawing-editor')

class WallConnectionFigure extends Drawable {
  constructor(model) {
    super()
    this._model = model
  }

  model() {
    return this._model
  }

  _draw(_context, _options) {
    // Do nothing
  }

  layer() { return 'Walls' }

  zLevel() { return this.model().zLevel() }

  vertices() {
    return this.model().vertices()
  }

  boundingBox() {
    return this.model().footprint().boundingBox()
  }

  contains(x, y) {
    return false
  }

  moveBy(x, y) {
    return false
  }

  defaultProperties() {
    return {
      strokeStyle: '#00000000',
      fillStyle: '#CFCFCF88'
    }
  }
}
module.exports = WallConnectionFigure
