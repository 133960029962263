const CompositeFigureXZ = require('./CompositeFigureXZ')

class WallSegmentFigureXZ extends CompositeFigureXZ {
  yLevel() { return this.model().edge().center().y() }

  _drawSelf(context, _options) {
    context._drawPolygon(this.model().shapeXZ())
  }

  // TODO: handles need to be figured out with this approach, unclear how to proceed.

  defaultProperties() {
    return {
      fillStyle: '#eeeeee22'
    }
  }
}
module.exports = WallSegmentFigureXZ
