const React = require('react')
const { DrawingEditorActionBar: { DrawingEditorActionBar }, pointerCapture } = require('construction-designer-core/drawing-editor-react')

const LayersControl = require('components/LayersControl')
const ShellBuilderZoomControl = require('components/ShellBuilderZoomControl')
const PropTypes = require('prop-types')

/**
 * A top "action bar" with undo/redo buttons, perspective links, a save button, etc.
 */
class ShellBuilderActionBar extends DrawingEditorActionBar {
  static propTypes = {
    editorModeStrategy: PropTypes.object.isRequired
  }

  _renderLayersControl() {
    const drawingController = this.props.drawingEditor.drawingController()
    if (!drawingController) return
    return <LayersControl drawingController={drawingController} />
  }

  _renderZoomControl() {
    const drawingController = this.props.drawingEditor.drawingController()
    const zoomPalette = this.props.drawingEditor.zoomPalette()
    if (!drawingController || !zoomPalette) return
    return <ShellBuilderZoomControl
             drawingController={drawingController}
             zoomPalette={zoomPalette}
           />
  }

  render() {
    return (
      <header className="action-bar" {...this.props.pointerCaptureProps}>
        <div className="action-bar__section">
          {
            this.props.editorModeStrategy.showHomeButton() &&
            <>
              {this.props.renderBackLink(this.props.drawingEditor)}

              <div className="half-height">
                <div className="vertical-divider" />
              </div>
            </>
          }

          {this.props.projectRepository.project().metadata().title}
        </div>
        <div className="action-bar__section">
          {this._renderViewLinks()}
          {this._renderLayersControl()}
        </div>
        <div className="action-bar__section">
          {this._renderZoomControl()}
          {this.props.editorModeStrategy.renderRequestQuoteButton()}
          {this.props.renderSaveButton(this.props.projectRepository)}
          {this.props.renderExtraElements(this.props.drawingEditor)}
          {this._renderDotMenu()}
        </div>
      </header>
    )
  }
}

module.exports = pointerCapture(ShellBuilderActionBar)
