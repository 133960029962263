const {
  Drawable3D,
  three: THREE
} = require('construction-designer-core/drawing-editor-3D')
const placeholderGeometry = require('shared/helpers/placeholderGeometry')


class EmptyFigure3D extends Drawable3D {
  shape3D() { return placeholderGeometry }
  boundingCube() { return placeholderGeometry.boundingCube() }
  addToScene(_scene) {}
  removeFromScene(_scene) {}
  applyLayerMapping(_mapping) {}
  layer() {}
  threeGeometry() { return placeholderGeometry }

  _threeMeshFigure() {
    const geometry = this.threeGeometry()
    const mesh = new THREE.Mesh(geometry)
    mesh.figure = this
    this._mesh = mesh
    return mesh
  }

  mesh() {
    if(!this._mesh) {
      this._mesh = this._threeMeshFigure()
    }
    return this._mesh
  }

  defaultHandles() { return [] }
  handles() { return [] }
  update() {}
}
module.exports = EmptyFigure3D
