const {
  extendObservable,
} = require('mobx')

const redefine = function (source, key) {
  const value = source[key]
  delete source[key]
  return value
}

const wrapObservable = function (object, keys = Object.keys(object)) {
  const proxy = keys.reduce((accumulator, element) => {
    accumulator[element] = redefine(object, element)
    return accumulator
  }, {})

  extendObservable(object, proxy)
}

module.exports = wrapObservable
